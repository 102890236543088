import * as React from 'react';
import { dsState, IDataset } from '../../../components/dataset/IDataset';
import { IBaseView } from '../base-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';
import { IconButton } from './icon-button';
import { UncontrolledTooltip } from 'reactstrap';

interface ITableEditMenu extends IBaseView {
    ds: IDataset<any>;
    hidden: boolean;
}

export const TableEditMenu = inject('rootStore')(
    observer(({ ds, hidden, rootStore }: ITableEditMenu) => {
        const {
            langStore: { lang },
        } = rootStore;
        if (hidden) {
            return null;
        } else {
            return (
                <>
                    {ds.state === dsState.dsEdit ? (
                        <div>
                            <IconButton
                                id="save"
                                onClick={async () => {
                                    await ds.post();
                                }}
                            >
                                <FontAwesomeIcon icon={['fal', 'check']} />
                            </IconButton>
                            <UncontrolledTooltip placement="bottom-end" target="save">
                                {lang.SAVE}
                            </UncontrolledTooltip>

                            <IconButton
                                id="cancel"
                                onClick={async () => {
                                    await ds.cancelAsync();
                                    // wg UweWindt/squid#508
                                    await ds.refresh(ds.cursor);
                                }}
                            >
                                <FontAwesomeIcon icon={['fal', 'times']} />
                            </IconButton>
                            <UncontrolledTooltip placement="bottom-end" target="cancel">
                                {lang.CANCEL}
                            </UncontrolledTooltip>
                        </div>
                    ) : /*ds.actual &&*/ ds.state === dsState.dsBrowse ? (
                        <>
                            <IconButton
                                id="edit"
                                onClick={() => {
                                    ds.edit();
                                }}
                            >
                                <FontAwesomeIcon icon={['fal', 'pen']} />
                            </IconButton>
                            <UncontrolledTooltip placement="bottom-end" target="edit">
                                {lang.EDIT}
                            </UncontrolledTooltip>
                        </>
                    ) : null}
                </>
            );
        }
    }),
);
