import { IField } from '../components/dataset/IField';
import { ActionImageRenderer } from './cell-renderers/ActionImageRenderer';

export interface IActionImage {
    no: string;
    imageno: number;
    image: string;
}

export const actionImageColumns: IField<IActionImage>[] = [
    {
        fieldName: 'no',
        dataType: 'number',
        label: 'ACTION_NO',
        primaryKey: true,
        rules: ['pk'],
        defaultValue: -1,
        insert: 'hide',
        grid: {
            width: 60,
            suppressSizeToFit: true,
        },
    },
    {
        fieldName: 'imageno',
        label: '',
        dataType: 'number',
        primaryKey: true,
        rules: ['pk'],
        grid: {
            width: 40,
        },
        hide: 'table',
    },
    {
        fieldName: 'image',
        dataType: 'string',
        label: 'IMAGE',
        insert: 'show',
        grid: {
            cellRenderer: ActionImageRenderer,
            flex: 2,
        },
    },
];
