import React from 'react';
import styled from 'styled-components';
import { cl_green, cl_red } from '../../views/_theme/colors';

export const posNegBg = (st: string) => {
    if (st === 'positive') {
        return cl_green;
    }
    if (st === 'negative') {
        return cl_red;
    }
    return 'white';
};

export class _PosNegRenderer extends React.Component<any, {}> {
    render() {
        const { className, value } = this.props;
        return <span className={className}>{value}</span>;
    }
}

export const PosNegRenderer = styled(_PosNegRenderer)`
    color: white;
    background-color: ${(props) => posNegBg(props.value)};
    padding: ${(props) => (props.value ? '1px 5px' : 0)};
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    line-height: 16px;
    text-align: center;
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin-top: 4px;
`;
