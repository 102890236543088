import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../_base/base-view';
import { DetailOneColLayout } from '../../_base/styled/layout/detail-one-col-layout';
import { TableForm } from '../../_base/styled/table-form';

@inject('rootStore')
@observer
export class CatalogEntryView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                catalogStore: { dsDetail },
            },
        } = this.props;
        return <DetailOneColLayout topElement={<TableForm icon="question" title="CATALOG_ENTRY" ds={dsDetail} />} />;
    }
}
