import * as React from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { IBaseView } from '../base-view';
import { darken } from 'polished';
import { cl_gray200 } from '../../_theme/colors';

@inject('rootStore')
@observer
class _BaseCardRightHeaderMenu extends React.Component<IBaseView> {
    @observable
    isOpen: boolean = false;

    @action.bound
    toggle() {
        this.isOpen = !this.isOpen;
    }

    render() {
        const { children, className } = this.props;
        return (
            <ButtonDropdown group={false} isOpen={this.isOpen} toggle={this.toggle}>
                <DropdownToggle className={className}>
                    <FontAwesomeIcon icon="ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu>{children}</DropdownMenu>
            </ButtonDropdown>
        );
    }
}

export const BaseCardRightHeaderMenu = styled(_BaseCardRightHeaderMenu)`
    flex: 1 1 auto;
    display: inline-block;
    height: 2rem;
    width: 2rem;
    margin-top: -9px;
    margin-bottom: -6px;
    padding: 0;
    border: 0;
    text-align: center;
    vertical-align: middle;
    background-color: ${cl_gray200};
    color: black;
    border-radius: 1rem;
    float: right;
    outline: none;

    button {
        :hover {
            background-color: ${(props) => darken(0.1, cl_gray200)};
            color: black;
        }

        :disabled {
            background-color: ${(props) => props.theme.cardHeaderButtonBg};
        }

        :focus {
            background-color: ${(props) => props.theme.cardHeaderButtonBg};
            outline: none;
        }

        .active {
            background-color: ${(props) => props.theme.cardHeaderButtonBg};
            outline: none;
        }
    }
`;
