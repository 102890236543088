import { inject, observer } from 'mobx-react';
import { Col, FormFeedback, FormGroup, Input, InputGroup } from 'reactstrap';
import { FieldSelectdlgButton } from './field-selectdlg-button';
import * as React from 'react';
import { IFieldProps } from '../i-db-field-props';
import { ILangKeys } from '../../../language/ILang';
import { FieldLabel } from '../field-label';

@inject('rootStore')
@observer
export class FieldSelectdlg extends React.Component<IFieldProps<any>, {}> {
    renderInput = () => {
        const { column, actual, error, readOnly } = this.props;

        const inputStyle = !readOnly ? { backgroundColor: 'white' } : {};
        return (
            <>
                <InputGroup>
                    <Input
                        id={column.fieldName as string}
                        name={column.fieldName as string}
                        type="text"
                        disabled={true}
                        autoComplete="off"
                        value={actual ? (actual[column.fieldName] !== null ? actual[column.fieldName] : '') : ''}
                        bsSize="sm"
                        invalid={!!error}
                        style={inputStyle}
                        onChange={() => {}} // Damit Warning aus der Console verschwindet (uncontrolled / controlled)
                    />
                    {!readOnly ? (
                        /* <InputGroupAddon addonType="append">*/
                        <FieldSelectdlgButton actual={actual} column={column} readOnly={readOnly} />
                    ) : /*  </InputGroupAddon>*/ null}
                    {error ? <FormFeedback>{error}</FormFeedback> : null}
                </InputGroup>
            </>
        );
    };

    render() {
        const {
            rootStore: {
                langStore: { lang },
            },
            column,
            row,
            onClickLabel,
        } = this.props;
        const label: string = lang[column.label as ILangKeys] ? lang[column.label as ILangKeys] : (column.fieldName as string);

        return (
            <FormGroup row={row}>
                <FieldLabel row={row} label={label} bold={column.primaryKey} onClick={onClickLabel} />
                {row ? <Col sm={9}> {this.renderInput()}</Col> : this.renderInput()}
            </FormGroup>
        );
    }
}
