import * as React from 'react';
import { IFieldProps } from '../i-db-field-props';
import { ILangKeys } from '../../../language/ILang';
import { Col, FormFeedback, FormGroup, Input, InputGroup } from 'reactstrap';
import { FieldLabel } from '../field-label';
import { inject, observer } from 'mobx-react';
import { FieldCatalogBtn } from './field-catalog-btn';

@inject('rootStore')
@observer
export class FieldCatalog extends React.Component<IFieldProps<any>, {}> {
    renderInput = () => {
        const {
            rootStore: {
                langStore: { lang },
            },
            column,
            actual,
            error,
            readOnly,
            onChanged,
            optionsFunc,
        } = this.props;

        const inputStyle = !readOnly ? { backgroundColor: 'white' } : {};
        return (
            <>
                <InputGroup>
                    <Input
                        id={column.fieldName as string}
                        name={column.fieldName as string}
                        type="text"
                        disabled={true}
                        autoComplete="off"
                        value={actual ? (actual[column.fieldName] !== null ? lang[column.textFunc(actual[column.fieldName])] : '') : ''}
                        bsSize="sm"
                        invalid={!!error}
                        style={inputStyle}
                    />
                    {!readOnly ? (
                        /* <InputGroupAddon addonType="append">*/
                        <FieldCatalogBtn actual={actual} column={column} readOnly={readOnly} onChanged={onChanged} optionsFunc={optionsFunc} />
                    ) : /*</InputGroupAddon>*/ null}
                    {error ? <FormFeedback>{error}</FormFeedback> : null}
                </InputGroup>
            </>
        );
    };

    render() {
        const {
            rootStore: {
                langStore: { lang },
            },
            column,
            row,
            onClickLabel,
        } = this.props;
        const label: string = lang[column.label as ILangKeys] ? lang[column.label as ILangKeys] : (column.fieldName as string);

        return (
            <FormGroup row={row}>
                <FieldLabel row={row} label={label} bold={column.primaryKey} onClick={onClickLabel} />
                {row ? <Col sm={9}> {this.renderInput()}</Col> : this.renderInput()}
            </FormGroup>
        );
    }
}
