import React from 'react';
import ReactDOM from 'react-dom';
// Supress mobx-warning part1
//import {observerBatching} from 'mobx-react-lite';

import './scss/styles.scss';
import { App } from './views/_layout/app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { configure } from 'mobx';

// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';

library.add(fad, fas, fal, far);

// Enable strict mode for MobX. This disallows state changes outside of an action

configure({ enforceActions: 'observed' });
// Supress mobx-warning part2
//observerBatching(customBatchedUpdates);

ReactDOM.render(
    /*
    ausgeklammert wg. reactstrap #1883
    9.0.1: Problem noch existent
    */
    /* <React.StrictMode>*/
    <App />,
    /*</React.StrictMode>*/
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
