import { BaseStore, IBaseStore } from './base-store';
import { configColumns, IConfig } from '../models/Config';
import { action } from 'mobx';
import { RouterState } from 'mobx-state-router';
import { dsState } from '../components/dataset/IDataset';

export interface IConfigStore extends IBaseStore<IConfig> {}

export class ConfigStore extends BaseStore<IConfig> implements IConfigStore {
    constructor(rootStore: any) {
        super(rootStore, '/gridApi/config/1', configColumns);
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        await this.open();
        this.afterOpen(fromState, toState);
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        await this.beforeClose(fromState, toState);
        if (this.rootStore.authStore.username !== '') {
            if (this.ds.state === dsState.dsEdit) {
                await this.ds.post();
            }
        }
    }
}
