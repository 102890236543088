import { Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Sticker } from '../../../_base/styled/sticker';
import { statusColor, statusText } from '../../../../models/Status';
import moment from 'moment/moment';
import * as React from 'react';
import { IActivity } from '../../../../models/Activity';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../../_base/base-view';

interface IMobileGembaListCard extends IBaseView {
    activity: IActivity;
}

export const MobileGembaListCard = inject('rootStore')(
    observer(({ rootStore, activity }: IMobileGembaListCard) => {
        const {
            routerStore,
            langStore: { lang },
        } = rootStore;

        return (
            <Card
                className={'card-mobile'}
                onClick={() => {
                    routerStore.goTo('mobilegemba', { gembano: activity.link });
                }}
            >
                <div className="header">
                    <div className={'left'}>
                        <FontAwesomeIcon icon={['fad', 'walking']} />
                        <div style={{ paddingLeft: '0.5rem' }}>{activity.link + ' - ' + activity.description1}</div>
                    </div>

                    <Sticker color={statusColor(activity.status)}>{lang[statusText(activity.status)]}</Sticker>
                </div>
                <div className={'body'}>
                    <div className={'left'}>
                        <div>{activity.projectno + ' - ' + activity.project}</div>
                        <div className={'responsible'}>
                            <FontAwesomeIcon icon={['fad', 'user']} />
                            <div style={{ paddingLeft: '0.5rem' }}>{activity.responsible}</div>
                        </div>
                        <div className={'responsible'}>
                            <FontAwesomeIcon icon={['fad', 'calendar-days']} />
                            <div style={{ paddingLeft: '0.5rem' }}>{moment(activity.duedate).format('YYYY-MM-DD')}</div>
                        </div>
                    </div>
                    {/*<div className={"right"}>
                        <div className={"findings"}>{activity.findings}</div>
                    </div>*/}
                </div>
            </Card>
        );
    }),
);
