import styled from 'styled-components';
import * as React from 'react';
import { IBaseView } from '../base-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DbGrid } from '../../../components/dbgrid/db-grid';
import { IDataset } from '../../../components/dataset/IDataset';
import { inject, observer } from 'mobx-react';
import { ILangKeys } from '../../../language/ILang';
import { IRouteNames } from '../../../routes/route-names';
import { TableGridMenu } from './table-grid-menu';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { IGroupDef } from '../../../components/dataset/groupDef';

interface ITableGrid extends IBaseView {
    id: string;
    icon: IconName;
    title: ILangKeys;
    ds: IDataset<any>;
    groupDef?: IGroupDef[];
    singleRouteName?: IRouteNames;
    onRowDoubleClicked?: any;
    menu?: JSX.Element;
    filterFields?: string[];
    height?: number;
    disabled?: boolean;
    showBackButton?: boolean;
    hideInsert?: boolean;
    hideDelete?: boolean;
    rightHeaderMenu?: JSX.Element;
}

@inject('rootStore')
@observer
class _TableGrid extends React.Component<ITableGrid, {}> {
    gridApi: any;

    onGridReady = (params: any) => {
        this.gridApi = params.api;
        // Todo: horizontaler Scrollbar wird sichtbar.
        //this.gridApi.sizeColumnsToFit();
    };

    onDefaultRowDoubleClicked = () => {
        let {
            rootStore: { routerStore },
            ds,
            singleRouteName,
        } = this.props;
        if (singleRouteName) {
            routerStore.goTo(singleRouteName, ds.pkValues);
        }
    };

    render() {
        const {
            rootStore: {
                langStore: { lang },
            },
            className,
            ds,
            groupDef,
            title,
            onRowDoubleClicked,
            menu,
            rightHeaderMenu,
            hideInsert,
            hideDelete,
            icon,
        } = this.props;
        return (
            <div className={className}>
                <div className="header">
                    <div className="left">
                        <FontAwesomeIcon icon={['fad', icon]} /> &nbsp;&nbsp;&nbsp;
                        {lang[title]}
                    </div>
                    {/*<div className="right">
                        {menu ? menu :
                            <TableGridMenu ds={ds} id="xxx" rightHeaderMenu={rightHeaderMenu} hideInsert={hideInsert}
                                           hideDelete={hideDelete}/>}
                    </div>*/}
                </div>
                <div className="menu">
                    {menu ? (
                        menu
                    ) : (
                        <TableGridMenu ds={ds} id="xxx" rightHeaderMenu={rightHeaderMenu} hideInsert={hideInsert} hideDelete={hideDelete} />
                    )}
                </div>

                <div className="t-body">
                    <DbGrid
                        ds={ds}
                        groupDef={groupDef}
                        onGridReady={this.onGridReady}
                        onRowDoubleClicked={onRowDoubleClicked ? onRowDoubleClicked : this.onDefaultRowDoubleClicked}
                    />
                </div>
            </div>
        );
    }
}

export const TableGrid = styled(_TableGrid)`
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    color: ${(props) => props.theme.color};
    background-color: ${(props) => props.theme.bg};
    border-radius: ${(props) => props.theme.borderRadius};
    border: ${(props) => props.theme.border};
    box-shadow: ${(props) => props.theme.boxShadow};

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${(props) => props.theme.box.header.bg};
        color: ${(props) => props.theme.color};
        border-bottom: ${(props) => props.theme.border};
        //
        padding: ${(props) => props.theme.box.header.padding};
        font-size: ${(props) => props.theme.box.header.fontSize};
        font-weight: ${(props) => props.theme.box.header.fontWeight};

        .left {
        }

        .right {
            font-weight: 100;
        }
    }

    .menu {
        display: flex;
        flex-direction: row;
        flex: ${(props) => props.theme.box.menu.flex};
        border-bottom: ${(props) => props.theme.border};
        padding: ${(props) => props.theme.box.menu.padding};
        justify-content: ${(props) => props.theme.box.menu.justifyContent};
    }

    .t-body {
        flex: 1 1 auto;
        padding: 0;

        .ag-root-wrapper {
            border: 0;
        }
    }
`;
