import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '../../../_base/styled/icon-button';
import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { observer, inject } from 'mobx-react';
import { action, observable, runInAction } from 'mobx';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import { Dialog } from '../../../../components/dialog/Dialog';
import { DbGrid } from '../../../../components/dbgrid/db-grid';

@inject('rootStore')
@observer
export class _ProjectMemberExtraMenu extends React.Component<IBaseView, {}> {
    @observable
    isOpen: boolean = false;

    @action.bound
    toggle() {
        this.isOpen = !this.isOpen;
    }

    @action.bound
    async importMemberFromProject() {
        const {
            rootStore: {
                projectStore: { dsExtraProject },
            },
        } = this.props;
        await dsExtraProject.open();
        runInAction(() => {
            this.isDialogOpen = true;
        });
    }

    @observable
    isDialogOpen: boolean = false;

    @action.bound
    async onOk() {
        const {
            rootStore: {
                projectStore: { importMemberfromProject, dsExtraProject },
            },
        } = this.props;
        if (dsExtraProject.actual?.projectno) {
            await importMemberfromProject(dsExtraProject.actual?.projectno);
            runInAction(() => {
                this.isDialogOpen = false;
                dsExtraProject.close();
            });
        }
    }

    @action.bound
    onCancel() {
        const {
            rootStore: {
                projectStore: { dsExtraProject },
            },
        } = this.props;
        this.isDialogOpen = false;
        dsExtraProject.close();
    }

    render() {
        const {
            rootStore: {
                projectStore: { dsExtraProject },
                langStore: { lang },
            },
            className,
        } = this.props;
        return (
            <>
                <Dropdown className={className} isOpen={this.isOpen} toggle={this.toggle} direction="down">
                    <DropdownToggle tag={'div'}>
                        <IconButton>
                            <FontAwesomeIcon icon={'ellipsis-vertical'} />
                        </IconButton>
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                        <DropdownItem className={'item'} onClick={this.importMemberFromProject}>
                            {lang['PROJECT_IMPORT_PROJECTMEMBER']}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <Dialog title={lang['PROJECT_IMPORT_PROJECTMEMBER']} isOpen={this.isDialogOpen} onOk={this.onOk} onCancel={this.onCancel}>
                    <div style={{ height: '300px' }}>
                        <DbGrid ds={dsExtraProject} />
                    </div>
                </Dialog>
            </>
        );
    }
}

export const ProjectMemberExtraMenu = styled(_ProjectMemberExtraMenu)`
    display: inline-block;

    .item {
        color: ${(props) => props.theme.box.header.color};
    }
`;
