export type IFieldName =
    | 'docname'
    | 'revision'
    | 'revision_date'
    | 'responsible'
    | 'responsible_function'
    | 'responsible_company'
    | 'internal'
    | 'external'
    | 'sup_prod_loc_name'
    | 'net_weight'
    | 'package_size'
    | 'palletized'
    | 'package_material'
    | 'po_number_delivery'
    | 'delivery_date'
    | 'sample_type'
    | 'customer_approver'
    | 'customer_approver_function'
    | 'customer_approver_company';

type IField = {
    [K in IFieldName]: string;
};

export const FieldText: IField = {
    docname: 'Document Name',
    revision: 'Revision',
    revision_date: 'Revision Date',
    responsible: 'Document Responsible',
    responsible_function: 'Responsible Function',
    responsible_company: 'Responsible Company',
    internal: 'Internal Laboratory Name',
    external: 'External Laboratory Name',
    sup_prod_loc_name: 'Supplier Production Location',
    net_weight: 'Package Weight',
    package_size: 'Package Size',
    palletized: 'Palletized/Not Palletized',
    package_material: 'Package Material',
    po_number_delivery: 'PO Number (Delivery)',
    delivery_date: 'Delivery Date',
    sample_type: 'Sample Type',
    customer_approver: 'Customer Approver',
    customer_approver_function: 'Customer Approver Function',
    customer_approver_company: 'Customer Approver Company',
};

interface IUploadReportConfiguration {
    headLine: string;
    fieldList: IFieldName[];
    mandatoryList: IFieldName[];
    responsible?: boolean;
    participating?: boolean;
}

export const uploadReportConfiguration = (elementno: number): IUploadReportConfiguration => {
    switch (elementno) {
        case 2:
            return {
                headLine: 'Team Feasibility Commitment',
                fieldList: [],
                mandatoryList: [],
            };
        case 3:
            return {
                headLine: 'D-FMEA',
                fieldList: ['docname', 'revision', 'revision_date'],
                mandatoryList: ['docname', 'revision', 'revision_date'],
                responsible: true,
                participating: true,
            };
        case 4:
            return {
                headLine: 'Part History',
                fieldList: ['docname', 'revision', 'revision_date'],
                mandatoryList: ['docname', 'revision', 'revision_date'],
            };
        case 5:
            return {
                headLine: 'Customer Engineering Approval',
                fieldList: [],
                mandatoryList: [],
            };
        case 6:
            return {
                headLine: 'Process Flow Chart',
                fieldList: ['docname', 'revision', 'revision_date', 'sup_prod_loc_name'],
                mandatoryList: ['docname', 'revision', 'revision_date', 'sup_prod_loc_name'],
                responsible: true,
            };
        case 7:
            return {
                headLine: 'Factory Floor Plan',
                fieldList: ['docname', 'revision', 'revision_date', 'sup_prod_loc_name'],
                mandatoryList: ['docname', 'revision', 'revision_date', 'sup_prod_loc_name'],
                responsible: true,
            };
        case 8:
            return {
                headLine: 'P-FMEA',
                fieldList: ['docname', 'revision', 'revision_date', 'sup_prod_loc_name'],
                mandatoryList: ['docname', 'revision', 'revision_date', 'sup_prod_loc_name'],
                responsible: true,
                participating: true,
            };
        case 9:
            return {
                headLine: 'Control Plan',
                fieldList: ['docname', 'revision', 'revision_date', 'sup_prod_loc_name'],
                mandatoryList: ['docname', 'revision', 'revision_date', 'sup_prod_loc_name'],
                responsible: true,
                participating: true,
            };
        case 10:
            return {
                headLine: 'Process Capability Studie',
                fieldList: ['docname', 'sup_prod_loc_name'],
                mandatoryList: ['docname', 'sup_prod_loc_name'],
                responsible: true,
            };
        case 11:
            return {
                headLine: 'Performance Test Report',
                fieldList: ['docname', 'internal', 'external'],
                mandatoryList: ['docname'],
                responsible: true,
            };
        case 12:
            return {
                headLine: 'Material Test Report',
                fieldList: ['docname', 'internal', 'external'],
                mandatoryList: ['docname'],
                responsible: true,
            };
        case 13:
            return {
                headLine: 'Dimensional Test Report',
                fieldList: ['docname', 'internal', 'external'],
                mandatoryList: ['docname'],
                responsible: true,
            };
        case 14:
            return {
                headLine: 'Appearance Approval',
                fieldList: ['docname', 'internal', 'external'],
                mandatoryList: ['docname'],
                responsible: true,
            };
        case 15:
            return {
                headLine: 'Measurement System Analysis',
                fieldList: ['docname', 'sup_prod_loc_name'],
                mandatoryList: ['docname', 'sup_prod_loc_name'],
                responsible: true,
            };
        case 16:
            return {
                headLine: 'Packaging Test Report',
                fieldList: ['docname', 'net_weight', 'package_size', 'palletized', 'package_material'],
                mandatoryList: ['docname'],
                responsible: true,
            };
        case 17:
            return {
                headLine: 'Product and Master Sample',
                fieldList: ['docname', 'sup_prod_loc_name', 'po_number_delivery', 'delivery_date', 'sample_type'],
                mandatoryList: ['docname', 'sup_prod_loc_name'],
                responsible: true,
            };
        case 18:
            return {
                headLine: 'Part Submission Warrant',
                fieldList: [],
                mandatoryList: [],
            };
        case 19:
            return {
                headLine: 'PSW Deviation Sheet',
                fieldList: ['docname'],
                mandatoryList: ['docname'],
                responsible: true,
            };
        case 20:
            return {
                headLine: 'Material Safety Data Sheet',
                fieldList: ['docname', 'revision', 'revision_date'],
                mandatoryList: ['docname'],
                responsible: true,
            };
        case 21:
            return {
                headLine: 'Form, Fit and Function',
                fieldList: ['docname', 'po_number_delivery', 'delivery_date', 'sample_type'],
                mandatoryList: ['docname'],
                responsible: true,
            };
        case 22:
            return {
                headLine: 'Qualified Laboratory Documentation',
                fieldList: ['docname'],
                mandatoryList: ['docname'],
                responsible: true,
            };
        case 23:
            return {
                headLine: 'List of checking Aids',
                fieldList: ['docname', 'sup_prod_loc_name'],
                mandatoryList: ['docname', 'sup_prod_loc_name'],
                responsible: true,
            };
        case 24:
            return {
                headLine: 'Sub Supplier Source Details',
                fieldList: ['docname', 'sup_prod_loc_name'],
                mandatoryList: ['docname', 'sup_prod_loc_name'],
                responsible: true,
            };
    }
};
