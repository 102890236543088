import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../base-view';
import styled from 'styled-components';

interface IBoxWithTree extends IBaseView {
    header: JSX.Element;
    menu: JSX.Element;
    body?: JSX.Element;
}

@inject('rootStore')
@observer
class _BoxWithTree extends React.Component<IBoxWithTree, {}> {
    render() {
        const { className, header, menu, body } = this.props;
        return (
            <div className={className}>
                <div className="header">{header}</div>
                <div className="menu">{menu}</div>
                <div className="cardbody">{body}</div>
            </div>
        );
    }
}

export const BoxWithTree = styled(_BoxWithTree)`
    display: flex;
    flex-direction: column;
    color: black;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: ${(props) => props.theme.borderRadius};
    border: ${(props) => props.theme.border};
    box-shadow: ${(props) => props.theme.boxShadow};
    background-color: ${(props) => props.theme.bg};

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${(props) => props.theme.color};
        border-bottom: ${(props) => props.theme.border};
        //
        padding: ${(props) => props.theme.box.header.padding};
        font-size: ${(props) => props.theme.box.header.fontSize};
        font-weight: ${(props) => props.theme.box.header.fontWeight};
        background-color: ${(props) => props.theme.box.header.backgroundColor};

        .left {
            flex: 1 1 auto;
            font-weight: 500;
            color: ${(props) => props.theme.color};
        }

        .right {
            display: block;
            float: right;
            flex: 0 0 auto;
            font-weight: 100;
            color: ${(props) => props.theme.color};
        }
    }

    .menu {
        display: flex;
        flex-direction: row;
        flex: ${(props) => props.theme.box.menu.flex};
        border-bottom: ${(props) => props.theme.border};
        padding: ${(props) => props.theme.box.menu.padding};
        justify-content: ${(props) => props.theme.box.menu.justifyContent};
    }

    .cardbody {
        flex: 1 1 auto;
        display: block;
        position: relative;
        background-color: white;
        color: ${(props) => props.theme.color};
    }
`;
