import * as React from 'react';
import { IFieldProps } from '../i-db-field-props';
import { inject, observer } from 'mobx-react';
import { FormGroup, Input, Label } from 'reactstrap';
import { FieldLabel } from '../field-label';
import { action } from 'mobx';
import { includesN } from '../../lib/Includes';

interface IFieldSelectRadio<T> extends IFieldProps<T> {
    disabledOptions?: number[];
}

@inject('rootStore')
@observer
export class FieldSelectRadio extends React.Component<IFieldSelectRadio<any>, {}> {
    render() {
        const {
            rootStore: {
                langStore: { lang },
            },
            actual,
            column,
            optionsFunc,
            disabledOptions,
        } = this.props;
        const label: string = lang[column.label] ? lang[column.label] : (column.fieldName as string);
        const options = optionsFunc ? optionsFunc() : column.options;
        return (
            <FormGroup tag="fieldset">
                <FieldLabel row={false} label={label} bold={column.primaryKey} />
                {options.map((option: number, i) => {
                    return actual ? (
                        <FormGroup check key={i}>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="radio1"
                                    value={option}
                                    disabled={disabledOptions ? includesN(disabledOptions, option) : false}
                                    checked={actual ? option === actual[column.fieldName] : false}
                                    onChange={action(() => {
                                        actual[column.fieldName] = option;
                                    })}
                                />
                                &nbsp;&nbsp;
                                {lang[column.textFunc(option)]}
                            </Label>
                        </FormGroup>
                    ) : null;
                })}
            </FormGroup>
        );
    }
}
