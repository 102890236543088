import * as React from 'react';
import styled from 'styled-components';
import { IBaseView } from '../../../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { action } from 'mobx';
import { auditScoreColors, auditScoreText, IAreaNode } from '../../../../../stores/audit-online-store';
import { IAuditQuestion } from '../../../../../models/AuditQuestion';
import { IDataset } from '../../../../../components/dataset/IDataset';
import { IRootStore } from '../../../../../routes/root-store';
import { cl_blue, cl_gray600, cl_green, cl_red, cl_white, cl_yellow } from '../../../../_theme/colors';
import { ScoreBadge2 } from '../../../rating/rating-score-badge';

interface IQuestion {
    question: IAuditQuestion;
    active: boolean;
    rootStore?: IRootStore;
}

/*const Score = ({text, score}: any) => {
    console.log(text)
    switch (score) {
        case AuditScore.NOT_APPLICABLE:
            return <div className="scoretext gray">{text}</div>
        case AuditScore.UNRATED:
            return <div className="scoretext white">{text}</div>
        case AuditScore.NO_COMPLIANCE:
            return <div className="scoretext red">{text}</div>
        case AuditScore.UNSATISFACTORY_COMPLIANCE:
            return <div className="scoretext red">{text}</div>
        case AuditScore.PARTIAL_COMPLIANCE:
            return <div className="scoretext yellow">{text}</div>
        case AuditScore.PREDOMINANT_COMPLIANCE:
            return <div className="scoretext green">{text}</div>
        case AuditScore.FULL_COMPLIANCE:
            return <div className="scoretext green">{text}</div>

        case 3.5:
            return <div className="scoretext red">{text}</div>
        case 7:
            return <div className="scoretext green">{text}</div>
        case 8.5:
            return <div className="scoretext green">{text}</div>
        case 10:
            return <div className="scoretext green">{text}</div>
        default:
            return <div className="scoretext white">{text}</div>
    }

}*/

const Question = inject('rootStore')(
    observer(({ question, active, rootStore }: IQuestion) => {
        const {
            auditStore: { ds },
        } = rootStore;
        return (
            <>
                <div className={active ? 'title active' : 'title'}>
                    {question.attentionflag ? (
                        <FontAwesomeIcon color="red" size="1x" icon={['fas', 'exclamation-triangle']} />
                    ) : (
                        <FontAwesomeIcon size="1x" icon={['fas', 'question-circle']} />
                    )}
                    &nbsp;&nbsp;
                    {question.question}
                </div>
                <div className={active ? 'score active' : 'score'}>
                    <ScoreBadge2
                        text={auditScoreText(ds.actual?.ratingmethod, question.score)}
                        color={auditScoreColors(ds.actual?.ratingmethod, question.score).bg}
                    />
                </div>
            </>
        );
    }),
);

interface IElement {
    area: IAreaNode;
    questElement: string;
    toggle: (element: string) => any;
    dsAuditQuestion: IDataset<IAuditQuestion>;
}

const Element = observer(({ area, questElement, toggle, dsAuditQuestion }: IElement) => (
    <ul className="level_2">
        {area.children.map((element, index) => (
            <li key={index} onClick={toggle(element.element)}>
                <div className="title">
                    <FontAwesomeIcon icon={['fad', 'folder-open']} />
                    &nbsp;&nbsp;
                    <span className={element.element === questElement ? 'active' : ''}>{element.element}</span>
                </div>
                <ul className="level_3">
                    {element.children.map((question, index) => (
                        <li
                            key={index}
                            onClick={() => {
                                dsAuditQuestion.locate(['questionno'], { questionno: question.questionno });
                            }}
                        >
                            <Question question={question} active={question.questionno === dsAuditQuestion.actual?.questionno} />
                        </li>
                    ))}
                </ul>
            </li>
        ))}
    </ul>
));

@inject('rootStore')
@observer
class _AuditDetailTree extends React.Component<IBaseView, {}> {
    @action.bound
    toggleArea(area: string) {
        return (e: any) => {
            e.stopPropagation();
            let {
                rootStore: {
                    auditStore: { setQuestArea },
                },
            } = this.props;
            setQuestArea(area);
        };
    }

    @action.bound
    toggleElement(element: string) {
        return (e: any) => {
            e.stopPropagation();
            let {
                rootStore: {
                    auditStore: { setQuestElement },
                },
            } = this.props;
            setQuestElement(element);
        };
    }

    renderAreas = () => {
        const {
            rootStore: {
                auditStore: { questionaere, questArea, questElement, dsAuditQuestion },
            },
        } = this.props;
        return (
            <ul className="level_1">
                {questionaere.map((area, index) => (
                    <li key={'l1' + index}>
                        <div className="title">
                            <FontAwesomeIcon icon={['fad', 'folder-open']} />
                            &nbsp;&nbsp;
                            <span className={area.area === questArea ? 'active' : ''}>{area.area}</span>
                        </div>
                        <Element area={area} questElement={questElement} toggle={this.toggleElement} dsAuditQuestion={dsAuditQuestion} />
                    </li>
                ))}
            </ul>
        );
    };

    render() {
        const { className } = this.props;
        return <div className={className}>{this.renderAreas()}</div>;
    }
}

export const AuditDetailTree = styled(_AuditDetailTree)`
  display: block;
  position: absolute;
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin-bottom: -1px;
  overflow-y: auto;
  color: ${(props) => props.theme.color};

  .level_1 {
    display: block;
    float: left;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    list-style-type: none;
    margin: 0;

    li {
      padding: 0;

      .title {
        display: block;
        float: left;
        width: 100%;
        box-sizing: border-box;
        padding: 0.25rem 0.75rem;
        border-bottom: ${(props) => props.theme.border};
        cursor: pointer;
        flex: 0 1 100%;

        .active {
          color: ${cl_blue};
          font-weight: 700;
        }

      }


    }
  }


  .level_2 {
    display: block;
    float: left;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    list-style-type: none;
    margin: 0;

    li {
      padding: 0;

      .title {
        display: block;
        float: left;
        width: 100%;
        box-sizing: border-box;
        padding: 0.25rem 2.0rem;
        border-bottom: ${(props) => props.theme.border};
        cursor: pointer;
        flex: 1 1 100%;

        .active {
          color: ${cl_blue}
          font-weight: 700;
        }
      }


    }
  }

  .level_3 {
    display: block;
    float: left;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    list-style-type: none;
    margin: 0;

    li {
      padding: 0;
      display: flex;
      flex-direction: row;


      .title {
        display: block;
        float: left;
        width: 100%;
        box-sizing: border-box;
        padding: 0.25rem 3.5rem;
        border-bottom: ${(props) => props.theme.border};
        cursor: pointer;
        flex: 1 1 auto;
      }

      .score {
        display: flex;
        flex: 0 0 3rem;
        box-sizing: border-box;
        border-bottom: ${(props) => props.theme.border};
        align-items: center;
        justify-content: center;
        padding-right:0.5rem;


        .scoretext {
          background-color: ${cl_red};
          width: 80%;
          border-radius: ${(props) => props.theme.borderRadius};
          text-align: center;
          color: white;
          font-weight: 500;
          line-height: 1.2;


        }

        .green {
          background-color: ${cl_green};
        }

        .yellow {
          background-color: ${cl_yellow};
        }

        .red {
          background-color: ${cl_red};
        }

        .gray {
          background-color: ${cl_gray600};
        }

        .white {
          background-color: ${cl_white};
          color: black;
        }
      }

      .active {
        background-color: ${(props) => props.theme.main.bg};
      }
    }
  }

`;
