import * as React from 'react';
import { statusColor, statusText } from '../Status';
import { rootStore } from '../../views/_layout/app';
import styled from 'styled-components';
import { observer } from 'mobx-react';

@observer
class _StatusRenderer extends React.Component<any, any> {
    render() {
        const {
            langStore: { lang },
        } = rootStore;
        const { className, value } = this.props;
        return <span className={className}>{lang[statusText(value)]}</span>;
    }
}

export const StatusRenderer = styled(_StatusRenderer)`
    color: white;
    background-color: ${(props) => statusColor(props.value)};
    padding: 1px 5px;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    line-height: 16px;
    text-align: center;
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin-top: 4px;
`;
