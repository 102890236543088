import { ILang } from '../language/ILang';
import { Lang_en } from '../language/lang-en';
import { action, observable } from 'mobx';
import { IRootStore } from '../routes/root-store';
import { Lang_de } from '../language/lang-de';

export type Tlang = 'Lang_en' | 'Lang_de';
// en:0
//de:1

export interface ILangStore {
    language: Tlang;
    lang: ILang;
    setLanguage: (language: Tlang) => void;
}

export class LangStore implements ILangStore {
    rootStore: IRootStore;
    @observable lang: ILang = Lang_en;
    @observable language: Tlang;

    constructor(rootStore: IRootStore) {
        this.rootStore = rootStore;
        this.lang = Lang_en;
        this.language = 'Lang_en';
    }

    @action.bound
    setLanguage(language: Tlang) {
        this.language = language;
        switch (language) {
            case 'Lang_en':
                this.lang = Lang_en;
                break;
            case 'Lang_de':
                this.lang = Lang_de;
                break;
            default:
                this.lang = Lang_en;
                this.language = 'Lang_en';
        }
    }
}
