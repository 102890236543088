import { BaseStore, IBaseStore } from './base-store';
import { auditColumns, IAudit } from '../models/Audit';
import { IRootStore } from '../routes/root-store';
import * as R from 'ramda';
import * as localforage from 'localforage';
import { RouterState } from 'mobx-state-router';
import { action, computed, observable, runInAction } from 'mobx';
import { auditQuestionColumns, AuditScore, IAuditQuestion } from '../models/AuditQuestion';
import { auditQuestionImageColumns } from '../models/AuditQuestionImage';
import { IProject } from '../models/Project';
import { create, persist } from 'mobx-persist';
import { IDataset } from '../components/dataset/IDataset';
import { Dataset } from '../components/dataset/dataset';
import { IArea, IAuditImage } from './audit-online-store';
import axios from 'axios';
import { authorizer } from '../components/dataset/authorizer';
import { imageMime } from '../lib/image-mime';
import { resizeImage } from '../lib/resize-image';

localforage.config({
    driver: localforage.WEBSQL, // Force WebSQL; same as using setDriver()
    name: 'squid',
    version: 1.0,
    size: 80980736, // Size of database, in bytes. WebSQL-only for now.
    storeName: 'keyvaluepairs', // Should be alphanumeric, with underscores.
    description: 'some description',
});

const getBase64 = async (url: string): Promise<string> => {
    const response: any = await axios.get(url, { responseType: 'arraybuffer' });
    return Buffer.from(response.data, 'binary').toString('base64');
};

interface IAuditOffline {
    audit?: IAudit;
    auditQuestions?: IAuditQuestion[];
    auditImages?: IAuditImage[];
    project?: IProject;
}

export const hydrate = create({
    storage: localforage,
    jsonify: false,
    debounce: 0,
});

export interface IAuditOfflineStore extends IBaseStore<IAudit> {
    //
    auditOffline: IAuditOffline;
    //
    areas: IArea[];
    actArea: string;
    setArea: (area: string) => void;
    //
    elements: IArea[];
    actElement: string;
    setElement: (element: string) => void;
    nextElement: () => void;
    prevElement: () => void;
    //
    actQuestionno: string;
    setActQuestionno: (questionno: string) => void;
    //
    images: IAuditImage[];
    uploadFile: (acceptFile: any[]) => Promise<void>;
    deleteFile: (imageno: number) => Promise<void>;
    canDeleteFile: boolean;
}

export class AuditOfflineStore extends BaseStore<IAudit> implements IAuditOfflineStore {
    dsDiv: IDataset<any>;

    @persist
    @observable
    auditno: string = '';

    @persist('object')
    @observable
    auditOffline: IAuditOffline = {};

    @action.bound
    async loadfromUrl(url: string, columns: any[], filter: object): Promise<any[]> {
        this.dsDiv.dataUrl = url;
        this.dsDiv.columns = columns;
        this.dsDiv.filter = filter;
        await this.dsDiv.open();
        const result = R.clone(this.dsDiv.data);
        this.dsDiv.close();
        return result;
    }

    @observable
    actQuestionno: string = '';

    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/audit/', R.clone(auditColumns));
        this.dsDiv = new Dataset<any>('', []);
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        const keyFields = R.pick(this.ds.pkFields as string[], toState.params);
        switch (toState.routeName) {
            case 'auditcollectoffline':
                if (fromState.routeName !== 'auditcollectcollectoffline') {
                    await hydrate('offlineAudit', this, {});
                }
                if (this.auditOffline?.audit?.auditno !== keyFields.auditno) {
                    if (this.auditOffline?.audit?.auditno) {
                        await this.saveAudit();
                        await this.loadAudit(keyFields.auditno);
                    } else {
                        await this.loadAudit(keyFields.auditno);
                    }
                }
                await Promise.resolve(); // wichtig sonst crashed es beim goBack. keine Ahnung warum.
                runInAction(() => {
                    if (
                        fromState.routeName !== 'auditcollectcollectoffline' &&
                        this.auditOffline.auditQuestions &&
                        this.auditOffline.auditQuestions[0]
                    ) {
                        this.setArea(this.areas[0].label);
                        this.setElement(this.elements[0].label);
                        this.setActQuestionno(this.auditOffline.auditQuestions[0].questionno);
                    }
                });
                break;

            case 'auditcollectcollectoffline':
                try {
                    if (fromState.routeName !== 'auditcollectoffline') {
                        await hydrate('offlineAudit', this, {});
                    }
                    if (this.auditOffline?.audit?.auditno !== keyFields.auditno) {
                        if (this.auditOffline?.audit?.auditno) {
                            await this.saveAudit();
                            await this.loadAudit(keyFields.auditno);
                        } else {
                            await this.loadAudit(keyFields.auditno);
                            await Promise.resolve(); // wichtig sonst crashed es beim goBack. keine Ahnung warum.
                        }
                    }
                    runInAction(() => {
                        if (
                            fromState.routeName !== 'auditcollectoffline' &&
                            this.auditOffline.auditQuestions &&
                            this.auditOffline.auditQuestions[0]
                        ) {
                            this.setArea(this.auditOffline.auditQuestions[0].area);
                            this.setElement(this.auditOffline.auditQuestions[0].element);
                            this.setActQuestionno(this.auditOffline.auditQuestions[0].questionno);
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
                await Promise.resolve();
                break;
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        switch (fromState.routeName) {
            case 'auditcollectoffline':
                if (toState.routeName !== 'auditcollectcollectoffline') {
                    await this.saveAudit();
                }
                await Promise.resolve();
                break;

            case 'auditcollectcollectoffline':
                await Promise.resolve();
                break;
        }
    }

    @computed
    get images(): IAuditImage[] {
        if (this.auditOffline?.auditImages) {
            return this.auditOffline?.auditImages.filter((image) => image.questionno === this.actQuestionno && image.action !== 'DELETE');
        } else {
            return [];
        }
    }

    /**
     *  Lädt Audit in den Offline Buffer
     */
    @action.bound
    async loadAudit(auditno: string) {
        await this.open({ auditno: auditno });
        runInAction(() => {
            this.auditno = this.ds.actual.auditno;
        });
        runInAction(() => {
            this.close();
        });
        //
        const audits = await this.loadfromUrl('/gridApi/audit/', auditColumns, { auditno: this.auditno });
        this.auditOffline.audit = audits[0];
        //
        const questions = await this.loadfromUrl('/gridApi/auditquestion/', auditQuestionColumns, { auditno: this.auditno });
        this.auditOffline.auditQuestions = questions
            .filter((record) => !record.unselected)
            .sort((a, b) => {
                if (a.question < b.question) {
                    return -1;
                }
                if (a.question > b.question) {
                    return 1;
                }
                return 0;
            });

        // Bild nachladen als data-url
        let auditImages = await this.loadfromUrl('/gridApi/auditquestionimage/', auditQuestionImageColumns, { auditno: this.auditno });
        auditImages = await Promise.all(
            auditImages.map(async (auditImage): Promise<IAuditImage> => {
                auditImage.src = '/gridApi/image/auditquestionimage/' + auditImage.auditno + '/' + auditImage.questionno + '/' + auditImage.image;
                // Nur Bilder anfordern, alles andere bleibt auf dem Server.
                if (imageMime(auditImage.image) !== '') {
                    auditImage.dataUrl = 'data:image/jpeg;base64,' + (await getBase64(auditImage.src));
                } else {
                    auditImage.dataUrl = '';
                    await Promise.resolve();
                }
                auditImage.altText = auditImage.image;
                auditImage.caption = auditImage.image;
                auditImage.action = 'NO';
                return auditImage;
            }),
        );
        this.auditOffline.auditImages = auditImages;
        //
        const project = await this.loadfromUrl('/gridApi/project/', auditQuestionImageColumns, { projectno: this.auditOffline.audit.projectno });
        this.auditOffline.project = project[0];
    }

    /**
     * Speichert das Offline Audit auf den Server
     * und leert den Offline Buffer
     */
    @action.bound
    async saveAudit() {
        //console.log('saving...');

        await axios.post('/gridApi/auditoffline/upload', this.auditOffline, authorizer());

        /*this.auditOffline.auditImages
            .filter((image) => image.action === 'INSERT')
            .forEach((image) => {
                console.log(image.caption);
            })*/

        runInAction(() => {
            this.auditno = '';
            this.auditOffline = {};
        });
        //console.log('saved');
        await Promise.resolve();
    }

    //
    @computed
    // Alle Areas die vorhanden sind.
    get areas(): IArea[] {
        if (this.auditOffline?.auditQuestions) {
            let areas = R.uniq(
                this.auditOffline.auditQuestions.map((question) => {
                    return { label: question.area, completed: true, enabled: true };
                }),
            );

            const filtered = this.auditOffline.auditQuestions.filter((question) => question.score === AuditScore.UNRATED);

            areas.forEach((area) => {
                if (filtered.find((f) => f.area === area.label)) {
                    area.completed = false;
                }
            });

            areas.sort((first, second) => {
                return first.label < second.label ? -1 : first.label > second.label ? 1 : 0;
            });

            return areas;
        } else {
            return [];
        }
    }

    @action.bound
    setArea(area: string) {
        this.actArea = area;
        // dies ist ein Hilfskonstrukt, da das computed der elements-Liste zu diesem zeitpunkt, also Brute-Force
        const myElements = R.uniq(this.auditOffline.auditQuestions.filter((question) => question.area === area).map((data) => data.element));
        if (myElements.length > 0) {
            this.setElement(myElements[0]);
        }
    }

    @observable
    actArea: string = '';

    @computed
    get elements(): IArea[] {
        if (this.auditOffline?.auditQuestions) {
            let elements = R.uniq(
                this.auditOffline.auditQuestions
                    .filter((question) => question.area === this.actArea)
                    .map((question) => {
                        return { label: question.element, completed: true, enabled: true };
                    }),
            );
            const filtered = this.auditOffline.auditQuestions.filter((question) => question.score === AuditScore.UNRATED);

            elements.forEach((element) => {
                if (filtered.find((f) => f.element === element.label)) {
                    element.completed = false;
                }
            });

            return elements;
        } else {
            return [];
        }
    }

    @action.bound
    setElement(element: string) {
        this.actElement = element;
    }

    @observable
    actElement: string = '';

    @action.bound
    nextElement() {
        let i = this.elements.findIndex((element) => element.label === this.actElement);
        if (i === -1) {
            return;
        }
        if (this.elements.length === i + 1) {
            this.nextArea();
            return;
        }
        this.setElement(this.elements[i + 1].label);
    }

    @action.bound
    nextArea() {
        let i = this.areas.findIndex((area) => area.label === this.actArea);
        if (i === -1) {
            return;
        }
        if (i === this.areas.length - 1) {
            return;
        }
        this.setArea(this.areas[i + 1].label);
    }

    @action.bound
    prevElement() {
        let i = this.elements.findIndex((element) => element.label === this.actElement);
        if (i === -1) {
            return;
        }
        if (i === 0) {
            this.prevArea();
            return;
        }
        //console.log('Element',i);
        this.setElement(this.elements[i - 1].label);
    }

    @action.bound
    prevArea() {
        let i = this.areas.findIndex((area) => area.label === this.actArea);
        if (i === -1) {
            return;
        }
        if (i === 0) {
            return;
        }
        //console.log('Area',i);
        this.setArea(this.areas[i - 1].label);
    }

    @action.bound
    setActQuestionno(questionno: string) {
        this.actQuestionno = questionno;
    }

    @action.bound
    async uploadFile(acceptFile: any[]): Promise<void> {
        let file: File = acceptFile[0];
        if (acceptFile[0].type === 'image/jpeg') {
            file = await resizeImage(file);
        }
        let reader = new FileReader();
        reader.onload = () => {
            //console.log(reader.result);
            runInAction(() => {
                this.auditOffline.auditImages.push({
                    auditno: this.auditno,
                    questionno: this.actQuestionno,
                    imageno: -1,
                    image: file.name,
                    src: '/gridApi/image/auditquestionimage/' + this.auditno + '/' + this.actQuestionno + '/' + file.name,
                    // reader liefert das "data:....." prefix schon mit.
                    dataUrl: reader.result as any,
                    altText: file.name,
                    caption: file.name,
                    action: 'INSERT',
                });
            });

            //console.log(this.auditOffline.auditImages)
        };
        await reader.readAsDataURL(file);

        //await Promise.resolve();
    }

    @action.bound
    deleteFile = async (imageno: number): Promise<void> => {
        console.log('imageno=', imageno);
        let auditImage = this.images.find((auditImage) => auditImage.imageno === imageno);
        if (auditImage) {
            auditImage.action = 'DELETE';
        }
        await Promise.resolve();
    };

    /**
     *  canDeleteFile wird durchgereicht an AuditCollectCollectImages
     *  und steuert dort den DeleteButton
     */
    @computed
    get canDeleteFile() {
        return this.images.length > 0;
    }
}
