import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { inject, observer } from 'mobx-react';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import { cl_blue, cl_gray500 } from '../../../_theme/colors';
import { statusColor } from '../../../../models/Status';
import { action } from 'mobx';
import { ProjectActivityTyp } from '../../../../models/ProjectActivity';

interface IGanttValue {
    description1: string;
    startdate: string;
    duedate: string;
    status: number;
    projectno: string;
    activityno: string;
    activitytyp: number;
    link: string;
}

export interface IGantt extends IBaseView {
    from: string;
    to: string;
    data: IGanttValue[];
}

@inject('rootStore')
@observer
export class DashboardGanttChart extends React.Component<IGantt, {}> {
    /**
     *  Gantt Chart übernimmt daten als von bis.
     *  um 1 tägige Activities darstellen zu können (start===due)
     *  wird  bei due 1 tag aufaddiert
     */

    ganttHeight = () => {
        const { data } = this.props;
        return (data.length * 20 + 20).toString() + 'px';
    };

    /**
     * Wennn aktuelles Datum zwischen from und to liegt, anzeigen sonst nicht.
     */
    showAxisPointer = () => {
        const { from, to } = this.props;
        return moment(from).isBefore(moment()) && moment(to).isAfter(moment());
    };

    @action.bound
    onClick(params: any) {
        const {
            rootStore: { routerStore },
        } = this.props;
        switch (params.data.activitytyp) {
            case ProjectActivityTyp.ACTIVITY:
                routerStore.goTo('projectactivity', {
                    projectno: params.data.projectno,
                    activityno: params.data.activityno,
                });
                break;
            case ProjectActivityTyp.AUDIT:
                routerStore.goTo('audit', {
                    auditno: params.data.link,
                });
                break;
            case ProjectActivityTyp.INSPECTION:
                routerStore.goTo('inspection', {
                    inspectionno: params.data.link,
                });
                break;
            case ProjectActivityTyp.PPAP:
                routerStore.goTo('ppaporder', {
                    ppapno: params.data.link,
                });
                break;
            case ProjectActivityTyp.GEMBA_WALK:
                routerStore.goTo('gemba', {
                    gembano: params.data.link,
                });
                break;
        }
    }

    getOption = () => {
        const { from, to, data } = this.props;
        return {
            grid: {
                top: '0',
                left: '160px',
                right: '1%',
                bottom: '20px',
                containLabel: false,
                width: 'auto',
                height: 'auto',
            },
            tooltip: {
                trigger: 'none',
                triggerOn: 'none',
            },
            yAxis: {
                type: 'category',
                data: data.map((record) => record.description1),
                inverse: true,
                axisLabel: {
                    fontSize: 10,
                    margin: 4,
                    rotate: 0,
                    overflow: 'truncate',
                    fontWeight: 400,
                    color: cl_blue,
                    width: 160,
                },
            },
            xAxis: {
                type: 'time',
                min: from,
                max: moment(to).add(1, 'days').format('YYYY-MM-DD'),
                //interval: 15,
                axisLabel: {
                    fontSize: 9,
                    margin: 4,
                    color: cl_blue,
                    rotate: 0,
                },
                axisPointer: {
                    show: this.showAxisPointer(),
                    value: moment().format('YYYY-MM-DD'),
                    snap: false,
                    triggerTooltip: false,
                    status: 'hide',
                    lineStyle: {
                        color: 'red',
                        width: 2,
                    },
                    label: {
                        show: false,
                        formatter: function () {
                            return moment().format('YYYY-MM-DD');
                        },
                        backgroundColor: '#7581BD',
                    },
                    handle: {
                        show: true,
                        size: 0,
                    },
                },
            },
            series: [
                {
                    name: 'Activity',
                    type: 'bar',
                    stack: 'Total',
                    zlevel: -1,
                    z: 3,
                    itemStyle: {
                        //borderColor: 'transparent',
                        color: cl_gray500,
                    },
                    emphasis: {
                        disable: true,
                    },

                    data: data.map((record) => {
                        let value = moment(record.duedate).add(1, 'days').format('YYYY-MM-DD');
                        return {
                            value: value,
                            projectno: record.projectno,
                            activityno: record.activityno,
                            link: record.link,
                            activitytyp: record.activitytyp,
                            itemStyle: {
                                borderColor: 'white',
                                color: statusColor(record.status),
                            },
                        };
                    }),
                },
                {
                    name: 'Empty',
                    type: 'bar',
                    stack: 'Total',
                    zlevel: -1,
                    z: 4,

                    emphasis: {
                        disable: true,
                    },

                    data: data.map((record) => {
                        return {
                            value: moment(record.startdate).format('YYYY-MM-DD'),
                            projectno: record.projectno,
                            activityno: record.activityno,
                            itemStyle: {
                                borderColor: 'white',
                                color: 'white',
                            },
                        };
                    }),
                },
            ],
        };
    };

    render() {
        return (
            <ReactEcharts
                notMerge={true}
                lazyUpdate={true}
                option={this.getOption()}
                style={{ height: this.ganttHeight(), width: '100%' }}
                opts={{ renderer: 'svg' }}
                onEvents={{ click: this.onClick }}
            />
        );
    }
}
