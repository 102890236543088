import { Col, Row } from 'reactstrap';
import { IBaseView } from '../../../_base/base-view';
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import styled from 'styled-components';
import { cl_green, cl_red } from '../../../_theme/colors';
import { dsState } from '../../../../components/dataset/IDataset';

export const _PosNegButtons = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                gembaStore: { dsGembaFinding },
            },
            className,
        } = props;

        const click = (posneg: string) => {
            return action(() => {
                if (dsGembaFinding.state === dsState.dsEdit) {
                    dsGembaFinding.actual.posneg = dsGembaFinding.actual.posneg === posneg ? '' : posneg;
                }
            });
        };

        return (
            <div className={className}>
                <Row>
                    <Col>
                        <button
                            className={dsGembaFinding.actual?.posneg === 'positive' ? 'green-active' : 'green'}
                            onClick={click('positive')}
                            disabled={!(dsGembaFinding.state === dsState.dsEdit)}
                        >
                            positive
                        </button>
                    </Col>
                    <Col>
                        <button
                            className={dsGembaFinding.actual?.posneg === 'negative' ? 'red-active' : 'red'}
                            onClick={click('negative')}
                            disabled={!(dsGembaFinding.state === dsState.dsEdit)}
                        >
                            negative
                        </button>
                    </Col>
                </Row>
            </div>
        );
    }),
);

export const PosNegButtons = styled(_PosNegButtons)`
    display: block;
    height: 100%;
    width: 100%;
    margin-bottom: 1rem;

    button {
        background-color: white;
        width: 100%;
        border-radius: 0.25rem;
        padding: 0.25rem;
    }

    .green {
        color: ${cl_green};
        border: 2px solid ${cl_green};
    }

    .green-active {
        font-weight: 500;
        color: white;
        background-color: ${cl_green};
        border: 2px solid ${cl_green};
    }

    .red {
        color: ${cl_red};
        border: 2px solid ${cl_red};
    }

    .red-active {
        font-weight: 500;
        color: white;
        background-color: ${cl_red};
        border: 2px solid ${cl_red};
    }
`;
