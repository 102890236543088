import * as React from 'react';
import { IBaseView } from '../../../../_base/base-view';
import { IPpapReportBasepage } from '../../ppap-report-basepage';
import { PageTitle } from '../../helper/page-title';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { IPpapOrderElement } from '../../../../../models/PpapOrderElement';
import { IPPAPRisklevel } from '../../../../../models/Ppap';
import { action } from 'mobx';
import { dsState, IDataset } from '../../../../../components/dataset/IDataset';
import { FormField, TableField } from '../../helper/FormEditor';
import { PpapWarrantDisposition } from './ppap-warrant-disposition';
import { cl_gray300 } from '../../../../_theme/colors';

interface IHeader extends IBaseView {
    element: IPpapOrderElement;
}

interface ICheckbox {
    checked?: boolean;
}

const Checkbox = observer(
    ({ checked }: ICheckbox) => <input type="checkbox" checked={checked} readOnly />, //style={{marginTop:'4px'}}
);

interface IEditCheckbox {
    ds: IDataset<any>;
    fieldname: string;
}

const EditCheckbox = observer(({ ds, fieldname }: IEditCheckbox) => {
    return (
        <input
            type="checkbox"
            name={fieldname}
            checked={ds.actual ? ds.actual[fieldname] === true : false}
            onChange={action((e: any) => {
                if (ds.state === dsState.dsEdit) {
                    ds.actual[e.target.name] = e.target.checked;
                }
            })}
        />
    );
});

const _Header = inject('rootStore')(
    observer((props: IHeader) => {
        const {
            rootStore: {
                ppapStore: { ds, dsProduct, dsPpapDetail18 },
            },
        } = props;
        return (
            <table className={props.className}>
                <tbody>
                    <tr>
                        <td style={{ width: '10%' }}>Part Name</td>
                        <td className="data" style={{ width: '20%' }}>
                            {dsProduct.actual?.description1}
                        </td>
                        <td style={{ width: '8%' }}>Customer Part No</td>
                        <td className="data" style={{ width: '15%' }}>
                            {dsProduct.actual?.productno}
                        </td>
                        <td style={{ width: '8%' }}>ECL</td>
                        <td className="data" style={{ width: '20%' }}>
                            {ds.actual?.eclrevision}
                        </td>
                    </tr>
                    <tr>
                        <td>Supplier Drawing No</td>
                        <td className="data">
                            <TableField ds={dsPpapDetail18} fieldName="supplierdrawingno" readonly={!(dsPpapDetail18.state === dsState.dsEdit)} />
                        </td>
                        <td>Supplier Part No</td>
                        <td className="data">
                            <TableField ds={dsPpapDetail18} fieldName="supplierpartno" readonly={!(dsPpapDetail18.state === dsState.dsEdit)} />
                        </td>
                        <td>ECL Date</td>
                        <td className="data">{ds.actual?.ecldate ? moment(ds.actual?.ecldate).format('YYYY-MM-DD') : ''}</td>
                    </tr>
                    <tr>
                        <td>Customer Drawing No</td>
                        <td className="data">{dsProduct.actual?.drawingno}</td>
                        <td>PPAP PO No</td>
                        <td className="data"> {ds.actual?.purchaseno}</td>
                        <td>PPAP Part Family</td>
                        <td className="data">{dsProduct.actual?.ppap_family}</td>
                    </tr>
                </tbody>
            </table>
        );
    }),
);

const Header = styled(_Header)`
    width: 100%;
    font-size: 12px;

    .data {
        border-bottom: 1px solid black;
    }
`;

const SupplierCustomerInformation = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                ppapStore: { ds, dsCustomer, dsSupplier },
            },
        } = props;
        return (
            <table style={{ width: '100%', fontSize: '11px' }}>
                <tbody>
                    <tr>
                        <td style={{ fontWeight: 'bold', fontSize: '14px' }} colSpan={2}>
                            SUPPLIER ORGANISATION INFORMATION
                        </td>
                        <td style={{ fontWeight: 'bold', fontSize: '14px' }} colSpan={2}>
                            CUSTOMER ORGANISATION INFORMATION
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: '12%' }}>Supplier Name</td>
                        <td style={{ borderBottom: '1px solid black' }}>{dsSupplier.actual?.supplier1}</td>
                        <td style={{ width: '15%' }}>Customer Name/Division</td>
                        <td style={{ borderBottom: '1px solid black' }}>{dsCustomer.actual?.customer1}</td>
                    </tr>
                    <tr>
                        <td>Street Address</td>
                        <td style={{ borderBottom: '1px solid black' }}>{dsSupplier.actual?.street}</td>
                        <td>Customer Contact</td>
                        <td style={{ borderBottom: '1px solid black' }}>{ds.actual?.responsiblecust}</td>
                    </tr>
                    <tr>
                        <td>Zip/City/Country</td>
                        <td style={{ borderBottom: '1px solid black' }}>
                            {dsSupplier.actual?.postcode} {dsSupplier.actual?.city}, {dsSupplier.actual?.country}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }),
);

const Note = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                ppapStore: { dsPpapDetail18 },
            },
        } = props;
        return (
            <table style={{ width: '100%', fontSize: 10 }}>
                <tbody>
                    <tr style={{ height: '3rem' }}>
                        <td style={{ width: '3rem' }}>Note:</td>
                        <td style={{ width: '25rem' }}>
                            Does this part contain prohibited or declarable substances according to
                            <br />
                            REACH candidate list and/or REACH annex 14 + 17?
                        </td>
                        <td style={{ width: '3rem' }}>
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '2rem',
                                    fontWeight: 'bold',
                                    border: '1px solid black',
                                    backgroundColor: cl_gray300,
                                    cursor: 'pointer',
                                }}
                                onClick={action(() => {
                                    if (dsPpapDetail18.state === dsState.dsEdit) {
                                        dsPpapDetail18.actual.note_declarable = !dsPpapDetail18.actual.note_declarable;
                                    }
                                })}
                            >
                                {dsPpapDetail18.actual?.note_declarable ? 'YES' : 'NO'}
                            </div>
                        </td>
                        <td style={{ width: 'auto', verticalAlign: 'top' }} rowSpan={2}>
                            Comment
                            <br />
                            <textarea
                                rows={8}
                                style={{ width: '100%', fontSize: 12, backgroundColor: cl_gray300 }}
                                name="comment_note"
                                readOnly={dsPpapDetail18.state !== dsState.dsEdit}
                                value={dsPpapDetail18.actual?.comment_note ?? ''}
                                onChange={action((event: any) => {
                                    if (dsPpapDetail18.state === dsState.dsEdit) {
                                        dsPpapDetail18.actual.comment_note = event.target.value;
                                    }
                                })}
                            />
                        </td>
                    </tr>
                    <tr style={{ height: '3rem' }}>
                        <td>Note:</td>
                        <td>Safety and/or Government Regulation?</td>
                        <td>
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '2rem',
                                    border: '1px solid black',
                                    fontWeight: 'bold',
                                    backgroundColor: cl_gray300,
                                    cursor: 'pointer',
                                }}
                                onClick={action(() => {
                                    if (dsPpapDetail18.state === dsState.dsEdit) {
                                        dsPpapDetail18.actual.note_regulations = !dsPpapDetail18.actual.note_regulations;
                                    }
                                })}
                            >
                                {dsPpapDetail18.actual?.note_regulations ? 'YES' : 'NO'}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }),
);

const ReasonForSubmission = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                ppapStore: { dsPpapDetail18 },
            },
        } = props;
        return (
            <>
                <p style={{ fontWeight: 'bold', marginBottom: 4 }}>
                    REASON FOR SUBMISSION (check at least one)<sup>*</sup>
                </p>
                <table style={{ width: '100%', fontSize: 10 }}>
                    <tbody>
                        <tr style={{ padding: 0 }}>
                            <td style={{ width: '1rem', padding: 0 }}>
                                <EditCheckbox ds={dsPpapDetail18} fieldname="re_01" />
                            </td>
                            <td style={{ padding: 0 }}>New part design</td>
                            <td style={{ width: '1rem', padding: 0 }}>
                                <EditCheckbox ds={dsPpapDetail18} fieldname="re_07" />
                            </td>
                            <td style={{ padding: 0 }}>
                                Change of subcontractor (e.g. heat-treating, plating) that can or may affect form, fit, function, performance, and/or
                                durability
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <EditCheckbox ds={dsPpapDetail18} fieldname="re_02" />
                            </td>
                            <td>Engineering change to design, specification or material</td>
                            <td>
                                <EditCheckbox ds={dsPpapDetail18} fieldname="re_08" />
                            </td>
                            <td>Activation of tooling after 12 months or more of inactive status</td>
                        </tr>
                        <tr>
                            <td>
                                <EditCheckbox ds={dsPpapDetail18} fieldname="re_03" />
                            </td>
                            <td>Correction of discrepancy on a previous PPAP submission</td>
                            <td>
                                <EditCheckbox ds={dsPpapDetail18} fieldname="re_09" />
                            </td>
                            <td>
                                Product or process changes related to components of the production part that impacts form, fit, function, performance,
                                and/or durability
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <EditCheckbox ds={dsPpapDetail18} fieldname="re_04" />
                            </td>
                            <td>Change of material or finishing</td>
                            <td>
                                <EditCheckbox ds={dsPpapDetail18} fieldname="re_10" />
                            </td>
                            <td>Change in the inspection or test method</td>
                        </tr>
                        <tr>
                            <td>
                                <EditCheckbox ds={dsPpapDetail18} fieldname="re_05" />
                            </td>
                            <td>Production following refurbishment or rearrangement of existing tooling or equipment</td>
                            <td>
                                <EditCheckbox ds={dsPpapDetail18} fieldname={'re_11'} />
                            </td>
                            <td>Changes in equipment, e.g. new equipment, additional equipment, replacement, or change in equipment size</td>
                        </tr>
                        <tr>
                            <td>
                                <EditCheckbox ds={dsPpapDetail18} fieldname="re_06" />
                            </td>
                            <td>Production from tooling or equipment moved to a different plant location</td>
                            <td />
                            <td />
                        </tr>
                    </tbody>
                </table>
                <p />
            </>
        );
    }),
);

const SubmissionLevel = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                ppapStore: { ds },
            },
        } = props;
        return (
            <>
                <hr style={{ marginTop: 4, marginBottom: 4, border: '2px solid black' }} />
                <p style={{ fontWeight: 'bold', marginBottom: 4 }}>SUBMISSION LEVEL</p>
                <table style={{ fontSize: 10 }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '1rem' }}>
                                <Checkbox checked={ds.actual?.level === IPPAPRisklevel.HIGH} />
                            </td>
                            <td>High risk level</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox checked={ds.actual?.level === IPPAPRisklevel.MEDIUM} />
                            </td>
                            <td>Medium risk level</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox checked={ds.actual?.level === IPPAPRisklevel.LOW} />
                            </td>
                            <td>Low risk level</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }),
);

const Documents = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                ppapStore: { dsPpapOrderElement },
            },
        } = props;

        const findDoc = (elementno: number) => {
            let element = dsPpapOrderElement.data?.find((element) => element.elementno === elementno);
            return !!element;
        };

        return (
            <>
                <table style={{ width: '100%', fontSize: 10 }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '1rem' }}>
                                <Checkbox checked={true} />
                            </td>
                            <td>1 Product Quality Plan</td>
                            <td style={{ width: '1rem' }}>
                                <Checkbox checked={findDoc(10)} />
                            </td>
                            <td>10 Process Capability Studies</td>
                            <td style={{ width: '1rem' }}>
                                <Checkbox checked={findDoc(20)} />
                            </td>
                            <td>20 Safety Data Sheet</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox checked={findDoc(2)} />
                            </td>
                            <td>2 Team Feasibility Commitment, TFC</td>
                            <td>
                                <Checkbox checked={findDoc(11)} />
                            </td>
                            <td>11 Performance Test Report</td>
                            <td>
                                <Checkbox checked={findDoc(21)} />
                            </td>
                            <td>21 Form, Fit and Function</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox checked={findDoc(3)} />
                            </td>
                            <td>3 Design Failure Mode & Effect Analysis, DFMEA 3)</td>
                            <td>
                                <Checkbox checked={findDoc(12)} />
                            </td>
                            <td>12 Material Test Report</td>
                            <td>
                                <Checkbox checked={findDoc(22)} />
                            </td>
                            <td>22 Qualified Laboratory Documentation</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox checked={findDoc(4)} />
                            </td>
                            <td>4 Part History</td>
                            <td>
                                <Checkbox checked={findDoc(13)} />
                            </td>
                            <td>13 Dimension Test Report</td>
                            <td>
                                <Checkbox checked={findDoc(23)} />
                            </td>
                            <td>23 List of Checking Aids</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox checked={findDoc(5)} />
                            </td>
                            <td>5 Customer Engineering Approval</td>
                            <td>
                                <Checkbox checked={findDoc(14)} />
                            </td>
                            <td>14 Appearance Approval Report</td>
                            <td>
                                <Checkbox checked={findDoc(24)} />
                            </td>
                            <td>24 Sub Supplier Source Details</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox checked={findDoc(6)} />
                            </td>
                            <td>6 Process Flow Chart</td>
                            <td>
                                <Checkbox checked={findDoc(15)} />
                            </td>
                            <td>15 Measurement System Analysis</td>
                            <td>{/*<Checkbox checked={findDoc(25)}/>*/}</td>
                            <td>{/*25 Type Name (in TOC)*/}</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox checked={findDoc(7)} />
                            </td>
                            <td>7 Factory Floor Plan</td>
                            <td>
                                <Checkbox checked={findDoc(16)} />
                            </td>
                            <td>16 Packaging Test Report</td>
                            <td>{/*<Checkbox checked={findDoc(26)}/>*/}</td>
                            <td>{/*26 Type Name (in TOC)*/}</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox checked={findDoc(8)} />
                            </td>
                            <td>8 Process Failure Mode & Effect Analysis, PFMEA</td>
                            <td>
                                <Checkbox checked={findDoc(17)} />
                            </td>
                            <td>17 Product and Master Sample</td>
                            <td>{/*<Checkbox checked={findDoc(27)}/>*/}</td>
                            <td>{/*27 Type Name (in TOC)*/}</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox checked={findDoc(9)} />
                            </td>
                            <td>9 Control Plan</td>
                            <td>
                                <Checkbox checked={findDoc(18)} />
                            </td>
                            <td>18 Part Submission Warrant, PSW</td>
                            <td>{/*<Checkbox checked={findDoc(28)}/>*/}</td>
                            <td>{/*28 Type Name (in TOC)*/}</td>
                        </tr>
                        <tr>
                            <td />
                            <td />
                            <td>
                                <Checkbox checked={findDoc(19)} />
                            </td>
                            <td>19 Part Submission Warrant, Dev</td>
                            <td>{/*<Checkbox checked={findDoc(29)}/>*/}</td>
                            <td>{/*29 Type Name (in TOC)*/}</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }),
);

const Declaration = () => (
    <>
        <p style={{ fontWeight: 'bold', marginBottom: '4px' }}>DECLARATION</p>
        <p style={{ fontSize: 10 }}>
            I confirm that the samples represented by this warrant are representative of our parts and have been made to the relevant customer
            <br />
            drawings and specifications, and are made from specified materials on regular production tooling with no operations other than the regular
            <br />
            production process. I also certify that documented evidence of such compliance is on file and available for review.
            <br />I have noted any deviation from this declaration below.
        </p>
    </>
);

const Explanation = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                ppapStore: { ds, dsPpapDetail18, dsSupResponsible },
            },
        } = props;
        return (
            <>
                <table style={{ width: '80%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '35%', fontWeight: 'bold' }}>EXPLANATION/COMMENTS</td>
                            <td style={{ width: '65%' }}>
                                <FormField ds={dsPpapDetail18} fieldName="comment1" readonly={!(dsPpapDetail18.state === dsState.dsEdit)} />
                            </td>
                        </tr>
                        <tr>
                            <td />
                            <td>
                                <FormField ds={dsPpapDetail18} fieldName="comment2" readonly={!(dsPpapDetail18.state === dsState.dsEdit)} />
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: 10 }}>List deviations and reference to PSW deviation report:</td>
                            <td>
                                <FormField ds={dsPpapDetail18} fieldName="list_deviations" readonly={!(dsPpapDetail18.state === dsState.dsEdit)} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: 10 }}>Organization Authorized Signature</td>
                            <td style={{ borderBottom: '1px solid black' }}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: 10 }}>Print Name</td>
                            <td style={{ borderBottom: '1px solid black' }}>{ds.actual?.responsiblesup}</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: 10 }}>Title</td>
                            <td style={{ borderBottom: '1px solid black' }}>{dsSupResponsible.actual?.funktion}</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }),
);

const CustomerOnly = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                ppapStore: { ds, dsPpapDetail18 },
            },
        } = props;
        return (
            <>
                <hr style={{ border: '2px solid black' }} />
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td width={'33%'} />
                            <td width={'33%'} style={{ fontWeight: 'bold' }}>
                                FOR CUSTOMER USE ONLY
                            </td>
                            <td width={'33%'} />
                        </tr>
                    </tbody>
                </table>
                <p />
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td width={'50%'} style={{ fontSize: 14, borderRight: '3px solid black' }}>
                                <PpapWarrantDisposition ds={dsPpapDetail18} />
                                <br />
                                <table style={{ width: '97%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '35%' }}>Date</td>
                                            <td style={{ borderBottom: '1px solid black' }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Customer signature</td>
                                            <td style={{ borderBottom: '1px solid black' }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td> Print Name</td>
                                            <td style={{ borderBottom: '1px solid black' }}>{ds.actual?.responsiblecust}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td width={'50%'} style={{ paddingLeft: '1rem' }}>
                                <span style={{ fontWeight: 'bold' }}>Comment</span>
                                <br />
                                <textarea
                                    rows={8}
                                    style={{ width: '100%', fontSize: 12, backgroundColor: cl_gray300 }}
                                    name="ppap_warrant_comment"
                                    readOnly={dsPpapDetail18.state !== dsState.dsEdit}
                                    value={dsPpapDetail18.actual?.ppap_warrant_comment ?? ''}
                                    onChange={action((event: any) => {
                                        if (dsPpapDetail18.state === dsState.dsEdit) {
                                            dsPpapDetail18.actual.ppap_warrant_comment = event.target.value;
                                        }
                                    })}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }),
);

export class PpapReportPartSubmissionWarrant extends React.Component<IPpapReportBasepage, {}> {
    render() {
        const { element } = this.props;
        return (
            <div>
                <PageTitle>{element?.elementno + ') ' + element?.element}</PageTitle>
                <Header element={element} />
                <p />
                <SupplierCustomerInformation />
                <p />
                <Note />
                <ReasonForSubmission />
                <SubmissionLevel />
                <p />
                <Documents />
                <Declaration />
                <Explanation />
                <CustomerOnly />
            </div>
        );
    }
}
