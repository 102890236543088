import { BaseStore } from './base-store';
import { gembaTemplateColumns, IGembaTemplate } from '../models/GembaTemplate';
import { IRootStore } from '../routes/root-store';
import { action } from 'mobx';
import { RouterState } from 'mobx-state-router';
import { decodeParams } from '../components/lib/encode-decode-params';

export class GembaTemplateStore extends BaseStore<IGembaTemplate> {
    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/gembatemplate/', gembaTemplateColumns);
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        switch (toState.routeName) {
            case 'gembatemplatetable':
                await this.open();
                if (fromState.routeName === 'gembatemplate') {
                    this.ds.locate(this.ds.pkFields, fromState.params);
                }
                break;
            case 'gembatemplate':
                await this.open(decodeParams(toState.params));
                break;
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        this.close();
        await Promise.resolve();
    }
}
