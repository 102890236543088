import { action, observable } from 'mobx';
import { validatorRules } from './validator-rules';
import { ILangKeys } from '../../language/ILang';
import { IField, ValidationRule } from './IField';

interface IValidator<T> {
    columns: IField<T>[];
    clear: () => void;
    check: (data: T) => boolean;
}

interface IValidationError<T> {
    field: keyof T;
    isValid: boolean;
    text: ILangKeys;
}

export class Validator<T> implements IValidator<T> {
    columns: IField<T>[];

    @observable
    errors: IValidationError<T>[];

    constructor(columns: IField<T>[]) {
        this.columns = columns;
        this.errors = [];
    }

    @action.bound
    clear() {
        this.errors = [];
    }

    @action.bound
    check(data: T) {
        this.clear();
        this.columns
            .filter((column) => column.rules)
            .forEach((column) => {
                column.rules.forEach((rule: ValidationRule<T>) => {
                    let result: true | ILangKeys;
                    if (Array.isArray(rule)) {
                        // equalto
                        result = validatorRules[rule[0]]((data as any)[column.fieldName], (data as any)[rule[1]]);
                    } else {
                        result = validatorRules[rule]((data as any)[column.fieldName]);
                    }
                    if (result !== true) {
                        this.errors.push({
                            field: column.fieldName,
                            isValid: false,
                            text: result,
                        });
                    }
                });
            });
        return this.errors.length === 0;
    }
}
