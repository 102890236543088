import * as React from 'react';
import { TableForm } from '../../../../_base/styled/table-form';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { DbField } from '../../../../../components/dbform/db-field';
import { inject, observer } from 'mobx-react';
import { action, computed } from 'mobx';
import { AuditStatistic } from './audit-statistic';
import { dsState } from '../../../../../components/dataset/IDataset';
import { Status } from '../../../../../models/Status';
import { IconButton } from '../../../../_base/styled/icon-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBaseView } from '../../../../_base/base-view';
import { exporter } from '../../../../test/xlsxExporter';

@inject('rootStore')
@observer
export class AuditDetailTopElement extends React.Component<IBaseView, {}> {
    @action.bound
    async gotoProject() {
        const {
            rootStore: {
                routerStore,
                auditStore: { ds },
            },
        } = this.props;
        await routerStore.goTo('project', {
            projectno: ds.actual.projectno,
        });
    }

    @action.bound
    async gotoAudit() {
        const {
            rootStore: {
                routerStore,
                auditStore: { ds },
            },
        } = this.props;
        console.log('audit');
        await routerStore.goTo('audit', {
            auditno: ds.actual.refauditno,
        });
    }

    @action.bound
    async goToCollect() {
        const {
            rootStore: {
                routerStore,
                auditStore: { ds },
            },
        } = this.props;
        await routerStore.goTo('auditcollectonline', {
            auditno: ds.actual.auditno,
        });
    }

    @action.bound
    async goToReport() {
        const {
            rootStore: {
                routerStore,
                auditStore: { ds },
            },
        } = this.props;
        await routerStore.goTo('auditreport', {
            auditno: ds.actual.auditno,
        });
    }

    @action.bound
    fileExport() {
        const {
            rootStore: {
                auditStore: { ds, dsAuditQuestion },
            },
        } = this.props;
        exporter(dsAuditQuestion, ds.actual.auditno, 'Audit');
    }

    @computed
    get hideMenu() {
        const {
            rootStore: {
                auditStore: { ds, dsProject, saveLeadauditor },
                authStore: { username },
            },
        } = this.props;

        return !(
            (username === ds.actual?.leadauditor && ds.actual?.status !== Status.APPROVED) ||
            username === dsProject.actual?.owner ||
            (ds.state === dsState.dsEdit && username === saveLeadauditor)
        );
    }

    extraMenu = () => {
        const {
            rootStore: {
                auditStore: { ds },
                langStore: { lang },
                authStore: { username },
            },
        } = this.props;
        return (
            <>
                {(username === ds.actual?.leadauditor || username === ds.actual?.leadauditor) && ds.actual?.status !== Status.APPROVED ? (
                    <>
                        <IconButton id="gotocollect" onClick={this.goToCollect}>
                            <FontAwesomeIcon icon={['fad', 'user-md-chat']} />
                        </IconButton>
                        <UncontrolledTooltip placement="bottom" target="gotocollect">
                            {lang['DATACOLLECT']}
                        </UncontrolledTooltip>
                    </>
                ) : null}
                <IconButton id="report" onClick={this.goToReport}>
                    <FontAwesomeIcon icon={['fad', 'print']} />
                </IconButton>
                <UncontrolledTooltip placement="bottom" target="report">
                    {lang['REPORT']}
                </UncontrolledTooltip>
                <IconButton id="export" onClick={this.fileExport}>
                    <FontAwesomeIcon icon={['fad', 'file-export']} />
                </IconButton>
                <UncontrolledTooltip placement="bottom" target="export">
                    {lang['EXPORT']}
                </UncontrolledTooltip>
            </>
        );
    };

    @computed
    get keyTitle() {
        const {
            rootStore: {
                auditStore: { ds },
            },
        } = this.props;
        let rv1 = ds.actual?.auditno ?? '';
        let rv2 = ds.actual?.audit ?? '';
        return rv1 && rv2 ? rv1 + ' - ' + rv2 : '';
    }

    render() {
        const {
            rootStore: {
                auditStore: { ds, statusOptionsFunc },
            },
        } = this.props;

        return (
            <TableForm
                icon={ds.actual?.isdevelopment ? 'chart-line' : 'eye'}
                title={ds.actual?.isdevelopment ? 'PROJECTTASKTYP_DEVELOPMENT' : 'AUDIT'}
                ds={ds}
                keyTitle={this.keyTitle}
                open={false}
                extraMenu={this.extraMenu()}
                hideMenu={this.hideMenu}
                ownerField={'leadauditor'}
            >
                <Row>
                    <Col xs={6}>
                        <Row>
                            <Col xs={4}>
                                <DbField ds={ds} field="auditno" />
                                <DbField ds={ds} field="audit" />
                                <DbField ds={ds} field="startdate" />
                                <DbField ds={ds} field="audittemplate" />
                            </Col>
                            <Col xs={4}>
                                <DbField ds={ds} field="leadauditor" />
                                <DbField ds={ds} field="coauditor01" />
                                <DbField ds={ds} field="status" optionsFunc={statusOptionsFunc} />
                                {ds.actual?.isdevelopment ? <DbField ds={ds} field="refauditno" onClickLabel={this.gotoAudit} /> : null}

                                {/*<DbField ds={ds} field="isdevelopment"/>*/}
                            </Col>
                            <Col xs={4}>
                                <DbField ds={ds} field="duedate" />
                                {/* <DbField ds={ds} field="supplierno"/>*/}
                                <DbField ds={ds} field="supplier1" />
                                <DbField ds={ds} field="plant" />
                                <DbField ds={ds} field="projectno" onClickLabel={this.gotoProject} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={ds} field={'gantt'} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <DbField ds={ds} field="comment" />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6}>
                        <AuditStatistic />
                    </Col>
                </Row>
            </TableForm>
        );
    }
}
