import * as React from 'react';
import { CardHeader } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { historyAdapter } from '../../_layout/app';
import { IBaseView } from '../base-view';
import { ILangKeys } from '../../../language/ILang';
import styled from 'styled-components';
import { BaseCardHeaderLeftMenu } from './base-card-header-left-menu';
import { BaseCardHeaderTitle } from './base-card-header-title';
import { LeftMenu } from './left-menu';
import { RightMenu } from './right-menu';

interface IBaseCardHeader extends IBaseView {
    title: ILangKeys;
    showBackButton?: boolean;
    rightHeaderMenu?: JSX.Element;
    leftHeaderMenu?: JSX.Element;
    className?: string;
}

@inject('rootStore')
@observer
export class _BaseCardHeader extends React.Component<IBaseCardHeader, any> {
    @action.bound
    goBack = () => {
        historyAdapter.goBack();
    };

    render() {
        const { className, showBackButton, rightHeaderMenu, title } = this.props;
        return (
            <CardHeader className={className} style={{ display: 'flex' }}>
                <LeftMenu menu={<BaseCardHeaderLeftMenu showBackButton={showBackButton} onClick={this.goBack} />} />
                <BaseCardHeaderTitle title={title} />
                <RightMenu menu={rightHeaderMenu} />
            </CardHeader>
        );
    }
}

export const BaseCardHeader = styled(_BaseCardHeader)`
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
    display: flex;
    padding: 0.625rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: ${(props) => props.theme.bg};
    color: ${(props) => props.theme.color};
    font-size: 1.25rem;
    font-weight: bold;
`;
