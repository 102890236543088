import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '../../../_base/styled/icon-button';
import { IBaseView } from '../../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { Dataset } from '../../../../components/dataset/dataset';
import { IPpapOrderElement, ppapOrderElementColumns } from '../../../../models/PpapOrderElement';
import { IDataset } from '../../../../components/dataset/IDataset';
import { Dialog } from '../../../../components/dialog/Dialog';
import { PpapElementCheckbox } from './ppap-element-checkbox';

@inject('rootStore')
@observer
export class PpapOrderLeftSelectElement extends React.Component<IBaseView, {}> {
    @observable
    isOpen: boolean = false;

    @observable
    dsPpapElement: IDataset<IPpapOrderElement>;

    constructor(props: IBaseView) {
        super(props);

        this.dsPpapElement = new Dataset<IPpapOrderElement>('/gridApi/ppapelement/', ppapOrderElementColumns);
    }

    @action.bound
    async onOpened() {
        const {
            rootStore: {
                ppapStore: { ds },
            },
        } = this.props;
        this.dsPpapElement.filter = { ppapno: ds.actual.ppapno };
        await this.dsPpapElement.open();
        /*runInAction(() => {
            this.areas = R.uniq(this.dsAuditQuestion.data
                .map(question => {
                    return {label: question.area, completed: true, enabled: !question.unselected}
                })
            );
        })*/
    }

    @action.bound
    onOpen() {
        this.isOpen = true;
    }

    @action.bound
    async onCancel() {
        const {
            rootStore: {
                ppapStore: { dsPpapOrderElement },
            },
        } = this.props;
        this.isOpen = false;
        await dsPpapOrderElement.refresh(dsPpapOrderElement.cursor);
    }

    @action.bound
    onOk() {}

    render() {
        const {
            rootStore: {
                langStore: { lang },
            },
        } = this.props;
        return (
            <>
                <IconButton>
                    <FontAwesomeIcon icon={['fad', 'layer-group']} onClick={this.onOpen} />
                </IconButton>
                <Dialog
                    title={lang.PPAP_ELEMENTS}
                    isOpen={this.isOpen}
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                    onOpened={this.onOpened}
                    hideCancel={true}
                    hideOk={true}
                >
                    <div style={{ height: '400px', overflowY: 'auto' }}>
                        {this.dsPpapElement.data.map((element) => (
                            <PpapElementCheckbox key={element.elementno} element={element} />
                        ))}
                    </div>
                </Dialog>
            </>
        );
    }
}
