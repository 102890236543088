import styled from 'styled-components';

export const Block = styled.div`
    display: block;
    height: auto;
    color: black;
    border: solid 1px black;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
`;

export const GraphBlock = styled.div`
    margin-top: 1rem;
    display: block;
    max-width: 100%;
    height: 400px;
    color: black;
    border: solid 1px black;
`;

export const NewPage = styled.div`
    display: block;
    margin-top: 1rem;
    page-break-before: always;
`;

export const ElementBlock = styled.div`
    margin-top: 1rem;
    display: block;
    height: 200px;
    width: 100%;
    color: black;
    border: solid 1px black;
`;
