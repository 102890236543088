import { ILang, ILangKeys } from '../language/ILang';
import { cl_blue, cl_gray500, cl_green, cl_red, cl_white, cl_yellow } from '../views/_theme/colors';
import { darken } from 'polished';

export const STATUS_ALL: number = undefined;

export enum Status {
    PLANNED = 100,
    INPROGRESS = 200,
    COMPLETED = 300,
    NOTAPPROVED = 350,
    COND_APPROVED = 390,
    APPROVED = 400,
}

export enum StatusComplete {
    PLANNED = 100,
    INPROGRESS = 200,
    COMPLETED = 300,
    //NOTAPPROVED = 350,
}

export const statusText = (status: number): ILangKeys => {
    switch (status) {
        case STATUS_ALL:
            return 'ALL';
        case Status.PLANNED:
            return 'STATUS_PLANNED';
        case Status.INPROGRESS:
            return 'STATUS_INPROGRESS';
        case Status.COMPLETED:
            return 'STATUS_COMPLETED';
        case Status.NOTAPPROVED:
            return 'STATUS_NOT_APPROVED';
        case Status.COND_APPROVED:
            return 'STATUS_COND_APPROVED';
        case Status.APPROVED:
            return 'STATUS_APPROVED';
        default:
            return 'Unknown';
    }
};

/**
 * Wandelt den lokalisierten Statustext in den Status oder undefined
 *
 * @param lang - Language store
 * @param text - Lokalisierter StatusText
 */
export const statusTexttoStatus = (lang: ILang, text: string): number => {
    switch (text) {
        case lang['STATUS_PLANNED']:
            return Status.PLANNED;
        case lang['STATUS_INPROGRESS']:
            return Status.INPROGRESS;
        case lang['STATUS_COMPLETED']:
            return Status.COMPLETED;
        case lang['STATUS_NOT_APPROVED']:
            return Status.NOTAPPROVED;
        case lang['STATUS_COND_APPROVED']:
            return Status.COND_APPROVED;
        case lang['STATUS_APPROVED']:
            return Status.APPROVED;
        default:
            return undefined;
    }
};

export const statusColor = (status: Status): string => {
    switch (status) {
        case Status.PLANNED:
            return cl_gray500;
        case Status.INPROGRESS:
            return cl_yellow;
        case Status.COMPLETED:
            return cl_blue;
        case Status.NOTAPPROVED:
            return cl_red;
        case Status.COND_APPROVED:
            return darken(0.2, cl_green);
        case Status.APPROVED:
            return cl_green;
        default:
            return cl_white;
    }
};

export const cellStyleStatus = function (params: any) {
    switch (params.value) {
        case Status.PLANNED:
            return { backgroundColor: cl_gray500, color: 'white' };
        case Status.INPROGRESS:
            return { backgroundColor: cl_yellow, color: 'white' };
        case Status.COMPLETED:
            return { backgroundColor: cl_blue, color: 'white' };
        case Status.NOTAPPROVED:
            return { backgroundColor: cl_red, color: 'white' };
        case Status.COND_APPROVED:
            //return {backgroundColor: darken(0.3, cl_green), color: 'white'}
            return { backgroundColor: cl_red, color: 'white' };
        case Status.APPROVED:
            return { backgroundColor: cl_green, color: 'white' };
    }
};
