import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBaseView } from '../base-view';
import Dropzone from 'react-dropzone';
import { IconButton } from '../styled/icon-button';
import { UncontrolledTooltip } from 'reactstrap';
import { IconName } from '@fortawesome/fontawesome-common-types';

interface IMenuFileUpload extends IBaseView {
    id: string;
    disabled?: boolean;
    onDrop: (acceptFile: any[]) => Promise<any>;
    accept?: string;
    iconName?: IconName;
    environment?: boolean;
}

@inject('rootStore')
@observer
export class MenuFileUpload extends React.Component<IMenuFileUpload, {}> {
    onClick = () => {};

    render() {
        const {
            rootStore: {
                langStore: { lang },
            },
            id,
            disabled,
            onDrop,
            accept,
            iconName,
            environment,
        } = this.props;
        return (
            <div style={{ display: 'inline-block' }}>
                <Dropzone onDrop={onDrop} disabled={disabled} accept={accept}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            {environment ? <input {...getInputProps()} capture="environment" /> : <input {...getInputProps()} type={'file'} />}
                            <input {...getInputProps()} capture="environment" />
                            <IconButton id={id} onClick={disabled ? undefined : this.onClick} disabled={disabled}>
                                <FontAwesomeIcon icon={iconName ? ['fad', iconName] : ['fad', 'file-import']} />
                            </IconButton>
                            <UncontrolledTooltip placement="bottom-end" target={id}>
                                {environment ? lang.CAMERA : lang.UPLOAD}
                            </UncontrolledTooltip>
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    }
}
