import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import { cl_gray300 } from '../../_theme/colors';
import { IBaseView } from '../../_base/base-view';
import { MenuFileUpload } from '../../_base/menu/menu-file-upload';
import { MenuDelete } from '../../_base/menu/menu-delete';

const Image = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    align-self: stretch;
    height: 100%;
`;

const IconCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 2rem;
    justify-content: center;
    align-items: center;
    background-color: white;

    .btn {
        margin-top: 0.25rem;
        padding-top: 0.5rem;
        border-radius: 2rem;
        padding: 0px;
        width: 2rem;
        height: 2rem;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    }
`;

const ImageCol = styled.div`
    flex: 1 1 auto;
    display: block;
    border: solid 1px ${cl_gray300};
`;

const CarouselContainer = styled.div`
    box-sizing: border-box;
    display: block;
    position: relative;
    background-color: ${cl_gray300};
`;

const ImgContainer = styled.span`
    text-align: center;
    display: block;
`;

const Img = styled.img`
    //max-width:100%;
    //height:130px;
    object-fit: contain;
    width: 100%;
    height: 100%;
`;

@inject('rootStore')
@observer
export class InspectionCollectImages extends React.Component<IBaseView, {}> {
    animating: boolean = false;

    @observable
    activeIndex: number = 0;

    setAnimating = (value: boolean) => (this.animating = value);

    @action.bound
    next() {
        const {
            rootStore: {
                inspectionStore: { images },
            },
        } = this.props;
        if (this.animating) return;
        this.activeIndex = this.activeIndex === images.length - 1 ? 0 : this.activeIndex + 1;
    }

    @action.bound
    previous() {
        const {
            rootStore: {
                inspectionStore: { images },
            },
        } = this.props;
        if (this.animating) return;
        this.activeIndex = this.activeIndex === 0 ? images.length - 1 : this.activeIndex - 1;
    }

    @action.bound
    goToIndex(newIndex: number) {
        if (this.animating) return;
        this.activeIndex = newIndex;
    }

    renderSlides = () => {
        const {
            rootStore: {
                inspectionStore: { images },
            },
        } = this.props;
        return images.map((item) => {
            return (
                <CarouselItem onExiting={() => this.setAnimating(true)} onExited={() => this.setAnimating(false)} key={item.src}>
                    <ImgContainer>
                        <Img src={item.src} alt={item.altText} />
                    </ImgContainer>
                </CarouselItem>
            );
        });
    };

    render() {
        const {
            rootStore: {
                inspectionStore: { dsImage, images, uploadFile },
            },
        } = this.props;

        return (
            <Image>
                <IconCol>
                    <MenuFileUpload id={'xxx-btn-upload'} onDrop={uploadFile} disabled={false} />
                    <MenuDelete id={'xxx-btn-delete'} ds={dsImage} />
                </IconCol>
                <ImageCol>
                    <CarouselContainer className="CarouselContainer">
                        <Carousel activeIndex={this.activeIndex} next={this.next} previous={this.previous}>
                            <CarouselIndicators items={images} activeIndex={this.activeIndex} onClickHandler={this.goToIndex} />
                            {this.renderSlides()}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                        </Carousel>
                    </CarouselContainer>
                </ImageCol>
            </Image>
        );
    }
}
