import { IField } from '../components/dataset/IField';
import { userColumns } from './User';
import moment from 'moment/moment';

export interface ILogbook {
    id: number;
    date: string;
    user: string;
    typ: string;
    action: string;
    comment1: string;
    ipaddress: string;
    key1: string;
    key2: string;
    key3: string;
}

export const logbookColumns: IField<ILogbook>[] = [
    {
        fieldName: 'id',
        dataType: 'number',
        //label: 'PPAP_ORDERNO',
        primaryKey: true,
        insert: 'hide',
        grid: {
            width: 80,
        },
    },
    {
        fieldName: 'date',
        //label: 'PROJECTTASK_DUEDATE',
        dataType: 'string',
        insert: 'show',
        grid: {
            cellRenderer: (params: any) => moment(params.value).format('YYYY-MM-DD HH:mm:ss'),
            width: 130,
        },
        input: 'calendar',
        calendar: {
            popperPlacement: 'top',
        },
    },
    {
        fieldName: 'ipaddress',
        dataType: 'string',
        grid: {
            width: 130,
        },
    },
    {
        fieldName: 'user',
        label: 'USER_USERNAME',
        dataType: 'string',
        input: 'selectdlg',
        useAsFilter: true,
        selectdlg: {
            url: '/gridApi/user/',
            columns: userColumns,
            field: 'username',
        },
        grid: {
            width: 130,
        },
    },

    {
        fieldName: 'action',
        dataType: 'string',
        //label: 'PPAP_ORDERNO',
        //primaryKey: true,
        insert: 'hide',
        grid: {
            width: 100,
        },
    },
    {
        fieldName: 'typ',
        dataType: 'string',
        //label: 'PPAP_ORDERNO',
        //primaryKey: true,
        insert: 'hide',
        grid: {
            width: 130,
        },
    },
    {
        fieldName: 'key1',
        dataType: 'string',
        //label: 'PPAP_ORDERNO',
        //primaryKey: true,
        insert: 'hide',
        grid: {
            width: 130,
        },
    },
    {
        fieldName: 'key2',
        dataType: 'string',
        //label: 'PPAP_ORDERNO',
        //primaryKey: true,
        insert: 'hide',
        grid: {
            width: 130,
        },
    },
    {
        fieldName: 'key3',
        dataType: 'string',
        //label: 'PPAP_ORDERNO',
        //primaryKey: true,
        insert: 'hide',
        grid: {
            width: 130,
        },
    },
    {
        fieldName: 'comment1',
        dataType: 'string',
        //label: 'PPAP_ORDERNO',
        //primaryKey: true,
        insert: 'hide',
        grid: {
            flex: 1,
        },
    },
];
