import { Button } from 'reactstrap';
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../../_base/base-view';

export const GembaNext = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                mobileGembaStore: { next },
            },
        } = props;
        return (
            <div className={'gemba-next'}>
                <Button onClick={next}>Next</Button>
            </div>
        );
    }),
);
