import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { IBaseView } from '../../_base/base-view';
import { Dialog } from '../../../components/dialog/Dialog';
import { DbForm } from '../../../components/dbform/db-form';
import { DbField } from '../../../components/dbform/db-field';

@inject('rootStore')
@observer
class _InspectionCollectInfo extends React.Component<IBaseView, {}> {
    @observable
    isOpen: boolean = false;

    @action.bound
    toggleOpen(e: any) {
        this.isOpen = !this.isOpen;
        e.preventDefault();
    }

    render() {
        const {
            rootStore: {
                inspectionStore: { dsInspectionQuestion },
            },
            className,
        } = this.props;
        return (
            <div className={className}>
                <Button onClick={this.toggleOpen} color="primary">
                    <FontAwesomeIcon icon="info" />
                </Button>
                <Dialog isOpen={this.isOpen} title={''} onOk={this.toggleOpen} onCancel={this.toggleOpen}>
                    <DbForm ds={dsInspectionQuestion}>
                        <DbField ds={dsInspectionQuestion} field="requirements" readOnly={true} />
                        {/*<DbField ds={dsInspectionQuestion} field="reference" readOnly={true}/>*/}
                    </DbForm>
                </Dialog>
            </div>
        );
    }
}

export const InspectionCollectInfo = styled(_InspectionCollectInfo)`
    display: inline-block;
    margin-top: -1.5rem;

    .btn {
        border-radius: 2rem;
        width: 2rem;
        height: 2rem;
        padding-top: 2px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    }
`;
