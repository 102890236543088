import * as React from 'react';
import { CardBody, Col, Container, Row } from 'reactstrap';
import { IBaseView } from '../_base/base-view';
import { inject, observer } from 'mobx-react';
import { DbForm } from '../../components/dbform/db-form';
import { action } from 'mobx';
import axios from 'axios';
import { authorizer } from '../../components/dataset/authorizer';
import { historyAdapter } from '../_layout/app';
import { TableCard } from '../_base/styled/table-card';
import { IconButton } from '../_base/styled/icon-button';

@inject('rootStore')
@observer
export class ChangePasswordView extends React.Component<IBaseView, {}> {
    @action.bound
    async onChangePassword() {
        const {
            rootStore: {
                authStore: { username, cdsPassword, doLogout },
            },
        } = this.props;
        if (cdsPassword.validator.check(cdsPassword.actual)) {
            await cdsPassword.post();
            const passchange = {
                username: username,
                oldpasswort: cdsPassword.actual.oldpassword,
                newpassword: cdsPassword.actual.newpassword1,
            };
            await axios.post('/gridApi/changepassword/', passchange, authorizer());
            doLogout();
        }
    }

    @action.bound
    async onCancel() {
        historyAdapter.goBack();
    }

    render() {
        const {
            rootStore: {
                langStore: { lang },
                authStore: { cdsPassword },
            },
        } = this.props;
        return (
            <Container>
                <Row style={{ paddingTop: '1rem' }}>
                    <Col md={3} />
                    <Col md={6}>
                        <TableCard title="SETTINGS" icon="user">
                            <CardBody>
                                <DbForm ds={cdsPassword} doOK={this.onChangePassword} colCount={1} />
                                <p>{lang.USER_CHANGE_PASSWORD_NOTE}</p>
                                <IconButton id="change-btn" onClick={this.onChangePassword}>
                                    {lang.USER_CHANGE_PASSWORD}
                                </IconButton>{' '}
                                <IconButton id="cancel-btn" onClick={this.onCancel}>
                                    {lang.CANCEL}
                                </IconButton>
                            </CardBody>
                        </TableCard>
                    </Col>
                </Row>
            </Container>
        );
    }
}
