import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../../_base/base-view';

export const GembaPosNeg = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                mobileGembaStore: { dsGembaFinding },
            },
        } = props;

        const click = (posneg: string) => {
            return action(() => {
                dsGembaFinding.actual.posneg = dsGembaFinding.actual.posneg === posneg ? '' : posneg;
            });
        };

        return (
            <div className={'gemba-posneg'}>
                <button className={dsGembaFinding.actual?.posneg === 'positive' ? 'green-active' : 'green'} onClick={click('positive')}>
                    positive
                </button>
                <button className={dsGembaFinding.actual?.posneg === 'negative' ? 'red-active' : 'red'} onClick={click('negative')}>
                    negative
                </button>
            </div>
        );
    }),
);
