import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../../_base/base-view';

export const GembaHeader = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                mobileGembaStore: { ds, dsProject, dsProjectActivity },
            },
        } = props;
        return (
            <div className={'gemba-header'}>
                {dsProject.actual?.projectno + ' - ' + dsProject.actual?.project}
                <br />
                <FontAwesomeIcon icon={['fad', 'walking']} /> {dsProjectActivity.actual?.link + ' - ' + ds.actual?.gemba}
            </div>
        );
    }),
);
