import { cl_blue } from '../../../_theme/colors';
import * as React from 'react';

export interface IEintrag {
    label: string;
    value: string;
}

export const Eintrag = ({ label, value }: IEintrag) => (
    <>
        <td>
            <span style={{ fontWeight: 'bold' }}>{label}</span>
        </td>
        <td>
            <span style={{ color: cl_blue }}>{value}</span>
        </td>
    </>
);
