import * as React from 'react';
import styled from 'styled-components';
import { IBaseView } from '../base-view';
import { inject, observer } from 'mobx-react';
import { Card } from 'reactstrap';
import { BaseCardHeader } from '../card/base-card-header';
import { ILangKeys } from '../../../language/ILang';
import { cl_gray100 } from '../../_theme/colors';
import { BaseCarousel } from './base-carousel';
import { MenuFileUpload } from '../menu/menu-file-upload';
import { action, observable, runInAction } from 'mobx';
import { IDataset } from '../../../components/dataset/IDataset';
import { MenuDelete } from '../menu/menu-delete';

const Body = (props: any) => <div className="body">{props.children}</div>;

const Menu = ({ menu, children }: any) => <div className="menu">{menu ? menu : children}</div>;

const Images = (props: any) => <div className="images">{props.children}</div>;

interface IBaseCardImage extends IBaseView {
    title: ILangKeys;
    showBackButton?: boolean;
    rightHeaderMenu?: any;
    height?: number;
    menu?: any;
    images: any[];
    uploadFile: (acceptFile: any[]) => Promise<void>;
    ds: IDataset<any>;
    hideInsert?: boolean;
    hideDelete?: boolean;
}

@inject('rootStore')
@observer
class _BaseCardImage extends React.Component<IBaseCardImage, {}> {
    @observable
    activeIndex: number = 0;

    @action.bound
    setActiveIndex(activeIndex: number) {
        const { ds } = this.props;
        this.activeIndex = activeIndex;
        ds.cursor = activeIndex;
    }

    @action.bound
    async onClickUpload(acceptFile: any[]) {
        const { images, uploadFile } = this.props;
        await uploadFile(acceptFile);
        runInAction(() => {
            this.setActiveIndex(images.length);
        });
    }

    @action.bound
    onAfterDelete() {
        const { ds } = this.props;
        this.activeIndex = ds.cursor;
    }

    render() {
        const { className, title, rightHeaderMenu, showBackButton, height, menu, ds, hideInsert, hideDelete } = this.props;
        const { images } = this.props;
        return (
            <Card className={className}>
                <BaseCardHeader title={title} rightHeaderMenu={rightHeaderMenu} showBackButton={showBackButton} />
                <Body height={height}>
                    <Menu menu={menu}>
                        {!hideInsert ? <MenuFileUpload id="upload" onDrop={this.onClickUpload} disabled={false} /> : null}
                        {!hideDelete ? (
                            <MenuDelete id={'xxx-btn-delete'} ds={ds} disabled={ds.data.length === 0} onAfterDelete={this.onAfterDelete} />
                        ) : null}
                    </Menu>
                    <Images>
                        <BaseCarousel images={images} height={height} activeIndex={this.activeIndex} setActiveIndex={this.setActiveIndex} />
                    </Images>
                </Body>
            </Card>
        );
    }
}

export const BaseCardImage = styled(_BaseCardImage)`
    color: black;
    position: relative;
    height: 100%;
    overflow: auto;
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.45);
    border-radius: ${(props) => props.theme.cardRadius};
    border: 0;

    .body {
        padding: 0;
        position: relative;
        border: 0;
        display: flex;
        height: ${(props) => (props.height ? props.height : 300)}px;

        .menu {
            flex: 0 0 2rem;
            background-color: ${cl_gray100};

            .btn {
                padding: 0;
                width: 2rem;
                height: 2rem;
                border-radius: 1rem;
                margin: 2px;
                box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
            }
        }

        .images {
            flex: 1 1 auto;
            background-color: darkgrey;
        }
    }
`;
