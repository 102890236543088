import { BaseStore, IBaseStore } from './base-store';
import { customerColumns, ICustomer } from '../models/Customer';
import { IRootStore } from '../routes/root-store';
import { dsState, IDataset } from '../components/dataset/IDataset';
import { action, computed, observable, runInAction } from 'mobx';
import { IProject, projectColumns } from '../models/Project';
import { Dataset } from '../components/dataset/dataset';
import { RouterState } from 'mobx-state-router';
import axios from 'axios';
import { IUser, userColumns } from '../models/User';
import * as R from 'ramda';
import { includes } from '../components/lib/Includes';

export interface ICustomerStore extends IBaseStore<ICustomer> {
    ds: IDataset<ICustomer>;
    dsProject: IDataset<IProject>;
    uploadLogo: (acceptFile: any[]) => Promise<void>;
    uploadIcon: (acceptFile: any[]) => Promise<void>;
    logoUrl: string;
    iconUrl: string;
    dsUser: IDataset<IUser>;
}

export class CustomerStore extends BaseStore<ICustomer> implements ICustomerStore {
    @observable
    dsProject: IDataset<IProject>;

    @observable
    dsUser: IDataset<IUser>;

    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/customer/', customerColumns);
        //
        this.dsProject = new Dataset<IProject>('/gridApi/project/', projectColumns);
        this.dsProject.setMasterSource(this.ds, [{ field: 'customerno', masterField: 'customerno' }]);

        this.dsUser = new Dataset<IUser>(
            '/gridApi/user/',
            R.clone(userColumns).map((column) => {
                if (includes(['supplierno', 'supplier1', 'customerno', 'customer1'], column.fieldName)) {
                    column.hide = 'table';
                }
                return column;
            }),
        );
        this.dsUser.setMasterSource(this.ds, [{ field: 'customerno', masterField: 'customerno' }]);
    }

    @action.bound
    async openDetails() {
        await this.dsProject.open();
        await this.dsUser.open();
    }

    @action.bound
    closeDetails() {
        this.dsUser.close();
        this.dsProject.close();
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        if (toState.routeName === 'customertable') {
            await this.open();
            if (fromState.routeName === 'catalog') {
                this.ds.locate(this.ds.pkFields, fromState.params);
            }
        } else if (toState.routeName === 'customer') {
            await this.open(toState.params as any);
            await this.openDetails();
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'customer') {
            if (this.ds.state === dsState.dsEdit) {
                await this.ds.post();
            }
            this.closeDetails();
            this.close();
            Promise.resolve();
        } else if (fromState.routeName === 'customertable') {
            this.close();
            Promise.resolve();
        }
    }

    @action.bound
    async uploadLogo(acceptFile: any[]): Promise<void> {
        const url = '/gridApi/customerlogo/upload';
        const file = acceptFile[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('customerno', this.ds.actual.customerno);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        await axios.post(url, formData, config);
        runInAction(async () => {
            this.ds.actual.logo = file.name;
        });
    }

    @action.bound
    async uploadIcon(acceptFile: any[]): Promise<void> {
        const url = '/gridApi/customericon/upload';
        const file = acceptFile[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('customerno', this.ds.actual.customerno);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        await axios.post(url, formData, config);
        runInAction(async () => {
            this.ds.actual.icon = file.name;
        });
    }

    @computed
    get logoUrl() {
        if (this.ds.actual?.logo) {
            return '/gridApi/customerlogo/download/' + this.ds.actual?.customerno + '/' + this.ds.actual?.logo;
        } else return '';
    }

    @computed
    get iconUrl() {
        if (this.ds.actual?.icon) {
            return '/gridApi/customericon/download/' + this.ds.actual?.customerno + '/' + this.ds.actual?.icon;
        } else return '';
    }
}
