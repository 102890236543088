import { IField } from '../components/dataset/IField';
import { enumToArray } from '../components/lib/enumToArray';
import { ILangKeys } from '../language/ILang';
import { IGroupDef } from '../components/dataset/groupDef';

export enum PPAP_DOC {
    OPTIONAL = 100,
    MANDATORY = 200,
}

export const ppapDocText = (doc: PPAP_DOC): ILangKeys => {
    switch (doc) {
        case PPAP_DOC.MANDATORY:
            return 'PPAP_MANDATORY';
        case PPAP_DOC.OPTIONAL:
            return 'PPAP_OPTIONAL';
        default:
            return 'Unknown';
    }
};

export enum PPAP_SR {
    SUBMIT = 100,
    RETAIN = 200,
    SUBMIT_OR_RETAIN = 300,
}

export const ppapSRText = (sr: PPAP_SR): ILangKeys => {
    switch (sr) {
        case PPAP_SR.SUBMIT:
            return 'PPAP_SUBMIT';
        case PPAP_SR.RETAIN:
            return 'PPAP_RETAIN';
        case PPAP_SR.SUBMIT_OR_RETAIN:
            return 'PPAP_SUBMIT_OR_RETAIN';

        default:
            return 'Unknown';
    }
};

export interface PpapStandardElement {
    standardno: string;
    elementno: number;
    phase: string;
    reference: string;
    element: string;
    doc1: number;
    sr1: number;
    doc2: number;
    sr2: number;
    doc3: number;
    sr3: number;
    template: string;
    requirements: string;
}

export const ppapStandardElementColumns: IField<PpapStandardElement>[] = [
    {
        fieldName: 'standardno',
        dataType: 'string',
        primaryKey: true,
        insert: 'hide',
        hide: 'table',
        label: 'PPAP_STANDARD',
        rules: ['pk', 'mandatory'],
    },
    {
        fieldName: 'elementno',
        dataType: 'number',
        label: 'PPAP_ELEMENTNO',
        primaryKey: true,
        //hide: 'table',
        rules: ['pk', 'number', 'mandatory'],
        grid: {
            width: 50,
        },
    },
    {
        fieldName: 'element',
        dataType: 'string',
        label: 'PPAP_ELEMENT',
        grid: {
            width: 300,
        },
    },
    {
        fieldName: 'reference',
        dataType: 'string',
        label: 'PPAP_ELEMENT_REFERENCE',
        grid: {
            width: 140,
        },
    },

    {
        fieldName: 'phase',
        dataType: 'string',
        label: 'PPAP_PHASE',
        grid: {
            width: 150,
        },
    },
    {
        fieldName: 'doc1',
        dataType: 'number',
        label: 'PPAP_DOCUMENTATION',
        input: 'select',
        options: enumToArray(PPAP_DOC),
        textFunc: ppapDocText,
        defaultValue: 100,
        grid: {
            width: 100,
        },
    },
    {
        fieldName: 'sr1',
        dataType: 'number',
        label: 'PPAP_SUBMISSION',
        input: 'select',
        options: enumToArray(PPAP_SR),
        textFunc: ppapSRText,
        defaultValue: 100,
        grid: {
            width: 80,
        },
    },
    {
        fieldName: 'doc2',
        dataType: 'number',
        label: 'PPAP_DOCUMENTATION',
        input: 'select',
        options: enumToArray(PPAP_DOC),
        textFunc: ppapDocText,
        defaultValue: 100,
        grid: {
            width: 100,
        },
    },
    {
        fieldName: 'sr2',
        dataType: 'number',
        label: 'PPAP_SUBMISSION',
        input: 'select',
        options: enumToArray(PPAP_SR),
        textFunc: ppapSRText,
        defaultValue: 100,
        grid: {
            width: 80,
        },
    },
    {
        fieldName: 'doc3',
        dataType: 'number',
        label: 'PPAP_DOCUMENTATION',
        input: 'select',
        options: enumToArray(PPAP_DOC),
        textFunc: ppapDocText,
        defaultValue: 100,
        grid: {
            width: 100,
        },
    },
    {
        fieldName: 'sr3',
        dataType: 'number',
        label: 'PPAP_SUBMISSION',
        input: 'select',
        options: enumToArray(PPAP_SR),
        textFunc: ppapSRText,
        defaultValue: 100,
        grid: {
            width: 80,
        },
    },
    {
        fieldName: 'template',
        dataType: 'string',
        label: 'PPAP_ELEMENT_TEMPLATE',
        input: 'fileuploaddlg',
        fileuploaddlg: {
            url: '/gridApi/upload/ppaptemplatedocument/',
        },
    },
    {
        fieldName: 'requirements',
        label: 'PPAP_ELEMENT_REQUIREMENTS',
        dataType: 'string',
        input: 'textarea',
        rows: 4,
        hide: 'table',
    },
];

export const ppapStandardElementGroupDef: IGroupDef[] = [
    {
        field: 'elementno',
    },
    {
        field: 'reference',
    },
    {
        field: 'phase',
    },
    {
        field: 'element',
    },
    {
        headerName: 'PPAP_RISKLEVEL_LOW',
        children: [
            {
                field: 'doc1',
                label: 'PPAP_DOCUMENTATION',
            },
            {
                field: 'sr1',
                label: 'PPAP_SUBMIT_OR_RETAIN',
            },
        ],
    },
    {
        headerName: 'PPAP_RISKLEVEL_MEDIUM',
        children: [
            {
                field: 'doc2',
                label: 'PPAP_DOCUMENTATION',
            },
            {
                field: 'sr2',
                label: 'PPAP_SUBMIT_OR_RETAIN',
            },
        ],
    },
    {
        headerName: 'PPAP_RISKLEVEL_HIGH',
        children: [
            {
                field: 'doc3',
                label: 'PPAP_DOCUMENTATION',
            },
            {
                field: 'sr3',
                label: 'PPAP_SUBMIT_OR_RETAIN',
            },
        ],
    },
];
