import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { FormGroup } from 'reactstrap';
import { action } from 'mobx';
import { cl_gray600, cl_green, cl_red, cl_yellow } from '../../_theme/colors';
import { AuditScore } from '../../../models/AuditQuestion';
import { IAuditRating } from './audit-rating';

const Button = ({ text, className, onClick }: any) => (
    <div className={className} onClick={onClick}>
        {text}
    </div>
);

@inject('rootStore')
@observer
class _AuditRatingVestasSba extends React.Component<IAuditRating, {}> {
    onClickButton = (score: number) => {
        const {
            rootStore: {
                authStore: { username },
            },
            audit,
            question,
            readOnly,
        } = this.props;
        return action(() => {
            if (username === audit?.leadauditor && !readOnly) {
                question.score = question.score === score ? AuditScore.UNRATED : score;
            }
        });
    };

    render() {
        const { question, className } = this.props;

        return (
            <div className={className}>
                <FormGroup tag="fieldset">
                    {question?.level01 ? (
                        <Button className={question.score === 0 ? 'level gray' : 'level'} onClick={this.onClickButton(0)} text={question.level01} />
                    ) : null}
                    {question?.level02 ? (
                        <Button
                            className={question.score === 3.5 ? 'level gray' : 'level'}
                            onClick={this.onClickButton(3.5)}
                            text={question.level02}
                        />
                    ) : null}
                    {question?.level03 ? (
                        <Button className={question.score === 7 ? 'level gray' : 'level'} onClick={this.onClickButton(7)} text={question.level03} />
                    ) : null}
                    {question?.level04 ? (
                        <Button
                            className={question.score === 8.5 ? 'level gray' : 'level'}
                            onClick={this.onClickButton(8.5)}
                            text={question.level04}
                        />
                    ) : null}
                    {question?.level05 ? (
                        <Button className={question.score === 10 ? 'level gray' : 'level'} onClick={this.onClickButton(10)} text={question.level05} />
                    ) : null}
                    <Button className={question?.score === -2 ? 'level gray' : 'level'} onClick={this.onClickButton(-2)} text="n.a" />
                </FormGroup>
            </div>
        );
    }
}

export const AuditRatingVestasSba = styled(_AuditRatingVestasSba)`
    cursor: pointer;

    .level {
        background-color: ${(props) => props.theme.bgBody};
        color: ${(props) => props.theme.color};
        border: ${(props) => props.theme.border};
        margin-bottom: 0.5rem;
        padding: 0.5rem;
        border-radius: ${(props) => props.theme.borderRadius};
        text-align: center;
    }

    .green {
        background-color: ${cl_green};
        color: ${(props) => props.theme.bg};
    }

    .yellow {
        background-color: ${cl_yellow};
        color: ${(props) => props.theme.color};
    }

    .red {
        background-color: ${cl_red};
        color: ${(props) => props.theme.bg};
    }

    .gray {
        background-color: ${cl_gray600};
        color: ${(props) => props.theme.bg};
    }

    .white {
        background-color: ${(props) => props.theme.bg};
        color: ${(props) => props.theme.color};
    }
`;
