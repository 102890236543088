import * as React from 'react';
import { IBaseView } from '../_base/base-view';
import { inject, observer } from 'mobx-react';
import { TableGrid } from '../_base/styled/table-grid';
import { TableLayout } from '../_base/styled/layout/table-layout';
import { BaseTableFilterMenu } from '../_base/table-filter/base-table-filter-menu';
import { UsergroupRight } from '../../models/rights/enum-usergrouprights';

@inject('rootStore')
@observer
export class TaskTableView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                actionStore: { ds },
                authStore: { hasUserRight },
            },
        } = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="actions"
                    icon="tasks"
                    title="ACTIONS"
                    ds={ds}
                    singleRouteName="action"
                    // Abgeklemmt wg #325
                    rightHeaderMenu={
                        hasUserRight(UsergroupRight.UR_FILTER_ALL) ? <BaseTableFilterMenu storeName="actionStore" filtername="action" /> : null
                    }
                    hideInsert={true}
                    hideDelete={true}
                />
            </TableLayout>
        );
    }
}
