import * as React from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../_base/base-view';
import { InspectionCollectQuestion } from './inspection-collect-question';

const MainContent = styled.div`
    box-sizing: border-box;
    display: block;
    overflow-y: auto;
`;

@inject('rootStore')
@observer
class _InspectionCollectMain extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                inspectionStore: { dsInspectionQuestion, actArea, actElement },
            },
            className,
        } = this.props;
        return (
            <div className={className}>
                <h4>{actArea}</h4>
                <h4>{actElement}</h4>
                <MainContent>
                    {dsInspectionQuestion.data
                        .filter((question) => question.area === actArea && question.element === actElement)
                        .map((question, index) => (
                            <InspectionCollectQuestion
                                key={index}
                                question={question}
                                cursor={dsInspectionQuestion.actual.questionno === question.questionno}
                                className={'question'}
                            />
                        ))}
                </MainContent>
            </div>
        );
    }
}

export const InspectionCollectMain = styled(_InspectionCollectMain)`
    display: block;
    height: 100%;
    color: black;
    padding: 0.5rem;
    background-color: white;
    overflow-y: auto;
`;
