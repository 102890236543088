import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Block } from '../report/inspection-report-styled';
import moment from 'moment';
import { IBaseView } from '../../_base/base-view';

@inject('rootStore')
@observer
export class InspectionReportHeader extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                inspectionStore: { ds },
            },
        } = this.props;
        return (
            <>
                <Row>
                    <Col>
                        <h1 style={{ border: 'solid 1px black', textAlign: 'center' }}>Inspection-Report</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Block className={'Block'}>
                            <Row>
                                <Col>
                                    <p>Inspectionno:{ds.actual?.inspectionno}</p>
                                    <p>Inspector:{ds.actual?.inspector}</p>
                                    <p>Due date:{moment(ds.actual?.duedate).format('DD-MM-YYYY')}</p>
                                </Col>
                                <Col>
                                    <p>Customer:{ds.actual?.customerno}</p>
                                    <p>Inspection:{ds.actual?.inspection}</p>
                                    <p>Inspection Plan:{ds.actual?.planno}</p>
                                </Col>
                            </Row>
                        </Block>
                    </Col>
                </Row>
            </>
        );
    }
}
