import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

interface IScoreBadge2 {
    text: string;
    color: string;
    className?: any;
}

const _ScoreBadge2 = observer(({ text, color, className }: IScoreBadge2) => <div className={className}>{text}</div>);

export const ScoreBadge2 = styled(_ScoreBadge2)`
    display: block;
    background-color: ${(props) => props.color};
    color: white;
    width: 100%;
    text-align: center;
    font-weight: 500;
    line-height: 1.2;
    border-radius: ${(props) => props.theme.borderRadius};
`;
