import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../_base/base-view';
import { UsergroupRight } from '../../../models/rights/enum-usergrouprights';
import { BaseTableFilterMenu } from '../../_base/table-filter/base-table-filter-menu';
import { TableGrid } from '../../_base/styled/table-grid';
import { TableLayout } from '../../_base/styled/layout/table-layout';

@inject('rootStore')
@observer
export class InspectionTableView extends React.Component<IBaseView, {}> {
    // Todo
    /*    @action.bound
        goToCollect() {
            const {rootStore: {routerStore, inspectionStore: {ds}}} = this.props;
            routerStore.goTo('auditcollect', {
                auditno: ds.actual.auditno
            })
        }*/

    render() {
        const {
            rootStore: {
                inspectionStore: { ds },
                authStore: { hasUserRight },
            },
        } = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="inspection"
                    icon="clipboard-check"
                    title="INSPECTIONS"
                    ds={ds}
                    singleRouteName="inspection"
                    rightHeaderMenu={
                        hasUserRight(UsergroupRight.UR_FILTER_ALL) ? (
                            <BaseTableFilterMenu storeName="inspectionStore" filtername="inspection" />
                        ) : null
                    }
                    hideInsert={!hasUserRight(UsergroupRight.UR_INSPECTION_INSPECTION_INSERT)}
                    hideDelete={!hasUserRight(UsergroupRight.UR_INSPECTION_INSPECTION_DELETE) || ds.actual?.projectno !== ''}
                />
            </TableLayout>
        );
    }
}
