import { RouterState, RouterStore } from 'mobx-state-router';
import { routerRoutes } from './routes';
import { AppStore, IAppStore } from '../stores/app-store';
import { ILangStore, LangStore } from '../stores/lang-store';
import { IBaseStore } from '../stores/base-store';
import { usergroupColumns } from '../models/Usergroup';
import { IUsergroupStore, UsergroupStore } from '../stores/usergroup-store';
import { CatalogStore, ICatalogStore } from '../stores/catalog-store';
import { IProductStore, ProductStore } from '../stores/product-store';
import { CustomerStore, ICustomerStore } from '../stores/customer-store';
import { IProjectStore, ProjectStore } from '../stores/project-store';
import { AuthStore, IAuthStore } from '../stores/auth-store';
import { IPpapStandardStore, PpapStandardStore } from '../stores/ppap-standard-store';
import { IPPAPStore, PpapStore } from '../stores/ppap-store';
import { ISupplierStore, SupplierStore } from '../stores/supplier-store';
import { ActionStore, IActionStore } from '../stores/action-store';
import { AuditTemplateStore, IAuditTemplateStore } from '../stores/audit-template-store';
import { IUserStore, UserStore } from '../stores/user-store';
import { AuditOnlineStore, IAuditOnlineStore } from '../stores/audit-online-store';
import { ConfigStore, IConfigStore } from '../stores/config-store';
import { IInspectionPlanStore, InspectionPlanStore } from '../stores/inspection-plan-store';
import { IInspectionStore, InspectionStore } from '../stores/inspection-store';
import { DashboardStore, IDashboardStore } from '../stores/dashboard-store';
import { IProjectActionStore, ProjectActionStore } from '../stores/project-action-store';
import { GembaStore, IGembaStore } from '../stores/gemba-store';
import { ActivityStore, IActivityStore } from '../stores/activity-store';
import { AuditOfflineStore, IAuditOfflineStore } from '../stores/audit-offline-store';
import { IGembaTemplate } from '../models/GembaTemplate';
import { GembaTemplateStore } from '../stores/gemba-template-store';
import { IMobileStore, MobileStore } from '../stores/mobile-store';
import { IMobileGembaStore, MobileGembaStore } from '../stores/mobile-gemba-store';
import { ILogbook } from '../models/Logbook';
import { LogbookStore } from '../stores/logbookStore';

export interface IRootStore {
    routerStore: RouterStore;
    appStore: IAppStore;
    authStore: IAuthStore;
    langStore: ILangStore;
    actionStore: IActionStore;
    auditStore: IAuditOnlineStore;
    auditOfflineStore: IAuditOfflineStore;
    auditTemplateStore: IAuditTemplateStore;
    dashboardStore: IDashboardStore;

    // configuration
    configStore: IConfigStore;
    logbookStore: IBaseStore<ILogbook>;
    //administration
    userStore: IUserStore;
    usergroupStore: IUsergroupStore;
    // base data
    productStore: IProductStore;
    customerStore: ICustomerStore;
    supplierStore: ISupplierStore;
    // plans
    catalogStore: ICatalogStore;
    //
    projectStore: IProjectStore;
    projectActionStore: IProjectActionStore;
    //
    ppapStandardStore: IPpapStandardStore;
    ppapStore: IPPAPStore;
    //
    inspectionPlanStore: IInspectionPlanStore;
    inspectionStore: IInspectionStore;
    //
    gembaTemplateStore: IBaseStore<IGembaTemplate>;
    gembaStore: IGembaStore;
    //
    activityStore: IActivityStore;
    //
    mobileStore: IMobileStore;
    mobileGembaStore: IMobileGembaStore;
    //
    divStore: IBaseStore<any>;

    [index: string]: any;
}

const notFound = new RouterState('notFound');

export class RootStore implements IRootStore {
    routerStore: RouterStore = new RouterStore(this, routerRoutes, notFound);
    appStore: IAppStore = new AppStore(this);
    authStore: IAuthStore = new AuthStore(this);
    dashboardStore: IDashboardStore = new DashboardStore(this);
    langStore: ILangStore = new LangStore(this);
    //Configuration
    configStore: IConfigStore = new ConfigStore(this);
    logbookStore: IBaseStore<ILogbook> = new LogbookStore(this);
    //Administration
    userStore: IUserStore = new UserStore(this);
    usergroupStore: IUsergroupStore = new UsergroupStore(this, '/gridApi/usergroup/', usergroupColumns);
    // Base data,
    productStore: IProductStore = new ProductStore(this);
    customerStore: ICustomerStore = new CustomerStore(this);
    supplierStore: ISupplierStore = new SupplierStore(this);
    //Plans
    catalogStore: ICatalogStore = new CatalogStore(this);
    // Audit
    auditTemplateStore: IAuditTemplateStore = new AuditTemplateStore(this);
    //auditStore: IAuditStore = new AuditStore(this);
    auditStore: IAuditOnlineStore = new AuditOnlineStore(this);
    auditOfflineStore: IAuditOfflineStore = new AuditOfflineStore(this);
    // Action
    actionStore: IActionStore = new ActionStore(this);
    //
    projectStore: IProjectStore = new ProjectStore(this);
    projectActionStore: IProjectActionStore = new ProjectActionStore(this);
    //
    ppapStandardStore: IPpapStandardStore = new PpapStandardStore(this);
    ppapStore: IPPAPStore = new PpapStore(this);
    //
    inspectionPlanStore: IInspectionPlanStore = new InspectionPlanStore(this);
    inspectionStore: IInspectionStore = new InspectionStore(this);
    //
    gembaTemplateStore: IBaseStore<IGembaTemplate> = new GembaTemplateStore(this);
    gembaStore: IGembaStore = new GembaStore(this);
    //
    activityStore: IActivityStore = new ActivityStore(this);
    //
    mobileStore: IMobileStore = new MobileStore(this);
    mobileGembaStore: IMobileGembaStore = new MobileGembaStore(this);
    //
    divStore: IBaseStore<any> = null;
}
