import React from 'react';

export class CheckboxRender extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.checkedHandler = this.checkedHandler.bind(this);
    }
    checkedHandler(event: any) {
        let checked = event.target.checked;
        let colId = this.props.column.colId;
        this.props.node.setDataValue(colId, checked);
    }
    render() {
        return (
            <input
                type="checkbox"
                //onClick={this.checkedHandler}
                checked={this.props.value}
                onChange={() => {}}
            />
        );
    }
}
