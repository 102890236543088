export const PpapCommentRenderer = (props: any) =>
    props.data.type ? (
        <>
            file :{' '}
            <a
                href={
                    '/gridApi/ppaporderdocument/' +
                    props.data.ppapno +
                    '/' +
                    props.data.elementno +
                    '/' +
                    props.data.commentno +
                    '/' +
                    props.data.document
                }
                target="_blank"
                rel="noreferrer"
            >
                {props.data.document}{' '}
            </a>
        </>
    ) : (
        props.data.comment
    );
