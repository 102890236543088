import { IBaseView } from '../../../_base/base-view';
import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '../../../_base/styled/icon-button';

interface IPpapOrderPrintMenu extends IBaseView {
    goToReport: () => Promise<void>;
}

@inject('rootStore')
@observer
export class PpapOrderPrintMenu extends React.Component<IPpapOrderPrintMenu, {}> {
    @observable
    isopen = false;

    @action.bound
    toggle() {
        this.isopen = !this.isopen;
    }

    render() {
        const {
            rootStore: {
                ppapStore: { ds },
                langStore: { lang },
            },
            goToReport,
        } = this.props;
        const hrefpdf = '/gridApi/ppapmergedpdf/' + ds.actual?.ppapno;

        return (
            <Dropdown style={{ display: 'inline-block' }} isOpen={this.isopen} toggle={this.toggle} direction="down">
                <DropdownToggle tag="span">
                    <IconButton id="id_download_report2">
                        <FontAwesomeIcon icon={['fad', 'print']} />
                    </IconButton>
                </DropdownToggle>
                <DropdownMenu style={{ fontSize: 14 }} right={true}>
                    <DropdownItem onClick={goToReport}>{lang.REPORT}</DropdownItem>
                    <DropdownItem onClick={() => {}}>
                        <a style={{ color: 'black' }} href={hrefpdf} rel="noreferrer" target="_blank">
                            {lang.ACTION_ATTACHEMENTS}
                        </a>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}
