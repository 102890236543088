import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { UsergroupRight } from '../../../../models/rights/enum-usergrouprights';
import { TableGrid } from '../../../_base/styled/table-grid';
import { BaseTableFilterMenu } from '../../../_base/table-filter/base-table-filter-menu';
import { TableLayout } from '../../../_base/styled/layout/table-layout';

@inject('rootStore')
@observer
export class ProjectTableView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                projectStore: { ds },
                authStore: { hasUserRight },
            },
        } = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="project"
                    icon="project-diagram"
                    ds={ds}
                    title="PROJECTS"
                    singleRouteName="project"
                    rightHeaderMenu={
                        hasUserRight(UsergroupRight.UR_FILTER_ALL) ? <BaseTableFilterMenu storeName="projectStore" filtername="project" /> : null
                    }
                    hideInsert={!hasUserRight(UsergroupRight.UR_PROJECT_PROJECT_INSERT)}
                    hideDelete={!hasUserRight(UsergroupRight.UR_PROJECT_PROJECT_DELETE)}
                />
            </TableLayout>
        );
    }
}
