import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { DbForm } from '../../../../components/dbform/db-form';
import { Col, Row } from 'reactstrap';
import { DbField } from '../../../../components/dbform/db-field';

@inject('rootStore')
@observer
export class PpapElementTab extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                ppapStore: { ds, dsPpapOrderElement, statusElementOptionsFunc },
                authStore: { username },
            },
        } = this.props;
        return (
            <div style={{ padding: '1rem' }}>
                <DbForm ds={dsPpapOrderElement}>
                    <Row>
                        <Col>
                            <DbField ds={dsPpapOrderElement} field="element" readOnly={true} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DbField ds={dsPpapOrderElement} field="doc" readOnly={true} />
                            <DbField ds={dsPpapOrderElement} field="responsible" readOnly={username !== ds.actual?.owner} />
                        </Col>
                        <Col>
                            <DbField ds={dsPpapOrderElement} field="sr" readOnly={username !== ds.actual?.owner} />
                            <DbField ds={dsPpapOrderElement} field="status" optionsFunc={statusElementOptionsFunc} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DbField
                                ds={dsPpapOrderElement}
                                field="startdate"
                                optionsFunc={statusElementOptionsFunc}
                                readOnly={username !== ds.actual?.owner}
                            />
                        </Col>
                        <Col>
                            <DbField
                                ds={dsPpapOrderElement}
                                field="duedate"
                                optionsFunc={statusElementOptionsFunc}
                                readOnly={username !== ds.actual?.owner}
                            />
                        </Col>
                    </Row>
                </DbForm>
            </div>
        );
    }
}
