import moment from 'moment';
import { Validator } from './validator';
import { IField } from './IField';

export enum dsState {
    dsInactive = 0,
    dsPending = 1,
    dsBrowse = 2,
    dsEdit = 3,
    dsInsert = 4,
}

export const dateCellRenderer = (params: any) => (params.value ? moment(params.value).format('YYYY-MM-DD') : '');

export const datetimeCellRenderer = (params: any) => moment(params.value).format('YYYY-MM-DD HH:mm');

export interface IMasterField {
    field: string;
    masterField: string;
}

export interface IDataset<T> {
    dataUrl: string;
    columns: IField<T>[];
    state: dsState;
    filter: T | {};
    data: T[];
    errorMessage: string;
    validator: Validator<T>;
    cursor: number;
    open: () => Promise<void>;
    post: () => Promise<void>;
    delete: () => Promise<void>;
    close: () => void;
    actual: T;
    setCursor: (aCursor: number) => void;
    pkFields: [...(keyof T)[]];
    pkValues: Partial<T>;
    locate: (fields: [...(keyof T)[]], value: Partial<T>) => boolean;
    edit: () => void;
    insert: (defaultValues?: T) => void;
    cancel: () => void;
    cancelAsync: () => Promise<void>;
    cursorUrl: string;
    autoRefresh: boolean;
    refresh: (cursor: number) => Promise<void>;
    moveBy: (delta: number) => number;
    first: () => void;
    last: () => void;
    prev: () => void;
    next: () => void;
    hasPrev: boolean;
    hasNext: boolean;
    masterSource?: IDataset<any>;
    setMasterSource: (masterSource?: IDataset<any>, masterfields?: IMasterField[]) => void;
    clearMasterSource: () => void;
    getMasterFilter: () => T | {};
    setLookupDs: (fieldName: string, ds: IDataset<any>) => void;
    //setMasterFilter:any;
    onBeforeInsert: (ds: IDataset<T>) => void;
    onAfterInsert: (ds: IDataset<T>) => void;
    onCanDelete: (ds: IDataset<T>) => Promise<boolean>;
    onAfterDelete: (ds: IDataset<T>) => void;
    onAfterOpen: (ds: IDataset<T>) => void;
    onAfterEdit: (ds: IDataset<T>) => void;
}
