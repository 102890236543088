import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import { TableLayout } from '../../_base/styled/layout/table-layout';
import { TableGrid } from '../../_base/styled/table-grid';
import { UsergroupRight } from '../../../models/rights/enum-usergrouprights';
import { BaseTableFilterMenu } from '../../_base/table-filter/base-table-filter-menu';

@inject('rootStore')
@observer
export class GembaTemplateTableView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                gembaTemplateStore: { ds },
                authStore: { hasUserRight },
            },
        } = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="gemba"
                    icon="walking"
                    title="GEMBA_TEMPLATES"
                    ds={ds}
                    singleRouteName="gembatemplate"
                    rightHeaderMenu={
                        hasUserRight(UsergroupRight.UR_FILTER_ALL) ? (
                            <BaseTableFilterMenu storeName="gembaTemplateStore" filtername="gembatemplate" />
                        ) : null
                    }
                    hideInsert={!hasUserRight(UsergroupRight.UR_GEMBA_TEMPLATE_INSERT)}
                    hideDelete={!hasUserRight(UsergroupRight.UR_GEMBA_TEMPLATE_DELETE)}
                />
            </TableLayout>
        );
    }
}
