import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../../_base/base-view';
import { DbGrid } from '../../../../components/dbgrid/db-grid';

@inject('rootStore')
@observer
export class PpapBlogTab extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                ppapStore: { dsPpapElementComment },
            },
        } = this.props;
        return (
            <div style={{ position: 'relative', height: '100%' }}>
                <DbGrid ds={dsPpapElementComment} />
            </div>
        );
    }
}
