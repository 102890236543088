import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { ProjectActivityTyp } from '../ProjectActivity';

class _ProjectActivityLinkRenderer extends React.Component<any, any> {
    render() {
        const {
            className,
            data: { activitytyp, description1 },
        } = this.props;
        return (
            <span className={className}>
                {activitytyp === ProjectActivityTyp.AUDIT ? (
                    description1.startsWith('Development') || description1.startsWith('Improvement') ? (
                        <FontAwesomeIcon icon={['fad', 'chart-line']} />
                    ) : (
                        <FontAwesomeIcon icon={['fad', 'eye']} />
                    )
                ) : activitytyp === ProjectActivityTyp.DEVELOPMENT ? (
                    <FontAwesomeIcon icon={['fad', 'chart-line']} />
                ) : activitytyp === ProjectActivityTyp.PPAP ? (
                    <FontAwesomeIcon icon={['fad', 'box-check']} />
                ) : activitytyp === ProjectActivityTyp.INSPECTION ? (
                    <FontAwesomeIcon icon={['fad', 'clipboard-check']} />
                ) : activitytyp === ProjectActivityTyp.GEMBA_WALK ? (
                    <FontAwesomeIcon icon={['fad', 'walking']} />
                ) : (
                    <FontAwesomeIcon icon={['fad', 'chart-network']} />
                )}
            </span>
        );
    }
}

export const ProjectActivityLinkRenderer = styled(_ProjectActivityLinkRenderer)`
    padding: 1px 5px;
    border-radius: 6px;
    display: inline-block;
    width: 26px;
    line-height: 16px;
    text-align: center;
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin-top: 4px;
    margin-left: -5px;
    margin-right: 0;
`;
