import * as React from 'react';
import { CardBody } from 'reactstrap';
import styled from 'styled-components';

const _FormBody = (props: any) => <CardBody className={props.className}>{props.children}</CardBody>;

export const FormBody = styled(_FormBody)`
    padding: 1rem;
    overflow: auto;
    display: block;
    width: 100%;
    height: ${(props) => (props.height ? props.height + 'px' : '100%')};
`;
