import React from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const iconRenderer = (icon: IconProp, mask: IconProp, color: string) => {
    const newNode = document.createElement('span');
    ReactDOM.render(<FontAwesomeIcon icon={icon} mask={mask} color={color} />, newNode);
    return newNode;
};
