import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { IDataset } from '../../../../components/dataset/IDataset';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { fileIcon } from '../../../../lib/file-icon';
import { action } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cl_gray300 } from '../../../_theme/colors';

interface IImage {
    onClick: () => void;
    title: string;
    src: string;
    active: string;
}

const Image = observer(({ onClick, title, src, active }: IImage) => {
    const icon: IconName | '' = fileIcon(title);
    return (
        <td>
            <div className={'image ' + active} onClick={action(onClick)}>
                {icon !== '' ? (
                    <div className="icon">
                        <FontAwesomeIcon size="10x" icon={['fad', icon]} />
                    </div>
                ) : (
                    <img src={src} alt="img" />
                )}
            </div>
            <div className="title">
                <a href={src} target={'_blank'} rel="noreferrer">
                    {title}
                </a>
            </div>
        </td>
    );
});

interface IImageListView {
    dsImage: IDataset<any>;
    className?: string;
    imageBaseUrl: string; // /gridApi/image/auditquestionimage/
    key1: 'auditno' | 'gembano';
    key2: 'questionno' | 'findingno';
}

@observer
class _ImageListView extends React.Component<IImageListView, {}> {
    renderRow = (row: number) => {
        const { dsImage, imageBaseUrl, key1, key2 } = this.props;
        return (
            <tr key={row}>
                <Image
                    onClick={() => {
                        dsImage.cursor = row * 2;
                    }}
                    src={imageBaseUrl + dsImage.data[row * 2][key1] + '/' + dsImage.data[row * 2][key2] + '/' + dsImage.data[row * 2].image}
                    title={dsImage.data[row * 2].image}
                    active={dsImage.cursor === row * 2 ? 'active' : ''}
                />
                {dsImage.data.length > row * 2 + 1 ? (
                    <Image
                        onClick={() => {
                            dsImage.cursor = row * 2 + 1;
                        }}
                        src={
                            imageBaseUrl +
                            dsImage.data[row * 2 + 1][key1] +
                            '/' +
                            dsImage.data[row * 2 + 1][key2] +
                            '/' +
                            dsImage.data[row * 2 + 1].image
                        }
                        title={dsImage.data[row * 2 + 1].image}
                        active={dsImage.cursor === row * 2 + 1 ? 'active' : ''}
                    />
                ) : (
                    <td />
                )}
            </tr>
        );
    };

    render() {
        const { dsImage, className } = this.props;
        let numrows = Math.trunc(dsImage.data.length / 2);
        numrows = dsImage.data.length % 2 > 0 ? numrows + 1 : numrows;
        return (
            <div className={className}>
                <table>
                    <tbody>{Array.from(Array(numrows).keys()).map((row) => this.renderRow(row))}</tbody>
                </table>
            </div>
        );
    }
}

export const ImageListView = styled(_ImageListView)`
    table {
        width: 100%;
    }

    td {
        padding: 1rem;
        width: 50%;
    }

    .icon {
        display: inline-block;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${(props) => props.theme.color};
    }

    .image {
        display: block;
        height: 280px;
        border: 1px solid ${cl_gray300};
        padding: 0; // wenn padding <> 0, gibt es Probleme mit Zoom im Browser bzw Windows Anzeigeeinstellung

        img {
            width: 100%;
            height: 274px;
            object-fit: contain;
            object-position: 50% 50%;
        }
    }

    .active {
        border: 3px solid ${cl_gray300};
    }

    .title {
        text-align: center;
    }
`;
