import { IBaseView } from '../../_base/base-view';
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Eintrag } from './helper/eintrag';
import moment from 'moment';
import { IPpapOrderElement } from '../../../models/PpapOrderElement';
import { IPpapOrderDocument } from '../../../models/PpapDocument';
import { PageTitle } from './helper/page-title';

export interface IPpapReportBasepage extends IBaseView {
    element?: IPpapOrderElement;
    headline?: string;
}

@inject('rootStore')
@observer
export class PpapReportBasepage extends React.Component<IPpapReportBasepage, {}> {
    getGeneral = () => {
        const {
            rootStore: {
                ppapStore: { ds, dsSupplier, dsCustomer, dsProduct },
                langStore: { lang },
            },
        } = this.props;
        return (
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <Eintrag label={lang.CUSTOMER_CUSTOMER1} value={dsCustomer.actual?.customer1} />
                        <Eintrag label={lang.PRODUCT_SPEZIFICATION} value={dsProduct.actual?.spezification} />
                    </tr>
                    <tr>
                        <Eintrag label={lang.SUPPLIER_SUPPLIER1} value={dsSupplier.actual?.supplier1} />
                        <Eintrag label={lang.PRODUCT_DRAWINGNO} value={dsProduct.actual?.drawingno} />
                    </tr>
                    <tr>
                        <Eintrag label={lang.PRODUCT_PRODUCTNO} value={ds.actual?.productno} />
                        <Eintrag label={lang.PPAP_ECLREVISION} value={ds.actual?.eclrevision} />
                    </tr>
                    <tr>
                        <Eintrag label={lang.PRODUCT_DESCRIPTION1} value={dsProduct.actual?.description1} />
                        <Eintrag label={lang.PPAP_ECLDATE} value={ds.actual?.ecldate ? moment(ds.actual?.ecldate).format('YYYY-MM-DD') : ''} />
                    </tr>
                    <tr>
                        <Eintrag label={lang.PPAP_REVISION} value={ds.actual?.ppaprevision} />
                        <Eintrag label={''} value={''} />
                    </tr>
                </tbody>
            </table>
        );
    };

    getHrefDoc = (document: IPpapOrderDocument) => {
        return '/gridApi/ppaporderdocument/' + document.ppapno + '/' + document.elementno + '/' + document.document;
    };

    /*getUploads = () => {
        const {rootStore: {ppapStore: {dsPpapUploads}}, element} = this.props;
        return (
            <div>
                {element ? dsPpapUploads.data
                    .filter((document) => document.elementno === element.elementno)
                    .map((document, index) =>
                        <p key={index} style={{color: cl_blue}}>
                            <a href={this.getHrefDoc(document)}>
                                {document.document}
                            </a>
                        </p>
                    ) : null
                }
            </div>
        )
    }*/

    render() {
        const {
            rootStore: {
                langStore: { lang },
            },
            element,
            headline,
            children,
        } = this.props;
        return (
            <>
                <PageTitle>{element ? element.elementno + ') ' + element.element : headline}</PageTitle>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '66%' }}>
                                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{lang.PPAP_GENERAL_INFO}</span>
                                <hr style={{ margin: '0.25rem' }} />
                            </td>
                        </tr>
                        <tr>
                            <td>{this.getGeneral()}</td>
                        </tr>
                    </tbody>
                </table>
                <p />
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{lang.PPAP_SHEET_INFO}</span>
                <hr style={{ margin: '0.25rem' }} />
                {children}
            </>
        );
    }
}
