import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Row } from 'reactstrap';
import { MenuFileUpload } from '../../../_base/menu/menu-file-upload';
import styled from 'styled-components';
import { cl_gray300 } from '../../../_theme/colors';
import { IAuditImage } from '../../../../stores/audit-online-store';
import { MenuImageDelete } from './MenuImageDelete';

const ImageCol = styled.div`
    flex: 1 1 auto;
    display: block;
    border: solid 1px ${cl_gray300};
`;

const CarouselContainer = styled.div`
    box-sizing: border-box;
    display: block;
    position: relative;
    background-color: ${cl_gray300};
`;

const ImgContainer = styled.span`
    text-align: center;
    display: block;
`;

const Img = styled.img`
    //max-width:100%;
    //height:130px;
    object-fit: contain;
    width: 100%;
    height: 100%;
`;

interface IAuditCollectCollectImages {
    images: IAuditImage[];
    uploadFile: any;
    accept: string;
    deleteFile: (imageno: number) => Promise<void>;
    canDeleteFile: boolean;
}

@inject('rootStore')
@observer
export class AuditCollectCollectImages extends React.Component<IAuditCollectCollectImages, {}> {
    animating: boolean = false;

    @observable
    activeIndex: number = 0;

    setAnimating = (value: boolean) => (this.animating = value);

    @action.bound
    next() {
        const { images } = this.props;
        if (this.animating) return;
        this.activeIndex = this.activeIndex === images.length - 1 ? 0 : this.activeIndex + 1;
    }

    @action.bound
    previous() {
        const { images } = this.props;
        if (this.animating) return;
        this.activeIndex = this.activeIndex === 0 ? images.length - 1 : this.activeIndex - 1;
    }

    @action.bound
    goToIndex(newIndex: number) {
        if (this.animating) return;
        this.activeIndex = newIndex;
    }

    renderSlides = () => {
        const { images } = this.props;
        if (images) {
            return images.map((item, index) => {
                //console.log(item.base64,item.image)
                return (
                    <CarouselItem onExiting={() => this.setAnimating(true)} onExited={() => this.setAnimating(false)} key={index}>
                        <ImgContainer>
                            {item.dataUrl ? <Img src={item.dataUrl} alt={item.altText} /> : <Img src={item.src} alt={item.altText} />}
                        </ImgContainer>
                    </CarouselItem>
                );
            });
        } else {
            return null;
        }
    };

    onClickDelete = async () => {
        const { deleteFile, images } = this.props;
        await deleteFile(images[this.activeIndex].imageno);
    };

    render() {
        const { images, uploadFile, accept, canDeleteFile } = this.props;
        return (
            <div className={'image'}>
                <div className={'iconcol'}>
                    <Row>
                        <Col>
                            <MenuFileUpload
                                id={'xxx-btn-camera'}
                                onDrop={uploadFile}
                                disabled={false}
                                accept={accept}
                                iconName={'camera'}
                                environment={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MenuFileUpload id={'xxx-btn-upload'} onDrop={uploadFile} disabled={false} accept={accept} environment={true} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MenuImageDelete id={'xxx-btn-delete'} disabled={!canDeleteFile} onClickDelete={this.onClickDelete} />
                        </Col>
                    </Row>
                </div>
                <ImageCol>
                    <CarouselContainer className="CarouselContainer">
                        <Carousel activeIndex={this.activeIndex} next={this.next} previous={this.previous} interval={false}>
                            <CarouselIndicators items={images} activeIndex={this.activeIndex} onClickHandler={this.goToIndex} />
                            {this.renderSlides()}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                        </Carousel>
                    </CarouselContainer>
                </ImageCol>
            </div>
        );
    }
}
