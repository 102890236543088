import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { Col, Row } from 'reactstrap';
import { AuditStatisticScore } from '../order/top/audit-statistic-score';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { AuditStatisticArea } from '../order/top/audit-statistic-area';
import { cl_blue } from '../../../_theme/colors';
import { RatingMethod } from '../../../../models/AuditTemplate';

@inject('rootStore')
@observer
export class AuditReportPage2 extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                auditStore: { ds, dsAuditQuestion, auditScore, SBAScore },
            },
        } = this.props;
        return (
            <div style={{ paddingTop: '10mm' }}>
                <Row style={{ paddingBottom: '3rem' }}>
                    <Col>
                        <h1 style={{ fontSize: 48, color: cl_blue }}>Overview</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: 'top' }}>
                                        <h2 style={{ color: cl_blue, width: '15mm' }}>
                                            <FontAwesomeIcon icon={['fad', 'clock']} />
                                        </h2>
                                    </td>
                                    <td>
                                        <h4>Date</h4>
                                        <h4>
                                            {moment(ds.actual?.startdate).format('DD-MM-YYYY')} - {moment(ds.actual?.duedate).format('DD-MM-YYYY')}
                                        </h4>
                                        <h4>&nbsp;</h4>
                                        <h4>&nbsp;</h4>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: 'top' }}>
                                        <h2 style={{ color: cl_blue, width: '15mm' }}>
                                            <FontAwesomeIcon icon={['fad', 'bolt']} />
                                        </h2>
                                    </td>
                                    <td>
                                        <h4>Findings</h4>
                                        <h4>
                                            {
                                                dsAuditQuestion.data.filter(
                                                    (question) => question.score === 0 || question.score === 4 || question.score === 6,
                                                ).length
                                            }
                                        </h4>
                                        <h4>&nbsp;</h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col>
                        <div style={{ width: '60mm', height: '60mm', margin: 'auto' }}>
                            {ds.actual?.ratingmethod === RatingMethod.VESTAS_SBA ? (
                                <AuditStatisticScore auditScore={SBAScore} ratingMethod={ds.actual?.ratingmethod} />
                            ) : (
                                <AuditStatisticScore auditScore={auditScore} ratingMethod={ds.actual?.ratingmethod} />
                            )}
                        </div>
                        <h2 style={{ textAlign: 'center', paddingTop: '1rem' }}>Overall Score</h2>
                    </Col>
                </Row>
                <Row style={{ paddingBottom: '3rem' }}>
                    <Col>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: 'top' }}>
                                        <h2 style={{ color: cl_blue, width: '15mm' }}>
                                            <FontAwesomeIcon icon={['fad', 'quote-right']} />
                                        </h2>
                                    </td>
                                    <td>
                                        <h4>Summary</h4>
                                        <h4>{ds.actual?.comment}</h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: 'top' }}>
                                        <h2 style={{ color: cl_blue, width: '15mm' }}>
                                            <FontAwesomeIcon icon={['fad', 'chart-bar']} />
                                        </h2>
                                    </td>
                                    <td>
                                        <h4>Area</h4>
                                        <div style={{ height: '150mm', width: '800px', margin: 'auto' }}>
                                            <AuditStatisticArea orientation="vertical" top="10px" right="0px" left="0px" labelRotate={45} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        );
    }
}
