import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { DetailLayout } from '../../../_base/styled/layout/detail-layout';
import { AuditDetailTopElement } from './top/audit-detail-top-element';
import { AuditDetailMainLeftElement } from './main-left/audit-detail-main-left-element';
import { AuditDetailMainRightElement } from './main-right/audit-detail-main-right-element';

@inject('rootStore')
@observer
export class AuditDetailView extends React.Component<IBaseView, {}> {
    render() {
        return (
            <DetailLayout
                topElement={<AuditDetailTopElement />}
                mainLeftElement={<AuditDetailMainLeftElement />}
                mainRightElement={<AuditDetailMainRightElement />}
            />
        );
    }
}
