import * as React from 'react';
import { IFieldProps } from '../i-db-field-props';
import { Col, FormFeedback, FormGroup, Input } from 'reactstrap';
import { ILangKeys } from '../../../language/ILang';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FieldLabel } from '../field-label';

@inject('rootStore')
@observer
export class FieldLookup extends React.Component<IFieldProps<any>, {}> {
    // lookup is always readonly
    @action.bound
    onChange() {}

    renderInput() {
        const { error, column, readOnly } = this.props;
        const { ds, displayField } = column.lookup;

        const value = ds.actual ? (ds.actual[displayField] !== null ? ds.actual[displayField] : '') : '';
        return (
            <>
                <Input
                    id={displayField as string}
                    name={displayField as string}
                    type="text"
                    disabled={readOnly}
                    autoComplete="off"
                    value={value}
                    onChange={this.onChange}
                    bsSize="sm"
                    invalid={!!error}
                />
                {error ? <FormFeedback>{error}</FormFeedback> : null}
            </>
        );
    }

    render() {
        const {
            rootStore: {
                langStore: { lang },
            },
            column,
            row,
        } = this.props;
        const label: string = lang[column.label as ILangKeys] ? lang[column.label as ILangKeys] : (column.fieldName as string);
        return (
            <FormGroup row={row}>
                <FieldLabel row={row} label={label} bold={column.primaryKey} />
                {row ? <Col sm={9}> {this.renderInput()}</Col> : this.renderInput()}
            </FormGroup>
        );
    }
}
