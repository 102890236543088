import { IBaseView } from '../base-view';
import { IDataset } from '../../../components/dataset/IDataset';
import { observer } from 'mobx-react';
import * as React from 'react';
import { MenuInsert } from '../menu/menu-insert';
import { MenuDelete } from '../menu/menu-delete';

interface ITableMenu extends IBaseView {
    id: string;
    ds: IDataset<any>;
    disabled?: boolean;
    menuInsert?: JSX.Element;
    hideInsert?: boolean;
    hideDelete?: boolean;
    rightHeaderMenu?: any;
}

export const TableGridMenu = observer((props: ITableMenu) => {
    const { ds, id, disabled, menuInsert, hideInsert, hideDelete, rightHeaderMenu } = props;
    return (
        <>
            {!hideInsert
                ? menuInsert ?? <MenuInsert id={id} ds={ds} disabled={disabled || (ds.masterSource ? ds.masterSource.cursor === undefined : false)} />
                : null}
            {!hideDelete && ds.data.length > 0 ? <MenuDelete id={id} ds={ds} disabled={disabled || ds.cursor === undefined} /> : null}
            {rightHeaderMenu ? rightHeaderMenu : null}
        </>
    );
});
