import { IField, ITextFunc } from '../dataset/IField';
import * as R from 'ramda';
import { ILang } from '../../language/ILang';

const renderFunc =
    (lang: ILang) =>
    (textFunc: ITextFunc) =>
    (params: any): any => {
        return lang[textFunc(params.value)];
    };

// This is a mapping function IField => ColDef

export const fieldToColDef =
    (lang: ILang) =>
    (field: IField<any>): any => {
        let c: any = R.clone(field);

        // Maybe it would be a good idea, to extract this to a more central point.
        // delete the key's that are not part of the ag-grid column Definition
        delete c.dataType;
        delete c.primaryKey;
        delete c.readOnly;
        delete c.defaultValue;
        delete c.input;
        delete c.options;
        delete c.insert;
        delete c.mandatory;
        delete c.selectdlg;
        delete c.lookup;
        delete c.rules;
        delete c.maxLength;
        delete c.useAsFilter;

        c.field = c.fieldName;
        delete c.fieldName;

        c.headerName = lang[field.label];

        delete c.label;

        // migrate to v20
        c.resizable = true;

        c.autoHeight = true;
        //c.cellClass = "cell-wrap-text";

        // c.sortable=true;
        // c.filter=true;
        // c.checkboxSelection= true;

        // wenn eine textFunc definiert ist, diese auf cellRenderer zuweisen.
        if (c.textFunc && !c.grid?.cellRendererFramework) {
            c.cellRenderer = renderFunc(lang)(c.textFunc);
            delete c.textFunc;
        }

        if (c.grid) {
            if (c.grid.cellClassRules) {
                c.cellClassRules = c.grid.cellClassRules;
            }

            if (c.grid.cellStyle) {
                c.cellStyle = c.grid.cellStyle;
            }

            if (c.grid.cellRenderer) {
                c.cellRenderer = c.grid.cellRenderer;
                if (c.textFunc) {
                    delete c.textFunc;
                }
            }

            /*if (c.grid.cellRendererFramework) {
            c.cellRendererFramework = c.grid.cellRendererFramework;
            if (c.textFunc){
                delete c.textFunc;
            }
        }*/

            if (c.grid.width) {
                c.width = c.grid.width;
            }
            if (c.grid.flex) {
                c.flex = c.grid.flex;
            }
            if (c.grid.suppressSizeToFit) {
                c.suppressSizeToFit = c.grid.suppressSizeToFit;
            }

            if (c.grid.autoHeight) {
                c.autoHeight = c.grid.autoHeight;
            }
            if (c.grid.wrapText) {
                c.wrapText = c.grid.wrapText;
            }

            delete c.grid;
        }
        delete c.calendar;

        return c;
    };
