import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { PPAP_SR } from '../PpapStandardElement';
import { cl_gray500, cl_green } from '../../views/_theme/colors';

export const ppapSRText = (sr: PPAP_SR): string => {
    switch (sr) {
        case PPAP_SR.SUBMIT:
            return 'S';
        case PPAP_SR.RETAIN:
            return 'R';
        case PPAP_SR.SUBMIT_OR_RETAIN:
            return '*';

        default:
            return 'Unknown';
    }
};

export const ppapSRColor = (sr: PPAP_SR): string => {
    switch (sr) {
        case PPAP_SR.SUBMIT:
            return cl_green;
        case PPAP_SR.RETAIN:
            return cl_gray500;
        case PPAP_SR.SUBMIT_OR_RETAIN:
            return 'gray';

        default:
            return 'Unknown';
    }
};

@observer
class _SubmissionRenderer extends React.Component<any, any> {
    render() {
        const { className, value } = this.props;
        return <span className={className}>{ppapSRText(value)}</span>;
    }
}

export const SubmissionRenderer = styled(_SubmissionRenderer)`
    color: white;
    background-color: ${(props) => ppapSRColor(props.value)};
    padding: 1px 5px;
    border-radius: 6px;
    //border: 1px solid black;
    display: inline-block;
    width: 100%;
    line-height: 16px;
    text-align: center;
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin-top: 4px;
`;
