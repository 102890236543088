import * as React from 'react';
import { IBaseView } from '../../base-view';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';

interface IDetailLayout extends IBaseView {
    topElement?: JSX.Element;
    mainLeftElement?: JSX.Element;
    mainRightElement?: JSX.Element;
    leftPercent?: number;
}

@inject('rootStore')
@observer
export class _DetailLayout extends React.Component<IDetailLayout, {}> {
    render() {
        const { className, topElement, mainLeftElement, mainRightElement } = this.props;
        return (
            <div className={className}>
                <div className="top-area">{topElement}</div>
                <div className="main-area">
                    <div className="main-left">{mainLeftElement}</div>
                    <div className="main-right">{mainRightElement}</div>
                </div>
            </div>
        );
    }
}

export const DetailLayout = styled(_DetailLayout)`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    //
    display: flex;
    flex-direction: column;
    //
    color: black;
    background-color: ${(props) => props.theme.bgBody};
    overflow-x: hidden;
    overflow-y: auto;
    padding: ${(props) => props.theme.gutter};
    box-shadow: ${(props) => props.theme.boxShadowInset};

    .top-area {
        display: block;
        flex: 0 0 auto;
    }

    .main-area {
        display: flex;
        flex-direction: row;
        height: 30%;
        flex: 1 1 auto;
        padding-top: ${(props) => (props.topElement ? props.theme.gutter : 0)};

        .main-left {
            display: block;
            height: 100%;
            flex: 0 0 ${(props) => props.leftPercent ?? 50}%;
            padding-right: ${(props) => props.theme.gutter};
        }

        .main-right {
            display: block;
            height: 100%;
            flex: 0 0 ${(props) => (props.leftPercent ? 100 - props.leftPercent : 50)}%;
            //padding-left: ${(props) => props.theme.gutter};
        }
    }
`;
