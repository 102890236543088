import * as React from 'react';
import { IBaseView } from '../../../../_base/base-view';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { IconButton } from '../../../../_base/styled/icon-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverBody, UncontrolledTooltip } from 'reactstrap';

const IconMenuItem = ({ children, onClick }: any) => (
    <div
        style={{
            cursor: 'pointer',
            marginTop: '0.125rem',
            marginBottom: '0.125rem',
        }}
        onClick={onClick}
    >
        {children}
    </div>
);

interface IAuditDetailMainRightToggleDocument extends IBaseView {
    id: string;
    icon: IconName;
}

@inject('rootStore')
@observer
export class AuditDetailMainRightToggleDocument extends React.Component<IAuditDetailMainRightToggleDocument, {}> {
    @observable
    isOpen: boolean;

    @action.bound
    toggle() {
        this.isOpen = !this.isOpen;
    }

    render() {
        const { id, icon } = this.props;
        const {
            rootStore: {
                auditStore: { actionByQuestionFilter, toggleActionFilter },
                langStore: { lang },
            },
        } = this.props;
        return (
            <div style={{ display: 'inline-flex' }}>
                <IconButton id={id} onClick={this.toggle}>
                    <FontAwesomeIcon icon={['fas', icon]} />
                </IconButton>
                <UncontrolledTooltip placement="bottom" target={id}>
                    {lang['FILTER']}
                </UncontrolledTooltip>
                <Popover trigger="legacy" placement="bottom-end" isOpen={this.isOpen} target={id} toggle={this.toggle} hideArrow={true}>
                    <PopoverBody>
                        <IconMenuItem
                            onClick={() => {
                                toggleActionFilter();
                                this.toggle();
                            }}
                        >
                            <FontAwesomeIcon icon={actionByQuestionFilter ? ['fas', 'check-circle'] : ['far', 'circle']} />
                            &nbsp;&nbsp;{lang['PPAP_FILTER_ELEMENT']}
                        </IconMenuItem>
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}
