import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { PrintContainer } from '../../../_base/styled/print/print-container';
import { GembaReportPage3 } from './page3/gemba-report-page3';
import { NewPage } from '../../../audit/order/report/audit-report-styled';
import { GembaReportPage1 } from './page1/gemba-report-page1';
import { GembaReportPage2 } from './page2/gemba-report-page2';

@inject('rootStore')
@observer
export class GembaReportView extends React.Component<IBaseView, {}> {
    render() {
        return (
            <PrintContainer>
                <GembaReportPage1 />
                <NewPage />
                <GembaReportPage2 />
                <NewPage />
                <GembaReportPage3 />
            </PrintContainer>
        );
    }
}
