import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import { action } from 'mobx';
import { DetailOneColLayout } from '../../_base/styled/layout/detail-one-col-layout';
import { TableForm } from '../../_base/styled/table-form';
import { TableGrid } from '../../_base/styled/table-grid';
import { CsvButtonUpload } from '../../_base/csv/csv-button-upload';
import axios from 'axios';
import { authorizer } from '../../../components/dataset/authorizer';
import { CsvButtonDownload } from '../../_base/csv/csv-button-download';

@inject('rootStore')
@observer
export class AuditTemplateDetailView extends React.Component<IBaseView, {}> {
    @action.bound
    async goToQuestion() {
        const {
            rootStore: {
                routerStore,
                auditTemplateStore: { dsQuestion },
            },
        } = this.props;
        await routerStore.goTo('audittemplatequestion', {
            audittemplate: dsQuestion.actual.audittemplate,
            questionno: dsQuestion.actual.questionno,
        });
    }

    @action.bound
    async uploadCsv(rawdata: Array<any>) {
        const {
            rootStore: {
                auditTemplateStore: { ds, dsQuestion },
            },
        } = this.props;
        const fields = rawdata[0].data;
        const data = rawdata.filter((zeile: any, i: number) => i > 0);

        let newQuestions = data
            .map((record: any) => {
                let rv: any = {};
                fields.forEach((f: string, i: number) => {
                    rv[fields[i]] = record.data[i];
                });
                rv['audittemplate'] = ds.actual.audittemplate;
                rv['mandatory'] = false;
                rv['unselected'] = rv['unselected'] === 'true';
                rv['attentionflag'] = rv['attentionflag'] === 'true';

                return rv;
            })
            .filter((record) => record.questionno);

        newQuestions.forEach((question) => {
            axios.post('/gridApi/audittemplatequestion/', question, authorizer());
        });

        dsQuestion.close();
        await dsQuestion.open();
    }

    render() {
        const {
            rootStore: {
                auditTemplateStore: { ds, dsQuestion },
            },
        } = this.props;
        return (
            <DetailOneColLayout
                topElement={<TableForm icon="eye" title="AUDIT" ds={ds} keyTitle={ds.actual?.audittemplate} open={true} hideMenu={true} />}
                mainElement={
                    <TableGrid
                        id="audit1"
                        icon="book-open"
                        title="INSPECTION_PLAN_QUESTIONS"
                        ds={dsQuestion}
                        onRowDoubleClicked={this.goToQuestion}
                        rightHeaderMenu={
                            <>
                                {dsQuestion.data?.length > 0 ? null : <CsvButtonUpload onUpload={this.uploadCsv} />}
                                <CsvButtonDownload ds={dsQuestion} />
                            </>
                        }
                    />
                }
            />
        );
    }
}
