import { BaseStore, IBaseStore } from './base-store';
import { IInspection, inspectionColumns } from '../models/Inspection';
import { IRootStore } from '../routes/root-store';
import { IDataset } from '../components/dataset/IDataset';
import { ActionTyp, IAction, inspectionActionColumns } from '../models/Action';
import { IInspectionQuestion, inspectionQuestionColumns, InspectionScore, scoreText } from '../models/InspectionQuestion';
import { action, computed, observable, runInAction } from 'mobx';

import { Dataset } from '../components/dataset/dataset';
import { IInspectionQuestionImage, inspectionQuestionImageColumns } from '../models/InspectionQuestionImage';
import moment from 'moment';
import * as R from 'ramda';
import { RouterState } from 'mobx-state-router';
import axios from 'axios';
import { ICatValue } from '../components/graphic/bar-graph';
import { IProject, projectColumns } from '../models/Project';

interface IArea {
    label: string;
    completed: boolean;
}

export interface IInspectionStore extends IBaseStore<IInspection> {
    dsInspectionQuestion: IDataset<IInspectionQuestion>;
    dsImage: IDataset<IInspectionQuestionImage>;
    dsAction: IDataset<IAction>;
    dsReportImages: IDataset<IInspectionQuestionImage>;
    dsProject: IDataset<IProject>;

    actionByQuestionFilter: boolean;
    toggleActionFilter: () => Promise<void>;
    //
    uploadFile: (acceptFile: any[]) => Promise<void>;
    images: any[];
    //
    inspectionScore: number; //0 bis 100

    getCatValue: (data: any[], groupfield: string, valuefield: string, filter?: (x: any) => boolean) => ICatValue[];

    // Evaluation
    evaluation: 'SCORE' | 'AREA';
    setEvaluation: (evaluation: 'SCORE' | 'AREA') => void;
    //

    titleOpen: boolean;
    toggleTitleOpen: () => void;
    //
    areas: IArea[];
    actArea: string;
    setArea: (area: string) => void;
    elements: IArea[];
    actElement: string;
    setElement: (element: string) => void;
    //
    nextElement: () => void;
    nextArea: () => void;
    prevElement: () => void;
    prevArea: () => void;
    //
    progressValue: number;
    progressText: string;

    questionScoreFilter: number;
    setQuestionScoreFilter: (score: number) => void;
}

export class InspectionStore extends BaseStore<IInspection> implements IInspectionStore {
    @action.bound
    dsOnAfterInsert(ds: IDataset<IInspection>) {
        const {
            authStore: { username },
        } = this.rootStore;
        ds.actual.createdby = username;
        ds.actual.createdat = moment().format();
    }

    @observable
    dsInspectionQuestion: IDataset<IInspectionQuestion>;

    @action.bound
    dsInspectionQuestiononAfterOpen(ds: IDataset<IInspectionQuestion>) {
        if (this.questionScoreFilter !== undefined) {
            ds.data = ds.data.filter((record) => record.score === 0);
        }
    }

    @observable
    dsAction: IDataset<IAction>;

    @observable
    dsProject: IDataset<IProject>;

    @observable
    dsImage: IDataset<IInspectionQuestionImage>;

    @observable
    dsReportImages: IDataset<IInspectionQuestionImage>;

    @action.bound
    dsInspectionOnAfterInsert(ds: IDataset<IAction>) {
        const {
            authStore: { username },
        } = this.rootStore;
        ds.actual.typ = ActionTyp.INSPECTIONTask;
        ds.actual.createdby = username;
        ds.actual.taskowner = username;
        ds.actual.createdat = moment().format();
        ds.actual.key1 = this.dsInspectionQuestion.actual.inspectionno;
        ds.actual.key2 = this.dsInspectionQuestion.actual.questionno;
    }

    @observable
    questionScoreFilter: number = undefined;

    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/inspection/', inspectionColumns);
        this.ds.onAfterInsert = this.dsOnAfterInsert;

        this.dsInspectionQuestion = new Dataset<IInspectionQuestion>('/gridApi/inspectionquestion/', inspectionQuestionColumns);
        this.dsInspectionQuestion.setMasterSource(this.ds, [{ field: 'inspectionno', masterField: 'inspectionno' }]);
        this.dsInspectionQuestion.onAfterOpen = this.dsInspectionQuestiononAfterOpen;

        this.dsAction = new Dataset<IAction>('/gridApi/action/', R.clone(inspectionActionColumns), { typ: ActionTyp.INSPECTIONTask });
        this.dsAction.setMasterSource(this.dsInspectionQuestion, this.actionFilter);
        this.dsAction.onAfterInsert = this.dsInspectionOnAfterInsert;

        this.dsImage = new Dataset<IInspectionQuestionImage>('/gridApi/inspectionquestionimage/', inspectionQuestionImageColumns);
        this.dsImage.setMasterSource(this.dsInspectionQuestion, [
            {
                field: 'inspectionno',
                masterField: 'inspectionno',
            },
            {
                field: 'questionno',
                masterField: 'questionno',
            },
        ]);

        this.dsReportImages = new Dataset<IInspectionQuestionImage>('/gridApi/inspectionquestionimage/', inspectionQuestionImageColumns);

        this.dsProject = new Dataset<IProject>('/gridApi/project/', projectColumns);
        this.dsProject.setMasterSource(this.ds, [
            {
                field: 'projectno',
                masterField: 'projectno',
            },
        ]);
    }

    @observable
    actionByQuestionFilter: boolean = true;

    @computed
    get actionFilter() {
        if (this.actionByQuestionFilter) {
            return [
                {
                    field: 'key1',
                    masterField: 'inspectionno',
                },
                {
                    field: 'key2',
                    masterField: 'questionno',
                },
            ];
        } else {
            return [
                {
                    field: 'key1',
                    masterField: 'inspectionno',
                },
            ];
        }
    }

    @action.bound
    async toggleActionFilter() {
        this.actionByQuestionFilter = !this.actionByQuestionFilter;
        this.dsAction.setMasterSource(this.dsInspectionQuestion, this.actionFilter);
        await this.dsAction.refresh(0);
    }

    @action.bound
    async openDetails() {
        this.dsInspectionQuestion.autoRefresh = false; // !!!!!
        await this.dsProject.open();
        await this.dsInspectionQuestion.open();
        this.setArea(this.dsInspectionQuestion.actual?.area);
        this.setElement(this.dsInspectionQuestion.actual?.element);
        // hier muss für das responsible Feld die Url: /gridapi/projectmember/<projectno> gesetzt werden.
        // Da columns im constructor gecloned sind, dürfte das keine Seiteneffekte haben
        const responsible = this.dsAction.columns.find((column) => column.fieldName === 'responsible');
        responsible.selectdlg.url = '/gridApi/projectmember/?projectno=' + this.dsProject.actual.projectno;
        responsible.selectdlg.InserRight = undefined;
        await this.dsAction.open();
        await this.dsImage.open();
        runInAction(() => {
            try {
                let column = this.dsInspectionQuestion.columns.find((column) => column.fieldName === 'score');
                if (column) {
                    column.textFunc = scoreText;
                }
            } catch (e) {
                console.log(e);
            }
        });
    }

    beforeEnter = async (fromState: RouterState, toState: RouterState) => {
        this.dsCheck.dataUrl = '/gridApi/inspection/';
        this.dsCheck.filter = { inspectionno: toState.params.inspectionno };
        await this.dsCheck.open();
        const rv = this.dsCheck.data.length === 1;
        this.dsCheck.close();
        return rv;
    };

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        const {
            authStore: { username },
        } = this.rootStore;
        this.dsInspectionQuestion.columns.find((column) => column.fieldName === 'score').textFunc = scoreText;
        switch (toState.routeName) {
            case 'inspectiontable':
                await this.loadFilter('inspection');
                await this.open(this.cdsFilter.actual);
                if (fromState.routeName === 'inspection' || fromState.routeName === 'inspectioncollect') {
                    this.ds.locate(this.ds.pkFields, fromState.params);
                }
                break;

            case 'inspection':
                fromState.params.no = parseInt(fromState.params['no']) as any;
                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                if (username === this.ds.actual.inspector) {
                    this.ds.edit();
                }
                await this.openDetails();
                if (fromState.routeName === 'inspectionquestion') {
                    this.dsInspectionQuestion.locate(this.dsInspectionQuestion.pkFields, fromState.params);
                } else if (fromState.routeName === 'action') {
                    this.dsInspectionQuestion.locate(['questionno'], { questionno: toState.queryParams.questionno });
                }
                break;

            case 'inspectionreport':
                fromState.params.no = parseInt(fromState.params['no']) as any;
                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                await this.openDetails();
                this.dsReportImages.filter = toState.params;
                await this.dsReportImages.open();
                if (fromState.routeName === 'inspectionquestion') {
                    this.dsInspectionQuestion.locate(this.dsInspectionQuestion.pkFields, fromState.params);
                }
                break;

            case 'inspectionquestion':
                this.dsInspectionQuestion.columns.find((column) => column.fieldName === 'score').textFunc = scoreText;
                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                await this.openDetails();
                this.dsInspectionQuestion.locate(this.dsInspectionQuestion.pkFields, toState.params);
                if (username === this.ds.actual.inspector) {
                    this.dsInspectionQuestion.edit();
                }
                break;

            case 'inspectioncollect':
                this.titleOpen = true;
                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                await this.openDetails();
                await this.dsInspectionQuestion.edit();
                break;
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'inspectionquestion') {
            if (this.rootStore.authStore.username !== '') {
                await this.dsInspectionQuestion.post();
            }
            this.closeDetails();
            this.close();
        } else if (fromState.routeName === 'inspection') {
            this.closeDetails();
            if (this.rootStore.authStore.username !== '') {
                await this.ds.post();
            }
            this.close();
        } else if (fromState.routeName === 'inspectionreport') {
            this.dsReportImages.close();
            this.closeDetails();
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'inspectiontable') {
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'inspectioncollect') {
            if (this.rootStore.authStore.username !== '') {
                await this.dsInspectionQuestion.post();
            }
            this.closeDetails();
            this.close();
        }
    }

    @action.bound
    closeDetails() {
        this.dsProject.open();
        this.dsImage.close();
        this.dsAction.close();
        this.dsInspectionQuestion.close();
    }

    @computed
    get areas(): IArea[] {
        let areas = R.uniq(
            this.dsInspectionQuestion.data.map((question) => {
                return { label: question.area, completed: true };
            }),
        );

        const filtered = this.dsInspectionQuestion.data.filter((question) => question.score === InspectionScore.UNRATED);

        areas.forEach((area) => {
            if (filtered.find((f) => f.area === area.label)) {
                area.completed = false;
            }
        });

        return areas;
    }

    @action.bound
    setArea(area: string) {
        this.actArea = area;
        // dies ist ein Hilfskonstrukt, da das computed der elements-Liste zu diesem zeitpunkt, also Brute-Force
        const myElements = R.uniq(this.dsInspectionQuestion.data.filter((question) => question.area === area).map((data) => data.element));
        if (myElements.length > 0) {
            this.setElement(myElements[0]);
        }
    }

    @observable
    actArea: string = '';

    @computed
    get elements(): IArea[] {
        let elements = R.uniq(
            this.dsInspectionQuestion.data
                .filter((question) => question.area === this.actArea)
                .map((question) => {
                    return { label: question.element, completed: true };
                }),
        );
        const filtered = this.dsInspectionQuestion.data.filter((question) => question.score === InspectionScore.UNRATED);

        elements.forEach((element) => {
            if (filtered.find((f) => f.element === element.label)) {
                element.completed = false;
            }
        });

        return elements;
    }

    @action.bound
    setElement(element: string) {
        this.actElement = element;
    }

    @observable
    actElement: string = '';

    @observable
    titleOpen: boolean = true;

    @action.bound
    toggleTitleOpen() {
        this.titleOpen = !this.titleOpen;
    }

    // Todo Check
    @computed
    get images() {
        return this.dsImage.data.map((image) => {
            return {
                src: '/gridApi/image/inspectionquestionimage/' + image.inspectionno + '/' + image.questionno + '/' + image.image,
                altText: image.image,
                caption: image.image,
            };
        });
    }

    @action.bound
    async uploadFile(acceptFile: any[]): Promise<void> {
        const url = '/gridApi/inspectionquestionimage/fileupload';
        const file = acceptFile[0];
        const formData = new FormData();
        formData.append('type', 'inspectionquestionimage');
        formData.append('file', file);
        formData.append('inspectionno', this.dsInspectionQuestion.actual.inspectionno);
        formData.append('questionno', this.dsInspectionQuestion.actual.questionno.toString());
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        await axios.post(url, formData, config);
        await this.dsImage.refresh(this.dsImage.cursor);
        await runInAction(async () => {
            this.dsImage.last();
        });
    }

    @computed
    get inspectionScore(): number {
        const target = this.dsInspectionQuestion.data.length * 10;

        let score = this.dsInspectionQuestion.data.reduce((sum: number, record: any) => {
            if (record.score >= 0) {
                sum = sum + record.score;
            }
            return sum;
        }, 0);

        return target ? Math.round((score / target) * 100) : 0;
    }

    getCatValue = (data: any[], groupfield: string, valuefield: string, filter?: (x: any) => boolean): ICatValue[] => {
        // optionalen filter anwenden
        let _data: any[] = filter ? data.filter(filter) : data;

        // Kategorien bestimmen
        let catList = R.uniq(_data.map((question: any) => question[groupfield]));

        return catList.map((category) => {
            let rv = { category: category, value: 0 };

            let questions = _data.filter((record: any) => record[groupfield] === category);

            if (questions.find((record) => record.score === InspectionScore.NOT_APPLICABLE)) {
                if (questions.find((record) => record.score !== InspectionScore.NOT_APPLICABLE)) {
                    questions = questions.filter((record) => record.score !== InspectionScore.NOT_APPLICABLE);
                    const maxScore = questions.length * 10;
                    const score = questions.reduce((sum, record) => {
                        sum = sum + record[valuefield];
                        return sum;
                    }, 0);
                    //console.log(maxScore,score);
                    rv.value = (score / maxScore) * 100;
                } else {
                    rv.value = -20;
                }
            } else {
                const maxScore = questions.length * 10;
                const score = questions.reduce((sum, record) => {
                    sum = sum + record[valuefield];
                    return sum;
                }, 0);
                //console.log(maxScore,score);
                rv.value = (score / maxScore) * 100;
            }

            return rv;
        });
    };

    @observable
    evaluation: 'SCORE' | 'AREA' = 'SCORE';

    @action.bound
    setEvaluation(evaluation: 'SCORE' | 'AREA') {
        this.evaluation = evaluation;
    }

    //
    // Actions for Navigation

    @action.bound
    nextElement() {
        let i = this.elements.findIndex((element) => element.label === this.actElement);
        if (i === -1) {
            return;
        }
        if (this.elements.length === i + 1) {
            this.nextArea();
            return;
        }
        this.setElement(this.elements[i + 1].label);
    }

    @action.bound
    nextArea() {
        let i = this.areas.findIndex((area) => area.label === this.actArea);
        if (i === -1) {
            return;
        }
        if (i === this.areas.length - 1) {
            return;
        }
        this.setArea(this.areas[i + 1].label);
    }

    @action.bound
    prevElement() {
        let i = this.elements.findIndex((element) => element.label === this.actElement);
        if (i === -1) {
            return;
        }
        if (i === 0) {
            this.prevArea();
            return;
        }
        //console.log('Element',i);
        this.setElement(this.elements[i - 1].label);
    }

    @action.bound
    prevArea() {
        let i = this.areas.findIndex((area) => area.label === this.actArea);
        if (i === -1) {
            return;
        }
        if (i === 0) {
            return;
        }
        //console.log('Area',i);
        this.setArea(this.areas[i - 1].label);
    }

    //
    @computed
    get progressValue() {
        return (
            (this.dsInspectionQuestion.data.length === 0
                ? 0
                : this.dsInspectionQuestion.data.filter((question) => question.score !== InspectionScore.UNRATED || question.notapplicable).length /
                  this.dsInspectionQuestion.data.length) * 100
        );
    }

    @computed
    get progressText() {
        return (
            this.dsInspectionQuestion.data
                .filter((question) => question.score !== InspectionScore.UNRATED || question.notapplicable)
                .length.toString() +
            ' / ' +
            this.dsInspectionQuestion.data.length.toString()
        );
    }

    @action.bound
    setQuestionScoreFilter(score: number) {
        this.questionScoreFilter = score;
        this.dsInspectionQuestion.refresh(this.dsInspectionQuestion.cursor);
    }
}
