import { IField } from '../components/dataset/IField';

export interface IProduct {
    productno: string;
    description1: string;
    description2: string;
    prodgroup: string;
    drawingno: string;
    spezification: string;
    ppap_family: string;
    weight: string;
    revision: string;
    revision_date: string;

    [key: string]: string | number;
}

export const productColumns: IField<IProduct>[] = [
    {
        fieldName: 'productno',
        dataType: 'string',
        label: 'PRODUCT_PRODUCTNO',
        primaryKey: true,
        rules: ['pk'],
    },
    {
        fieldName: 'description1',
        dataType: 'string',
        label: 'PRODUCT_DESCRIPTION1',
    },
    {
        fieldName: 'revision',
        dataType: 'string',
        label: 'PRODUCT_REVISION',
        hide: 'table',
    },
    {
        fieldName: 'revision_date',
        dataType: 'string',
        label: 'PRODUCT_REVISIONDATE',
        hide: 'table',
        input: 'calendar',
    },
    {
        fieldName: 'description2',
        dataType: 'string',
        label: 'PRODUCT_DESCRIPTION2',
    },
    {
        fieldName: 'prodgroup',
        dataType: 'string',
        label: 'PRODUCT_PRODUCTGROUP',
    },
    {
        fieldName: 'drawingno',
        dataType: 'string',
        label: 'PRODUCT_DRAWINGNO',
    },
    {
        fieldName: 'spezification',
        dataType: 'string',
        label: 'PRODUCT_SPEZIFICATION',
    },
    {
        fieldName: 'ppap_family',
        dataType: 'string',
        label: 'PRODUCT_PPAP_FAMILY',
        useAsFilter: true,
        input: 'input',
        hide: 'table',
    },
    {
        fieldName: 'weight',
        dataType: 'string',
        label: 'PRODUCT_WEIGHT',
        hide: 'table',
    },
];
