import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import { PrintContainer } from '../../_base/styled/print/print-container';
import { NewPage } from '../../audit/order/report/audit-report-styled';
import { PpapReportCoverPage } from './pages/00-basic-pages/ppap-report-cover-page';
import { PpapReportDetails } from './ppap-report-details';
import { PpapReportPartFamily } from './pages/00-basic-pages/ppap-report-part-family';
import { PpapReportPage } from './ppap-report-page';
import { PpapReportProductQualityPlan } from './pages/01-product-quality-plan/ppap-report-product-quality-plan';

@inject('rootStore')
@observer
export class PPAPReportView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                ppapStore: { dsPpapOrderElement, dsPpapFamily, dsPpapUploads },
            },
        } = this.props;
        return (
            <PrintContainer>
                <PpapReportCoverPage />
                <NewPage />
                <PpapReportDetails />
                {dsPpapFamily.data.length > 0 ? (
                    <>
                        <NewPage />
                        <PpapReportPartFamily />
                    </>
                ) : null}
                <NewPage />
                <PpapReportProductQualityPlan />
                {dsPpapOrderElement.data.map((element, index) => {
                    return (
                        <React.Fragment key={index}>
                            <NewPage />
                            <PpapReportPage element={element} dsDocument={dsPpapUploads} />
                        </React.Fragment>
                    );
                })}
            </PrintContainer>
        );
    }
}
