import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { IBaseView } from '../../views/_base/base-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '../../views/_base/styled/icon-button';
import Draggable from 'react-draggable';

const StyledModal = styled(Modal)`

  border-radius: ${(props) => props.theme.borderRadius};

  .modal-content {
    border: 0;
    border-radius: ${(props) => props.theme.borderRadius};
    color: ${(props) => props.theme.color};

  }


  .close {
    color: ${(props) => props.theme.bg}
    opacity: 1;
  }
`;

const StyledModalHeader = styled(ModalHeader)`
    min-height: 3rem;
    padding: 0.5rem 1rem;
    border-top-left-radius: ${(props) => props.theme.borderRadius};
    border-top-right-radius: ${(props) => props.theme.borderRadius};
    background-color: ${(props) => props.theme.modal.backgroundColor};
    color: ${(props) => props.theme.bg};
`;

const StyledModalFooter = styled(ModalFooter)`
    padding: 0.375rem;
    padding-left: 1rem;
    padding-right: 1rem;
`;

const StyledModalCloseButton = styled.div`
    display: flex;
    position: absolute;
    top: -10px;
    right: -10px;
    height: 30px;
    width: 30px;
    background-color: ${(props) => props.theme.bg};
    color: ${(props) => props.theme.color};
    border-radius: 15px;
    align-items: center;
    justify-content: center;
`;

// OnCancel and onOk are any Type; Function or MouseEvent don't work.
interface IDialog extends IBaseView {
    isOpen: boolean;
    onCancel: any; // callback
    onOk: any; // callback
    title: string;
    extraButtons?: any; // Todo better dataType
    okColor?: string;
    hideOk?: boolean;
    hideCancel?: boolean;
    onOpened?: () => void;
}

// Generic Modal Dialog
// place the content as child.
@inject('rootStore')
@observer
export class Dialog extends React.Component<IDialog, any> {
    handleEnter = (target: any) => {
        const { onOk } = this.props;
        if (target.charCode === 13) {
            onOk();
            return;
        }
    };

    handleEscape = (target: any) => {
        const { onCancel } = this.props;
        if (target.key === 'Escape') {
            onCancel();
            return;
        }
    };

    render() {
        const {
            rootStore: {
                langStore: { lang },
            },
            extraButtons,
            isOpen,
            title,
            onCancel,
            onOk,
            okColor,
            hideOk,
            hideCancel,
            onOpened,
        } = this.props;

        const modalfooterStyle = extraButtons ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' };

        return (
            <Draggable handle={'.modal-header'}>
                <StyledModal isOpen={isOpen} onKeyEnter={this.handleEnter} onKeyUp={this.handleEscape} centered onOpened={onOpened}>
                    <div>
                        <StyledModalCloseButton onClick={onCancel}>
                            <FontAwesomeIcon icon={['fas', 'times']} />
                        </StyledModalCloseButton>
                        <StyledModalHeader>{title}</StyledModalHeader>
                        <ModalBody style={{ paddingBottom: 0 }}>{this.props.children}</ModalBody>
                        <StyledModalFooter style={modalfooterStyle}>
                            {extraButtons ? extraButtons : null}
                            <div>
                                {hideOk ? null : (
                                    <IconButton color={okColor ? okColor : 'primary'} onClick={onOk}>
                                        {lang['OK']}
                                    </IconButton>
                                )}
                                &nbsp;
                                {hideCancel ? null : (
                                    <IconButton color="secondary" onClick={onCancel}>
                                        {lang['CANCEL']}
                                    </IconButton>
                                )}
                            </div>
                        </StyledModalFooter>
                    </div>
                </StyledModal>
            </Draggable>
        );
    }
}
