import { IField } from '../components/dataset/IField';

export interface IGembaFindingImage {
    gembano: string;
    findingno: string;
    imageno: number;
    image: string;
}

export const gembaFindingImage: IField<IGembaFindingImage>[] = [
    {
        fieldName: 'gembano',
        dataType: 'string',
        label: 'GEMBA_GEMBANO',
        primaryKey: true,
        rules: ['pk'],
        insert: 'hide',
        hide: 'table',
    },
    {
        fieldName: 'findingno',
        label: 'GEMBA_FINDINGNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        grid: {
            width: 60,
        },
    },
    {
        fieldName: 'imageno',
        label: '',
        dataType: 'number',
        primaryKey: true,
        rules: ['pk'],
        grid: {
            width: 60,
        },
        hide: 'table',
    },
    {
        fieldName: 'image',
        dataType: 'string',
        label: 'IMAGE',
        insert: 'show',
    },
];
