import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../_base/base-view';
import { Col, Row } from 'reactstrap';
import { DbField } from '../../../components/dbform/db-field';
import { DetailOneColLayout } from '../../_base/styled/layout/detail-one-col-layout';
import { TableForm } from '../../_base/styled/table-form';
import { RatingMethod } from '../../../models/AuditTemplate';

@inject('rootStore')
@observer
export class AuditTemplateQuestionView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                auditTemplateStore: { ds, dsQuestion },
            },
        } = this.props;
        return (
            <DetailOneColLayout
                topElement={<TableForm icon="eye" title="AUDIT" ds={ds} keyTitle={ds.actual?.audittemplate} open={false} />}
                mainElement={
                    <TableForm icon="question" title="AUDIT_TEMPLATE_QUESTION" ds={dsQuestion} open={true}>
                        <Row>
                            <Col>
                                <DbField ds={dsQuestion} field="audittemplate" />
                                <DbField ds={dsQuestion} field="reference" />
                                <DbField ds={dsQuestion} field="area" />
                                <DbField ds={dsQuestion} field="element" />
                                <DbField ds={dsQuestion} field="question" />
                                <DbField ds={dsQuestion} field="requirements" />
                                <Row>
                                    <Col>
                                        <DbField ds={dsQuestion} field="mandatory" />
                                        <DbField ds={dsQuestion} field="unselected" />
                                        <DbField ds={dsQuestion} field="attentionflag" />
                                    </Col>
                                    <Col>
                                        <DbField ds={dsQuestion} field="chapter" />
                                    </Col>
                                    <Col>
                                        <DbField ds={dsQuestion} field="weighting" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <DbField ds={dsQuestion} field="questionno" />
                                {ds.actual?.ratingmethod === RatingMethod.VESTAS_SBA ? (
                                    <>
                                        <DbField ds={dsQuestion} field="level01" />
                                        <DbField ds={dsQuestion} field="level02" />
                                        <DbField ds={dsQuestion} field="level03" />
                                        <DbField ds={dsQuestion} field="level04" />
                                        <DbField ds={dsQuestion} field="level05" />
                                    </>
                                ) : null}
                            </Col>
                        </Row>
                    </TableForm>
                }
            />
        );
    }
}
