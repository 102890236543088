import * as React from 'react';
import { IBaseView } from '../../../../_base/base-view';
import { IconButton } from '../../../../_base/styled/icon-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverBody } from 'reactstrap';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RatingMethod } from '../../../../../models/AuditTemplate';

interface IMenuItem extends IBaseView {
    filterValue: number;
    filterText: string;
    toggle: () => void;
}

const MenuItem = inject('rootStore')(
    observer(({ toggle, filterValue, filterText, rootStore }: IMenuItem) => {
        const {
            auditStore: { questionScoreFilter, setQuestionScoreFilter },
        } = rootStore;
        return (
            <div
                style={{
                    cursor: 'pointer',
                    marginTop: '0.125rem',
                    marginBottom: '0.125rem',
                }}
                onClick={() => {
                    setQuestionScoreFilter(filterValue);
                    toggle();
                }}
            >
                <FontAwesomeIcon icon={questionScoreFilter === filterValue ? ['fas', 'check-circle'] : ['far', 'circle']} />
                &nbsp;&nbsp;{filterText}
            </div>
        );
    }),
);

@inject('rootStore')
@observer
export class AuditSelectScoreFilter extends React.Component<IBaseView, {}> {
    @observable
    isOpen: boolean;

    @action.bound
    toggle() {
        this.isOpen = !this.isOpen;
    }

    render() {
        const {
            rootStore: {
                auditStore: { ds },
                langStore: { lang },
            },
        } = this.props;
        return (
            <>
                <IconButton id="treefilter" onClick={this.toggle}>
                    <FontAwesomeIcon icon={['fas', 'filter']} />
                </IconButton>
                <Popover trigger="legacy" placement="bottom-end" isOpen={this.isOpen} target="treefilter" toggle={this.toggle} hideArrow={true}>
                    <PopoverBody>
                        <MenuItem filterValue={undefined} filterText={lang['ALL']} toggle={this.toggle} />
                        {ds.actual?.ratingmethod === RatingMethod.VESTAS_SBA ? (
                            <>
                                <MenuItem filterValue={8.5} filterText="score &lt;= 85" toggle={this.toggle} />
                                <MenuItem filterValue={7} filterText="score &lt;= 70" toggle={this.toggle} />
                                <MenuItem filterValue={3.5} filterText="score &lt;= 35" toggle={this.toggle} />
                            </>
                        ) : (
                            <>
                                <MenuItem filterValue={8} filterText="score &lt;= 8" toggle={this.toggle} />
                                <MenuItem filterValue={6} filterText="score &lt;= 6" toggle={this.toggle} />
                                <MenuItem filterValue={4} filterText="score &lt;= 4" toggle={this.toggle} />
                            </>
                        )}
                    </PopoverBody>
                </Popover>
            </>
        );
    }
}
