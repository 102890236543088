import * as React from 'react';
import { Col, Form, Row } from 'reactstrap';
import { DbField } from './db-field';
import { observer } from 'mobx-react';
import { dsState, IDataset } from '../dataset/IDataset';
import { IField } from '../dataset/IField';

export enum FormMode {
    edit, // Default
    insert,
    copy,
    rename,
}

export type TcolCount = 1 | 2 | 3 | 4 | 6 | undefined;

export interface DBFormProps<T> {
    ds: IDataset<T>;
    formMode?: FormMode;
    colCount?: TcolCount;
    doOK?: Function;
    row?: boolean;
    children?: React.ReactNode;
}

@observer
export class DbForm<T> extends React.Component<DBFormProps<T>, any> {
    constructor(props: DBFormProps<any>) {
        super(props);
        this.filter = this.filter.bind(this);
    }

    filter(column: IField<T>) {
        if (column.fieldName === 'taskowner') {
            return column.hide !== 'detail' && column.hide !== 'always';
        }

        switch (this.props.formMode) {
            case FormMode.edit:
                return column.hide !== 'detail' && column.hide !== 'always';

            case FormMode.insert:
            case FormMode.copy:
            case FormMode.rename:
                return column.insert === 'show' || (column.primaryKey && column.insert !== 'hide');

            default:
                return column.hide !== 'detail' && column.hide !== 'always';
        }
    }

    readOnly = (column: IField<T>, state: dsState): boolean => {
        return (
            (state !== dsState.dsInsert && column.readOnly) ||
            state === dsState.dsInactive ||
            state === dsState.dsBrowse ||
            state === dsState.dsPending ||
            (state === dsState.dsEdit && column.primaryKey === true)
        );
    };

    sm = (colCount: number): number => {
        colCount = colCount ? colCount : 2;
        return colCount ? 12 / colCount : 6;
    };

    defaultFields = () => {
        let { ds, colCount, row } = this.props;
        return (
            <Row>
                {ds.actual
                    ? ds.columns.filter(this.filter).map((column: IField<T>) => (
                          <Col sm={this.sm(colCount)} key={column.fieldName as string}>
                              <DbField ds={ds} column={column} readOnly={this.readOnly(column, ds.state)} row={row} />
                          </Col>
                      ))
                    : null}
            </Row>
        );
    };

    render() {
        let { ds, children, doOK } = this.props;
        // padding set to org variables.less value of 15, which is now set to 0, due the alignment of the ag-grid
        if (ds) {
            return (
                <Form
                    onSubmit={(event: any) => {
                        event.preventDefault();
                        if (doOK) {
                            doOK();
                        }
                    }}
                >
                    {children ? children : this.defaultFields()}
                </Form>
            );
        } else {
            return null;
        }
    }
}
