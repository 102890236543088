import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IBaseView } from '../../../../_base/base-view';
import { cl_blue } from '../../../../_theme/colors';
import styled from 'styled-components';
import { IPpapDetail02 } from '../../../../../models/PPAPDetail02';
import { dsState, IDataset } from '../../../../../components/dataset/IDataset';
import { TableField } from '../../helper/FormEditor';
import { MultiCheckbox } from '../../helper/multi-checkbox';
import { StyledTable } from '../../../order/right/ppap-upload-tab/styled-table';
import { StyledHeadline } from '../../../order/right/ppap-upload-tab/styled-headline';

const StyledText = styled.p`
    border: 1px solid ${(props) => props.theme.color};
    color: ${(props) => props.theme.color};
`;

interface IPPAPDetail02Props {
    dsPpapDetail02: IDataset<IPpapDetail02>;
}

const ResposibleEngineer = observer(({ dsPpapDetail02 }: IPPAPDetail02Props) => {
    return (
        <>
            <StyledHeadline>Responsible Engineer</StyledHeadline>
            <StyledTable>
                <thead>
                    <tr>
                        <td style={{ width: '30%' }}>Name</td>
                        <td>Function</td>
                        <td>Company</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <TableField ds={dsPpapDetail02} fieldName="respname" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                        </td>
                        <td>
                            <TableField ds={dsPpapDetail02} fieldName="respfunction" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                        </td>
                        <td>
                            <TableField ds={dsPpapDetail02} fieldName="respcompany" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                        </td>
                    </tr>
                </tbody>
            </StyledTable>
        </>
    );
});

const Participating = observer(({ dsPpapDetail02 }: IPPAPDetail02Props) => (
    <>
        <StyledHeadline style={{ margin: 0, fontWeight: 'bold' }}>Participating</StyledHeadline>
        <StyledTable>
            <thead>
                <tr>
                    <td style={{ width: '30%' }}>Name</td>
                    <td>Function</td>
                    <td>Company</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="name01" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="function01" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="company01" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="name02" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="function02" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="company02" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="name03" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="function03" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="company03" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="name04" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="function04" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="company04" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="name05" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="function05" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="company05" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="name06" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="function06" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="company06" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="name07" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="function07" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                    <td>
                        <TableField ds={dsPpapDetail02} fieldName="company07" readonly={!(dsPpapDetail02.state === dsState.dsEdit)} />
                    </td>
                </tr>
            </tbody>
        </StyledTable>
    </>
));

const FeasibilityConsiderations = () => (
    <>
        <StyledHeadline>Feasibility Considerations</StyledHeadline>
        <StyledText style={{ width: '50%' }}>
            Our product quality planning team has considered the following questions, not intended to be all-inclusive in performing a feasibility
            evaluation. The drawings and/or specifications provided have been used as a basis for analyzing the ability to meet all specified
            requirements. All "no" answers are supported with attached comments identifying our concerns and/or proposed changes to enable us to meet
            the specified requirements.
        </StyledText>
    </>
);

interface IConsideration {
    ds: IDataset<any>;
    fieldname: string;
    consideration: string;
}

const Consideration = inject('rootStore')(
    observer(({ ds, fieldname, consideration }: IConsideration) => {
        return (
            <tr>
                <td style={{ width: '60%', border: '1px solid black' }}>{consideration}</td>
                <td style={{ border: '1px solid black' }}>
                    <MultiCheckbox ds={ds} fieldname={fieldname} checkValue={2} uncheckValue={-1} />
                </td>
                <td style={{ border: '1px solid black' }}>
                    <MultiCheckbox ds={ds} fieldname={fieldname} checkValue={1} uncheckValue={-1} />
                </td>
                <td style={{ border: '1px solid black' }}>
                    <MultiCheckbox ds={ds} fieldname={fieldname} checkValue={0} uncheckValue={-1} />
                </td>
                <td style={{ border: '1px solid black' }}>
                    <TableField ds={ds} fieldName={fieldname + '_c'} readonly={!(ds.state === dsState.dsEdit)} />
                </td>
                <td style={{ border: '1px solid black' }}>
                    <TableField ds={ds} fieldName={fieldname + '_ic'} readonly={!(ds.state === dsState.dsEdit)} />
                </td>
            </tr>
        );
    }),
);

const Considerations = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                ppapStore: { dsPpapDetail02 },
            },
        } = props;
        return (
            <table style={{ width: '100%', border: '1px solid black' }}>
                <thead style={{ backgroundColor: cl_blue, color: 'white' }}>
                    <tr style={{ fontWeight: 'bolder' }}>
                        <td>CONSIDERATION</td>
                        <td>YES</td>
                        <td>NO</td>
                        <td>NA</td>
                        <td>COMMENTS</td>
                        <td>INTERNAL COMMENTS</td>
                    </tr>
                </thead>
                <tbody>
                    <Consideration
                        ds={dsPpapDetail02}
                        fieldname={'isdefined'}
                        consideration="Is product adequately defined (application requirements, etc. to enable feasibility evaluation)?"
                    />
                    <Consideration
                        ds={dsPpapDetail02}
                        fieldname={'isperformance'}
                        consideration="Can Engineering Performance Secifications be met as written?"
                    />
                    <Consideration
                        ds={dsPpapDetail02}
                        fieldname={'istolerances'}
                        consideration="Can product be manufactured to tolerances specified on drawing?"
                    />
                    <Consideration
                        ds={dsPpapDetail02}
                        fieldname={'iscpk'}
                        consideration="Can product be manufactured with Cpk's that meet requirements?"
                    />
                    <Consideration ds={dsPpapDetail02} fieldname={'iscapacity'} consideration="Is there adequate capacity to produce product?" />
                    <Consideration
                        ds={dsPpapDetail02}
                        fieldname={'ishandling'}
                        consideration="Does the design allow the use of efficient material handling techniques?"
                    />
                    <tr>
                        <td style={{ width: '60%', border: '1px solid black' }}>Can the product be manufactured without incurring any unusual:</td>
                        <td />
                    </tr>
                    <Consideration
                        ds={dsPpapDetail02}
                        fieldname={'iscostcapital'}
                        consideration="&nbsp;&nbsp;&nbsp;&nbsp;- Costs for capital equipment?"
                    />

                    <Consideration ds={dsPpapDetail02} fieldname={'iscosttooling'} consideration="&nbsp;&nbsp;&nbsp;&nbsp;- Costs for tooling?" />
                    <Consideration
                        ds={dsPpapDetail02}
                        fieldname={'isalternative'}
                        consideration="&nbsp;&nbsp;&nbsp;&nbsp;- Alternative manufacturing methods?"
                    />
                    <Consideration
                        ds={dsPpapDetail02}
                        fieldname={'isstatrequired'}
                        consideration="Is statistical process control required on the product?"
                    />
                    <tr>
                        <td style={{ width: '60%', border: '1px solid black' }}>
                            Is statistical process control presently used on similar products?
                        </td>
                        <td />
                    </tr>
                    <Consideration
                        ds={dsPpapDetail02}
                        fieldname={'isstable'}
                        consideration="&nbsp;&nbsp;&nbsp;&nbsp;- Are the processes in control and stable?"
                    />
                    <Consideration
                        ds={dsPpapDetail02}
                        fieldname={'iscapability'}
                        consideration="&nbsp;&nbsp;&nbsp;&nbsp;- Are cpk's greater than 1.33?"
                    />
                </tbody>
            </table>
        );
    }),
);

const Conclusion = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                ppapStore: { dsPpapDetail02 },
            },
        } = props;
        return (
            <>
                <p style={{ margin: 0, fontWeight: 'bold' }}>Conclusion</p>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ width: '2rem' }}>
                                <MultiCheckbox ds={dsPpapDetail02} fieldname="feasible" checkValue={2} uncheckValue={-1} />
                            </td>
                            <td style={{ width: '7rem' }}>Feasible</td>
                            <td>Product can be produced as specified with no revions.</td>
                        </tr>
                        <tr>
                            <td>
                                <MultiCheckbox ds={dsPpapDetail02} fieldname="feasible" checkValue={1} uncheckValue={-1} />
                            </td>
                            <td>Feasible</td>
                            <td>Changes recommended (see attached).</td>
                        </tr>
                        <tr>
                            <td>
                                <MultiCheckbox ds={dsPpapDetail02} fieldname="feasible" checkValue={0} uncheckValue={-1} />
                            </td>
                            <td>Not Feasible</td>
                            <td>Design revision required to produce product within the specified requirements</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }),
);

@inject('rootStore')
@observer
export class PpapReportTeamFeasibilityCommitment extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                ppapStore: { dsPpapDetail02 },
            },
        } = this.props;
        return (
            <>
                <ResposibleEngineer dsPpapDetail02={dsPpapDetail02} />
                <p />
                <Participating dsPpapDetail02={dsPpapDetail02} />
                <p />
                <FeasibilityConsiderations />
                <Considerations />
                <p />
                <Conclusion />
            </>
        );
    }
}
