import * as React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Sticker } from '../../_base/styled/sticker';
import { statusColor, statusText } from '../../../models/Status';
import { IProjectStatus } from '../../../stores/dashboard-store';
import { IBaseView } from '../../_base/base-view';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { DashboardPpap } from './ppap/dashboard-ppap';
import { DashboardGemba } from './gemba/dashboard-gemba';
import { DashboardGantt } from './gantt/dashboard-gantt';
import { DashboardAudit } from './audit/dashboard-audit';

export interface IDashboardProjectCard extends IBaseView {
    projectStatus: IProjectStatus;
}

@inject('rootStore')
@observer
export class DashboardProjectCard extends React.Component<IDashboardProjectCard, {}> {
    @action.bound
    gotoProject(projectno: string) {
        const {
            rootStore: { routerStore },
        } = this.props;
        return async () => {
            await routerStore.goTo('project', { projectno: projectno });
        };
    }

    render() {
        const {
            rootStore: {
                langStore: { lang },
            },
            projectStatus,
        } = this.props;
        return (
            <Card style={{ height: '100%' }}>
                <CardHeader style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                    <Row>
                        <Col md={8}>
                            <div className="status-left" onClick={this.gotoProject(projectStatus.project.projectno)}>
                                <div className="logo">
                                    {projectStatus.project.suppliericon && projectStatus.project.supplierno ? (
                                        <img
                                            style={{ objectFit: 'contain', width: '2rem', height: '2rem' }}
                                            src={
                                                '/gridApi/supplierlogo/download/' +
                                                projectStatus.project.supplierno +
                                                '/' +
                                                projectStatus.project.suppliericon
                                            }
                                            alt=""
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon="project-diagram" />
                                    )}
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                {projectStatus.project.project}
                            </div>
                        </Col>
                        <Col md={4} className="status-right">
                            <Sticker color={statusColor(projectStatus.project.status)}>{lang[statusText(projectStatus.project.status)]}</Sticker>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody style={{ padding: '0.625rem' }}>
                    {projectStatus.audits.length > 0 ? <DashboardAudit projectStatus={projectStatus} /> : null}
                    {projectStatus.ppapstati.length > 0 ? <DashboardPpap projectStatus={projectStatus} /> : null}
                    {projectStatus.gemba.length > 0 ? <DashboardGemba projectStatus={projectStatus} /> : null}
                    {projectStatus.gantt.data.length > 0 ? <DashboardGantt projectStatus={projectStatus} /> : null}
                </CardBody>
            </Card>
        );
    }
}
