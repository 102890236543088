import * as React from 'react';
import { CardBody } from 'reactstrap';
import styled from 'styled-components';

const _GridBody = (props: any) => <CardBody className={props.className}>{props.children}</CardBody>;

export const GridBody = styled(_GridBody)`
    padding: 0;
    position: relative;
    height: 100%;
    border: 0;
`;
