import React from 'react';
import { observer } from 'mobx-react';
import { RatingMethod } from '../../../models/AuditTemplate';
import { AuditRatingBinary } from './audit-rating-binary';
import { AuditRatingVda63 } from './audit-rating-vda63';
import { AuditRatingVda61 } from './audit-rating-vda61';
import { AuditRatingVestasSba } from './audit-rating-vestas-sba';
import { IAuditQuestion } from '../../../models/AuditQuestion';
import { IBaseView } from '../../_base/base-view';
import { IAudit } from '../../../models/Audit';

export interface IAuditRating extends IBaseView {
    audit: IAudit;
    question: IAuditQuestion;
    readOnly: boolean;
}

export const AuditRating = observer((props: IAuditRating) => {
    switch (props.audit?.ratingmethod) {
        case RatingMethod.VDA61:
            return <AuditRatingVda61 {...props} />;
        case RatingMethod.BINARY:
            return <AuditRatingBinary {...props} />;
        case RatingMethod.VDA63:
            return <AuditRatingVda63 {...props} />;
        case RatingMethod.VESTAS_SBA:
            return <AuditRatingVestasSba {...props} />;
        default:
            return null;
    }
});
