import { BaseStore, IBaseStore } from './base-store';
import { IPpapOrder, ppapOrderColumns } from '../models/Ppap';
import { IRootStore } from '../routes/root-store';
import { IDataset } from '../components/dataset/IDataset';
import { IPpapOrderElement, ppapOrderElementColumns } from '../models/PpapOrderElement';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { Dataset } from '../components/dataset/dataset';
import { IAction, ActionTyp, ppapActionColumns } from '../models/Action';
import moment from 'moment';
import { IPpapOrderDocument, ppapOrderDocumentColumns } from '../models/PpapDocument';
import { PPAP_DOC, PPAP_SR } from '../models/PpapStandardElement';
import * as R from 'ramda';
import { ISupplier, supplierColumns } from '../models/Supplier';
import { customerColumns, ICustomer } from '../models/Customer';
import axios from 'axios';
import { RouterState } from 'mobx-state-router';
import { Status } from '../models/Status';
import { authorizer } from '../components/dataset/authorizer';
import { enumToArray } from '../components/lib/enumToArray';
import { IProject, projectColumns } from '../models/Project';
import { IPpapElementComment, ppapElementCommentColumns } from '../models/PPAPElementComment';
import { resizeImage } from '../lib/resize-image';
import { IProduct, productColumns } from '../models/Product';
import { IPpapDetail18, ppapDetail18Columns } from '../models/PpapDetail18';
import { IPpapDetail02, ppapDetail02Columns } from '../models/PPAPDetail02';
import { IPpapDetail04, ppapDetail04Columns } from '../models/PPAPDetail04';
import { IPpapDetail05, ppapDetail05Columns } from '../models/PpapDetail05';
import { IUser, userColumns } from '../models/User';
import { IPPAPPartFamily, ppapPartFamilyColumns } from '../models/PPAPPartFamily';

export interface IPPAPStore extends IBaseStore<IPpapOrder> {
    dsPpapOrderElement: IDataset<IPpapOrderElement>;
    dsAction: IDataset<IAction>;
    dsPpapOrderDocument: IDataset<IPpapOrderDocument>;
    dsPpapUploads: IDataset<IPpapOrderDocument>;
    dsPpapElementComment: IDataset<IPpapElementComment>;
    dsSupplier: IDataset<ISupplier>;
    dsCustomer: IDataset<ICustomer>;
    dsProject: IDataset<IProject>;
    dsProduct: IDataset<IProduct>;
    dsPpapFamily: IDataset<IPPAPPartFamily>;
    dsPpapDetail02: IDataset<IPpapDetail02>;
    dsPpapDetail04: IDataset<IPpapDetail04>;
    dsPpapDetail05: IDataset<IPpapDetail05>;
    dsPpapDetail18: IDataset<IPpapDetail18>;
    dsCustResponsible: IDataset<IUser>;
    dsSupResponsible: IDataset<IUser>;
    //
    includeMandatory: boolean;
    toggleMandatory: () => Promise<void>;
    filterSubmit: boolean;
    toggleSubmit: () => void;
    //
    actionByElementFilter: boolean;
    toggleActionFilter: () => Promise<void>;
    //

    uploadUploadFile: (acceptFile: any[]) => Promise<void>;
    uploadBlogFile: (acceptFile: any[]) => Promise<void>;
    toggleElement: (ppapno: string, elementno: number, doc: PPAP_DOC) => Promise<void>;
    //
    saveOwner: string;
    statusElementOptionsFunc: () => number[];
}

export class PpapStore extends BaseStore<IPpapOrder> implements IPPAPStore {
    /**
     *  dient als Zwischenspeicher des aktuellen Owners, wenn Form in Edit Mode geht und der Owner geändert wird.
     */
    saveOwner: string;

    @action.bound
    dsOnAfterInsert(ds: IDataset<IPpapOrder>) {
        const {
            authStore: { username },
        } = this.rootStore;
        ds.actual.status = Status.PLANNED;
        ds.actual.createdby = username;
        ds.actual.createdat = moment().format();
    }

    @action.bound
    dsOnAfterEdit(ds: IDataset<IPpapOrder>) {
        this.saveOwner = ds.actual.owner;
    }

    @observable
    dsPpapOrderElement: IDataset<IPpapOrderElement>;

    @observable
    dsAction: IDataset<IAction>;

    @observable
    dsPpapOrderDocument: IDataset<IPpapOrderDocument>;

    @observable
    dsPpapUploads: IDataset<IPpapOrderDocument>;

    @observable
    dsPpapElementComment: IDataset<IPpapElementComment>;

    @observable
    dsSupplier: IDataset<ISupplier>;

    @observable
    dsCustomer: IDataset<ICustomer>;

    @observable
    dsProject: IDataset<IProject>;

    @observable
    dsProduct: IDataset<IProduct>;

    @observable
    dsPpapFamily: IDataset<IPPAPPartFamily>;

    @observable
    dsPpapDetail02: IDataset<IPpapDetail02>;

    @observable
    dsPpapDetail04: IDataset<IPpapDetail04>;

    @observable
    dsPpapDetail05: IDataset<IPpapDetail05>;

    @observable
    dsPpapDetail18: IDataset<IPpapDetail18>;

    @observable
    dsCustResponsible: IDataset<IUser>;

    @observable
    dsSupResponsible: IDataset<IUser>;

    @action.bound
    dsActionOnAfterInsert(ds: IDataset<IAction>) {
        const {
            authStore: { username },
        } = this.rootStore;
        ds.actual.typ = ActionTyp.PPAPTask;
        ds.actual.createdby = username;
        ds.actual.taskowner = username;
        ds.actual.createdat = moment().format();
    }

    @action.bound
    dsPpapElementCommentOnAfterInsert(ds: IDataset<IPpapElementComment>) {
        const {
            authStore: { username },
        } = this.rootStore;
        ds.actual.elementno = this.dsPpapOrderElement.actual.elementno;
        ds.actual.user = username;
        ds.actual.timestamp = moment().format();
        ds.actual.commentno = ds.data.length > 1 ? ds.data[ds.data.length - 2].commentno + 1 : 1;
        //console.log(ds.data.length,ds.data[ds.data.length - 2].commentno,ds.actual.commentno)
    }

    @observable
    includeMandatory: boolean = false;

    mandatoryFilter = { doc: PPAP_DOC.MANDATORY.toString() };

    @observable
    filterSubmit: boolean = false;

    submitFilter = { sr: PPAP_SR.SUBMIT.toString() };

    @observable
    actionByElementFilter: boolean = true;

    @action.bound
    dsPpapOrderElementAfterOpen(ds: IDataset<IPpapOrderElement>) {
        ds.data = ds.data.filter((record) => record.doc === PPAP_DOC.MANDATORY);
    }

    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/ppap/', ppapOrderColumns);

        this.ds.onAfterInsert = this.dsOnAfterInsert;

        this.dsPpapOrderElement = new Dataset<IPpapOrderElement>('/gridApi/ppapelement/', ppapOrderElementColumns);
        this.dsPpapOrderElement.setMasterSource(this.ds, [
            {
                field: 'ppapno',
                masterField: 'ppapno',
            },
        ]);
        this.dsPpapOrderElement.onAfterOpen = this.dsPpapOrderElementAfterOpen;

        this.dsPpapElementComment = new Dataset<IPpapElementComment>('/gridApi/ppapelementcomment/', ppapElementCommentColumns);
        this.dsPpapElementComment.setMasterSource(this.dsPpapOrderElement, [
            {
                field: 'ppapno',
                masterField: 'ppapno',
            },
            {
                field: 'elementno',
                masterField: 'elementno',
            },
        ]);
        this.dsPpapElementComment.onAfterInsert = this.dsPpapElementCommentOnAfterInsert;

        this.dsAction = new Dataset<IAction>('/gridApi/action/', R.clone(ppapActionColumns), { typ: ActionTyp.PPAPTask });
        this.dsAction.setMasterSource(this.dsPpapOrderElement, this.actionFilter);
        this.dsAction.onAfterInsert = this.dsActionOnAfterInsert;

        this.dsPpapOrderDocument = new Dataset<IPpapOrderDocument>('/gridApi/ppapdocument/', ppapOrderDocumentColumns);
        this.dsPpapOrderDocument.setMasterSource(this.dsPpapOrderElement, [
            {
                field: 'ppapno',
                masterField: 'ppapno',
            },
            {
                field: 'elementno',
                masterField: 'elementno',
            },
        ]);

        this.dsPpapUploads = new Dataset<IPpapOrderDocument>('/gridApi/ppapdocument/', ppapOrderDocumentColumns);

        this.dsSupplier = new Dataset<ISupplier>('/gridApi/supplier/', supplierColumns);
        this.dsSupplier.setMasterSource(this.ds, [
            {
                field: 'supplierno',
                masterField: 'supplierno',
            },
        ]);

        this.dsCustomer = new Dataset<ICustomer>('/gridApi/customer/', customerColumns);
        this.dsCustomer.setMasterSource(this.ds, [
            {
                field: 'customerno',
                masterField: 'customerno',
            },
        ]);

        this.dsProject = new Dataset<IProject>('/gridApi/project/', projectColumns);
        this.dsProject.setMasterSource(this.ds, [
            {
                field: 'projectno',
                masterField: 'projectno',
            },
        ]);

        this.dsProduct = new Dataset<IProduct>('/gridApi/product/', productColumns);
        this.dsProduct.setMasterSource(this.ds, [
            {
                field: 'productno',
                masterField: 'productno',
            },
        ]);
        this.dsPpapFamily = new Dataset<IPPAPPartFamily>('/gridApi/ppappartfamily/', ppapPartFamilyColumns);
        this.dsPpapFamily.setMasterSource(this.ds, [
            {
                field: 'ppapno',
                masterField: 'ppapno',
            },
        ]);

        this.dsPpapDetail02 = new Dataset<IPpapDetail02>('/gridApi/ppapdetail02/', ppapDetail02Columns);
        this.dsPpapDetail02.setMasterSource(this.ds, [
            {
                field: 'ppapno',
                masterField: 'ppapno',
            },
        ]);

        this.dsPpapDetail04 = new Dataset<IPpapDetail04>('/gridApi/ppapdetail04/', ppapDetail04Columns);
        this.dsPpapDetail04.setMasterSource(this.ds, [
            {
                field: 'ppapno',
                masterField: 'ppapno',
            },
        ]);

        this.dsPpapDetail05 = new Dataset<IPpapDetail05>('/gridApi/ppapdetail05/', ppapDetail05Columns);
        this.dsPpapDetail05.setMasterSource(this.ds, [
            {
                field: 'ppapno',
                masterField: 'ppapno',
            },
        ]);

        this.dsPpapDetail18 = new Dataset<IPpapDetail18>('/gridApi/ppapdetail18/', ppapDetail18Columns);
        this.dsPpapDetail18.setMasterSource(this.ds, [
            {
                field: 'ppapno',
                masterField: 'ppapno',
            },
        ]);

        this.dsCustResponsible = new Dataset<IUser>('/gridApi/user/', userColumns);
        this.dsCustResponsible.setMasterSource(this.ds, [
            {
                field: 'username',
                masterField: 'responsiblecust',
            },
        ]);

        this.dsSupResponsible = new Dataset<IUser>('/gridApi/user/', userColumns);
        this.dsSupResponsible.setMasterSource(this.ds, [
            {
                field: 'username',
                masterField: 'responsiblesup',
            },
        ]);
    }

    @action.bound
    async openDetails() {
        this.saveOwner = '';
        //
        this.includeMandatory = false;
        this.dsPpapOrderElement.filter = this.includeMandatory ? this.mandatoryFilter : {};
        //
        this.actionByElementFilter = true;
        //
        await this.dsProject.open();
        await this.dsCustomer.open();
        await this.dsSupplier.open();
        await this.dsPpapOrderElement.open();
        await this.dsPpapElementComment.open();
        await this.dsPpapOrderDocument.open();
        // hier muss für das responsible Feld die Url: /gridapi/projectmember/<projectno> gesetzt werden.
        // Da columns im constructor gecloned sind, dürfte das keine Seiteneffekte haben
        const responsible = this.dsAction.columns.find((column) => column.fieldName === 'responsible');
        responsible.selectdlg.url = '/gridApi/projectmember/?projectno=' + this.dsProject.actual?.projectno;
        responsible.selectdlg.InserRight = undefined;
        await this.dsAction.open();
        await this.dsProduct.open();
        await this.dsPpapFamily.open();
        await this.dsPpapDetail02.open();
        await this.dsPpapDetail04.open();
        await this.dsPpapDetail05.open();
        await this.dsPpapDetail18.open();
        await this.dsCustResponsible.open();
        await this.dsSupResponsible.open();
    }

    /**
     * PPAP Userauswahl auf konkretes Projekt anpassen.
     * Die auswählbaren User sind immer Projektbezogen.
     * Die Api braucht die Projektnummer in der Url.
     * Als Reaktion ausgeführt, dann passiert es automatisch.
     */
    disposeMe = reaction(
        () => this.ds.actual?.projectno,
        (projectno) => {
            if (projectno) {
                this.ds.columns.find((column) => column.fieldName === 'owner').selectdlg.url = '/gridApi/projectuser/' + projectno;
            } else {
                this.ds.columns.find((column) => column.fieldName === 'owner').selectdlg.url = '/gridApi/user/';
            }
        },
    );

    @action.bound
    closeDetails() {
        this.saveOwner = '';
        this.dsProject.close();
        this.dsAction.close();
        this.dsPpapOrderDocument.close();
        this.dsPpapElementComment.close();
        this.dsPpapOrderElement.close();
        this.dsSupplier.close();
        this.dsCustomer.close();
        this.dsProduct.close();
        this.dsPpapFamily.close();
        this.dsPpapDetail02.close();
        this.dsPpapDetail04.close();
        this.dsPpapDetail05.close();
        this.dsPpapDetail18.close();
        this.dsCustResponsible.close();
        this.dsSupResponsible.close();
    }

    beforeEnter = async (fromState: RouterState, toState: RouterState) => {
        this.dsCheck.dataUrl = '/gridApi/ppap/';
        this.dsCheck.filter = { ppapno: toState.params.ppapno };
        await this.dsCheck.open();
        const rv = this.dsCheck.data.length === 1;
        this.dsCheck.close();
        return rv;
    };

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        switch (toState.routeName) {
            case 'ppapordertable':
                await this.loadFilter('ppap');
                await this.open(this.cdsFilter.actual);
                if (fromState.routeName === 'ppaporder') {
                    this.ds.locate(this.ds.pkFields, fromState.params);
                }
                break;
            case 'ppaporder':
            case 'ppapreport':
                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                await this.openDetails();
                if (toState.routeName === 'ppapreport') {
                    this.dsPpapUploads.filter = { ppapno: this.ds.actual.ppapno };
                    await this.dsPpapUploads.open();
                }
                break;
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'ppapreport') {
            this.dsPpapUploads.close();
        }
        if (fromState.routeName === 'ppaporder' || toState.routeName === 'ppapreport') {
            this.closeDetails();
            await this.ds.post();
            this.close();
        } else if (fromState.routeName === 'ppapordertable') {
            this.close();
        }
    }

    private setPpapOrderElementFilter = () => {
        this.dsPpapOrderElement.filter = R.mergeAll([this.includeMandatory ? this.mandatoryFilter : {}, this.filterSubmit ? this.submitFilter : {}]);
    };

    @action.bound
    async toggleMandatory() {
        this.includeMandatory = !this.includeMandatory;
        this.setPpapOrderElementFilter();
        await this.dsPpapOrderElement.refresh(this.dsPpapOrderElement.cursor);
    }

    @action.bound
    async toggleSubmit() {
        this.filterSubmit = !this.filterSubmit;
        this.setPpapOrderElementFilter();
        await this.dsPpapOrderElement.refresh(this.dsPpapOrderElement.cursor);
    }

    @action.bound
    async toggleActionFilter() {
        this.actionByElementFilter = !this.actionByElementFilter;
        this.dsAction.setMasterSource(this.dsPpapOrderElement, this.actionFilter);
        await this.dsAction.refresh(0);
    }

    @computed
    get actionFilter() {
        if (this.actionByElementFilter) {
            return [
                {
                    field: 'key1',
                    masterField: 'ppapno',
                },
                {
                    field: 'key2',
                    masterField: 'elementno',
                },
            ];
        } else {
            return [
                {
                    field: 'key1',
                    masterField: 'ppapno',
                },
            ];
        }
    }

    /**
     * Upload a file for ppaporderdocument "Uploads"
     * @param acceptFile
     */
    @action.bound
    async uploadUploadFile(acceptFile: any[]): Promise<void> {
        const url = '/gridApi/fileupload/ppaporderdocument/';
        if (acceptFile.length > 0) {
            let file: File = acceptFile[0];

            // hier sollen doch nur pdf hochgeladen werden
            /*if (acceptFile[0].type === 'image/jpeg') {
                file = await resizeImage(file);
            }*/
            const formData = new FormData();
            //formData.append('type', 'ppaporderdocument');
            formData.append('file', file);
            formData.append('ppapno', this.dsPpapOrderElement.actual.ppapno);
            formData.append('elementno', this.dsPpapOrderElement.actual.elementno.toString());
            const config = authorizer();
            config.headers['content-type'] = 'multipart/form-data';
            await axios.post(url, formData, config);
            runInAction(async () => {
                await this.dsPpapOrderDocument.refresh(this.dsPpapOrderDocument.cursor);
                this.dsPpapOrderDocument.last();
            });
        } else {
            await Promise.resolve();
        }
    }

    /**
     * Upload a file for ppapElementComment "Blog"
     * @param acceptFile
     */
    @action.bound
    async uploadBlogFile(acceptFile: any[]): Promise<void> {
        const {
            authStore: { username },
        } = this.rootStore;
        const url = '/gridApi/fileupload/ppaporderelementcomment/';
        let file: File = acceptFile[0];
        if (acceptFile[0].type === 'image/jpeg') {
            file = await resizeImage(file);
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('ppapno', this.dsPpapOrderElement.actual.ppapno);
        formData.append('elementno', this.dsPpapOrderElement.actual.elementno.toString());
        formData.append('user', username);
        formData.append('timestamp', moment().format());
        const config = authorizer();
        config.headers['content-type'] = 'multipart/form-data';
        await axios.post(url, formData, config);
        runInAction(async () => {
            await this.dsPpapElementComment.refresh(this.dsPpapElementComment.cursor);
            this.dsPpapElementComment.last();
        });
    }

    @action.bound
    async toggleElement(ppapno: string, elementno: number, doc: PPAP_DOC) {
        await axios.put('/gridApi/ppapelement/' + ppapno + '/' + elementno, { doc: doc }, authorizer());
        //await this.dsPpapOrderElement.refresh(this.dsPpapOrderElement.cursor);
    }

    statusElementOptionsFunc = () => {
        const {
            authStore: { username },
        } = this.rootStore;
        if (username === this.ds.actual?.owner) {
            return enumToArray(Status);
        } else if (username === this.dsPpapOrderElement.actual?.responsible) {
            return [Status.PLANNED, Status.INPROGRESS, Status.COMPLETED];
        } else {
            return [];
        }
    };
}
