import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../_base/base-view';
import { Col, Row } from 'reactstrap';
import { DbField } from '../../../components/dbform/db-field';
import { action, computed } from 'mobx';
import { UsergroupRight } from '../../../models/rights/enum-usergrouprights';
import { TableForm } from '../../_base/styled/table-form';
import { Logo } from '../../_base/logo/logo';
import { DetailOneColLayout } from '../../_base/styled/layout/detail-one-col-layout';
import { CardWithTab, ITab } from '../../_base/styled/card-with-tab';
import { DbGrid } from '../../../components/dbgrid/db-grid';

@inject('rootStore')
@observer
export class UserDetailView extends React.Component<IBaseView, {}> {
    @action.bound
    gotoUserGroup() {
        const {
            rootStore: {
                routerStore,
                userStore: { ds },
            },
        } = this.props;
        routerStore.goTo('usergroup', {
            usergroupno: ds.actual.usergroupno,
        });
    }

    @action.bound
    async gotoProject() {
        const {
            rootStore: {
                routerStore,
                userStore: { dsProject },
            },
        } = this.props;
        await routerStore.goTo('project', {
            projectno: dsProject.actual?.projectno,
        });
    }

    @action.bound
    async gotoSupplier() {
        const {
            rootStore: {
                routerStore,
                userStore: { ds },
            },
        } = this.props;
        await routerStore.goTo('supplier', {
            supplierno: ds.actual?.supplierno,
        });
    }

    @action.bound
    async gotoCustomer() {
        const {
            rootStore: {
                routerStore,
                userStore: { ds },
            },
        } = this.props;
        await routerStore.goTo('customer', {
            customerno: ds.actual?.customerno,
        });
    }

    @action.bound
    async gotoActivity() {
        const {
            rootStore: {
                routerStore,
                userStore: { dsActivity },
            },
        } = this.props;
        await routerStore.goTo(
            'project',
            {
                projectno: dsActivity.actual?.projectno,
            },
            { activityno: dsActivity.actual?.activityno },
        );
    }

    @action.bound
    async gotoAction() {
        const {
            rootStore: {
                routerStore,
                userStore: { dsAction },
            },
        } = this.props;
        await routerStore.goTo('action', {
            no: dsAction.actual?.no,
        });
    }

    getTabs = (): ITab[] => {
        const {
            rootStore: {
                userStore: { dsProject, dsActivity, dsAction },
            },
        } = this.props;
        return [
            {
                title: 'PROJECTS',
                icon: 'project-diagram',
                content: (
                    <div style={{ position: 'relative', height: '100%' }}>
                        <DbGrid ds={dsProject} onRowDoubleClicked={this.gotoProject} />
                    </div>
                ),
            },
            {
                title: 'PROJECTTASK_ACTIVITIES',
                icon: 'chart-network',
                content: (
                    <div style={{ position: 'relative', height: '100%' }}>
                        <DbGrid ds={dsActivity} onRowDoubleClicked={this.gotoActivity} />
                    </div>
                ),
            },
            {
                title: 'ACTIONS',
                icon: 'tasks',
                content: (
                    <div style={{ position: 'relative', height: '100%' }}>
                        <DbGrid ds={dsAction} onRowDoubleClicked={this.gotoAction} />
                    </div>
                ),
            },
        ];
    };

    @computed
    get keyTitle() {
        const {
            rootStore: {
                userStore: { ds },
            },
        } = this.props;
        return ds.actual?.username ?? '';
    }

    render() {
        const {
            rootStore: {
                userStore: { ds, pictureUrl, uploadPicture },
                authStore: { hasUserRight },
                langStore: { lang },
            },
        } = this.props;
        return (
            <DetailOneColLayout
                topElement={
                    <TableForm ds={ds} icon="user" title="USER" keyTitle={this.keyTitle}>
                        <Row>
                            <Col>
                                <DbField ds={ds} field="username" />
                                <DbField ds={ds} field="isactive" />
                                <DbField ds={ds} field="firstname" />
                                <DbField ds={ds} field="lastname" />
                                <DbField ds={ds} field="email" />
                                <DbField ds={ds} field="funktion" />
                                <Row>
                                    <Col>
                                        <DbField ds={ds} field={'telefon1'} />
                                    </Col>
                                    <Col>
                                        <DbField ds={ds} field={'telefon2'} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Logo
                                    label={lang['IMAGE']}
                                    src={pictureUrl}
                                    ds={ds}
                                    fieldName="picture"
                                    uploadFile={uploadPicture}
                                    lang={lang}
                                    width={136}
                                    height={136}
                                />
                                <DbField
                                    ds={ds}
                                    field="usergroupno"
                                    onClickLabel={
                                        hasUserRight(UsergroupRight.UR_ADMIN_USERGROUP) && ds.actual?.usergroupno ? this.gotoUserGroup : null
                                    }
                                />
                                <DbField
                                    ds={ds}
                                    field={'supplierno'}
                                    onClickLabel={hasUserRight(UsergroupRight.UR_BASE_SUPPLIER) && ds.actual?.usergroupno ? this.gotoSupplier : null}
                                />
                                <DbField
                                    ds={ds}
                                    field={'customerno'}
                                    onClickLabel={hasUserRight(UsergroupRight.UR_BASE_CUSTOMER) && ds.actual?.usergroupno ? this.gotoCustomer : null}
                                />
                                <DbField ds={ds} field="language" />
                            </Col>
                        </Row>
                    </TableForm>
                }
                mainElement={<CardWithTab tabs={this.getTabs()} />}
            />
        );
    }
}
