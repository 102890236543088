import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { cl_blue } from '../../../_theme/colors';
import styled from 'styled-components';
import { AuditScore } from '../../../../models/AuditQuestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auditScoreColors, auditScoreText } from '../../../../stores/audit-online-store';
import { IReportImage, ReportImages } from '../../../_shared/report/report-Images';

const Block = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    color: black;
    border: solid 3px ${cl_blue};
    border-radius: 0.5rem;
    padding-top: 1rem;
    padding-left: 0;
    padding-right: 0;
`;

const Headline = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
`;

const HeadLeft = styled.div`
    flex: 0 0 85%;
    background-color: ${cl_blue};
    color: white;
    border-radius: 0 0.5rem 0.5rem 0;
    padding-left: 0.5rem;
`;

const _HeadRight = ({ children, className }: any) => <div className={className}>{children}</div>;

const HeadRight = styled(_HeadRight)`
    display: block;
    flex: 1 1 auto;
    background-color: ${(props) => props.bg};
    color: white;
    border-radius: 0.5rem 0 0 0.5rem;
    text-align: right;
    margin-left: 1rem;
    padding-right: 0.5rem;
`;

@inject('rootStore')
@observer
export class AuditReportPage3 extends React.Component<IBaseView, {}> {
    calcReportImages = (questionno?: string): IReportImage[] => {
        const {
            rootStore: {
                auditStore: { dsReportImages },
            },
        } = this.props;

        if (questionno) {
            return dsReportImages.data
                .filter((image) => image.questionno === questionno)
                .map((image) => {
                    return {
                        src: '/gridApi/image/auditquestionimage/' + image.auditno + '/' + image.questionno + '/' + image.image,
                        title: image.image,
                    };
                });
        } else {
            return [];
        }
    };

    render() {
        const {
            rootStore: {
                auditStore: { ds, dsAuditQuestion },
            },
        } = this.props;
        return (
            <div>
                {dsAuditQuestion.data
                    .filter((question) => question.score !== AuditScore.UNRATED)
                    .map((question, i) => {
                        return (
                            <Row style={{ paddingTop: '0.5rem', pageBreakBefore: 'auto', pageBreakInside: 'avoid' }} key={i}>
                                <Col>
                                    <Block>
                                        <h5 style={{ paddingLeft: '0.5rem', fontWeight: 300 }}>
                                            {question.area}
                                            {question.element ? (
                                                <>
                                                    &nbsp;
                                                    <span style={{ color: cl_blue, fontWeight: 600 }}>&gt;</span>
                                                    &nbsp;
                                                    {question.element}
                                                </>
                                            ) : null}
                                        </h5>
                                        <Headline>
                                            <HeadLeft>
                                                <h5 style={{ marginTop: 8, fontWeight: 500 }}>{question.question}</h5>
                                            </HeadLeft>
                                            <HeadRight bg={auditScoreColors(ds.actual.ratingmethod, question.score).bg}>
                                                <h5 style={{ marginTop: 8 }}>
                                                    <FontAwesomeIcon icon={['fas', 'star']} />
                                                    &nbsp;
                                                    {auditScoreText(ds.actual.ratingmethod, question.score)}
                                                    {/*{question.score === AuditScore.NOT_APPLICABLE ? 'n.a.' : question.score}*/}
                                                </h5>
                                            </HeadRight>
                                        </Headline>
                                        <h5
                                            style={{
                                                paddingLeft: '0.5rem',
                                                paddingRight: '0.5rem',
                                                fontWeight: 300,
                                            }}
                                        >
                                            {question.answer}
                                        </h5>
                                        <ReportImages images={this.calcReportImages(question.questionno)} />
                                    </Block>
                                </Col>
                            </Row>
                        );
                    })}
            </div>
        );
    }
}
