import { IField } from '../components/dataset/IField';
import { ProjectActivityLinkRenderer } from './cell-renderers/ProjectActivityLinkRenderer';
import { enumToArray } from '../components/lib/enumToArray';
import { dateCellRenderer } from '../components/dataset/IDataset';
import { UsergroupRight } from './rights/enum-usergrouprights';
import { DuedateRenderer } from './_helper/duedate-renderer';
import { Status, statusText } from './Status';
import { StatusRenderer } from './cell-renderers/StatusRenderer';
import { ProjectActivityTyp, projectActivityTypText } from './ProjectActivity';
import { customerColumns } from './Customer';
import { supplierColumns } from './Supplier';
import { catalogDetailColumns } from './CatalogDetail';
import { userColumns } from './User';
import { projectColumns } from './Project';
import * as R from 'ramda';
import { includes } from '../components/lib/Includes';

/**
 *  Activity eigentlich Projectactivity, aber mit zusätzlichen Feldern angereichert als übersicht gedacht.
 */
export interface IActivity {
    projectno: string;
    project: string;
    projectgroup: string;
    customerno: string;
    customer1: string;
    supplierno: string;
    supplier1: string;
    activityno: string;
    description1: string;
    activitytyp: ProjectActivityTyp;
    link: string;
    phase: string;
    responsible: string;
    duedate: string;
    status: number;
    comment: string;
    createdby: string;
    createdat: string;
}

export const activityColumns: IField<IActivity>[] = [
    {
        fieldName: 'link',
        dataType: 'string',
        label: '',
        grid: {
            cellRenderer: ProjectActivityLinkRenderer,
            width: 40,
        },
        readOnly: true,
    },
    {
        fieldName: 'activitytyp',
        dataType: 'number',
        label: 'PROJECTTASK_TYP',
        rules: ['integer'],
        input: 'select',
        options: enumToArray(ProjectActivityTyp),
        textFunc: projectActivityTypText,
        defaultValue: ProjectActivityTyp.ACTIVITY,
        //insert: 'show',
        hide: 'table',
        readOnly: true,
        //useAsFilter: true,
        grid: {
            width: 120,
        },
    },
    {
        fieldName: 'description1',
        label: 'PROJECTTASK_DESCRIPTION1',
        dataType: 'string',
        insert: 'show',
        grid: {
            flex: 1,
        },
    },
    {
        fieldName: 'projectno',
        label: 'PROJECT_PROJECTNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        insert: 'hide',
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/project/',
            columns: projectColumns,
            field: 'projectno',
        },
        grid: {
            width: 100,
        },
    },
    {
        fieldName: 'project',
        dataType: 'string',
        label: 'PROJECT_PROJECT',
    },
    {
        fieldName: 'projectgroup',
        dataType: 'string',
        label: 'PROJECT_PROJECTGROUP',
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/catalogdetail/?catalogno=10003',
            columns: catalogDetailColumns,
            field: 'description1',
        },
    },
    {
        fieldName: 'customerno',
        label: 'CUSTOMER_CUSTOMERNO',
        dataType: 'string',
        insert: 'show',
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/customer/',
            columns: customerColumns,
            InserRight: UsergroupRight.UR_BASE_CUSTOMER_INSERT,
        },
        hide: 'table',
    },
    {
        fieldName: 'customer1',
        dataType: 'string',
        label: 'CUSTOMER_CUSTOMER1',
        //show: 'table',
        readOnly: true,
    },
    {
        fieldName: 'supplierno',
        label: 'SUPPLIER_SUPPLIERNO',
        dataType: 'string',
        insert: 'show',
        input: 'selectdlg',
        hide: 'table',
        useAsFilter: true,
        selectdlg: {
            url: '/gridApi/supplier/',
            columns: supplierColumns,
            InserRight: UsergroupRight.UR_BASE_SUPPLIER_INSERT,
        },
    },
    {
        fieldName: 'supplier1',
        dataType: 'string',
        label: 'SUPPLIER_SUPPLIER1',
        readOnly: true,
    },
    {
        fieldName: 'activityno',
        label: 'PROJECTTASK_TASKNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        hide: 'table',
        grid: {
            width: 60,
        },
    },
    {
        fieldName: 'phase',
        label: 'PROJECTTASK_PHASE',
        dataType: 'string',
        maxLength: 80,
        hide: 'always',
        grid: {
            width: 60,
        },
    },

    {
        fieldName: 'createdby',
        dataType: 'string',
        label: 'CREATED_BY',
        readOnly: true,
        hide: 'table',
    },
    {
        fieldName: 'createdat',
        dataType: 'string',
        label: 'CREATED_DAT',
        input: 'calendar',
        grid: {
            cellRenderer: dateCellRenderer,
        },
        readOnly: true,
        hide: 'table',
    },
    {
        fieldName: 'responsible',
        label: 'PROJECTTASK_RESPONSIBLE',
        dataType: 'string',
        insert: 'show',
        input: 'selectdlg',
        rules: ['mandatory'],
        selectdlg: {
            url: '/gridApi/user/',
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT,
        },
        useAsFilter: true,
        grid: {
            width: 150,
        },
    },
    {
        fieldName: 'status',
        label: 'PPAP_STATUS',
        dataType: 'number',
        input: 'catalog',
        options: enumToArray(Status),
        textFunc: statusText,
        defaultValue: 100,
        useAsFilter: true,
        grid: {
            cellRenderer: StatusRenderer,
            width: 100,
        },
    },

    {
        fieldName: 'duedate',
        label: 'PROJECTTASK_DUEDATE',
        dataType: 'string',
        insert: 'show',
        rules: ['mandatory'],
        grid: {
            cellRenderer: DuedateRenderer,
            //cellStyle: dateCellStyle,
            width: 100,
        },
        input: 'calendar',
    },

    {
        fieldName: 'comment',
        dataType: 'string',
        label: 'PROJECT_COMMENT',
        hide: 'table',
        input: 'textarea',
    },
];

export const mobileActivityColumns: IField<IActivity>[] = R.clone(activityColumns).map((field) => {
    if (field.fieldName === 'projectno') {
        field.useAsFilter = true;
        field.primaryKey = false;
    }
    if (includes(['supplierno', 'responsible', 'status'], field.fieldName)) {
        field.useAsFilter = false;
    }
    return field;
});
