import styled from 'styled-components';

export const StyledTable = styled.table`
    width: ${(props) => props.width || '50%'};
    border: 1px solid ${(props) => props.theme.color};
    background-color: white;
    font-size: 12px;

    thead {
        background-color: ${(props) => props.theme.color};
        color: ${(props) => props.theme.bg};
        font-weight: bold;
    }

    tbody {
        td {
            border: 1px solid ${(props) => props.theme.color};
        }
    }
`;
