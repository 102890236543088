// returns true if s exists in string array x
export const includes = (x: string[], s: string): boolean => {
    let rx: string[] = x.filter((xv: string) => xv === s);
    return rx.length > 0;
};

// returns true if s exists in number array x
export const includesN = (x: number[], s: number): boolean => {
    let rx: number[] = x.filter((xv: number) => xv === s);
    return rx.length > 0;
};

// returns true if s NOT exists in stringarray x
export const excludes = (x: string[], s: string): boolean => {
    let rx: string[] = x.filter((xv: string) => xv !== s);
    return rx.length > 0;
};
