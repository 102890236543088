import { inject, observer } from 'mobx-react';
import { Col, FormFeedback, FormGroup, Input } from 'reactstrap';
import { action } from 'mobx';
import * as React from 'react';
import { IFieldProps } from '../i-db-field-props';
import { FieldLabel } from '../field-label';
import { ILangKeys } from '../../../language/ILang';

@inject('rootStore')
@observer
export class FieldSelect extends React.Component<IFieldProps<any>, {}> {
    renderInput = () => {
        const {
            rootStore: {
                langStore: { lang },
            },
            column,
            actual,
            error,
            readOnly,
        } = this.props;

        return (
            <>
                <Input
                    type="select"
                    autoComplete="off"
                    id={column.fieldName as string}
                    name={column.fieldName as string}
                    disabled={readOnly}
                    value={actual ? actual[column.fieldName] : ''}
                    onChange={action((e: any) => {
                        actual[column.fieldName] = Number(e.target.value);
                    })}
                    bsSize="sm"
                >
                    {column.options.map((option: number) => (
                        <option key={option} value={option}>
                            {lang[column.textFunc(option)]}
                        </option>
                    ))}
                </Input>
                {error ? <FormFeedback>{error}</FormFeedback> : null}
            </>
        );
    };

    render() {
        let {
            rootStore: {
                langStore: { lang },
            },
            column,
            row,
        } = this.props;
        const label: string = lang[column.label as ILangKeys] ? lang[column.label as ILangKeys] : (column.fieldName as string);
        //console.log(label);
        return (
            <FormGroup row={row}>
                <FieldLabel row={row} label={label} bold={column.primaryKey} />
                {row ? <Col sm={9}> {this.renderInput()}</Col> : this.renderInput()}
            </FormGroup>
        );
    }
}
