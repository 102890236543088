import * as React from 'react';
import { IBaseView } from '../_base/base-view';
import { inject, observer } from 'mobx-react';
import { TableGrid } from '../_base/styled/table-grid';
import { TableLayout } from '../_base/styled/layout/table-layout';
import { action } from 'mobx';
import { ProjectActivityTyp } from '../../models/ProjectActivity';
import { UsergroupRight } from '../../models/rights/enum-usergrouprights';
import { BaseTableFilterMenu } from '../_base/table-filter/base-table-filter-menu';
import { IconButton } from '../_base/styled/icon-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Status } from '../../models/Status';

@inject('rootStore')
@observer
export class ActivityTableView extends React.Component<IBaseView, {}> {
    @action.bound
    gotoActivity() {
        const {
            rootStore: {
                routerStore,
                activityStore: { ds },
            },
        } = this.props;
        switch (ds.actual?.activitytyp) {
            case ProjectActivityTyp.ACTIVITY:
                routerStore.goTo('projectactivity', {
                    projectno: ds.actual.projectno,
                    activityno: ds.actual.activityno,
                });
                break;
            case ProjectActivityTyp.AUDIT:
                routerStore.goTo('audit', {
                    auditno: ds.actual.link,
                });
                break;
            case ProjectActivityTyp.INSPECTION:
                routerStore.goTo('inspection', {
                    inspectionno: ds.actual.link,
                });
                break;
            case ProjectActivityTyp.PPAP:
                routerStore.goTo('ppaporder', {
                    ppapno: ds.actual.link,
                });
                break;
            case ProjectActivityTyp.GEMBA_WALK:
                routerStore.goTo('gemba', {
                    gembano: ds.actual.link,
                });
                break;
        }
    }

    @action.bound
    async gotoOffline() {
        const {
            rootStore: {
                routerStore,
                activityStore: { ds },
            },
        } = this.props;
        switch (ds.actual?.activitytyp) {
            case ProjectActivityTyp.AUDIT:
                await routerStore.goTo('auditcollectoffline', {
                    auditno: ds.actual.link,
                });
                break;
        }
    }

    @action.bound
    async gotoOnline() {
        const {
            rootStore: {
                routerStore,
                activityStore: { ds },
            },
        } = this.props;
        switch (ds.actual?.activitytyp) {
            case ProjectActivityTyp.AUDIT:
                await routerStore.goTo('auditcollectonline', {
                    auditno: ds.actual.link,
                });
                break;
            case ProjectActivityTyp.GEMBA_WALK:
                await routerStore.goTo('gembacollectonline', {
                    gembano: ds.actual.link,
                });
                break;
        }
    }

    render() {
        const {
            rootStore: {
                activityStore: { ds },
                authStore: { username, hasUserRight, hasModuleRight },
            },
        } = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="activity"
                    icon="chart-network"
                    ds={ds}
                    title="OFFLINE_ACTIVITIES"
                    onRowDoubleClicked={this.gotoActivity}
                    rightHeaderMenu={
                        <>
                            {(ds.actual?.activitytyp === ProjectActivityTyp.AUDIT || ds.actual?.activitytyp === ProjectActivityTyp.GEMBA_WALK) &&
                            username === ds.actual?.responsible &&
                            ds.actual?.status !== Status.APPROVED ? (
                                <>
                                    <IconButton onClick={this.gotoOnline}>
                                        <FontAwesomeIcon icon="user-md-chat" />
                                    </IconButton>
                                    {hasModuleRight('OFFLINE') ? (
                                        <IconButton onClick={this.gotoOffline}>
                                            <FontAwesomeIcon icon="wifi-slash" />
                                        </IconButton>
                                    ) : null}
                                </>
                            ) : null}
                            {hasUserRight(UsergroupRight.UR_FILTER_ALL) ? (
                                <BaseTableFilterMenu storeName="activityStore" filtername="activity" />
                            ) : null}
                        </>
                    }
                    hideInsert={true}
                    hideDelete={true}
                />
            </TableLayout>
        );
    }
}
