import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { TableGrid } from '../../_base/styled/table-grid';
import { TableLayout } from '../../_base/styled/layout/table-layout';

@inject('rootStore')
@observer
export class CatalogTableView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                catalogStore: { ds },
            },
        } = this.props;
        return (
            <TableLayout>
                <TableGrid id="catalog" icon="database" title="CATALOGS" ds={ds} singleRouteName="catalog" />
            </TableLayout>
        );
    }
}
