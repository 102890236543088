import Resizer from 'react-image-file-resizer';

export const resizeImage = async (file: File): Promise<File> =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            640,
            640,
            'JPEG',
            100,
            0,
            (file) => {
                resolve(file as any);
            },
            'file',
        );
    });
