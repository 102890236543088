import * as React from 'react';
import { IRootStore } from '../../../routes/root-store';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoundGridBtn } from '../styled/round-header-btn';

interface IMenuAutofit {
    rootStore?: IRootStore;
    id: string;
    autoFit: () => void;
    disabled?: boolean;
}

@inject('rootStore')
@observer
export class MenuAutofit extends React.Component<IMenuAutofit, {}> {
    onClick = () => {
        const { autoFit } = this.props;
        if (autoFit) {
            autoFit();
        }
    };

    render() {
        const { id } = this.props;
        return (
            <>
                <RoundGridBtn id={id + 'btn-autofit'} onClick={this.onClick}>
                    <FontAwesomeIcon icon="arrows-alt-h" />
                </RoundGridBtn>
            </>
        );
    }
}
