import { IBaseView } from '../base-view';
import { ILangKeys } from '../../../language/ILang';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { IconName } from '@fortawesome/fontawesome-common-types';

interface ITableCard extends IBaseView {
    title: ILangKeys;
    icon: IconName;
}

@inject('rootStore')
@observer
class _TableCard extends React.Component<ITableCard, {}> {
    render() {
        const {
            rootStore: {
                langStore: { lang },
            },
            className,
            title,
            icon,
            children,
        } = this.props;
        return (
            <div className={className}>
                <div className="t-header">
                    <div className="left">
                        <FontAwesomeIcon icon={['fad', icon]} /> &nbsp;&nbsp;&nbsp;
                        {lang[title]}
                        &nbsp;
                    </div>
                    <div className="right"></div>
                </div>
                {/*<div className="menu">
                </div>*/}
                {/*<div className="t-menu">

                    <IconButton >
                        <FontAwesomeIcon icon={["fad", icon]}/> &nbsp;&nbsp;&nbsp;
                    </IconButton>
                </div>*/}
                <div className="t-body">{children}</div>
            </div>
        );
    }
}

export const TableCard = styled(_TableCard)`
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0;
    height: 100%;
    width: 100%;
    color: ${(props) => props.theme.color};
    background-color: ${(props) => props.theme.bg};
    border-radius: ${(props) => props.theme.borderRadius};
    border: ${(props) => props.theme.border};
    box-shadow: ${(props) => props.theme.boxShadow};
    overflow: hidden;

    .t-header {
        flex: 0 0 auto;
        justify-content: space-between;
        align-items: center;
        border-radius: 0;
        display: flex;
        padding: ${(props) => props.theme.box.header.padding};
        font-size: 1.125rem;
        font-weight: ${(props) => props.theme.box.header.fontWeight};
        background-color: ${(props) => props.theme.bg};
        color: ${(props) => props.theme.color};
        border-bottom: ${(props) => props.theme.border};

        .right {
            font-weight: 100;
        }
    }

    .t-menu {
        flex: 0 0 1rem;
        border-bottom: ${(props) => props.theme.border};
        padding: 0.125rem 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .menu {
        display: flex;
        flex-direction: row;
        flex: ${(props) => props.theme.box.menu.flex};
        border-bottom: ${(props) => props.theme.border};
        padding: ${(props) => props.theme.box.menu.padding};
        justify-content: ${(props) => props.theme.box.menu.justifyContent};
    }

    .t-body {
        flex: 1 1 auto;
        padding: 0;
        overflow-y: auto;

        /*.ag-root-wrapper {
      border: 0;
    }*/
    }
`;
