import * as React from 'react';
import { IBaseView } from '../../base-view';
import ReactToPrint from 'react-to-print';
import { IconButton } from '../icon-button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'reactstrap';

const pageStyle = `
  @page {
   size: auto;
   margin: 20mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

class _PrintContainer extends React.Component<IBaseView, {}> {
    componentRef: any;

    render() {
        const { children, className } = this.props;
        return (
            <div className={className}>
                <div className="printheader">
                    <div className="cardheader">
                        <div className={'left'} />
                        <div className="right">
                            <ReactToPrint
                                trigger={() => (
                                    <IconButton>
                                        <FontAwesomeIcon icon={['fad', 'print']} />
                                    </IconButton>
                                )}
                                content={() => this.componentRef}
                                pageStyle={pageStyle}
                            />
                        </div>
                    </div>
                </div>
                <div className="printcontent" ref={(el) => (this.componentRef = el)}>
                    <Container style={{ backgroundColor: 'white' }}>{children}</Container>
                </div>
            </div>
        );
    }
}

export const PrintContainer = styled(_PrintContainer)`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    //
    display: flex;
    flex-direction: column;
    //
    color: black;
    background-color: ${(props) => props.theme.bgBody};
    overflow-x: hidden;
    overflow-y: auto;
    padding: ${(props) => props.theme.gutter};
    box-shadow: ${(props) => props.theme.boxShadowInset};

    .printheader {
        display: block;
        flex: 0 0 auto;

        .cardheader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: ${(props) => props.theme.color};
            border: ${(props) => props.theme.border};
            border-radius: ${(props) => props.theme.borderRadius};
            //
            padding: ${(props) => props.theme.box.header.padding};
            font-size: ${(props) => props.theme.box.header.fontSize};
            font-weight: ${(props) => props.theme.box.header.fontWeight};
            background-color: ${(props) => props.theme.box.header.backgroundColor};

            .keyTitle {
                font-weight: 500;
            }

            .right {
                font-weight: 100;
                color: black;
            }
        }
    }

    .printcontent {
        margin-top: 1rem;
        flex: 1 1 auto;
        display: block;
        position: relative;
        overflow-y: auto;
    }
`;
