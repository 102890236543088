import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '../../../../components/dialog/Dialog';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { IconButton } from '../../../_base/styled/icon-button';
import { IAuditQuestion } from '../../../../models/AuditQuestion';
import { IField } from '../../../../components/dataset/IField';
import { Field } from '../../../../components/dbform/field/field';
import { UncontrolledTooltip } from 'reactstrap';

interface IAuditCollectCollectInfo extends IBaseView {
    question: IAuditQuestion;
    columns: IField<any>[];
}

@inject('rootStore')
@observer
class _AuditCollectCollectInfo extends React.Component<IAuditCollectCollectInfo, {}> {
    @observable
    isOpen: boolean = false;

    @action.bound
    toggleOpen(e: any) {
        this.isOpen = !this.isOpen;
        e.preventDefault();
    }

    render() {
        const {
            question,
            columns,
            className,
            rootStore: {
                langStore: { lang },
            },
        } = this.props;

        return (
            <div className={className}>
                <IconButton id="information" onClick={this.toggleOpen} color="primary">
                    <FontAwesomeIcon icon={['fad', 'info']} />
                </IconButton>
                <UncontrolledTooltip placement="right" target="information">
                    {lang.INFO}
                </UncontrolledTooltip>
                <Dialog isOpen={this.isOpen} title={''} onOk={this.toggleOpen} onCancel={this.toggleOpen}>
                    <Field actual={question} column={columns.find((column) => column.fieldName === 'requirements')} readOnly={true} />
                    <Field actual={question} column={columns.find((column) => column.fieldName === 'reference')} readOnly={true} />
                </Dialog>
            </div>
        );
    }
}

export const AuditCollectCollectInfo = styled(_AuditCollectCollectInfo)`
    display: inline-block;
    margin-top: -1.5rem;

    .btn {
        border-radius: 2rem;
        width: 2rem;
        height: 2rem;
        padding-top: 2px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    }
`;
