import styled from 'styled-components';
import { cl_gray300 } from '../../../_theme/colors';
import { dsState, IDataset } from '../../../../components/dataset/IDataset';
import { action } from 'mobx';
import { observer } from 'mobx-react';

interface IFormField {
    className?: string;
    ds: IDataset<any>;
    fieldName: string;
    maxLength?: number;
    readonly: boolean;
}

const _FormField = observer(({ className, ds, fieldName, maxLength, readonly }: IFormField) => {
    return (
        <input
            className={className}
            name={fieldName}
            autoComplete="new-password"
            value={ds.actual ? ds.actual[fieldName] ?? '' : ''}
            maxLength={maxLength}
            readOnly={readonly}
            onChange={action((e: any) => {
                if (ds.state === dsState.dsEdit) {
                    ds.actual[fieldName] = e.target.value;
                }
            })}
        />
    );
});

const _AreaField = observer(({ className, ds, fieldName, maxLength, readonly }: IFormField) => {
    console.log(ds, fieldName);
    return (
        <textarea
            className={className}
            name={fieldName}
            rows={10}
            autoComplete="new-password"
            value={ds.actual ? ds.actual[fieldName] ?? '' : ''}
            maxLength={maxLength}
            readOnly={readonly}
            onKeyPress={
                // Enter soll die Form nicht submitten sondern neue Zeile erzeugen.
                (e) => {
                    if (e.key === 'Enter') {
                        e.stopPropagation();
                    }
                }
            }
            onChange={action((e: any) => {
                console.log(ds);
                if (ds.state === dsState.dsEdit) {
                    ds.actual[fieldName] = e.target.value;
                }
            })}
        />
    );
});

export const FormField = styled(_FormField)`
  width: 100%;
  border: 0;
  font-size: 12px;
  border-bottom: 1px solid black;
  background-color: ${cl_gray300};

  :focus {
    outline: ${(props) => (props.readonly ? 0 : '1px solid black')};
`;

export const TableField = styled(_FormField)`
  width: 100%;
  border: 0;
  font-size: 12px;
  //border-bottom: 1px solid black;
  background-color: ${cl_gray300};

  :focus {
    outline: ${(props) => (props.readonly ? 0 : '1px solid black')};
`;

export const AreaField = styled(_AreaField)`
  width: 100%;
  border: 0;
  font-size: 12px;
  //border-bottom: 1px solid black;
  background-color: ${cl_gray300};

  :focus {
    outline: ${(props) => (props.readonly ? 0 : '1px solid black')};
`;
