import styled from 'styled-components';
import { cl_gray500 } from '../../_theme/colors';

export const Sticker = styled.div`
    background-color: ${(props) => props.color || cl_gray500};
    color: white;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: initial;
    border-radius: 8px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
