import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { UsergroupRight } from '../../../models/rights/enum-usergrouprights';
import { TableGrid } from '../../_base/styled/table-grid';
import { TableLayout } from '../../_base/styled/layout/table-layout';

@inject('rootStore')
@observer
export class CustomerTableView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                customerStore: { ds },
                authStore: { hasUserRight },
            },
        } = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="customers"
                    icon="database"
                    title="CUSTOMERS"
                    ds={ds}
                    singleRouteName="customer"
                    hideInsert={!hasUserRight(UsergroupRight.UR_BASE_CUSTOMER_INSERT)}
                    hideDelete={!hasUserRight(UsergroupRight.UR_BASE_CUSTOMER_DELETE)}
                />
            </TableLayout>
        );
    }
}
