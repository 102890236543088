import { AuditScore, IAuditQuestion } from '../models/AuditQuestion';

export const calcVDA61Score = (question: IAuditQuestion): number => {
    const notApplicable = question.notapplicable;
    const qm = question.qm;
    const praxis = question.praxis;
    //console.log(notApplicable, qm, praxis);
    if (notApplicable) {
        return AuditScore.NOT_APPLICABLE;
    } else if (qm === 2 && praxis === 2) {
        return AuditScore.FULL_COMPLIANCE;
    } else if (qm === 0 && praxis === 2) {
        return AuditScore.PREDOMINANT_COMPLIANCE;
    } else if (qm === 2 && praxis === 1) {
        return AuditScore.PARTIAL_COMPLIANCE;
    } else if (qm === 0 && praxis === 1) {
        return AuditScore.UNSATISFACTORY_COMPLIANCE;
    } else if (qm === 0 && praxis === 0) {
        return AuditScore.NO_COMPLIANCE;
    } else if (qm === 2 && praxis === 0) {
        return AuditScore.NO_COMPLIANCE;
    } else return AuditScore.UNRATED;
};
