import { PpapReportProductQualityPlan } from './pages/01-product-quality-plan/ppap-report-product-quality-plan';
import * as React from 'react';
import { PpapReportBasepage } from './ppap-report-basepage';
import { IPpapOrderElement } from '../../../models/PpapOrderElement';
import { PpapUploadFileTab } from '../order/right/ppap-upload-tab/ppap-upload-file-tab';
import { uploadReportConfiguration } from '../order/right/ppap-upload-tab/upload-report-configuration';
import { IDataset } from '../../../components/dataset/IDataset';
import { IPpapOrderDocument } from '../../../models/PpapDocument';

interface IPpapReportPage {
    element: IPpapOrderElement;
    dsDocument: IDataset<IPpapOrderDocument>;
}

export const SheetSpecificInformation = (props: IPpapReportPage) => {
    const { element, dsDocument } = props;
    switch (element?.elementno) {
        case 1:
            return <PpapReportProductQualityPlan />;
        default:
            return (
                <PpapUploadFileTab element={element} dsDocument={dsDocument} {...uploadReportConfiguration(element.elementno)} hideCursor={true} />
            );
    }
};

export const PpapReportPage = (props: IPpapReportPage) => {
    const { element, dsDocument } = props;
    switch (element.elementno) {
        case 1:
            return <PpapReportProductQualityPlan />;
        default:
            return (
                <PpapReportBasepage element={element}>
                    <SheetSpecificInformation element={element} dsDocument={dsDocument} />
                </PpapReportBasepage>
            );
    }
};
