import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import { MobileGembaListCard } from '../gemba/card/mobile-gemba-list-card';
import { cl_red } from '../../_theme/colors';
import { TableLayout } from '../../_base/styled/layout/table-layout';
import { action } from 'mobx';

@inject('rootStore')
@observer
class _MobileActivityView extends React.Component<IBaseView> {
    @action.bound
    onClick() {}

    render() {
        const {
            rootStore: {
                mobileStore: { ds },
            },
            className,
        } = this.props;
        return (
            <TableLayout>
                <div className={className}>
                    <Container fluid style={{ padding: 0 }}>
                        {/*<Row style={{justifyContent: 'flex-end', marginBottom: '1rem'}}>
                            <Col xs={2} style={{maxWidth: '6rem'}}>
                                <BaseTableFilterMenu storeName="mobileStore" filtername="mobile"/>
                            </Col>
                        </Row>*/}
                        <Row style={{ alignContent: 'stretch', alignItems: 'stretch' }}>
                            {ds.data.map((activity, index) => (
                                <Col key={index} lg={3} md={4} sm={12} style={{ paddingBottom: '1rem' }}>
                                    <MobileGembaListCard activity={activity} />
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
            </TableLayout>
        );
    }
}

export const MobileActivityListView = styled(_MobileActivityView)`
    display: block;
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    .card-mobile {
        border: ${(props) => props.theme.border};
        height: 100%;
        border-radius: 0.5rem;
        cursor: pointer;
        box-shadow: ${(props) => props.theme.boxShadow};

        .header {
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
            align-items: center;
            background-color: white;
            justify-content: space-between;
            padding-right: 0.75rem;
            font-weight: bold;

            .left {
                display: flex;
                align-items: center;
            }
        }

        .body {
            color: black;
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            display: flex;

            .left {
                flex: 1 1 auto;

                .responsible {
                    display: flex;
                    align-items: center;
                    font-size: 0.75rem;
                }
            }

            .right {
                flex: 0 0 auto;
                display: flex;
                align-items: flex-end;

                .findings {
                    font-size: 2rem;
                    font-weight: 700;
                    color: ${cl_red};
                }
            }
        }
    }

    .neu {
        position: fixed;
        bottom: 0.5rem;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;

        button {
            background-color: ${cl_red};
            color: white;
            font-weight: 500;
            border: 0;
            border-radius: 1rem;
            padding: 0.25rem 2rem;
        }
    }
`;
