import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { MenuDelete } from '../../../../_base/menu/menu-delete';
import { IBaseView } from '../../../../_base/base-view';
import { dsState } from '../../../../../components/dataset/IDataset';
import { IconButton } from '../../../../_base/styled/icon-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';
import { Status } from '../../../../../models/Status';
import { PpapElementStateBtn } from '../ppap-element-state-btn';

@inject('rootStore')
@observer
export class PpapUploadMenu extends React.Component<IBaseView, {}> {
    stateButtonVisible = (): boolean => {
        const {
            rootStore: {
                ppapStore: { dsPpapOrderElement, ds },

                authStore: { username },
            },
        } = this.props;

        return (
            username === ds.actual?.owner ||
            (username === dsPpapOrderElement.actual?.responsible &&
                dsPpapOrderElement.actual?.status !== Status.APPROVED &&
                dsPpapOrderElement.actual?.status !== Status.COND_APPROVED)
        );
    };

    editMenuVisible = (): boolean => {
        const {
            rootStore: {
                ppapStore: { dsPpapOrderElement },
                authStore: { username },
            },
        } = this.props;
        return (
            username === dsPpapOrderElement.actual?.responsible &&
            dsPpapOrderElement.actual?.status !== Status.COMPLETED &&
            dsPpapOrderElement.actual?.status !== Status.APPROVED &&
            dsPpapOrderElement.actual?.status !== Status.COND_APPROVED
        );
    };

    render() {
        const {
            rootStore: {
                ppapStore: { dsPpapOrderDocument },
                langStore: { lang },
            },
        } = this.props;
        return (
            <>
                {dsPpapOrderDocument.state === dsState.dsBrowse ? (
                    <>
                        {this.stateButtonVisible() ? <PpapElementStateBtn /> : null}
                        {this.editMenuVisible() ? (
                            dsPpapOrderDocument.actual ? (
                                <>
                                    <IconButton
                                        id="edit"
                                        onClick={() => {
                                            dsPpapOrderDocument.edit();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={['fal', 'pen']} />
                                    </IconButton>
                                    <UncontrolledTooltip placement="bottom-end" target="edit">
                                        {lang.EDIT}
                                    </UncontrolledTooltip>
                                    <MenuDelete
                                        id={'PPPAP_ELEMENT_btn-delete'}
                                        ds={dsPpapOrderDocument}
                                        disabled={dsPpapOrderDocument.cursor === undefined}
                                    />
                                </>
                            ) : null
                        ) : null}
                    </>
                ) : dsPpapOrderDocument.state === dsState.dsEdit ? (
                    <>
                        <IconButton
                            id="save"
                            onClick={async () => {
                                await dsPpapOrderDocument.post();
                            }}
                        >
                            <FontAwesomeIcon icon={['fal', 'check']} />
                        </IconButton>
                        <UncontrolledTooltip placement="bottom-end" target="save">
                            {lang.SAVE}
                        </UncontrolledTooltip>
                        <IconButton
                            id="cancel"
                            onClick={async () => {
                                await dsPpapOrderDocument.cancelAsync();
                                // wg UweWindt/squid#508
                                await dsPpapOrderDocument.refresh(dsPpapOrderDocument.cursor);
                            }}
                        >
                            <FontAwesomeIcon icon={['fal', 'times']} />
                        </IconButton>
                        <UncontrolledTooltip placement="bottom-end" target="cancel">
                            {lang.CANCEL}
                        </UncontrolledTooltip>
                    </>
                ) : null}
            </>
        );
    }
}
