import styled from 'styled-components';

export const TableLayout = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    color: black;
    background-color: ${(props) => props.theme.bgBody};
    overflow-x: hidden;
    overflow-y: auto;
    padding: ${(props) => props.theme.gutter};
    box-shadow: ${(props) => props.theme.boxShadowInset};
`;
