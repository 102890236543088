import { inject, observer } from 'mobx-react';
import { Col, FormFeedback, FormGroup, Input } from 'reactstrap';
import { action } from 'mobx';
import * as React from 'react';
import { IFieldProps } from '../i-db-field-props';
import { FieldLabel } from '../field-label';

@inject('rootStore')
@observer
export class FieldTextarea extends React.Component<IFieldProps<any>, {}> {
    @action.bound
    onChange(e: any) {
        let { column, actual } = this.props;
        if (e.target.value === '' && column.dataType === 'number') {
            actual[e.target.name] = null;
        } else {
            //console.log(e.target.value.length);
            actual[e.target.name] = e.target.value;
        }
    }

    renderInput = () => {
        const { column, actual, error, readOnly } = this.props;

        const value = actual ? (actual[column.fieldName] !== null ? actual[column.fieldName] : '') : '';
        return (
            <>
                <Input
                    id={column.fieldName as string}
                    name={column.fieldName as string}
                    type="textarea"
                    rows={column.rows ?? 5}
                    disabled={readOnly}
                    autoComplete="off"
                    value={value}
                    onChange={this.onChange}
                    bsSize="sm"
                    invalid={!!error}
                    maxLength={column.maxLength ? column.maxLength : 524288}
                    onKeyDown={
                        // Enter soll die Form nicht submitten sondern neue Zeile erzeugen.
                        (e) => {
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                            }
                        }
                    }
                    fontSize={16}
                />
                {error ? <FormFeedback>{error}</FormFeedback> : null}
            </>
        );
    };

    render() {
        let {
            rootStore: {
                langStore: { lang },
            },
            column,
            row,
        } = this.props;
        const label: string = lang[column.label] ? lang[column.label] : (column.fieldName as string);
        return (
            <FormGroup>
                <FieldLabel label={label} bold={column.primaryKey} row={row} />
                {row ? <Col sm={9}> {this.renderInput()}</Col> : this.renderInput()}
            </FormGroup>
        );
    }
}
