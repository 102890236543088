import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { AuditCollectView } from './audit-collect-view';
import { inject, observer } from 'mobx-react';

@inject('rootStore')
@observer
export class AuditCollectOffline extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                auditOfflineStore: {
                    auditOffline: { audit },
                    areas,
                    actArea,
                    setArea,
                    elements,
                    actElement,
                    setElement,
                    images,
                    uploadFile,
                },
            },
        } = this.props;
        return (
            <AuditCollectView
                audit={audit}
                areas={areas}
                setArea={setArea}
                actArea={actArea}
                elements={elements}
                actElement={actElement}
                setElement={setElement}
                collectRouteName="auditcollectcollectoffline"
                images={images}
                uploadFile={uploadFile}
            />
        );
    }
}
