import { action } from 'mobx';
import { dsState, IDataset } from '../../../../components/dataset/IDataset';
import * as React from 'react';
import { observer } from 'mobx-react';

export interface IMultiCheckbox {
    ds: IDataset<any>;
    fieldname: string;
    checkValue: number;
    uncheckValue: number;
}

export const MultiCheckbox = observer(({ ds, fieldname, checkValue, uncheckValue }: IMultiCheckbox) => {
    return (
        <input
            type="checkbox"
            name={fieldname + checkValue.toString()}
            checked={ds.actual ? ds.actual[fieldname] === checkValue : false}
            readOnly={ds.state !== dsState.dsEdit}
            onChange={action(() => {
                if (ds.state === dsState.dsEdit) {
                    ds.actual[fieldname] = ds.actual[fieldname] === checkValue ? uncheckValue : checkValue;
                }
            })}
        />
    );
});
