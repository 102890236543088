import { observer } from 'mobx-react';
import * as React from 'react';
import moment from 'moment';

@observer
export class DateRenderer extends React.Component<any, any> {
    render() {
        const { value } = this.props;
        return <span>{value ? moment(value).format('YYYY-MM-DD') : ''}</span>;
    }
}
