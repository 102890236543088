import { Card } from 'reactstrap';
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ILangKeys } from '../../../language/ILang';
import { BaseCardHeader } from './base-card-header';
import { BaseCardFooter } from './base-card-footer';
import styled from 'styled-components';
import { IBaseView } from '../base-view';

export interface IBaseCard extends IBaseView {
    title: ILangKeys;
    height?: number;
    showBackButton?: boolean;
    rightHeaderMenu?: JSX.Element;
    footerContent?: JSX.Element;
}

@inject('rootStore')
@observer
export class _BaseCard extends React.Component<IBaseCard, {}> {
    render() {
        const { children, className, title, showBackButton, rightHeaderMenu, footerContent } = this.props;

        return (
            <Card className={className}>
                <BaseCardHeader title={title} showBackButton={showBackButton} rightHeaderMenu={rightHeaderMenu} />
                {children}
                <BaseCardFooter footerContent={footerContent} />
            </Card>
        );
    }
}

export const BaseCard = styled(_BaseCard)`
    color: black;
    position: relative;
    height: 100%;
    overflow: auto;
    border-radius: ${(props) => props.theme.cardRadius};
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.45);
    border: 0;
`;
