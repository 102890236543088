import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { UsergroupRight } from '../../../models/rights/enum-usergrouprights';
import { TableGrid } from '../../_base/styled/table-grid';
import { TableLayout } from '../../_base/styled/layout/table-layout';

@inject('rootStore')
@observer
export class UsergroupTableView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                usergroupStore: { ds },
                authStore: { hasUserRight },
            },
        } = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="usergroup"
                    icon="users"
                    title="USERGROUPS"
                    ds={ds}
                    singleRouteName="usergroup"
                    hideInsert={!hasUserRight(UsergroupRight.UR_ADMIN_USERGROUP_INSERT)}
                    hideDelete={
                        !hasUserRight(UsergroupRight.UR_ADMIN_USERGROUP_DELETE) ||
                        ds.actual?.usergroupno === 'Admin' ||
                        ds.actual?.usergroupno === 'Standard'
                    }
                />
            </TableLayout>
        );
    }
}
