import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { DbField } from '../../../components/dbform/db-field';
import { TableForm } from '../../_base/styled/table-form';
import { TableLayout } from '../../_base/styled/layout/table-layout';
import { Col, Row } from 'reactstrap';

@inject('rootStore')
@observer
export class ConfigurationView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                configStore: { ds },
                authStore: { hasModuleRight },
            },
        } = this.props;

        return (
            <TableLayout>
                <div style={{ maxHeight: '50%' }}>
                    <TableForm icon="cogs" title="CONFIGURATION" ds={ds}>
                        <Row>
                            <Col>
                                <DbField ds={ds} field="sendemail" />
                                <DbField ds={ds} field="emailonaction" />
                                <DbField ds={ds} field="emailonproject" />
                                <DbField ds={ds} field="emailonactivity" />
                                {hasModuleRight('PPAP') ? <DbField ds={ds} field="emailonppap" /> : null}
                                {hasModuleRight('AUDIT') ? <DbField ds={ds} field="emailonaudit" /> : null}
                                {hasModuleRight('IM') ? <DbField ds={ds} field="emailoninspection" /> : null}
                                {hasModuleRight('GW') ? <DbField ds={ds} field="emailongemba" /> : null}
                            </Col>
                            <Col>
                                &nbsp;
                                <br /> {/* wenn das hier nicht gesetzt ist,wird DBField nur nach refresh angezeigt, keine Ahnung warum */}
                                <DbField ds={ds} field="ppap_family_typ" />
                            </Col>
                        </Row>
                    </TableForm>
                </div>
            </TableLayout>
        );
    }
}
