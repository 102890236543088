import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { NewPage } from './audit-report-styled';
import { AuditReportPage1 } from './audit-report-page1';
import { AuditReportPage2 } from './audit-report-page2';
import { AuditReportPage3 } from './audit-report-page3';
import { PrintContainer } from '../../../_base/styled/print/print-container';

@inject('rootStore')
@observer
export class AuditReportView extends React.Component<IBaseView, {}> {
    render() {
        return (
            <PrintContainer>
                <AuditReportPage1 />
                <NewPage />
                <AuditReportPage2 />
                <NewPage />
                <AuditReportPage3 />
            </PrintContainer>
        );
    }
}
