import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { StyledTable } from '../../../order/right/ppap-upload-tab/styled-table';
import { IBaseView } from '../../../../_base/base-view';
import { AreaField, TableField } from '../../helper/FormEditor';
import { dsState } from '../../../../../components/dataset/IDataset';

export const PpapReportCustomerEngineeringApproval = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                ppapStore: { dsPpapDetail05 },
            },
        } = props;
        return (
            <>
                <StyledTable width={'100%'}>
                    <thead>
                        <tr>
                            <td style={{ width: '40%' }}>Customer Engineering Approval</td>
                            <td style={{ width: '30%' }}>Customer</td>
                            <td style={{ width: '30%' }}>Supplier</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Resposible Engineer Name</td>
                            <td>
                                <TableField ds={dsPpapDetail05} fieldName="custappname" readonly={!(dsPpapDetail05.state === dsState.dsEdit)} />
                            </td>
                            <td>
                                <TableField ds={dsPpapDetail05} fieldName="supappname" readonly={!(dsPpapDetail05.state === dsState.dsEdit)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Resposible Engineer Function</td>
                            <td>
                                <TableField ds={dsPpapDetail05} fieldName="custappfunction" readonly={!(dsPpapDetail05.state === dsState.dsEdit)} />
                            </td>
                            <td>
                                <TableField ds={dsPpapDetail05} fieldName="supappfunction" readonly={!(dsPpapDetail05.state === dsState.dsEdit)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Resposible Engineer Company</td>
                            <td>
                                <TableField ds={dsPpapDetail05} fieldName="custappcompany" readonly={!(dsPpapDetail05.state === dsState.dsEdit)} />
                            </td>
                            <td>
                                <TableField ds={dsPpapDetail05} fieldName="supappcompany" readonly={!(dsPpapDetail05.state === dsState.dsEdit)} />
                            </td>
                        </tr>
                    </tbody>
                </StyledTable>
                <p />
                <StyledTable width={'100%'}>
                    <thead>
                        <tr>
                            <td style={{ width: '70%' }}>Supplier Part</td>
                            <td style={{ width: '30%' }}>Supplier</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Supplier Part Number</td>
                            <td>
                                <TableField ds={dsPpapDetail05} fieldName="suppartno" readonly={!(dsPpapDetail05.state === dsState.dsEdit)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Supplier Part Name</td>
                            <td>
                                <TableField ds={dsPpapDetail05} fieldName="suppartname" readonly={!(dsPpapDetail05.state === dsState.dsEdit)} />
                            </td>
                        </tr>
                    </tbody>
                </StyledTable>

                <p />
                <StyledTable width={'100%'}>
                    <thead>
                        <tr>
                            <td style={{ width: '40%' }}>Customer Engineering Approval</td>
                            <td style={{ width: '60%' }}>&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ height: '12rem' }}>
                            <td style={{ verticalAlign: 'top' }}>Comments/Justification</td>
                            <td>
                                <AreaField ds={dsPpapDetail05} fieldName="comment" readonly={!(dsPpapDetail05.state === dsState.dsEdit)} />
                            </td>
                        </tr>
                    </tbody>
                </StyledTable>
            </>
        );
    }),
);
