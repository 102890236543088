import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IDataset } from '../../../components/dataset/IDataset';
import { RoundGridBtn } from '../styled/round-header-btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IMenuRefresh {
    id: string;
    ds: IDataset<any>;
    disabled?: boolean;
}

@inject('rootStore')
@observer
export class MenuRefresh extends React.Component<IMenuRefresh, {}> {
    onClick = () => {
        const { ds } = this.props;
        ds.refresh(ds.cursor);
    };

    render() {
        const { id, disabled } = this.props;
        return (
            <RoundGridBtn id={id + 'btn-refresh'} onClick={this.onClick} disabled={disabled}>
                <FontAwesomeIcon icon="sync" />
            </RoundGridBtn>
        );
    }
}
