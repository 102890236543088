import { BaseStore, IBaseStore } from './base-store';
import { Dataset } from '../components/dataset/dataset';
import { catalogDetailColumns, ICatalogDetail } from '../models/CatalogDetail';
import { catalogColumns, ICatalog } from '../models/Catalog';
import { action, observable } from 'mobx';
import { dsState, IDataset } from '../components/dataset/IDataset';
import { RouterState } from 'mobx-state-router';

export interface ICatalogStore extends IBaseStore<ICatalog> {
    dsDetail: IDataset<ICatalogDetail>;
}

export class CatalogStore extends BaseStore<ICatalog> implements ICatalogStore {
    @observable
    dsDetail: IDataset<ICatalogDetail>;

    constructor(rootStore: any) {
        super(rootStore, '/gridApi/catalog/', catalogColumns);

        this.dsDetail = new Dataset('/gridApi/catalogdetail/', catalogDetailColumns);
        this.dsDetail.setMasterSource(this.ds, [{ field: 'catalogno', masterField: 'catalogno' }]);
    }

    @action.bound
    async openDetails() {
        await this.dsDetail.open();
    }

    @action.bound
    closeDetails() {
        this.dsDetail.close();
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        if (toState.routeName === 'catalogtable') {
            await this.open();
            if (fromState.routeName === 'catalog') {
                this.ds.locate(this.ds.pkFields, fromState.params);
            }
        } else if (toState.routeName === 'catalog') {
            await this.open(toState.params as any);
            await this.openDetails();
            if (fromState.routeName === 'catalogentry') {
                this.dsDetail.locate(this.dsDetail.pkFields, fromState.params);
            }
        } else if (toState.routeName === 'catalogentry') {
            await this.open({ catalogno: toState.params.catalogno });
            await this.openDetails();
            if (this.dsDetail.locate(this.dsDetail.pkFields, toState.params)) {
                /*this.dsDetail.edit();*/
                //console.log(toState.params)
            }
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'catalogentry') {
            /*if (this.rootStore.authStore.username !== '') {
                await this.dsDetail.post();
            }*/
            this.closeDetails();
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'catalog') {
            this.closeDetails();
            if (this.ds.state === dsState.dsEdit) {
                await this.ds.post();
            }
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'catalogtable') {
            this.close();
            await Promise.resolve();
        }
    }
}
