import { enumToArray } from '../components/lib/enumToArray';
import { dateCellRenderer } from '../components/dataset/IDataset';
import { auditTemplateColumns, RatingMethod, ratingMethodText } from './AuditTemplate';
import { IField } from '../components/dataset/IField';
import { projectColumns } from './Project';
import { supplierColumns } from './Supplier';
import { Status, StatusComplete, statusText } from './Status';
import { UsergroupRight } from './rights/enum-usergrouprights';
import { StatusRenderer } from './cell-renderers/StatusRenderer';
import { DuedateRenderer } from './_helper/duedate-renderer';
import { projectMemberColumns } from './ProjectMember';
import { AuditIconRenderer } from './cell-renderers/AuditIconRenderer';

export interface IAudit {
    auditno: string;
    audit: string;
    status: Status;
    leadauditor: string;
    audittemplate: string;
    duedate: string;
    projectno: string;
    createdby: string;
    createdat: string;
    startdate: string;
    enddate: string;
    comment: string;
    reason: string;
    supplierno: string;
    supplier1: string;
    plant: string;
    ratingmethod: RatingMethod;
    coauditor01: string;
    gantt: boolean;
    isdevelopment: boolean;
    refauditno: string;

    //[key: string]: string | number;
}

export const auditColumns: IField<IAudit>[] = [
    {
        fieldName: 'auditno',
        dataType: 'string',
        label: 'AUDIT_AUDITNO',
        primaryKey: true,
        //rules: ['pk'],
        insert: 'hide',
        grid: {
            width: 70,
        },
    },
    {
        fieldName: 'isdevelopment',
        dataType: 'boolean',
        label: '',
        grid: {
            cellRenderer: AuditIconRenderer,
            width: 40,
        },
    },
    {
        fieldName: 'audit',
        dataType: 'string',
        label: 'AUDIT_AUDIT',
        insert: 'show',
    },
    {
        fieldName: 'refauditno',
        dataType: 'string',
        label: 'AUDIT_REF_AUDITNO',
        insert: 'hide',
        grid: {
            width: 70,
        },
    },

    {
        fieldName: 'supplierno',
        label: 'SUPPLIER_SUPPLIERNO',
        dataType: 'string',
        rules: ['mandatory'],
        insert: 'show',
        //readOnly: true,
        input: 'selectdlg',
        hide: 'table',
        selectdlg: {
            url: '/gridApi/supplier/',
            columns: supplierColumns,
            InserRight: UsergroupRight.UR_BASE_SUPPLIER_INSERT,
        },
        useAsFilter: true,
    },

    {
        fieldName: 'supplier1',
        dataType: 'string',
        label: 'SUPPLIER_SUPPLIER1',
        readOnly: true,
    },
    {
        fieldName: 'plant',
        dataType: 'string',
        label: 'AUDIT_PLANT',
        insert: 'show',
        hide: 'table',
    },
    {
        fieldName: 'status',
        dataType: 'number',
        label: 'AUDIT_STATUS',
        rules: ['number'],
        input: 'catalog',
        textFunc: statusText,
        options: enumToArray(StatusComplete),
        useAsFilter: true,
        defaultValue: Status.PLANNED,
        grid: {
            cellRenderer: StatusRenderer,
            width: 100,
        },
    },
    {
        fieldName: 'leadauditor',
        dataType: 'string',
        label: 'AUDIT_LEADAUDITOR',
        insert: 'show',
        rules: ['mandatory'],
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/user/',
            columns: projectMemberColumns,
            field: 'username',
        },
        useAsFilter: true,
        defaultValue: '',
    },
    {
        fieldName: 'coauditor01',
        dataType: 'string',
        label: 'AUDIT_COAUDITOR',
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/user/',
            columns: projectMemberColumns,
            field: 'username',
        },
        useAsFilter: true,
        defaultValue: '',
    },
    {
        fieldName: 'duedate',
        label: 'PROJECTTASK_DUEDATE',
        dataType: 'string',
        insert: 'show',
        rules: ['mandatory'],
        grid: {
            cellRenderer: DuedateRenderer,
            //cellStyle: dateCellStyle,
            width: 100,
        },
        input: 'calendar',
    },
    {
        fieldName: 'audittemplate',
        dataType: 'string',
        label: 'AUDIT_TEMPLATE',
        insert: 'show',
        //hide: "table",
        rules: ['mandatory'],
        input: 'selectdlg',
        readOnly: true,
        selectdlg: {
            url: '/gridApi/audittemplate/',
            columns: auditTemplateColumns,
            field: 'audittemplate',
            InserRight: UsergroupRight.UR_AUDIT_TEMPLATE_INSERT,
        },
    },
    {
        fieldName: 'ratingmethod',
        dataType: 'number',
        label: 'AUDIT_TEMPLATE_RATINGMETHOD',
        //insert: 'show',
        defaultValue: RatingMethod.VDA61,
        rules: ['number', 'mandatory'],
        input: 'select',
        textFunc: ratingMethodText,
        options: enumToArray(RatingMethod),
        readOnly: true,
    },
    {
        fieldName: 'projectno',
        label: 'PROJECT_PROJECTNO',
        dataType: 'string',
        //insert:'show',
        readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/project/',
            columns: projectColumns,
            InserRight: UsergroupRight.UR_PROJECT_PROJECT_INSERT,
        },
    },
    {
        fieldName: 'createdby',
        dataType: 'string',
        label: 'CREATED_BY',
        readOnly: true,
    },
    {
        fieldName: 'createdat',
        dataType: 'string',
        label: 'CREATED_DAT',
        input: 'calendar',
        grid: {
            cellRenderer: dateCellRenderer,
        },
        readOnly: true,
    },
    {
        fieldName: 'startdate',
        dataType: 'string',
        label: 'AUDIT_STARTDATE',
        input: 'calendar',
        hide: 'table',
        insert: 'show',
    },
    {
        fieldName: 'enddate',
        dataType: 'string',
        label: 'AUDIT_ENDDATE',
        input: 'calendar',
        hide: 'table',
        insert: 'show',
    },
    {
        fieldName: 'comment',
        dataType: 'string',
        label: 'AUDIT_COMMENT',
        input: 'textarea',
        hide: 'table',
    },
    {
        fieldName: 'reason',
        dataType: 'string',
        label: 'AUDIT_REASON',
        hide: 'table',
    },
    {
        fieldName: 'gantt',
        dataType: 'boolean',
        label: 'PROJECTTASK_GANTT',
        defaultValue: true,
        input: 'checkbox',
        hide: 'table',
    },
];
