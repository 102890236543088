import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import styled from 'styled-components';
import { cl_gray400 } from '../../_theme/colors';

interface ICustomCheckbox extends IBaseView {
    label?: string;
    checked?: boolean;
    onChange?: () => void;
}

class _AuditCheckbox extends React.Component<ICustomCheckbox, {}> {
    render() {
        const { className, label, checked, onChange } = this.props;
        return (
            <div className={className}>
                <label className={'container'}>
                    {label}
                    <input className="checkbox" type="checkbox" checked={checked} onChange={onChange} />
                    <span className="checkmark" />
                </label>
            </div>
        );
    }
}

export const AuditCheckbox = styled(_AuditCheckbox)`
    display: inline-block;

    .container {
        display: inline-block;
        position: relative;
        padding-left: 35px;
        padding-right: 0;
        margin-bottom: 12px;
        cursor: pointer;
        //font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */

    .checkmark {
        position: absolute;
        top: 0;
        left: 0.375rem;
        height: 25px;
        width: 25px;
        background-color: white;
        border: solid ${cl_gray400};
        border-width: 1px;
    }

    /* On mouse-over, add a grey background color */

    .container:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */

    .container input:checked ~ .checkmark {
        background-color: white;
    }

    /* Create the checkmark/indicator (hidden when not checked) */

    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */

    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */

    .container .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid black;
        border-width: 0 3px 3px 0;

        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;
