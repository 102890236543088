import { IField } from '../components/dataset/IField';
import { userColumns } from './User';
import { enumToArray } from '../components/lib/enumToArray';
import { PPAP_DOC, PPAP_SR, ppapDocText, ppapSRText } from './PpapStandardElement';
import { Status, statusText } from './Status';
import { UsergroupRight } from './rights/enum-usergrouprights';
import { StatusRenderer } from './cell-renderers/StatusRenderer';
import { DuedateRenderer } from './_helper/duedate-renderer';
import { DateRenderer } from './_helper/date-renderer';
import { SubmissionRenderer } from './cell-renderers/SubmissionRenderer';

export interface IPpapOrderElement {
    ppapno: string;
    elementno: number;
    element: string;
    doc: number;
    sr: number;
    responsible: string;
    startdate: string;
    duedate: string;
    status: number;
    template: string;
    phase: string;
    requirements: string;
    sheetspecific01: object;

    [key: string]: string | number | object;
}

export const ppapOrderElementColumns: IField<IPpapOrderElement>[] = [
    {
        fieldName: 'ppapno',
        dataType: 'string',
        label: 'PPAP_ORDER',
        primaryKey: true,
        rules: ['pk', 'mandatory'],
        hide: 'table',
    },
    {
        fieldName: 'elementno',
        dataType: 'number',
        label: 'PPAP_ELEMENTNO',
        primaryKey: true,
        rules: ['number', 'pk'],
        //hide: 'table',
        grid: {
            width: 40,
        },
    },
    {
        fieldName: 'element',
        dataType: 'string',
        label: 'PPAP_ELEMENT',
        grid: {
            flex: 1,
        },
    },
    {
        fieldName: 'phase',
        dataType: 'string',
        label: 'PPAP_PHASE',
        hide: 'table',
        grid: {
            width: 50,
        },
    },
    {
        fieldName: 'doc',
        dataType: 'number',
        label: 'PPAP_DOCUMENTATION',
        hide: 'table',
        grid: {
            width: 60,
        },
        input: 'select',
        options: enumToArray(PPAP_DOC),
        textFunc: ppapDocText,
    },

    {
        fieldName: 'responsible',
        dataType: 'string',
        label: 'PPAP_RESPONSIBLE_SUPPLIER',
        insert: 'show',
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/projectsupplieruser/',
            filter: (actual, fieldName, rootStore) => {
                return { projectno: rootStore.ppapStore.ds.actual.projectno };
            },
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT,
        },
    },
    {
        fieldName: 'sr',
        dataType: 'number',
        label: 'PPAP_SUBMISSION',
        //hide: 'table',
        grid: {
            cellRenderer: SubmissionRenderer,
            width: 45,
        },
        input: 'select',
        options: enumToArray(PPAP_SR),
        textFunc: ppapSRText,
    },
    /* {
         fieldName: 'responsible',
         label: 'PROJECTTASK_RESPONSIBLE',
         dataType: 'string',
         insert: 'show',
         input: 'selectdlg',
         selectdlg: {
             url: '/gridApi/user/',
             columns: userColumns,
             field: 'username',
             InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
         },
         grid: {
             width: 160
         }
     },*/
    {
        fieldName: 'status',
        label: 'PPAP_STATUS',
        dataType: 'number',
        input: 'catalog',
        options: enumToArray(Status),
        textFunc: statusText,
        grid: {
            cellRenderer: StatusRenderer,
            width: 100,
        },
    },
    {
        fieldName: 'startdate',
        label: 'PROJECTTASK_STARTDATE',
        dataType: 'string',
        insert: 'show',
        input: 'calendar',
        grid: {
            cellRenderer: DateRenderer,
            width: 100,
        },
    },
    {
        fieldName: 'duedate',
        label: 'PROJECTTASK_DUEDATE',
        dataType: 'string',
        insert: 'show',
        input: 'calendar',
        grid: {
            cellRenderer: DuedateRenderer,
            width: 100,
        },
    },
    {
        fieldName: 'template',
        dataType: 'string',
        label: 'PPAP_ELEMENT_TEMPLATE',
        readOnly: true,
        hide: 'table',
    },
    {
        fieldName: 'requirements',
        label: 'PPAP_ELEMENT_REQUIREMENTS',
        dataType: 'string',
        input: 'textarea',
        hide: 'table',
        rows: 12,
    },
];
