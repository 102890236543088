import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { Container } from 'reactstrap';
import { ReportMain } from '../../_shared/report/report-main';
import { InspectionReport } from './inspection-report';

@inject('rootStore')
@observer
export class InspectionReportView extends React.Component<IBaseView, {}> {
    render() {
        return (
            <Container>
                <ReportMain>
                    <InspectionReport />
                </ReportMain>
            </Container>
        );
    }
}
