import styled from 'styled-components';

export const AppLayout = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.bgBody};
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    .header {
        flex: 0 0 auto;
        display: flex;

        //padding: 0.5rem 0.75rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.75rem;
        padding-right: 2rem;
        box-sizing: border-box;
        color: ${(props) => props.theme.header.color};
        background-color: ${(props) => props.theme.header.bg};
        border-bottom: ${(props) => props.theme.border};
        justify-items: center;
        justify-content: center;
        align-items: center;

        .header-left {
            display: block;
            flex: 1 1 auto;
            background-color: ${(props) => props.theme.header.bg};
            font-size: 1.25rem;
            padding-left: 4px;

            .toggle-button {
                text-align: left;
                border: 0;
                cursor: pointer;
                color: ${(props) => props.theme.header.color};
                background-color: ${(props) => props.theme.header.bg};
            }
        }

        .center {
            display: block;
            flex: 1 1 auto;
            text-align: center;
            color: ${(props) => props.theme.header.color};
            font-weight: 500;
            font-size: 24px;
            @media (max-width: 576px) {
                font-size: 16px;
            }
        }

        .right {
            display: block;
            flex: 1 1 auto;
            position: relative;

            .inner {
                float: right;

                .btn-secondary {
                    color: ${(props) => props.theme.header.color};
                    background-color: ${(props) => props.theme.bg};
                    font-size: 0.875rem;
                    padding-right: 0;
                }

                .header-user {
                    background-color: ${(props) => props.theme.header.bg};
                    border: 0;
                    font-size: 0.875rem;
                    font-weight: 500;
                    text-overflow: clip;
                }
            }
        }
    }

    .middle {
        flex: 1 1 1rem;
        display: flex;
        flex-direction: row;
        background-color: ${(props) => props.theme.main.bgBody};
        color: white;
    }
`;
