import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import { UsergroupRight } from '../../../models/rights/enum-usergrouprights';
import { TableGrid } from '../../_base/styled/table-grid';
import { TableLayout } from '../../_base/styled/layout/table-layout';

@inject('rootStore')
@observer
export class PpapTemplateTableView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                ppapStandardStore: { ds },
                authStore: { hasUserRight },
            },
        } = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="ppapstandars"
                    icon="box-check"
                    title="PPAP_LEVEL"
                    ds={ds}
                    singleRouteName="ppapstandard"
                    hideInsert={!hasUserRight(UsergroupRight.UR_PPAP_TEMPLATE_INSERT)}
                    hideDelete={!hasUserRight(UsergroupRight.UR_PPAP_TEMPLATE_DELETE)}
                />
            </TableLayout>
        );
    }
}
