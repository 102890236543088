import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBaseView } from '../../_base/base-view';
import { cl_gray100, cl_green } from '../../_theme/colors';

const Header = styled.h6`
    text-align: center;
`;

const Liste = styled.ul`
    padding-inline-start: 0;
    background-color: ${cl_gray100};
`;

const ListItem = styled.li`
    display: block;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

@inject('rootStore')
@observer
export class InspectionCollectAreaList extends React.Component<IBaseView, {}> {
    onClick = (label: string) => {
        const {
            rootStore: {
                inspectionStore: { setArea },
            },
        } = this.props;
        return () => setArea(label);
    };

    render() {
        const {
            rootStore: {
                inspectionStore: { areas, actArea },
                langStore: { lang },
            },
        } = this.props;
        return (
            <>
                <Header>{lang.AUDIT_AREAS}</Header>
                <Liste>
                    {areas.map((area, i) => {
                        return (
                            <ListItem key={i} onClick={this.onClick(area.label)}>
                                <FontAwesomeIcon color={area.completed ? cl_green : cl_gray100} icon="check" />
                                &nbsp;&nbsp;
                                {area.label === actArea ? <b>{area.label}</b> : area.label}
                            </ListItem>
                        );
                    })}
                </Liste>
            </>
        );
    }
}
