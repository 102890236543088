import { usergroupColumns } from './Usergroup';
import { IField } from '../components/dataset/IField';
import { includes } from '../components/lib/Includes';
import { enumToArray } from '../components/lib/enumToArray';
import * as R from 'ramda';
import { UsergroupRight } from './rights/enum-usergrouprights';
import { customerColumns } from './Customer';
import { supplierColumns } from './Supplier';
import { CheckboxRender } from './_helper/checkbox-renderer';

export enum Language {
    LANG_EN,
    LANG_DE,
}

const languageText = (language: Language) => {
    switch (language) {
        case Language.LANG_EN:
            return 'ENGLISH';
        case Language.LANG_DE:
            return 'GERMAN';
        default:
            return 'Unknown';
    }
};

export interface IUser {
    username: string;
    email: string;
    firstname: string;
    lastname: string;
    usergroupno: string;
    picture: string;
    language: number;
    customerno: string;
    customer1: string; // View->Customer
    supplierno: string;
    supplier1: string; // View -> Supplier
    isactive: boolean;
    telefon1: string;
    telefon2: string;
    funktion: string;
}

export const userColumns: IField<IUser>[] = [
    {
        fieldName: 'username',
        dataType: 'string',
        label: 'USER_USERNAME',
        primaryKey: true,
        rules: ['pk'],
    },
    {
        fieldName: 'usergroupno',
        dataType: 'string',
        label: 'USER_USERGROUP',
        input: 'selectdlg',
        insert: 'show',
        selectdlg: {
            url: '/gridApi/usergroup/',
            columns: usergroupColumns,
            InserRight: UsergroupRight.UR_ADMIN_USERGROUP_INSERT,
        },
        rules: ['mandatory'],
    },
    {
        fieldName: 'isactive',
        dataType: 'boolean',
        label: 'USER_ISACTIVE',
        defaultValue: true,
        input: 'checkbox',
        grid: {
            width: 60,
            cellRenderer: CheckboxRender,
        },
    },
    {
        fieldName: 'email',
        dataType: 'string',
        label: 'USER_EMAIL',
        insert: 'show',
        rules: ['mandatory', 'email'],
    },
    {
        fieldName: 'firstname',
        dataType: 'string',
        label: 'USER_FIRSTNAME',
    },
    {
        fieldName: 'lastname',
        dataType: 'string',
        label: 'USER_LASTNAME',
    },
    {
        fieldName: 'language',
        dataType: 'number',
        label: 'LANGUAGE',
        input: 'select',
        textFunc: languageText,
        options: enumToArray(Language),
    },
    {
        fieldName: 'customerno',
        label: 'CUSTOMER_CUSTOMERNO',
        dataType: 'string',
        insert: 'show',
        useAsFilter: true,
        input: 'selectdlg',
        hide: 'table',
        selectdlg: {
            url: '/gridApi/customer/',
            columns: customerColumns,
            InserRight: UsergroupRight.UR_BASE_CUSTOMER_INSERT,
        },
    },
    {
        fieldName: 'customer1',
        dataType: 'string',
        label: 'CUSTOMER_CUSTOMER1',
        //show: 'table',
        readOnly: true,
    },
    {
        fieldName: 'supplierno',
        label: 'SUPPLIER_SUPPLIERNO',
        dataType: 'string',
        insert: 'show',
        useAsFilter: true,
        input: 'selectdlg',
        hide: 'table',
        selectdlg: {
            url: '/gridApi/supplier/',
            columns: supplierColumns,
            InserRight: UsergroupRight.UR_BASE_SUPPLIER_INSERT,
        },
    },
    {
        fieldName: 'supplier1',
        dataType: 'string',
        label: 'SUPPLIER_SUPPLIER1',
        readOnly: true,
    },
    {
        fieldName: 'telefon1',
        dataType: 'string',
        label: 'USER_TELEFON1',
    },
    {
        fieldName: 'telefon2',
        dataType: 'string',
        label: 'USER_TELEFON2',
    },
    {
        fieldName: 'funktion',
        dataType: 'string',
        label: 'USER_FUNCTION',
    },
];

// user in der usergroup detail views
export const usergroupUserColumns = R.clone(userColumns)
    .filter((c: IField<IUser>) => includes(['username', 'usergroupno', 'email'], c.fieldName as string))
    .map((c: IField<IUser>) => {
        if (c.fieldName === 'usergroupno') {
            c.hide = 'table';
            c.insert = 'hide';
        }
        return c;
    });
