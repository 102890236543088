import * as React from 'react';
import { Provider } from 'mobx-react';
import { rootStore } from '../../views/_layout/app';
import { ActionTyp } from '../Action';
import { RouterLink } from 'mobx-state-router';
import { UsergroupRight } from '../rights/enum-usergrouprights';

export class ActionLinkRenderer extends React.Component<any, any> {
    render() {
        return (
            <Provider rootStore={rootStore}>
                {this.props.value ? (
                    <div>
                        {this.props.data.typ === ActionTyp.PPAPTask && rootStore.authStore.hasUserRight(UsergroupRight.UR_PPAP_PPAP) ? (
                            <RouterLink routeName="ppaporder" params={{ ppapno: this.props.value as string }}>
                                {this.props.value}
                            </RouterLink>
                        ) : this.props.data.typ === ActionTyp.PROJECTTask && rootStore.authStore.hasUserRight(UsergroupRight.UR_PROJECT_PROJECT) ? (
                            <RouterLink
                                routeName="projectactivity"
                                params={{
                                    projectno: this.props.data.key1 as string,
                                    activityno: this.props.data.key2 as string,
                                }}
                            >
                                {this.props.value}
                            </RouterLink>
                        ) : this.props.data.typ === ActionTyp.AUDITTask && rootStore.authStore.hasUserRight(UsergroupRight.UR_AUDIT_AUDIT) ? (
                            <RouterLink
                                routeName="audit"
                                params={{ auditno: this.props.value as string }}
                                queryParams={{ questionno: this.props.data.key2 as string }}
                            >
                                {this.props.value}
                            </RouterLink>
                        ) : this.props.data.typ === ActionTyp.INSPECTIONTask &&
                          rootStore.authStore.hasUserRight(UsergroupRight.UR_INSPECTION_INSPECTION) ? (
                            <RouterLink routeName="inspection" params={{ inspectionno: this.props.value as string }}>
                                {this.props.value}
                            </RouterLink>
                        ) : this.props.data.typ ===
                          ActionTyp.GEMBATask /*&& rootStore.authStore.hasUserRight(UsergroupRight.UR_INSPECTION_INSPECTION)*/ ? (
                            <RouterLink routeName="gemba" params={{ gembano: this.props.value as string }}>
                                {this.props.value}
                            </RouterLink>
                        ) : (
                            ''
                        )}
                    </div>
                ) : null}
            </Provider>
        );
    }
}
