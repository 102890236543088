import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../_base/base-view';
import { Col, Container, DropdownItem, Row } from 'reactstrap';
import { BaseCardForm } from '../../_base/form/base-card-form';
import { DbField } from '../../../components/dbform/db-field';
import { BaseCardGrid } from '../../_base/grid/base-card-grid';
import { action } from 'mobx';
import { BaseCard } from '../../_base/card/base-card';
import { BaseCardRightHeaderMenu } from '../../_base/card/base-card-right-header-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InspectionStatisticArea } from '../statistic/inspection-statistic-area';
import { InspectionStatisticScore } from '../statistic/inspection-statistic-score';

export const InspectionActionMenu = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                inspectionStore: { actionByQuestionFilter, toggleActionFilter },
                langStore: { lang },
            },
        } = props;
        return (
            <BaseCardRightHeaderMenu>
                <DropdownItem onClick={toggleActionFilter}>
                    <FontAwesomeIcon icon={actionByQuestionFilter ? 'check-circle' : 'circle'} mask={actionByQuestionFilter ? undefined : 'circle'} />
                    &nbsp;{lang['AUDIT_FILTER_BY_QUESTION']}
                </DropdownItem>
            </BaseCardRightHeaderMenu>
        );
    }),
);

interface IInspectionMenu extends IBaseView {
    onCollect: () => void;
    onReport: () => void;
}

export const InspectionMenu = inject('rootStore')(
    observer((props: IInspectionMenu) => {
        const {
            rootStore: {
                langStore: { lang },
                inspectionStore: { ds },
                authStore: { username },
            },
            onCollect,
            onReport,
        } = props;
        return (
            <>
                <BaseCardRightHeaderMenu>
                    {username === ds.actual?.inspector ? <DropdownItem onClick={onCollect}>{lang['DATACOLLECT']}</DropdownItem> : null}
                    <DropdownItem onClick={onReport}>{lang['REPORT']}</DropdownItem>
                </BaseCardRightHeaderMenu>
            </>
        );
    }),
);

const InspectionEvaluationMenu = inject('rootStore')((props: IBaseView) => {
    const {
        rootStore: {
            inspectionStore: { evaluation, setEvaluation },
            langStore: { lang },
        },
    } = props;
    return (
        <BaseCardRightHeaderMenu>
            <DropdownItem
                onClick={() => {
                    setEvaluation('SCORE');
                }}
            >
                <FontAwesomeIcon icon={evaluation === 'SCORE' ? 'check-circle' : 'circle'} mask={evaluation === 'SCORE' ? undefined : 'circle'} />
                &nbsp;
                {lang['AUDIT_EVAL_OVERALL']}
            </DropdownItem>
            <DropdownItem
                onClick={() => {
                    setEvaluation('AREA');
                }}
            >
                <FontAwesomeIcon icon={evaluation === 'AREA' ? 'check-circle' : 'circle'} mask={evaluation === 'AREA' ? undefined : 'circle'} />
                &nbsp;
                {lang['AUDIT_EVAL_AREA']}
            </DropdownItem>
        </BaseCardRightHeaderMenu>
    );
});

const InspectionQuestionMenu = inject('rootStore')(
    observer((props: IBaseView) => {
        const {
            rootStore: {
                inspectionStore: { questionScoreFilter, setQuestionScoreFilter },
                langStore: { lang },
            },
        } = props;
        return (
            <BaseCardRightHeaderMenu>
                <DropdownItem
                    onClick={() => {
                        setQuestionScoreFilter(undefined);
                    }}
                >
                    <FontAwesomeIcon
                        icon={questionScoreFilter === undefined ? 'check-circle' : 'circle'}
                        mask={questionScoreFilter === undefined ? undefined : 'circle'}
                    />
                    &nbsp;
                    {lang['ALL']}
                </DropdownItem>
                <DropdownItem
                    onClick={() => {
                        setQuestionScoreFilter(0);
                    }}
                >
                    <FontAwesomeIcon
                        icon={questionScoreFilter === 0 ? 'check-circle' : 'circle'}
                        mask={questionScoreFilter === 0 ? undefined : 'circle'}
                    />
                    &nbsp; score = {lang['NOK']}
                </DropdownItem>
            </BaseCardRightHeaderMenu>
        );
    }),
);

@inject('rootStore')
@observer
export class InspectionDetailView extends React.Component<IBaseView, {}> {
    @action.bound
    goToQuestion() {
        const {
            rootStore: {
                routerStore,
                inspectionStore: { dsInspectionQuestion },
            },
        } = this.props;
        routerStore.goTo('inspectionquestion', {
            inspectionno: dsInspectionQuestion.actual.inspectionno,
            questionno: dsInspectionQuestion.actual.questionno,
        });
    }

    @action.bound
    goToAction() {
        const {
            rootStore: {
                routerStore,
                inspectionStore: { dsAction },
            },
        } = this.props;
        routerStore.goTo('action', { no: dsAction.actual.no });
    }

    @action.bound
    goToCollect() {
        const {
            rootStore: {
                routerStore,
                inspectionStore: { ds },
            },
        } = this.props;
        routerStore.goTo('inspectioncollect', {
            inspectionno: ds.actual.inspectionno,
        });
    }

    @action.bound
    goToProject() {
        const {
            rootStore: {
                routerStore,
                inspectionStore: { ds },
            },
        } = this.props;
        if (ds.actual.projectno) {
            routerStore.goTo('project', { projectno: ds.actual.projectno });
        }
    }

    @action.bound
    goToReport() {
        const {
            rootStore: {
                routerStore,
                inspectionStore: { ds },
            },
        } = this.props;
        routerStore.goTo('inspectionreport', {
            inspectionno: ds.actual.inspectionno,
        });
    }

    render() {
        const {
            rootStore: {
                inspectionStore: { ds, dsInspectionQuestion, dsAction, evaluation },
                authStore: { username },
            },
        } = this.props;
        return (
            <Container fluid>
                <Row style={{ paddingTop: '1rem' }}>
                    <Col sm="6" xs={{ size: 12 }} md={{ size: 6 }}>
                        <BaseCardForm
                            title="INSPECTION"
                            ds={ds}
                            height={220}
                            showBackButton={true}
                            rightHeaderMenu={<InspectionMenu onCollect={this.goToCollect} onReport={this.goToReport} />}
                        >
                            <Row>
                                <Col>
                                    <DbField ds={ds} field="inspectionno" />
                                    <DbField ds={ds} field="inspector" />
                                    <DbField ds={ds} field="customerno" />
                                </Col>
                                <Col>
                                    <DbField ds={ds} field="inspection" />
                                    <DbField ds={ds} field="status" />
                                    <DbField ds={ds} field="planno" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <DbField ds={ds} field="duedate" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DbField ds={ds} field="createdby" />
                                        </Col>
                                        <Col>
                                            <DbField ds={ds} field="createdat" />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col></Col>
                            </Row>
                            <Row>
                                <Col>
                                    {ds.actual?.projectno !== '' ? <DbField ds={ds} field="projectno" onClickLabel={this.goToProject} /> : null}
                                </Col>
                                <Col></Col>
                            </Row>
                        </BaseCardForm>
                    </Col>
                    <Col sm="6" xs={{ size: 12 }} md={{ size: 6 }}>
                        <BaseCard title="STATISTIC" rightHeaderMenu={<InspectionEvaluationMenu />}>
                            <div
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'stretch',
                                    justifyContent: 'space-evenly',
                                    padding: '1rem',
                                }}
                            >
                                {evaluation === 'SCORE' ? <InspectionStatisticScore /> : <InspectionStatisticArea />}
                            </div>
                        </BaseCard>
                    </Col>
                </Row>
                <Row style={{ paddingTop: '1rem' }}>
                    <Col md={6}>
                        <BaseCardGrid
                            id="question"
                            title="INSPECTION_PLAN_QUESTIONS"
                            ds={dsInspectionQuestion}
                            height={480}
                            onRowDoubleClicked={this.goToQuestion}
                            hideInsert={true}
                            hideDelete={true}
                            rightHeaderMenu={<InspectionQuestionMenu />}
                        />
                    </Col>
                    <Col md={6}>
                        <BaseCardGrid
                            id="action"
                            title="ACTIONS"
                            ds={dsAction}
                            height={480}
                            onRowDoubleClicked={this.goToAction}
                            rightHeaderMenu={<InspectionActionMenu />}
                            hideInsert={!(username === ds.actual?.inspector)}
                            hideDelete={!(username === ds.actual?.inspector)}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}
