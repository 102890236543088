import { action, computed, observable, reaction, runInAction } from 'mobx';
import * as R from 'ramda';
import { HttpAxiosAdapter, IHttpAdapter, NoHttpAdapter } from './HttpAxiosAdapter';
import { dsState, IDataset, IMasterField } from './IDataset';
import { Validator } from './validator';
import { IField } from './IField';

/**
 * 2020-05-03
 *
 * errorMessage:
 * ============
 * catches the post error. This Message can be used display as User feedback.
 *
 * setErrorMessage:
 * ===============
 * The Action to set or clear the Message
 *
 */
export class Dataset<T> implements IDataset<T> {
    // delegating the http actions
    private httpAdapter: IHttpAdapter;

    // reloades the data after post
    // switched to false, when no http
    autoRefresh: boolean = true;

    filter: T | {} = {};

    // the fixed part in the url
    dataUrl: string;

    //masterSource
    masterSource: IDataset<any> = undefined;
    masterFields: IMasterField[] = [];
    masterDispose: () => void;

    //Events
    onCanDelete: (ds: IDataset<T>) => Promise<boolean>;
    onBeforeInsert: (ds: IDataset<T>) => void;
    onAfterInsert: (ds: IDataset<T>) => void;
    onAfterDelete: (ds: IDataset<T>) => void;
    onAfterOpen: (ds: IDataset<T>) => void;
    onAfterEdit: (ds: IDataset<T>) => void;

    // Validator
    validator: Validator<T>;

    // ErrorMessage
    @observable
    errorMessage: string;

    @action.bound
    setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage;
    }

    constructor(dataUrl: string, columns: IField<T>[], filter: T | {} = {}, withHttp: boolean = true) {
        this.dataUrl = dataUrl;
        this.columns = columns;
        this.filter = filter;

        if (withHttp) {
            // the normal server communication
            this.httpAdapter = new HttpAxiosAdapter();
            this.autoRefresh = true;
        } else {
            // use as clientdataset no communication, no autoRefresh
            // (autorefresh closes the sample after post, so all data are gone, not usefull here)
            this.httpAdapter = new NoHttpAdapter();
            this.autoRefresh = false;
        }

        this.validator = new Validator<T>(this.columns);
    }

    @observable
    state: dsState = dsState.dsInactive;

    @observable
    data: T[] = [];

    @observable
    cursor: number = undefined;

    @observable
    columns: IField<T>[];

    // creates an empty object of dataType T which can be used when data are inserted
    // what's missing is dataType specific defaults
    newRecord = (): T => {
        return this.columns.reduce((rv: any, column: IField<T>): any => {
            switch (column.dataType) {
                case 'string':
                    column.defaultValue ? (rv[column.fieldName] = column.defaultValue) : (rv[column.fieldName] = '');
                    break;
                case 'number':
                    column.defaultValue ? (rv[column.fieldName] = column.defaultValue) : (rv[column.fieldName] = null);
                    break;
                case 'boolean':
                    column.defaultValue ? (rv[column.fieldName] = column.defaultValue) : (rv[column.fieldName] = false);
                    break;
                default:
                    column.defaultValue ? (rv[column.fieldName] = column.defaultValue) : (rv[column.fieldName] = '');
            }
            return rv;
        }, {});
    };

    @action
    async open() {
        let actualFilter: T | {};
        if (this.state !== dsState.dsInactive) {
            this.close();
        }
        if (this.masterSource) {
            // im Zweifelsfall wird Wert von Filter genommen
            // 26.9.19: Mit neuen typings für ramda kam fehlermeldung, deshalb any
            actualFilter = R.mergeLeft(this.filter as any, this.getMasterFilter());
        } else {
            actualFilter = R.clone(this.filter);
        }
        try {
            this.state = dsState.dsPending;
            // Debug Hilfe
            //if (this.dataUrl==='/gridApi/action/'){
            //console.log(this.dataUrl,actualFilter);
            //}
            let response = await this.httpAdapter.get(this.dataUrl, { params: actualFilter });
            runInAction(() => {
                // if cds => response is empty
                this.data = response ? response.data.data : [];
                this.data.length ? (this.cursor = 0) : (this.cursor = undefined);
                this.state = dsState.dsBrowse;
                if (this.onAfterOpen) {
                    this.onAfterOpen(this);
                }
                //console.log(this.dataUrl,'get',actualFilter)
            });
        } catch (e) {
            console.log('error in ds.open', e);
            action(() => {
                this.state = dsState.dsInactive;
            });
        }
    }

    @action close() {
        this.data = [];
        this.cursor = undefined;
        this.state = dsState.dsInactive;
    }

    // the actual record on cursor
    @computed
    get actual(): T {
        return this.cursor !== undefined && this.cursor < this.data.length ? this.data[this.cursor] : undefined;
    }

    @action.bound
    setCursor(aCursor: number) {
        this.cursor = aCursor;
    }

    // the Url for the actual record or undefined
    @computed get cursorUrl(): string {
        let rv;
        if (this.cursor === undefined) {
            rv = undefined;
        } else {
            //console.log(this.dataUrl[this.dataUrl.length - 1] === '/' ? this.dataUrl.slice(0, this.dataUrl.length - 1) : this.dataUrl);
            rv = this.columns
                .filter((c: IField<T>) => c.primaryKey)
                .reduce(
                    (v: string, c: IField<T>) => {
                        return v + '/' + encodeURIComponent(encodeURIComponent((this.actual as any)[c.fieldName]));
                    },
                    this.dataUrl[this.dataUrl.length - 1] === '/' ? this.dataUrl.slice(0, this.dataUrl.length - 1) : this.dataUrl,
                );
        }
        return rv;
    }

    @computed get pkFields(): [...(keyof T)[]] {
        return this.columns
            .filter((c: IField<T>) => c.primaryKey)
            .reduce((rv: [...(keyof T)[]], c: IField<T>): [...(keyof T)[]] => {
                rv.push(c.fieldName);
                return rv;
            }, []);
    }

    @computed
    get pkValues(): Partial<T> {
        let x: any = this.actual;
        let rv: any = {};
        this.pkFields.forEach((field) => {
            rv[field] = x[field];
        });
        return rv;
    }

    // move cursor to the record where value matches record.fieldName
    // [...(keyof T)[]] geht nicht da wird gemeckert, bei project-store
    @action.bound
    locate(fields: any[], value: Partial<T>): boolean {
        let newCursor: number = undefined;
        if (this.data.length === 0) {
            return false;
        } else {
            this.data.forEach((c: any, i: number) => {
                if (newCursor === undefined) {
                    if (
                        fields.reduce((rv: boolean, field: keyof T): boolean => {
                            return c[field] === (value as any)[field] && rv;
                        }, true)
                    ) {
                        newCursor = i;
                    }
                }
            });
            if (newCursor !== undefined) {
                this.setCursor(newCursor);
                return true;
            } else {
                return false;
            }
        }
    }

    @action.bound
    edit(): void {
        this.state = dsState.dsEdit;
        if (this.onAfterEdit) {
            this.onAfterEdit(this);
        }
    }

    // trash all changes and dsbrowse
    /**
     * @deprecated The method should not be used anymore
     * insted use: await cancelAsync
     */
    @action.bound
    cancel(): void {
        if (this.state === dsState.dsInsert) {
            this.data.splice(this.cursor, 1);
            if (this.data.length === 0) {
                this.cursor = undefined;
            }
            this.last();
        }
        this.validator.clear();
        this.setErrorMessage('');
        this.state = dsState.dsBrowse;
    }

    @action.bound
    async cancelAsync(): Promise<void> {
        if (this.state === dsState.dsInsert) {
            this.data.splice(this.cursor, 1);
            if (this.data.length === 0) {
                this.cursor = undefined;
            }
            this.last();
        }
        this.validator.clear();
        this.setErrorMessage('');
        this.state = dsState.dsBrowse;
        await Promise.resolve();
    }

    // insert and append are the same
    @action.bound
    insert(defaultValues?: T): void {
        if (this.onBeforeInsert) {
            this.onBeforeInsert(this);
        }
        this.state = dsState.dsInsert;
        // any, sonst typescript fehler
        // 26.9.19: Mit neuen typings für ramda kam fehlermeldung, deshalb any
        let newData: any = R.mergeRight(this.newRecord() as any, defaultValues ? defaultValues : {});
        if (this.masterSource) {
            newData = R.mergeRight(newData, this.getMasterFilter());
        }

        this.data.push(newData);
        this.setCursor(this.data.length - 1);

        if (this.onAfterInsert) {
            this.onAfterInsert(this);
        }
    }

    // depending on state insert=>post or edit=>put
    // on other states it will promise.resolve
    @action.bound
    async post() {
        // dsEdit
        if (this.state === dsState.dsEdit) {
            this.data[this.cursor] = this.actual;
            let newValue: T = R.clone(this.actual);
            await this.httpAdapter.put(this.cursorUrl, this.actual);
            if (this.autoRefresh) {
                this.close();
                await this.open();
                this.locate(this.pkFields, newValue);
            } else {
                runInAction(() => {
                    this.state = dsState.dsBrowse;
                });
            }
        }
        // dsInsert
        else if (this.state === dsState.dsInsert) {
            // es werden die whitespaces weggeschnitten
            // vielleicht sollte man das auch serverseitig machen.
            // momentan aber hier beim insert to DB
            this.trimactual();
            let newValue: T = R.clone(this.actual);
            try {
                await this.httpAdapter.post(this.dataUrl, this.actual);
                this.setErrorMessage('');
            } catch (e: any) {
                this.setErrorMessage(e.response.data);
                throw e;
            }
            if (this.autoRefresh) {
                this.close();
                await this.open();
                this.locate(this.pkFields, newValue);
            }
        } else {
            await Promise.resolve();
        }
    }

    // delete record on backend
    @action.bound
    async delete() {
        if (this.onCanDelete) {
            const canDelete = await this.onCanDelete(this);
            if (!canDelete) {
                return;
            }
        }
        await this.httpAdapter.delete(this.cursorUrl);
        runInAction(() => {
            this.data.splice(this.cursor, 1);
            if (this.data.length === 0) {
                this.setCursor(undefined);
                this.state = dsState.dsBrowse;
            } else if (this.cursor > this.data.length - 1) {
                this.setCursor(this.data.length - 1);
                this.state = dsState.dsBrowse;
            }
            if (this.onAfterDelete) {
                this.onAfterDelete(this);
            }
        });
    }

    // Cursor movement

    // moves the cursor delta record foreward or backward
    // return then effective moved records
    @action.bound
    moveBy(delta: number): number {
        if (this.cursor === undefined || this.data.length === 0) {
            return 0;
        } else {
            if (this.cursor + delta >= 0) {
                if (this.cursor + delta > this.data.length - 1) {
                    this.setCursor(this.data.length - 1);
                    return this.data.length - 1 - this.cursor;
                } else {
                    this.setCursor(this.cursor + delta);
                    return delta;
                }
            } else {
                delta = -this.cursor;
                this.setCursor(0);
                return delta;
            }
        }
    }

    // move to next record
    @action.bound
    next(): void {
        this.moveBy(1);
    }

    // move to previos record
    @action.bound
    prev(): void {
        this.moveBy(-1);
    }

    // move to first record
    @action.bound
    first(): void {
        if (this.data.length > 0) {
            this.setCursor(0);
        }
    }

    // move to last record
    @action.bound
    last(): void {
        if (this.data.length > 0) {
            this.setCursor(this.data.length - 1);
        }
    }

    @computed
    get hasPrev(): boolean {
        return this.cursor > 0;
    }

    @computed
    get hasNext(): boolean {
        return this.cursor < this.data.length - 1;
    }

    @action.bound
    async refresh(cursor: number) {
        this.close();
        await this.open();
        this.moveBy(cursor);
    }

    getMasterFilter = () => {
        return this.masterFields.reduce((masterFilter: any, x) => {
            masterFilter[x.field] = this.masterSource.actual ? this.masterSource.actual[x.masterField] : 'null';
            return masterFilter;
        }, {});
    };

    /** setzt den Filter auf die aktuellen Werte des Mastersource*/
    setMasterFilter = async () => {
        // Achtung: Pending ausgeklammert, sonst werden Reactionen verschluckt.
        // console.log('refresh',this.state,this.masterSource.state)
        if (
            this.masterSource.state !== dsState.dsInactive &&
            this.masterSource.state !== dsState.dsPending &&
            this.masterSource.cursor >= 0 &&
            this.state !== dsState.dsInactive &&
            this.state !== dsState.dsPending
        ) {
            // console.log('doing')
            await this.refresh(this.cursor);
        } else {
            await Promise.resolve();
        }
    };

    setMasterSource = (masterSource?: IDataset<any>, masterfields?: IMasterField[]) => {
        // alte masterSource reaction freigeben
        this.clearMasterSource();
        //
        this.masterSource = masterSource;
        this.masterFields = masterfields;
        this.masterDispose = reaction(
            () => {
                // eslint-disable-next-line
                return [this.masterSource.actual, this.masterSource.state, this.masterSource.data];
            },
            this.setMasterFilter,
            {
                delay: 10,
                name: 'mastersource_changed' + this.dataUrl,
            },
        );
    };

    clearMasterSource = () => {
        if (this.masterDispose) {
            this.masterDispose();
            this.masterDispose = undefined;
        }
    };

    setLookupDs = (fieldName: string, ds: IDataset<any>) => {
        this.columns.find((column) => column.fieldName === fieldName).lookup.ds = ds;
    };

    /**
     * Hilfsroutine um vor dem POST leading und trailing spaces zu löschen.
     * ansonsten kann auf den Datensatz nicht mehr zugegriffen werden wenn den pk betroffen ist.
     */
    trimactual = () => {
        this.columns
            .filter((column) => typeof this.actual[column.fieldName] === 'string')
            .forEach((column) => {
                this.actual[column.fieldName] = (this.actual[column.fieldName] as any).trim();
            });
    };
}
