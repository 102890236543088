import { observer } from 'mobx-react';
import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';

@observer
class _StartdateRenderer extends React.Component<any, any> {
    render() {
        const { className, value } = this.props;
        return <span className={value ? className : ''}>{value ? moment(value).format('YYYY-MM-DD') : ''}</span>;
    }
}

export const StartdateRenderer = styled(_StartdateRenderer)`
    color: black;
    background-color: white;
    margin-top: 4px;
    padding: 1px 4px;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    line-height: 16px;
    text-align: center;
    text-overflow: ellipsis;
    overflow-x: hidden;
`;
