import * as React from 'react';
import { SidebarItem } from './SidebarItem';
import { INavRoute } from '../../../../routes/routes';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../routes/root-store';
import { SidebarDropdown } from './SidebarDropdown';
import { IBaseView } from '../../../_base/base-view';

export interface ISidebarEntry extends IBaseView {
    route: INavRoute;
    isOpen?: boolean;
    toggle?: () => void;
}

const SidebarEntry = (props: ISidebarEntry) => (props.route.children ? <SidebarDropdown route={props.route} /> : <SidebarItem route={props.route} />);

interface ISidebarMenu {
    rootStore?: IRootStore;
}

@inject('rootStore')
@observer
export class SidebarMenu extends React.Component<ISidebarMenu> {
    render() {
        const {
            rootStore: {
                authStore: { sidebarRoutes },
            },
        } = this.props;

        return (
            <div className="menu">
                {sidebarRoutes.map((route, i) =>
                    route.children ? (
                        <SidebarEntry key={i} route={route} />
                    ) : (
                        <ul key={i}>
                            <SidebarItem route={route} />
                        </ul>
                    ),
                )}
            </div>
        );
    }
}
