import { IField } from '../components/dataset/IField';

export interface IAuditQuestionImage {
    auditno: string;
    questionno: string;
    imageno: number;
    image: string;
}

export const auditQuestionImageColumns: IField<IAuditQuestionImage>[] = [
    {
        fieldName: 'auditno',
        dataType: 'string',
        label: 'AUDIT_AUDITNO',
        rules: ['pk'],
        primaryKey: true,
        insert: 'hide',
        hide: 'table',
    },
    {
        fieldName: 'questionno',
        label: 'AUDIT_QUESTIONNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        grid: {
            width: 60,
        },
        hide: 'table',
    },
    {
        fieldName: 'imageno',
        label: '',
        dataType: 'number',
        primaryKey: true,
        rules: ['pk'],
        grid: {
            width: 60,
        },
        hide: 'table',
    },
    {
        fieldName: 'image',
        dataType: 'string',
        label: 'IMAGE',
        insert: 'show',
    },
];
