/**
 * Created by windt on 6/3/16.
 */

import { ILang } from './ILang';

export const Lang_en: ILang = {
    '': '',
    HOME: 'Home',
    // Buttons
    OK: 'Ok',
    NOK: 'nOk',
    SAVE: ' Save',
    CANCEL: 'Cancel',
    YES: 'Yes',
    NO: 'No',
    PREDOMINATE: 'Predom.',
    NOT_APPLICABLE: 'Not applicable',
    N_A: 'n.a.',
    ADD: 'New',
    DELETE: 'Delete',
    EDIT: 'Edit',
    COPY: 'Copy',
    RENAME: 'Rename',
    FILTER: 'Filter',
    REFRESH: 'Refresh',
    BACK: 'Go Back',
    CLOSE: 'Close',
    // Diverse
    CREATED_BY: 'Created by',
    CREATED_DAT: 'Created at',
    DATE: 'Date',
    DETAIL: 'Detail',
    UPLOAD: 'Upload File',
    CAMERA: 'Camera',
    DOWNLOAD: 'Download File',
    ALL: '(All)',
    CREATED: 'Created',
    RESPONSIBLE: 'Responsible',
    LINK: 'Link',
    PROGRESS: 'Progress',
    EXPORT: 'Export',
    IMPORT: 'Import',
    SETTINGS: 'Settings',
    NEXT: 'Next',
    PREVIOUS: 'Previous',
    DOOPEN: 'Open',
    DOCLOSE: 'Close',
    HANDOVER: 'Transfer Ownership',
    INFO: 'Information',
    NOT_POSSIBLE_TO_DELETE: 'Not possible to delete!',
    // Language
    LANGUAGE: 'Language',
    GERMAN: 'German',
    ENGLISH: 'English',
    //
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    LOGIN_ERROR: 'Login Error',
    LOGIN_INVALID_CREDENTIALS: 'Invalid login credentials. Please try again.',
    //
    DATASELECT: 'Select Data',
    EVALUATION: 'Evaluation',
    REPORT: 'Report',
    Controlchart_settings: 'Control chart settings',
    ControlLimits: 'Control limits',
    ClassLimits: 'Class limits',
    Calc_Controllimits: 'Calc Limits',
    Calc_Classlimits: 'Calc Limits',
    //div
    Unknown: 'Unknown',
    IMAGE: 'Attachment',
    IMAGES: 'Attachments',

    N: 'n',
    M: 'm',
    Ntotal: 'n(total)',
    Min: 'Min',
    Max: 'Max',
    Range: 'Range',
    Xbar: 'μ',
    Xb_3sigma: '+3σ',
    Xb__3sigma: '-3σ',
    SDEV: 's',

    Calculate: 'Calculate',
    Capability: 'Capability',
    Histogram: 'Histogram',
    ProbabilityNet: 'Probability Net',
    StatisticalResult: 'Statistical Results',
    ControlChart: 'Control Chart',
    Description1: 'Description 1',
    Description2: 'Description 2',
    TabTable: 'Table',
    TabDetail: 'Details',
    BaseData: 'Base Data',
    DELETE_ENTRY: 'Delete entry?',
    REALLY_DELETE_ENTRY: 'Really delete entry?',
    CONFIRMATION: 'Confirmation',

    // Sidebar Divider
    CONFIGURATION: 'Configuration',
    LOGBOOK: 'Logbook',
    ADMINISTRATION: 'Administration',
    BASEDATA: 'Base Data',
    PLANING: 'Planning',
    ORDER: 'Order',
    STATISTIC: 'Evaluation',

    Sigma_estimated: 'Simga(est)',
    // Chart-Type
    CHARTTYPE_XBS: 'Xb / S',
    CHARTTYPE_XBR: 'Xb / R',
    CHARTTYPE_MXMS: 'Moving X / Moving S',
    CHARTTYPE_XMS: 'X / Moving S',
    CHARTTYPE_MXMR: 'Moving X / Moving R',
    CHARTTYPE_XMR: 'X / Moving R',
    // Distribution Models
    DIST_MODEL: 'Distribution model',
    DIST_NORMAL: 'Normal',
    DIST_LOGNORMAL: 'Log-normal',
    DIST_WEIBULL: 'Weibull',
    DIST_FOLDED: 'Folded Normal',
    DIST_RAYLEIGH: 'Raleigh',
    // Char Type
    CHARTYPE_QUANTITATIVE: 'Quantitative',
    CHARTYPE_QUALITATIVE: 'Qualitative',
    // Config1
    CONFIG_EMAIL_NOTIFICATION: 'Email Notifications',
    CONFIG_EMAIL_NEW_ACTION: 'New Task',
    CONFIG_EMAIL_NEW_PPAP: 'New PPAP',
    CONFIG_EMAIL_NEW_PROJECT: 'New Project',
    CONFIG_EMAIL_NEW_ACTIVITY: 'New Activity',
    CONFIG_EMAIL_NEW_AUDIT: ' New Audit',
    CONFIG_EMAIL_NEW_INSPECTION: 'New Inspection',
    CONFIG_EMAIL_NEW_GEMBA: 'New Gemba',
    CONFIG_PPAP_FAMILY: 'PPAP Family',
    CONFIG_PPAP_FAMILY_PART_FIELD: ' Part Field',
    CONFIG_PPAP_FAMILY_PPAP_INDIVIDUAL: 'PPAP Individual',
    // Customer
    CUSTOMER: 'Customer',
    CUSTOMERS: 'Customers',
    CUSTOMER_CUSTOMERNO: 'Customer No',
    CUSTOMER_CUSTOMER1: 'Customer',
    CUSTOMER_CUSTOMER2: 'Company',
    CUSTOMER_DESCRIPTION1: 'Description 1',
    CUSTOMER_DESCRIPTION2: 'Description 2',
    CUSTOMER_STREET: 'Street',
    CUSTOMER_POSTCODE: 'Postcode',
    CUSTOMER_CITY: 'City',
    CUSTOMER_COUNTRY: 'Country',
    //Customer Contact
    CUSTOMERCONTACT_PERSON: 'Contact Person',
    CUSTOMERCONTACT_PERSONS: 'Contact Persons',
    CUSTOMERCONTACT_CONTACTNO: 'Contact No',
    CUSTOMERCONTACT_CONTACTNAME: 'Name',
    CUSTOMERCONTACT_POSITION: 'Position',
    CUSTOMERCONTACT_EMAIL: 'Email',
    CUSTOMERCONTACT_PHONE1: 'Phone 1',
    CUSTOMERCONTACT_PHONE2: 'Phone 2',
    CUSTOMERCONTACT_TELEFAX: 'Fax',
    // Datacollection
    DATACOLLECT: 'Data Collection',
    // Orderstatus
    OSTATUS_NEW: 'New',
    OSTATUS_PROBE_ARRIVED: 'Probes arrived',
    OSTATUS_PROBE_READY: 'Probes ready',
    OSTATUS_IN_TREATMENT: 'In Treatment',
    OSTATUS_MEASURING_READY: 'Measuring ready',
    OSTATUS_IN_MEASURING: 'In Mesuring',
    OSTATUS_MEASURING_FINISHED: 'Measuring finished',
    OSTATUS_VALIDATED: 'Validated',
    OSTATUS_DECISION_MADE: 'Decision made',
    OSTATUS_UPLOADED: 'Uploaded',
    // Order
    ORDER_ORDERNO: 'Order No',
    ORDER_STATUS: 'State',
    ORDER_DATACOLLECT: 'Measure',
    ORDER_DECISION: 'Decision',
    //
    OPROCESS: 'Process',
    OPCHAR: 'Characteristic',
    OPSAMPLES: 'Samples',
    //
    SAMPLE_SAMPLENO: 'Sample No',
    // Plantyp
    PLANTYP_INCOMING: 'Incoming',
    PLANTYP_SPC: 'SPC',
    PLANTYP_OUTGOING: 'Outgoing',
    PLANTYP_LABORATORY: 'Laboratory',
    // Planstatus
    PSTATUS_NEW: 'New',
    PSTATUS_EDITING: 'Editing',
    PSTATUS_PRELIM: 'Prelimary Released',
    PSTATUS_RELEASED: 'Released',
    PSTATUS_LOCKED: 'Locked',
    // Plan
    PLAN: 'Inspection Plan',
    PLANS: 'Plans',
    PLAN_PLANNO: 'Plan No',
    PLAN_VERSION: 'Version',
    PLAN_STATE: 'State',
    PLAN_DESCRIPTION1: 'Description 1',
    PLAN_DESCRIPTION2: 'Description 2',
    PLAN_PLANTYP: 'Plan dataType',
    PLAN_GROUP: 'Plan group',
    PLAN_HISTORY: 'History',
    PLAN_ORDERS: 'Orders',
    PLAN_RELEASEDBY: 'Released by',
    PLAN_RELEASEDAT: 'Released at',
    PLAN_COMMENT: 'Comment',
    // PlanHisto
    PLANHISTO: 'History',
    //PProcess
    PPROCESS: 'Process',
    PPROCESSES: 'Processes',
    PPROCESS_PROCESSES: 'Processes',
    PPROCESS_PROCESSNO: 'Process No',
    PPROCESS_PROCESS: 'Process',
    PPROCESS_N: 'n',
    PPROCESS_MTARGET: 'm(target)',
    PPROCESS_MACTUAL: 'm(actual)',
    //PPChar
    PPCHAR: 'Characteristic',
    PPCHARS: 'Characteristics',
    PPCHAR_CHARNO: 'Characteristic No',
    PPCHAR_CHARACTERISTIC: 'Characteristic',
    PPCHAR_CHARID: 'Char ID',
    PPCHAR_TYPE: 'Char Type',
    PPCHAR_UNITS: 'Units',
    PPCHAR_USL: 'USL',
    PPCHAR_LSL: 'LSL',
    PPCHAR_NOMINAL: 'Nominal',
    PPCHAR_DECIMALS: 'Decimals',
    PPCHAR_UCLX: 'UCLx',
    PPCHAR_LCLX: 'LCLx',
    PPCHAR_UCLS: 'UCLs',
    PPCHAR_LCLS: 'LCLs',
    PPCHAR_UCLR: 'UCLr',
    PPCHAR_LCLR: 'LCLr',
    PPCHAR_DISTMODEL: 'Distribution model',
    PPCHAR_AMM: 'AMM',
    PPCHAR_CLASSLIMIT: 'Class limit',
    PPCHAR_CLASSWIDTH: 'Class width',
    PPCHAR_TESTEQUIPMENT: 'Test Equipment',
    PPCHAR_X1: 'X1',
    PPCHAR_X2: 'X2',
    PPCHAR_X3: 'X3',
    PPCHAR_X4: 'X4',
    PPCHAR_X5: 'X5',
    PPCHAR_X6: 'X6',
    PPCHAR_X7: 'X7',
    PPCHAR_X8: 'X8',
    PPCHAR_X9: 'X9',
    PPCHAR_X10: 'X10',
    // IProduct
    PRODUCT: 'Part',
    PRODUCTS: 'Parts',
    PRODUCT_PRODUCTS: 'Parts',
    PRODUCT_PRODUCTNO: 'Part No',
    PRODUCT_DESCRIPTION1: 'Part Name',
    PRODUCT_DESCRIPTION2: 'Description 2',
    PRODUCT_PRODUCTGROUP: 'Product Group',
    PRODUCT_DRAWINGNO: 'Drawing No',
    PRODUCT_SPEZIFICATION: 'Technical Specification (TPS/ZPS)',
    PRODUCT_PPAP_FAMILY: 'PPAP Family',
    PRODUCT_WEIGHT: 'Weight',
    PRODUCT_REVISION: 'Revision',
    PRODUCT_REVISIONDATE: 'Revision date',
    // Statmethod
    STATMETHOD: 'Stat. Method',
    STATMETHOD_METHOD: 'Method',
    STATMETHOD_DESCRIPTION1: 'Description 1',
    STATMETHOD_CHARTTYPE: 'Chart dataType (N>1)',
    STATMETHOD_CHARTTYPEN1: 'Chart dataType (N=1)',
    STATMETHOD_MAFACTOR: 'Moving average factor',
    STATMETHOD_SHOWSPECLIMITS: 'Show Specification Limits',
    STATMETHOD_SHOWRAWVALUES: 'Show Raw Values',
    STATMETHOD_SHOWRCHART: 'Show Chart for S',
    STATMETHOD_SIGMA: 'Sigma Estimator',
    // Sigma Estimators
    SIGMA_SBC4: 'Sb/c4',
    SIGMA_RBD2: 'Rb/d2',
    SIGMA_SB2: 'Sb2',
    SIGMA_S: 'S',
    // Supplier
    SUPPLIER: 'Supplier',
    SUPPLIERS: 'Suppliers',
    SUPPLIER_SUPPLIERNO: 'Supplier No',
    SUPPLIER_SUPPLIER1: 'Supplier',
    SUPPLIER_SUPPLIER2: 'Company',
    SUPPLIER_DESCRIPTION1: 'Description 1',
    SUPPLIER_DESCRIPTION2: 'Description 2',
    SUPPLIER_CODE: 'Supplier Code',
    // SupplierContact
    SUPPLIERCONTACT_PERSON: 'Contact Person',
    SUPPLIERCONTACT_PERSONS: 'Contact Persons',
    SUPPLIERCONTACT_CONTACTNO: 'Contact No',
    SUPPLIERCONTACT_CONTACTNAME: 'Name',
    SUPPLIERCONTACT_POSITION: 'Position',
    SUPPLIERCONTACT_EMAIL: 'Email',
    SUPPLIERCONTACT_PHONE1: 'Phone 1',
    SUPPLIERCONTACT_PHONE2: 'Phone 2',
    SUPPLIERCONTACT_TELEFAX: 'Fax',

    // User
    USER: 'User',
    USERS: 'Users',
    USER_USERNAME: 'Username',
    USER_EMAIL: 'Email',
    USER_FIRSTNAME: 'Firstname',
    USER_LASTNAME: 'Lastname',
    USER_INITIALS: 'Initials',
    USER_USERGROUP: 'Usergroup',
    USER_NATIONALITY: 'Nationality',
    USER_PICTURE: 'Picture',
    USER_PASSWORD: 'Password',
    USER_CURRENT_PASSWORD: 'Current Password',
    USER_NEW_PASSWORD: 'New Password',
    USER_CONFIRMATION_PASSWORD: 'Confirmation Password',
    USER_CHANGE_PASSWORD: 'Change Password',
    USER_CHANGE_PASSWORD_NOTE:
        'After a successful password update, you will be redirected to the login page where\n' + 'you can log in with your new password.',
    USER_ISACTIVE: 'Active',
    USER_TELEFON1: 'Telefon 1',
    USER_TELEFON2: 'Telefon 2',
    USER_FUNCTION: 'Function',
    // Usergroup
    USERGROUP: 'User Group',
    USERGROUPS: 'User Groups',
    USERGROUP_USERGROUPNO: 'Usergroup',
    USERGROUP_DESCRIPTION1: 'Description 1',
    USERGROUP_DESCRIPTION2: 'Description 2',
    //UsergroupRight
    USERGROUPRIGHTS: 'Permissions',
    USERGROUPRIGHT_RIGHTNO: 'Permission No',
    USERGROUPRIGHT_ENABLED: 'Active',
    //Workflow
    WORKFLOW: 'Workflow',
    WORKFLOWS: 'Workflows',
    WORKFLOW_WORKFLOW: 'Workflow',
    WORKFLOW_DESCRIPTION1: 'Description 1',
    WORKFLOW_TYP: 'Type',
    WORKFLOW_TYP_DATACOLLECT: 'Data collection',
    // Decisioncatalog
    CATALOG: 'Catalog',
    CATALOGS: 'Catalogs',
    CATALOG_ENTRIES: 'Catalog Entries',
    CATALOG_ENTRY: 'Catalog Entry',
    DESCCAT_DECISIONCATALOG: 'Decision Catalog',
    DESCAT_CATALOGNO: 'Catalog No',
    DESCAT_TYPE: 'Type',
    DESCAT_DESCRIPTON1: 'Description',
    DESCAT_DETAILS: 'Catalog Entries',
    DESCATD_ENTRYNO: 'Entry No',
    DESCAT_TYPE_DECISION: 'Order Decision',
    DESCAT_TYPE_AUDIT: 'Audit',
    DESCAT_TYPE_APQP_PHASE: 'APQP Phase',
    DESCAT_RATING: 'Rating',
    //AG-GRID
    GRID_noRowsToShow: 'empty Data',
    AUTOSIZE_COLUMNS: 'Autosize Columns',
    // Api status codes
    STATUS_OK: 'OK',
    STATUS_ENTRY_ALREADY_EXISTS: 'Entry already exists.',
    STATUS_PRIMARY_KEY_FIELD_EMPTY: 'Primary key fieldName cannot be empty.',
    STATUS_FIELD_EMPTY: 'Field must have a value.',

    CAPABILITY_REPORT: 'Capability Report',
    CAPABILITY_BARS: 'Capability Bars',

    // Error messages
    ERRORMESSAGE: 'Error Message',
    DELETE_RELEASED_PLAN_NOT_ALLOWED: 'Cannot delete a Plan with status "Released".',
    ACTION_NOT_POSSIBLE: 'Action not possible.',

    TEST: 'Test',
    AUDIT: 'Audit',
    AUDITS: 'Audits',
    AUDIT_AUDITNO: 'Auditno',
    AUDIT_REF_AUDITNO: 'Reference Auditno',
    AUDIT_AUDIT: 'Audit',
    AUDIT_LEADAUDITOR: 'Lead Auditor',
    AUDIT_COAUDITOR: 'Co-Auditor',
    AUDIT_STATUS: 'Status',
    AUDIT_TYP: 'Typ',
    AUDIT_STARTDATE: 'Audit starts',
    AUDIT_ENDDATE: 'Audit ends',
    AUDIT_COMMENT: 'Summary',
    AUDIT_SUPPLIERNO: 'Supplierno',
    AUDIT_PLANT: 'Plant',
    //AUDIT_AUDITORS: 'Auditors',
    AUDIT_PARTICIPANTS: 'Participants',

    AUDIT_QUESTIONNO: 'Question No',
    AUDIT_QUESTION: 'Question',
    AUDIT_PART: 'Part',
    AUDIT_ELEMENTS: 'Elements',
    AUDIT_ELEMENT: 'Element',
    AUDIT_SUBELEMENT: 'Subelement',
    AUDIT_REQUIREMENTS: 'Requirements',
    AUDIT_TARGETPOINTS: 'Target',
    AUDIT_ACTUALPOINTS: 'Actual',
    AUDIT_NO_COMPLIANCE: '0 - No compliance with requirements',
    AUDIT_UNSATISFACTORY_COMPLIANCE: '4 - Unsatisfactory compliance with requirements, major nonconformaties',
    AUDIT_PARTIAL_COMPLIANCE: '6 - Partial compliance with requirements; more severe nonconformities',
    AUDIT_PREDOMINANT_COMPLIANCE: '8 - Predominant compliance with requirements; minor nonconformities',
    AUDIT_FULL_COMPLIANCE: '10 - Full compliance with requirements',
    AUDIT_UNRATED: 'unrated',
    AUDIT_0: '0',
    AUDIT_4: '4',
    AUDIT_6: '6',
    AUDIT_8: '8',
    AUDIT_10: '10',
    AUDIT_YES: 'Yes',
    AUDIT_NO: 'No',
    AUDIT_REMARKS: 'Results and Comments',
    AUDIT_EVIDENCE: 'Documents viewed',
    AUDIT_EDITEDBY: 'Edited by',
    AUDIT_EDITEDAT: 'Edited at',
    AUDIT_RATINGTYP: 'Rating',
    AUDIT_REASON: 'Reason',
    AUDIT_MANDATORY: 'Mandatory Question',
    AUDIT_ANSWER: 'Comment',
    AUDIT_REFERENCE: 'Reference',
    AUDIT_AREAS: 'Areas',
    AUDIT_AREA: 'Area',
    AUDIT_SCORE: 'Score',
    SYSTEMAUDIT: 'System Audit',
    PROCESSAUDIT: 'Process Audit',
    PRODUCTAUDIT: 'Product Audit',
    AUDIT_PROJECTNO: 'Projekt No',
    AUDIT_PROJECTNAME: 'Project Name',
    AUDIT_STATUS_NEW: 'New',
    AUDIT_STATUS_PLANNED: 'Planned',
    AUDIT_STATUS_PROCESS: 'In Process',
    AUDIT_STATUS_FINISHED: 'Finished',
    AUDIT_STATUS_COMPLETED: 'Completed',
    AUDIT_FILTER_BY_QUESTION: 'Filter by Question',
    AUDIT_QUESTION_EXTRA01: 'Findings',
    AUDIT_QUESTION_EXTRA02: 'Responsible',
    AUDIT_QUESTION_LEVEL01: 'Level 1',
    AUDIT_QUESTION_LEVEL02: 'Level 2',
    AUDIT_QUESTION_LEVEL03: 'Level 3',
    AUDIT_QUESTION_LEVEL04: 'Level 4',
    AUDIT_QUESTION_LEVEL05: 'Level 5',
    AUDIT_QUESTION_UNSELECTED: 'Unselected',
    AUDIT_QUESTION_ATTENTIONFLAG: 'Attention flag',
    AUDIT_QUESTION_CHAPTER: 'Chapter',
    AUDIT_QUESTION_WEIGHTING: 'Weighting',
    //
    AUDIT_TEMPLATES: 'Audit Templates',
    AUDIT_TEMPLATE: 'Template',
    AUDIT_TEMPLATE_ID: 'Template',
    AUDIT_TEMPLATE_PARENTID: 'parentid',
    AUDIT_TEMPLATE_TYP: 'Type',
    AUDIT_TEMPLATE_RATINGTYP: 'Rating',
    AUDIT_TEMPLATE_RATING_VDA_PROCESS: 'VDA Process',
    AUDIT_TEMPLATE_RATING_VDA_SYSTEM: 'VDA System',
    AUDIT_TEMPLATE_RATING_MATURITY: 'Maturity',
    AUDIT_TEMPLATE_TYP_AREA: 'Area',
    AUDIT_TEMPLATE_TYP_ELEMENT: 'Element',
    AUDIT_TEMPLATE_TYP_QUESTION: 'Question',
    AUDIT_TEMPLATE_TYP_SUBQUESTION: 'Subquestion',
    AUDIT_TEMPLATE_QUESTIONNO: 'Question No',
    AUDIT_TEMPLATE_QUESTION: 'Question',
    AUDIT_TEMPLATE_QUESTIONS: 'Audit questionnaire ',
    AUDIT_TEMPLATE_CHECKLIST: 'Check list',
    AUDIT_TEMPLATE_ELEMENT: 'Element',
    AUDIT_TEMPLATE_REQUIREMENTS: 'Requirements',
    AUDIT_TEMPLATE_RATING: 'Rating',
    AUDIT_TEMPLATE_RATINGMETHOD: 'Rating Schema',
    AUDIT_TEMPLATE_VDA61: 'VDA 6.1',
    AUDIT_TEMPLATE_BINARY: 'Binary',
    AUDIT_TEMPLATE_VDA63: ' VDA 6.3',
    AUDIT_TEMPLATE_VESTAS_SBA: ' Vestas SBA',
    //
    AUDIT_CHECKLIST_NO: 'No',
    AUDIT_CHECKLIST_KEYWORD: 'Keywords',
    AUDIT_CHECKLIST_FINDINGS: 'Findings',
    AUDIT_CHECKLIST_RATING: 'Rating',

    AUDIT_RATING_NONE: '',
    AUDIT_RATING_PKT4: '4',
    AUDIT_RATING_PKT6: '6',
    AUDIT_RATING_PKT8: '8',
    AUDIT_RATING_PKT10: '10',
    AUDIT_RATING_NA: 'n.a.',
    AUDIT_DEFINED_IN_QM: 'System', //'Completly defined in system',
    AUDIT_PROVEN_IN_PRACTICE: 'Praxis', //'Proven evidence shown in practice',
    //
    AUDIT_EVAL_OVERALL: 'Score',
    AUDIT_EVAL_AREA: 'Areas',
    //
    ACTION: 'Task',
    ACTIONS: 'Tasks',
    ACTION_NO: 'T-No',
    ACTION_TYPE: 'Task Type',
    ACTION_PROBLEM: 'Problem',
    ACTION_ACTION: 'Task',
    ACTION_STATUS: 'Status',
    ACTION_RESPONSIBLE: 'Responsible',
    ACTION_DURATION: 'Duration (Days)',
    ACTION_DUEDATE: 'Due Date',
    ACTION_CREATEDBY: 'Created by',
    ACTION_CREATEDAT: 'Created at',
    ACTION_COMPLETEDDAT: 'Completed at',
    ACTION_KEY1: 'Link',
    ACTION_KEY2: 'Element/Activity',
    ACTION_KEY3: '',
    ACTION_KEY4: '',
    ACTION_CONDUCTED: 'Conducted',
    ACTION_KEYWORD: 'Keyword',
    ACTION_ATTACHEMENTS: 'Attachments',
    ACTION_COMMENT: 'Comment',
    ACTION_OWNER: 'Task Owner',
    //
    TASK_STATUS_NEW: 'New',
    TASK_STATUS_INPROGRESS: 'In Progress',
    TASK_STATUS_COMPLETED: 'Completed',
    //
    PROJECTMANAGEMENT: 'Project Management',
    PROJECT: 'Project',
    PROJECTS: 'Projects',
    PROJECT_PROJECTNO: 'Project No',
    PROJECT_PROJECT: 'Project',
    PROJECT_PROJECTGROUP: 'Project Group',
    PROJECT_SUBGROUP01: 'Project Subgroup',
    PROJECT_DOCUMENTS: 'Attachments',
    PROJECT_OWNER: 'Project Owner',
    PROJECT_COMMENT: 'Comment',
    PROJECT_CUSTOMER_RESPONSIBLE: 'Customer Responsible',
    //PROJECT_SUPPLIER_CONTACT: 'Supplier Contact Person',
    PROJECT_STATUS: 'Status',
    PROJECT_START: 'Start Date',
    PROJECT_TERMIN: 'Due Date',
    PROJECT_EXTRAFIELD1: 'Extrafield 1',
    PROJECT_EXTRAFIELD2: 'Extrafield 2',
    PROJECT_EXTRAFIELD3: 'Extrafield 3',
    PROJECT_EXTRAFIELD4: 'Extrafield 4',
    PROJECT_ORIGIN: 'Copy Activities from:',
    PROJECT_MEMBER: 'Project Member',
    PROJECT_IMPORT_PROJECTMEMBER: 'Import Project Member',
    PROJECTTASK_TYP: 'Type',
    PROJECTTASKTYP_TASK: 'Activity',
    PROJECTTASKTYP_AUDIT: 'Audit',
    PROJECTTASKTYP_DEVELOPMENT: 'Development',
    PROJECTTASKTYP_PPAP: 'Part Qualification',
    PROJECTTASKTYP_INSPECTION: 'Inspection',
    PROJECTTASKTYP_GEMBAWALK: 'Gemba Walk',
    PROJECTTASK_TASKNO: 'A-No',
    PROJECTTASK_DESCRIPTION1: 'Description',
    PROJECTTASK_ACTIVITY: 'Activity',
    PROJECTTASK_ACTIVITIES: 'Activities',
    PROJECTTASK_PHASE: 'Phase',
    PROJECTTASK_STARTDATE: 'Start Date',
    PROJECTTASK_DUEDATE: 'Due Date',
    PROJECTTASK_RESPONSIBLE: 'Responsible',
    PROJECTTASK_LINK: 'Link',
    PROJECT_ACTIVITY_ADD: 'Activity New',
    PROJECT_ACTIVITY_DELETE: 'Activity Delete',
    PROJECT_ACTIVITY_CREATE_DEVELOPMENT: 'Create Development',
    PROJECTTASK_GANTT: 'Gantt Diagram',

    PROJECTDOCUMENT_DOCUMENTNO: 'D-No',
    PROJECTDOCUMENT_DOCUMENT: 'Document',

    PROJECTTEMPLATE: 'Project Template',
    PROJECTTEMPLATES: 'Project Templates',
    PROJECTTEMPLATE_TEMPLATENO: 'Template No',
    PROJECTTEMPLATE_TEMPLATE: 'Template',

    PPAP: 'Part Qualification',
    PPAP_LEVEL: 'Standards',
    PPAP_STANDARD: 'Standard',
    PPAP_REFERENCE: 'Reference Standard',
    //PPAP_DOCUMENTATION_MATRIX: 'PPAP Documentation Matrix',
    PPAP_RISKLEVEL: 'Risk Level',
    PPAP_RISKLEVEL_LOW: 'Low',
    PPAP_RISKLEVEL_MEDIUM: 'Medium',
    PPAP_RISKLEVEL_HIGH: 'High',
    PPAP_ORDER: 'PPAP',
    PPAP_ORDERS: 'PPAP',
    PPAP_ORDERNO: 'PQ No',
    PPAP_ELEMENTS: 'Elements',
    PPAP_ELEMENT: 'Element',
    PPAP_ELEMENTNO: 'El-No',
    PPAP_ELEMENT_REFERENCE: 'Reference',
    PPAP_ELEMENT_TEMPLATE: 'Template File',
    PPAP_ELEMENT_REQUIREMENTS: 'Requirements',
    PPAP_STATUS: 'State',
    PPAP_DOCUMENTATION: 'Documentation',
    PPAP_SUBMISSION: 'Submission',
    PPAP_SUBMIT: 'Submit',
    PPAP_RETAIN: 'Retain',
    PPAP_SUBMIT_OR_RETAIN: '*',
    PPAP_MANDATORY: 'Mandatory',
    PPAP_OPTIONAL: 'Optional',
    PPAP_OWNER: 'Responsible',
    PPAP_FILTER_MANDATORY: 'Only Mandatory Elements',
    PPAP_FILTER_SUBMIT: 'Only Submit Elements',
    PPAP_FILTER_ELEMENT: 'Filter by Element',
    PPAP_VDA: 'VDA',
    PPAP_AIAG: 'AIAG',
    PPAP_APQP4WIND: 'APQP4Wind',
    PPAP_PHASE: 'Phase',
    PPAP_RESPONSIBLE_SUPPLIER: 'Supplier Responsible',
    PPAP_BLOG: 'Blog',
    PPAP_COMMENTNO: 'No',
    PPAP_DOCUMENT: 'Document',
    PPAP_UPLOADS: 'Uploads',
    PPAP_EXTRADOCS: 'Attachments',
    PPAP_SUBMISSION_DATE: 'PSW Submission Date',
    PPAP_REVISION: 'PPAP Rev',
    PPAP_PURCHASENO: 'PPAP Purchase Order',
    PPAP_ECLREVISION: 'Engineering Change Level',
    PPAP_ECLDATE: 'Engineering Change Level (Date)',
    PPAP_RESPONSIBLE_CUSTOMER: 'Customer Responsible',
    PPAP_PPAP4WIND_DOCUMENTATION: 'PPAP4Wind Documentation',
    PPAP_GENERAL_INFO: 'General Info',
    PPAP_PRODUCT_INFO: 'Product Information',
    PPAP_PPAP_INFO: 'PPAP Information',
    PPAP_CUSTOMER_INFO: 'Customer Information',
    PPAP_SUPPLIER_INFO: 'Supplier Information',
    PPAP_SHEET_INFO: 'Sheet specific Information',
    PPAP_TEMPLATE: 'Form',
    PPAP_PRODUCT_QUALITY_PLAN: 'Product Quality Plan',

    //
    STATUS_PLANNED: 'Planned',
    STATUS_INPROGRESS: 'In Progress',
    STATUS_COMPLETED: 'Completed',
    STATUS_NOT_APPROVED: 'Not Approved',
    STATUS_COND_APPROVED: 'Cond. Approved',
    STATUS_APPROVED: 'Approved',
    //
    PPAPDOCUMENT_DOCUMENTNO: 'No',
    PPAPDOCUMENT_DOCUMENT: 'Document',
    //
    QUALITY_PLANING: 'Quality Planing',
    APQP: 'APQP',
    APQP_STANDARDS: 'APQP Standards',
    APQP_STANDARD: 'APQP Standard',
    APQP_MATRIX: 'APQP Matrix',
    APQP_ELEMENT: 'APQP Element',
    APQP_ELEMENTS: 'APQP Elements',

    ERROR_PRIMARY_KEY_CANNOT_BE_EMPTY: 'Mandatory Field',
    ERROR_MANDATORY_FIELD_CANNOT_BE_EMPTY: 'Mandatory Field',
    ERROR_INVALID_NUMERIC_VALUE: 'Numeric value expected',
    ERROR_INVALID_INTEGER_VALUE: 'Integer value expected',
    ERROR_VALUE_IS_NOT_EQUAL: 'Values is not equal',

    INSPECTIONS: 'Inspections',
    INSPECTION: ' Inspection',
    INSPECTION_PLANS: 'Inspection Plans',
    INSPECTION_PLAN: 'Inspection Plan',

    INSPECTION_PLAN_PLANNO: 'Planno',
    INSPECTION_PLAN_QUESTIONNO: 'Question No',
    INSPECTION_PLAN_QUESTIONS: 'Questionaire',
    INSPECTION_PLAN_AREA: 'Area',
    INSPECTION_PLAN_ELEMENT: 'Element',
    INSPECTION_PLAN_QUESTION: 'Question',
    INSPECTION_PLAN_MANDATORY: 'Mandatory',
    INSPECTION_INSPECTIONNO: 'Inspectionno',
    INSPECTION_INSPECTION: 'Inspection',
    INSPECTION_INSPECTOR: 'Inspector',

    GEMBAS: 'Gembas',
    GEMBA: 'Gemba',
    GEMBA_GEMBANO: 'Gemba No',
    GEMBA_GEMBA: 'Gemba',
    GEMBA_FINDINGS: 'Observations',
    GEMBA_FINDINGNO: 'No',
    GEMBA_FINDING: 'Observation',
    GEMBA_AREA: 'Location',
    GEMBA_ELEMENT: 'Gemba Element',
    GEMBA_TEMPLATES: 'Gemba Templates',
    GEMBA_TEMPLATE: 'Gemba Template',
    GEMBA_TEMPLATE_TEMPLATE: 'Template',
    GEMBA_TEMPLATE_DESCRIPTION: 'Description',
    GEMBA_TEMPLATE_AREA_CAT: 'Area Catalog',
    GEMBA_TEMPLATE_ELEMENT_CAT: 'Element Catalog',
    GEMBA_KEYWORD: 'Keyword',
    GEMBA_POSNEG: 'Pos/Neg',

    OFFLINE_ACTIVITIES: 'Offline Activities',

    MOBILE: 'Mobile',
    MOBILE_ACTIVITIES: 'Mobile Activities',

    FILTER_ALL: 'Filter All',
};
