import { inject, observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { IBaseView } from '../../base-view';

interface IDetailOneColLayout extends IBaseView {
    topElement?: JSX.Element;
    mainElement?: JSX.Element;
}

@inject('rootStore')
@observer
export class _DetailOneColLayout extends React.Component<IDetailOneColLayout, {}> {
    render() {
        const { className, topElement, mainElement } = this.props;
        return (
            <div className={className}>
                <div className="top-area">{topElement}</div>
                <div className="main-area">{mainElement}</div>
            </div>
        );
    }
}

export const DetailOneColLayout = styled(_DetailOneColLayout)`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    //
    display: flex;
    flex-direction: column;
    //
    color: black;
    background-color: ${(props) => props.theme.main.bgBody};
    overflow-x: hidden;
    overflow-y: auto;
    padding: ${(props) => props.theme.gutter};
    box-shadow: inset 4px 4px 4px -4px rgba(0, 0, 0, 0.25);

    .top-area {
        display: block;
        flex: 0 0 auto;
    }

    .main-area {
        display: flex;
        flex-direction: row;
        height: 30%;
        flex: 1 1 auto;
        padding-top: ${(props) => props.theme.gutter};
    }
`;
