import { IField } from '../components/dataset/IField';
import { userColumns } from './User';

export interface IProjectMember {
    projectno: string;
    username: string;
    customerno: string;
    supplierno: string;
    customer1: string;
    supplier1: string;
    funktion: string;
    email: string;
}

export const projectMemberColumns: IField<IProjectMember>[] = [
    {
        fieldName: 'projectno',
        label: 'PROJECT_PROJECTNO',
        dataType: 'string',
        primaryKey: true,
        hide: 'table',
        insert: 'hide',
        rules: ['pk'],
    },
    {
        fieldName: 'username',
        dataType: 'string',
        label: 'USER_USERNAME',
        primaryKey: true,
        rules: ['pk'],
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/user/',
            columns: userColumns,
            field: 'username',
            //InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        },
    },
    {
        fieldName: 'supplierno',
        dataType: 'string',

        hide: 'always',
    },
    {
        fieldName: 'customerno',
        dataType: 'string',
        hide: 'always',
    },
    {
        fieldName: 'supplier1',
        dataType: 'string',
        label: 'SUPPLIER_SUPPLIER1',
    },
    {
        fieldName: 'customer1',
        dataType: 'string',
        label: 'CUSTOMER_CUSTOMER1',
    },
    {
        fieldName: 'funktion',
        dataType: 'string',
        label: 'USER_FUNCTION',
    },
    {
        fieldName: 'email',
        dataType: 'string',
        label: 'USER_EMAIL',
    },
];
