import { IField } from '../components/dataset/IField';

export interface IPpapDetail05 {
    ppapno: string;
    custappname: string;
    custappfunction: string;
    custappcompany: string;
    supappname: string;
    supappfunction: string;
    supappcompany: string;
    suppartno: string;
    suppartname: string;
    comment: string;
}

export const ppapDetail05Columns: IField<IPpapDetail05>[] = [
    {
        fieldName: 'ppapno',
        dataType: 'string',
        label: 'PPAP_ORDERNO',
        primaryKey: true,
        rules: ['pk', 'mandatory'],
        insert: 'hide',
    },
];
