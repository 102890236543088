import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../_base/base-view';
import { StringMap } from 'mobx-state-router';
import { TableForm } from '../../_base/styled/table-form';
import { TableGrid } from '../../_base/styled/table-grid';
import { DetailOneColLayout } from '../../_base/styled/layout/detail-one-col-layout';

@inject('rootStore')
@observer
export class CatalogDetailView extends React.Component<IBaseView, {}> {
    goToEntry = () => {
        const {
            rootStore: {
                routerStore,
                catalogStore: { dsDetail },
            },
        } = this.props;
        routerStore.goTo('catalogentry', dsDetail.pkValues as StringMap);
    };

    render() {
        const {
            rootStore: {
                catalogStore: { ds, dsDetail },
            },
        } = this.props;
        return (
            <DetailOneColLayout
                topElement={<TableForm icon="database" title="CATALOG" ds={ds} />}
                mainElement={<TableGrid icon="database" id="projects" title="CATALOG_ENTRIES" ds={dsDetail} onRowDoubleClicked={this.goToEntry} />}
            />
        );
    }
}
