import * as React from 'react';
import { IBaseView } from '../../../_base/base-view';
import { inject, observer } from 'mobx-react';
import ReactEcharts from 'echarts-for-react';
import { action } from 'mobx';
import { cl_blue } from '../../../_theme/colors';

export interface IBarValue {
    ppapno: string;
    label: string;
    value: number;
    color: string;
}

interface IBarChart extends IBaseView {
    orientatation: 'vertical' | 'horizontal';
    data: IBarValue[];
    min?: number;
    max?: number;
}

@inject('rootStore')
@observer
export class DashboardPpapStatusChart extends React.Component<IBarChart, {}> {
    @action.bound
    onClick(params: any) {
        const {
            rootStore: { routerStore },
        } = this.props;
        if (params.componentType === 'series') {
            routerStore.goTo('ppaporder', { ppapno: params.name });
        }
    }

    getOption = () => {
        const { data, min, max, orientatation } = this.props;

        const categoryAxis = {
            type: 'category',
            data: data.map((row) => row.label),
            axisLabel: {
                fontSize: 10,
                fontWeight: 400,
                color: cl_blue,
                rotate: data.length > 3 ? 45 : 0,
                hideOverlap: false,
            },
        };

        const valueAxis = {
            type: 'value',
            min: min,
            max: max,
            axisLine: {
                show: true,
            },

            splitLine: {
                show: false,
            },
            axisLabel: {
                fontSize: 10,
                fontWeight: 400,
                color: cl_blue,
            },
            axisTick: {
                show: true,
            },
        };

        let rv: any = {
            grid: {
                containLabel: true,
                top: 16,
                bottom: 0,
                right: 10,
            },
            xAxis: orientatation === 'vertical' ? categoryAxis : valueAxis,
            yAxis: orientatation === 'vertical' ? valueAxis : categoryAxis,
            series: [
                {
                    data: data.map((row) => {
                        return {
                            name: row.ppapno,
                            value: Math.floor(row.value),
                            label: {
                                formatter: '{c} %',
                                show: true,
                                position: 'top',
                                fontSize: 10,
                                fontWeight: 400,
                            },
                            itemStyle: {
                                color: row.color,
                            },
                        };
                    }),
                    type: 'bar',
                    stack: 'total',
                    label: { show: true },
                },
                {
                    data: data.map((row) => {
                        return {
                            name: row.ppapno,
                            value: Math.floor(100 - row.value),
                            itemStyle: {
                                color: 'white',
                            },
                            emphasis: {
                                disabled: true,
                            },
                        };
                    }),
                    type: 'bar',
                    stack: 'total',
                    label: { show: false },
                },
            ],
        };
        return rv;
    };

    render() {
        return (
            <ReactEcharts
                notMerge={true}
                lazyUpdate={true}
                option={this.getOption()}
                style={{ height: '100%', width: '100%' }}
                opts={{ renderer: 'svg' }}
                onEvents={{ click: this.onClick }}
            />
        );
    }
}
