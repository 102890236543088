import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import { Status } from '../Status';
import { cl_red, cl_yellow } from '../../views/_theme/colors';

@observer
class _DuedateRenderer extends React.Component<any, any> {
    render() {
        const { className, value } = this.props;
        return <span className={value ? className : ''}>{value ? moment(value).format('YYYY-MM-DD') : ''}</span>;
    }
}

export const DuedateRenderer = styled(_DuedateRenderer)`
    color: ${(props) => {
        if (!props.value) {
            return 'black';
        } else if (
            moment(moment(props.value).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days') <= 0 &&
            props.data.status !== Status.COMPLETED &&
            props.data.status !== Status.APPROVED
        ) {
            return 'white';
        } else if (
            moment(moment(props.value).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days') < 7 &&
            moment(moment(props.value).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days') >= 1 &&
            props.data.status !== Status.COMPLETED &&
            props.data.status !== Status.APPROVED
        ) {
            return 'white';
        } else return 'black';
    }};
    background-color: ${(props) => {
        if (!props.value) {
            return 'white';
        } else if (
            moment(moment(props.value).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days') <= 0 &&
            props.data.status !== Status.COMPLETED &&
            props.data.status !== Status.APPROVED
        ) {
            return cl_red;
        } else if (
            moment(moment(props.value).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days') < 7 &&
            moment(moment(props.value).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days') &&
            props.data.status !== Status.COMPLETED &&
            props.data.status !== Status.APPROVED
        ) {
            return cl_yellow;
        } else return 'white';
    }};
    margin-top: 4px;
    padding: 1px 4px;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    line-height: 16px;
    text-align: center;
    text-overflow: ellipsis;
    overflow-x: hidden;
`;
