import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from './icon-button';
import { inject, observer } from 'mobx-react';
import { UncontrolledTooltip } from 'reactstrap';
import { IBaseView } from '../base-view';

interface ITableFormMenu extends IBaseView {
    open: boolean;
    toggle: () => void;
    extraMenu?: JSX.Element;
}

export const TableFormMenu = inject('rootStore')(
    observer(
        ({
            open,
            toggle,
            extraMenu,
            rootStore: {
                langStore: { lang },
            },
        }: ITableFormMenu) => (
            <>
                {extraMenu ?? null}
                <IconButton
                    id={'open-close'}
                    onClick={() => {
                        toggle();
                    }}
                >
                    <FontAwesomeIcon icon={open ? 'chevron-up' : 'chevron-down'} />
                </IconButton>
                <UncontrolledTooltip placement="bottom-end" target={'open-close'}>
                    {open ? lang.DOCLOSE : lang.DOOPEN}
                </UncontrolledTooltip>
            </>
        ),
    ),
);
