import React from 'react';
import { IBaseView } from '../../../_base/base-view';
import ReactEcharts from 'echarts-for-react';
import { ICatValue } from '../../../../components/graphic/bar-graph';
import { statusColor, statusText, statusTexttoStatus } from '../../../../models/Status';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { cl_blue } from '../../../_theme/colors';

interface IDashboradTaskStatus extends IBaseView {
    projectno: string;
    activityno: string;
    catValues: ICatValue[];
}

@inject('rootStore')
@observer
export class DashboardTaskStatus extends React.Component<IDashboradTaskStatus, {}> {
    @action.bound
    onClickSegment(projectno: string, activityno: string) {
        return (params: any) => {
            //console.log(params.name);
            const {
                rootStore: {
                    routerStore,
                    langStore: { lang },
                },
            } = this.props;
            if (params.componentType === 'series') {
                routerStore.goTo(
                    'projectactivityactiontable',
                    {
                        projectno: projectno,
                        activityno: activityno,
                    },
                    {
                        status: statusTexttoStatus(lang, params.name),
                    },
                );
            }
        };
    }

    getOption = (): echarts.EChartOption => {
        const {
            rootStore: {
                langStore: { lang },
            },
            catValues,
        } = this.props;
        //console.log(toJS(catValues));

        return {
            grid: {
                top: '0%',
                left: '50%',
                right: '13%',
                bottom: '0%',
                containLabel: false,
                width: 'auto',
                height: 'auto',
            },
            yAxis: {
                type: 'category',
                data: catValues.map((cv) => lang[statusText(parseInt(cv.category))]),
                axisLabel: {
                    fontSize: 8,
                    fontWeight: 400,
                    color: cl_blue,
                    margin: 4,
                    rotate: 0,
                },
            },
            xAxis: {
                type: 'value',
                show: false,
            },
            series: [
                {
                    data: catValues.map((cv) => {
                        return {
                            value: cv.value,
                            label: {
                                show: !!cv.value,
                                position: 'right',
                                fontSize: 7,
                                fontWeight: 400,
                                color: cl_blue,
                            },
                            itemStyle: {
                                color: statusColor(parseInt(cv.category)),
                            },
                        };
                    }),
                    type: 'bar',
                },
            ],
        };
    };

    render() {
        const { projectno, activityno } = this.props;
        return (
            <ReactEcharts
                notMerge={true}
                lazyUpdate={true}
                option={this.getOption()}
                style={{ height: '100%', width: '100%' }}
                opts={{ renderer: 'svg' }}
                onEvents={{ click: this.onClickSegment(projectno, activityno) }}
            />
        );
    }
}
