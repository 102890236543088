import { IField } from '../components/dataset/IField';

export interface ISupplier {
    supplierno: string;
    description1: string;
    description2: string;
    supplier1: string;
    supplier2: string;
    street: string;
    postcode: string;
    city: string;
    country: string;
    logo: string;
    icon: string;
    supplier_code: string;
}

export const supplierColumns: IField<ISupplier>[] = [
    {
        fieldName: 'supplierno',
        dataType: 'string',
        label: 'SUPPLIER_SUPPLIERNO',
        primaryKey: true,
        rules: ['pk'],
        grid: {
            width: 120,
        },
    },
    {
        fieldName: 'supplier_code',
        dataType: 'string',
        label: 'SUPPLIER_CODE',
        grid: {
            width: 120,
        },
    },
    {
        fieldName: 'supplier1',
        dataType: 'string',
        label: 'SUPPLIER_SUPPLIER1',
        insert: 'show',
        rules: ['mandatory'],
    },
    {
        fieldName: 'supplier2',
        dataType: 'string',
        label: 'SUPPLIER_SUPPLIER2',
    },
    {
        fieldName: 'street',
        dataType: 'string',
        label: 'CUSTOMER_STREET',
    },
    {
        fieldName: 'postcode',
        dataType: 'string',
        label: 'CUSTOMER_POSTCODE',
        grid: {
            width: 100,
        },
    },
    {
        fieldName: 'city',
        dataType: 'string',
        label: 'CUSTOMER_CITY',
    },
    {
        fieldName: 'country',
        dataType: 'string',
        label: 'CUSTOMER_COUNTRY',
    },
    {
        fieldName: 'description1',
        dataType: 'string',
        label: 'SUPPLIER_DESCRIPTION1',
    },
    {
        fieldName: 'description2',
        dataType: 'string',
        label: 'SUPPLIER_DESCRIPTION2',
    },
];
