import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../_base/base-view';
import { Col, Row } from 'reactstrap';
import { DbField } from '../../../components/dbform/db-field';
import { action } from 'mobx';
import { TableForm } from '../../_base/styled/table-form';
import { TableGrid } from '../../_base/styled/table-grid';
import { DetailOneColLayout } from '../../_base/styled/layout/detail-one-col-layout';

@inject('rootStore')
@observer
export class InspectionPlanDetailView extends React.Component<IBaseView, {}> {
    @action.bound
    goToQuestion() {
        const {
            rootStore: {
                routerStore,
                inspectionPlanStore: { dsQuestion },
            },
        } = this.props;
        routerStore.goTo('inspectionplanquestion', {
            planno: dsQuestion.actual.planno,
            questionno: dsQuestion.actual.questionno,
        });
    }

    render() {
        const {
            rootStore: {
                inspectionPlanStore: { ds, dsQuestion },
            },
        } = this.props;
        return (
            <DetailOneColLayout
                topElement={
                    <TableForm title="INSPECTION_PLAN" keyTitle={ds.actual?.planno} ds={ds} icon="clipboard-check">
                        <Row>
                            <Col md={6}>
                                <DbField ds={ds} field="planno" />
                            </Col>
                        </Row>
                    </TableForm>
                }
                mainElement={
                    <TableGrid
                        id="task"
                        icon="book-open"
                        title="INSPECTION_PLAN_QUESTIONS"
                        ds={dsQuestion}
                        height={490}
                        onRowDoubleClicked={this.goToQuestion}
                    />
                }
            />

            /* <Container fluid>
                 <Row style={{paddingTop: '1rem'}}>
                     <Col md={{size: 12}}>
                         <BaseCardForm title='INSPECTION_PLAN' ds={ds} height={100} showBackButton={true}>
                             <Row>
                                 <Col md={6}>
                                     <DbField ds={ds} field="planno"/>
                                 </Col>
                             </Row>
                         </BaseCardForm>
                     </Col>
                 </Row>
                 <Row style={{paddingTop: '1rem'}}>
                     <Col>
                         <BaseCardGrid
                             id="task"
                             title='INSPECTION_PLAN_QUESTIONS'
                             ds={dsQuestion}
                             height={490}
                             onRowDoubleClicked={this.goToQuestion}
                         />
                     </Col>
                 </Row>
             </Container>*/
        );
    }
}
