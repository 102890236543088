import React from 'react';
import { IBaseView } from '../_base/base-view';
import { Col, Container, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { historyAdapter } from '../_layout/app';

@inject('rootStore')
@observer
export class NoPermissionView extends React.Component<IBaseView, {}> {
    render() {
        return (
            <Container style={{ color: 'black', textAlign: 'center', width: '100%', height: '100%', position: 'relative' }}>
                <Row style={{ alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                    <Col>
                        <div className="rounded px-3 px-sm-4 py-3 py-sm-5">
                            <h1 style={{ color: 'black' }}>No Permission</h1>
                            Sorry, but you don't have the permission to access this.page
                            <br />
                            You can go back to the{' '}
                            <div
                                style={{ cursor: 'pointer', color: 'blue', display: 'inline' }}
                                onClick={() => {
                                    historyAdapter.goBack();
                                }}
                            >
                                previous page
                            </div>
                            .
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
