// Todo : Achtung nicht vollständig
export const imageMime = (filename: string): string => {
    const ext = filename.toLowerCase().split('.').pop();
    let mime: string = '';
    switch (ext) {
        case 'bmp':
            mime = 'image/bmp';
            break;
        case 'gif':
            mime = 'image/gif';
            break;
        case 'jpg':
        case 'jpeg':
            mime = 'image/jpeg';
            break;
        case 'png':
            mime = 'image/png';
            break;
        case 'svg':
            mime = 'image/svg+xml';
            break;
    }
    return mime;
};

export const acceptImageMime: string = 'image/bmp,image/gif,image/jpeg,image/png,image/svg+xml';
