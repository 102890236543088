import { IField } from '../components/dataset/IField';

export interface IInspectionPlan {
    planno: string;
}

export const inspectionPlanColumns: IField<IInspectionPlan>[] = [
    {
        fieldName: 'planno',
        dataType: 'string',
        label: 'INSPECTION_PLAN_PLANNO',
        primaryKey: true,
    },
];
