import * as React from 'react';
import { IBaseView } from '../_base/base-view';
import { inject, observer } from 'mobx-react';
import { DetailLayout } from '../_base/styled/layout/detail-layout';
import { TaskDetailForm } from './task-detail-form';
import { TableGrid } from '../_base/styled/table-grid';
import { MenuFileUpload } from '../_base/menu/menu-file-upload';
import { MenuDelete } from '../_base/menu/menu-delete';
import { Status } from '../../models/Status';

@inject('rootStore')
@observer
export class TaskDetailView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                actionStore: { ds, uploadFile, dsActionImage },
                authStore: { username },
            },
        } = this.props;
        return (
            <DetailLayout
                /*topElement={<TaskDetailForm/>}*/
                mainLeftElement={<TaskDetailForm />}
                mainRightElement={
                    <TableGrid
                        id="attachements"
                        title="PROJECT_DOCUMENTS"
                        icon="file"
                        ds={dsActionImage}
                        menu={
                            <div>
                                {(username === ds?.actual?.responsible && ds.actual?.status !== Status.APPROVED) ||
                                username === ds?.actual?.createdby ? (
                                    <>
                                        <MenuFileUpload id={'PROJECT_DOCUMENTS_btn-insert'} onDrop={uploadFile} />
                                        <MenuDelete id={'PROJECT_DOCUMENTS_btn-delete'} ds={dsActionImage} />
                                    </>
                                ) : null}
                            </div>
                        }
                    />
                }
            />
        );
    }
}
