import * as React from 'react';
import { MenuInsert } from '../menu/menu-insert';
import { MenuDelete } from '../menu/menu-delete';
import { MenuRefresh } from '../menu/menu-refresh';
import { MenuAutofit } from '../menu/menu-autofit';
import { observer } from 'mobx-react';
import { IDataset } from '../../../components/dataset/IDataset';

export interface IBaseCardGridMenu {
    children?: any;
    id: string;
    ds: IDataset<any>;
    autoFit?: () => void;
    disabled?: boolean;
    menuInsert?: JSX.Element;
    hideInsert?: boolean;
    hideDelete?: boolean;
}

export const BaseCardGridMenuStandard = observer((props: IBaseCardGridMenu) => {
    const { ds, autoFit, id, disabled, menuInsert, hideInsert, hideDelete } = props;

    return (
        <div className="grid-menu">
            {!hideInsert
                ? menuInsert ?? <MenuInsert id={id} ds={ds} disabled={disabled || (ds.masterSource ? ds.masterSource.cursor === undefined : false)} />
                : null}
            <br />
            {!hideDelete ? <MenuDelete id={id} ds={ds} disabled={disabled || ds.cursor === undefined} /> : null}
            <MenuRefresh id={id} ds={ds} />
            <MenuAutofit id={id} autoFit={autoFit} />
        </div>
    );
});
