import { IconName } from '@fortawesome/fontawesome-common-types';

export const fileIcon = (filename: string): IconName | '' => {
    const ext = filename.toLowerCase().split('.').pop();
    let icon: IconName | '' = '';
    switch (ext) {
        case 'mp4':
            icon = 'file-video';
            break;
        case 'doc':
        case 'docx':
            icon = 'file-word';
            break;
        case 'pdf':
            icon = 'file-pdf';
            break;
        case 'xls':
        case 'xlsx':
            icon = 'file-excel';
            break;
        case 'ppt':
        case 'pptx':
            icon = 'file-powerpoint';
            break;
    }

    return icon;
};
