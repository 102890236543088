import { IconName } from '@fortawesome/fontawesome-svg-core';
import { ILangKeys } from '../language/ILang';
import { RouterState, TransitionHook } from 'mobx-state-router';
import { checkForUserSignedIn, checkUserRight, onEnter, onExit } from './routes-default';
import { IRouteNames } from './route-names';
import { UsergroupRight } from '../models/rights/enum-usergrouprights';

export interface INavRoute {
    icon?: IconName;
    name: IRouteNames;
    label: ILangKeys;
    pattern: string;
    userRight?: number;
    beforeExit?: TransitionHook;
    beforeEnter?: TransitionHook;
    onExit?: TransitionHook;
    onEnter?: TransitionHook;
    children?: INavRoute[];
    isOpen?: boolean;
    selected?: boolean;
    isNavBar?: boolean;
}

/**
 * Masterlist of all routes.
 */
export const routes: INavRoute[] = [
    {
        name: '_auth',
        label: '',
        pattern: '',
        isNavBar: false,
        children: [
            {
                name: 'login',
                label: 'LOGIN',
                pattern: '/login',
                isNavBar: false,
                onEnter: async (fromState: RouterState, toState: RouterState, routerStore: any) => {
                    routerStore.rootStore.appStore.closeSidebar();
                    const cdsLogin = routerStore.rootStore.authStore.cdsLogin;
                    cdsLogin.insert();
                    cdsLogin.actual.username = '';
                    cdsLogin.actual.password = '';
                    await Promise.resolve();
                },
                onExit: async (fromState: RouterState, toState: RouterState, routerStore: any) => {
                    const cdsLogin = routerStore.rootStore.authStore.cdsLogin;
                    cdsLogin.close();
                    //routerStore.rootStore.appStore.openSidebar();
                    await Promise.resolve();
                },
            },
        ],
    },

    {
        name: 'home',
        label: 'HOME',
        userRight: UsergroupRight.UR_HOME,
        pattern: '/',
        icon: 'home',
        isNavBar: true,
        selected: true,
        beforeEnter: checkUserRight(UsergroupRight.UR_HOME),
        onEnter: onEnter('dashboardStore'),
        onExit: onExit('dashboardStore'),
    },
    {
        name: 'settings',
        label: 'SETTINGS',
        pattern: '/settings',
        beforeEnter: checkForUserSignedIn,
        onEnter: onEnter('authStore'),
        onExit: onExit('authStore'),
    },
    {
        name: '_configuration',
        label: 'CONFIGURATION',
        userRight: UsergroupRight.UR_CONF_CONFIGURATION,
        pattern: '',
        icon: 'cogs',
        isNavBar: true,

        children: [
            {
                name: 'configuration',
                label: 'CONFIGURATION',
                userRight: UsergroupRight.UR_CONF_CONFIGURATION,
                pattern: '/configuration',
                isNavBar: true,
                beforeEnter: checkUserRight(UsergroupRight.UR_CONF_CONFIGURATION),
                onEnter: onEnter('configStore'),
                onExit: onExit('configStore'),
            },
        ],
    },
    {
        name: '_administration',
        label: 'ADMINISTRATION',
        userRight: UsergroupRight.UR_ADMIN_SIDEBAR,
        pattern: '',
        icon: 'users',
        isNavBar: true,
        children: [
            {
                name: 'usergrouptable',
                label: 'USERGROUPS',
                userRight: UsergroupRight.UR_ADMIN_USERGROUP,
                pattern: '/usergroup',
                isNavBar: true,
                beforeEnter: checkUserRight(UsergroupRight.UR_ADMIN_USERGROUP),
                onEnter: onEnter('usergroupStore'),
                onExit: onExit('usergroupStore'),
            },
            {
                name: 'usergroup',
                label: 'USERGROUP',
                userRight: UsergroupRight.UR_ADMIN_USERGROUP,
                pattern: '/usergroup/:usergroupno',
                beforeEnter: checkUserRight(UsergroupRight.UR_ADMIN_USERGROUP),
                onEnter: onEnter('usergroupStore'),
                onExit: onExit('usergroupStore'),
            },
            {
                name: 'usertable',
                pattern: '/user',
                userRight: UsergroupRight.UR_ADMIN_USER,
                label: 'USERS',
                isNavBar: true,
                beforeEnter: checkUserRight(UsergroupRight.UR_ADMIN_USER),
                onEnter: onEnter('userStore'),
                onExit: onExit('userStore'),
            },
            {
                name: 'user',
                label: 'USER',
                userRight: UsergroupRight.UR_ADMIN_USER,
                pattern: '/user/:username',
                beforeEnter: checkUserRight(UsergroupRight.UR_ADMIN_USER),
                onEnter: onEnter('userStore'),
                onExit: onExit('userStore'),
            },
            {
                name: 'logbooktable',
                label: 'LOGBOOK',
                userRight: UsergroupRight.UR_ADMIN_LOGBOOK,
                pattern: '/logbook',
                isNavBar: true,
                beforeEnter: checkUserRight(UsergroupRight.UR_ADMIN_LOGBOOK),
                onEnter: onEnter('logbookStore'),
                onExit: onExit('logbookStore'),
            },
        ],
    },
    {
        name: '_basedata',
        label: 'BASEDATA',
        userRight: UsergroupRight.UR_BASE_SIDEBAR,
        pattern: '',
        icon: 'database',
        isNavBar: true,
        children: [
            {
                name: 'producttable',
                pattern: '/producttable',
                userRight: UsergroupRight.UR_BASE_PRODUCT,
                label: 'PRODUCTS',
                isNavBar: true,
                beforeEnter: checkUserRight(UsergroupRight.UR_BASE_PRODUCT),
                onEnter: onEnter('productStore'),
                onExit: onExit('productStore'),
            },
            {
                name: 'product',
                label: 'PRODUCT',
                userRight: UsergroupRight.UR_BASE_PRODUCT,
                pattern: '/product/:productno',
                beforeEnter: checkUserRight(UsergroupRight.UR_BASE_PRODUCT),
                onEnter: onEnter('productStore'),
                onExit: onExit('productStore'),
                isNavBar: false,
            },
            {
                name: 'customertable',
                label: 'CUSTOMERS',
                pattern: '/customertable',
                userRight: UsergroupRight.UR_BASE_CUSTOMER,
                isNavBar: true,
                beforeEnter: checkUserRight(UsergroupRight.UR_BASE_CUSTOMER),
                onEnter: onEnter('customerStore'),
                onExit: onExit('customerStore'),
            },
            {
                name: 'customer',
                label: 'CUSTOMER',
                userRight: UsergroupRight.UR_BASE_CUSTOMER,
                pattern: '/customer/:customerno',
                beforeEnter: checkUserRight(UsergroupRight.UR_BASE_CUSTOMER),
                onEnter: onEnter('customerStore'),
                onExit: onExit('customerStore'),
                isNavBar: false,
            },
            {
                name: 'suppliertable',
                label: 'SUPPLIERS',
                userRight: UsergroupRight.UR_BASE_SUPPLIER,
                pattern: '/supplier',
                beforeEnter: checkUserRight(UsergroupRight.UR_BASE_SUPPLIER),
                onEnter: onEnter('supplierStore'),
                onExit: onExit('supplierStore'),
                isNavBar: true,
            },
            {
                name: 'supplier',
                label: 'SUPPLIER',
                userRight: UsergroupRight.UR_BASE_SUPPLIER,
                pattern: '/supplier/:supplierno',
                beforeEnter: checkUserRight(UsergroupRight.UR_BASE_SUPPLIER),
                onEnter: onEnter('supplierStore'),
                onExit: onExit('supplierStore'),
                isNavBar: false,
            },
            {
                name: 'catalogtable',
                pattern: '/catalog',
                userRight: UsergroupRight.UR_BASE_CATALOG,
                label: 'CATALOGS',
                beforeEnter: checkUserRight(UsergroupRight.UR_BASE_CATALOG),
                onEnter: onEnter('catalogStore'),
                onExit: onExit('catalogStore'),
                isNavBar: true,
            },
            {
                name: 'catalog',
                pattern: '/catalog/:catalogno',
                userRight: UsergroupRight.UR_BASE_CATALOG,
                label: 'CATALOG',
                beforeEnter: checkUserRight(UsergroupRight.UR_BASE_CATALOG),
                onEnter: onEnter('catalogStore'),
                onExit: onExit('catalogStore'),
                isNavBar: false,
            },
            {
                name: 'catalogentry',
                pattern: '/catalogentry/:catalogno/:entryno',
                userRight: UsergroupRight.UR_BASE_CATALOG,
                label: '',
                beforeEnter: checkUserRight(UsergroupRight.UR_BASE_CATALOG),
                onEnter: onEnter('catalogStore'),
                onExit: onExit('catalogStore'),
                isNavBar: false,
            },
        ],
    },

    {
        name: '_project',
        label: 'PROJECTMANAGEMENT',
        userRight: UsergroupRight.UR_PROJECT_SIDEBAR,
        pattern: '',
        icon: 'project-diagram',
        isNavBar: true,
        children: [
            {
                name: 'projecttable',
                label: 'PROJECTS',
                userRight: UsergroupRight.UR_PROJECT_PROJECT,
                pattern: '/project',
                isNavBar: true,
                beforeEnter: checkUserRight(UsergroupRight.UR_PROJECT_PROJECT),
                onEnter: onEnter('projectStore'),
                onExit: onExit('projectStore'),
            },
            {
                name: 'project',
                label: 'PROJECT',
                userRight: UsergroupRight.UR_PROJECT_PROJECT,
                pattern: '/project/:projectno',
                beforeEnter: checkUserRight(UsergroupRight.UR_PROJECT_PROJECT, 'projectStore'),
                onEnter: onEnter('projectStore'),
                onExit: onExit('projectStore'),
            },
            {
                name: 'projectactivity',
                label: 'PROJECTTASK_ACTIVITY',
                userRight: UsergroupRight.UR_PROJECT_PROJECT,
                pattern: '/projectactivity/:projectno/:activityno',
                beforeEnter: checkUserRight(UsergroupRight.UR_PROJECT_PROJECT, 'projectStore'),
                onEnter: onEnter('projectStore'),
                onExit: onExit('projectStore'),
                isNavBar: false,
            },
            {
                name: 'projectactivityactiontable',
                label: 'PROJECTTASK_ACTIVITY',
                userRight: UsergroupRight.UR_PROJECT_PROJECT,
                pattern: '/projectactivityaction/:projectno/:activityno',
                beforeEnter: checkUserRight(UsergroupRight.UR_PROJECT_PROJECT, 'projectActionStore'),
                onEnter: onEnter('projectActionStore'),
                onExit: onExit('projectActionStore'),
                isNavBar: false,
            },
        ],
    },
    {
        name: '_ppap',
        label: 'PPAP',
        userRight: UsergroupRight.UR_PPAP_SIDEBAR,
        pattern: '',
        icon: 'box-check',
        isNavBar: true,
        children: [
            {
                name: 'ppapstandardtable',
                label: 'PPAP_LEVEL',
                userRight: UsergroupRight.UR_PPAP_TEMPLATE,
                pattern: '/ppapstandard',
                beforeEnter: checkForUserSignedIn,
                onEnter: onEnter('ppapStandardStore'),
                onExit: onExit('ppapStandardStore'),
                isNavBar: true,
            },
            {
                name: 'ppapstandard',
                label: 'PPAP_STANDARD',
                userRight: UsergroupRight.UR_PPAP_TEMPLATE,
                pattern: '/ppapstandard/:standardno',
                beforeEnter: checkForUserSignedIn,
                onEnter: onEnter('ppapStandardStore'),
                onExit: onExit('ppapStandardStore'),
            },
            {
                name: 'ppapstandardelement',
                userRight: UsergroupRight.UR_PPAP_TEMPLATE,
                pattern: '/ppapstandardelement/:standardno/:elementno',
                label: 'PPAP_ELEMENT',
                beforeEnter: checkForUserSignedIn,
                onEnter: onEnter('ppapStandardStore'),
                onExit: onExit('ppapStandardStore'),
            },
            {
                name: 'ppapordertable',
                label: 'PPAP_ORDERS',
                userRight: UsergroupRight.UR_PPAP_PPAP,
                pattern: '/ppap',
                beforeEnter: checkUserRight(UsergroupRight.UR_PPAP_PPAP),
                onEnter: onEnter('ppapStore'),
                onExit: onExit('ppapStore'),
                isNavBar: true,
            },
            {
                name: 'ppaporder',
                label: 'PPAP_ORDER',
                userRight: UsergroupRight.UR_PPAP_PPAP,
                pattern: '/ppap/:ppapno',
                beforeEnter: checkUserRight(UsergroupRight.UR_PPAP_PPAP, 'ppapStore'),
                onEnter: onEnter('ppapStore'),
                onExit: onExit('ppapStore'),
            },
            {
                name: 'ppapreport',
                pattern: '/ppapreport/:ppapno',
                label: '',
                beforeEnter: checkUserRight(UsergroupRight.UR_PPAP_PPAP, 'ppapStore'),
                onEnter: onEnter('ppapStore'),
                onExit: onExit('ppapStore'),
            },
        ],
    },
    {
        name: '_audit',
        label: 'AUDIT',
        userRight: UsergroupRight.UR_AUDIT_SIDEBAR,
        pattern: '',
        icon: 'eye',
        isNavBar: true,
        children: [
            {
                name: 'audittemplatetable',
                label: 'AUDIT_TEMPLATES',
                userRight: UsergroupRight.UR_AUDIT_TEMPLATE,
                pattern: '/audittemplate',
                beforeEnter: checkUserRight(UsergroupRight.UR_AUDIT_TEMPLATE),
                onEnter: onEnter('auditTemplateStore'),
                onExit: onExit('auditTemplateStore'),
                isNavBar: true,
            },
            {
                name: 'audittemplate',
                label: 'AUDIT_TEMPLATE',
                pattern: '/audittemplate/:audittemplate',
                beforeEnter: checkUserRight(UsergroupRight.UR_AUDIT_TEMPLATE),
                onEnter: onEnter('auditTemplateStore'),
                onExit: onExit('auditTemplateStore'),
            },
            {
                name: 'audittemplatequestion',
                pattern: '/audittemplate/:audittemplate/:questionno',
                label: 'AUDIT_TEMPLATE_QUESTION',
                beforeEnter: checkUserRight(UsergroupRight.UR_AUDIT_TEMPLATE),
                onEnter: onEnter('auditTemplateStore'),
                onExit: onExit('auditTemplateStore'),
            },
            //
            {
                name: 'audittable',
                label: 'AUDITS',
                userRight: UsergroupRight.UR_AUDIT_AUDIT,
                pattern: '/audit',
                beforeEnter: checkUserRight(UsergroupRight.UR_AUDIT_AUDIT),
                onEnter: onEnter('auditStore'),
                onExit: onExit('auditStore'),
                isNavBar: true,
            },
            {
                name: 'audit',
                label: 'AUDIT',
                pattern: '/audit/:auditno',
                beforeEnter: checkUserRight(UsergroupRight.UR_AUDIT_AUDIT, 'auditStore'),
                onEnter: onEnter('auditStore'),
                onExit: onExit('auditStore'),
            },

            {
                name: 'auditreport',
                pattern: '/auditreport/:auditno',
                label: '',
                beforeEnter: checkUserRight(UsergroupRight.UR_AUDIT_AUDIT, 'auditStore'),
                onEnter: onEnter('auditStore'),
                onExit: onExit('auditStore'),
            },
            {
                name: 'auditcollectonline',
                pattern: '/auditcollectonline/:auditno',
                label: 'DATACOLLECT',
                beforeEnter: checkUserRight(UsergroupRight.UR_AUDIT_AUDIT, 'auditStore'),
                onEnter: onEnter('auditStore'),
                onExit: onExit('auditStore'),
            },
            {
                name: 'auditcollectoffline',
                pattern: '/auditcollectoffline/:auditno',
                label: 'DATACOLLECT',
                beforeEnter: checkUserRight(UsergroupRight.UR_AUDIT_AUDIT, 'auditOfflineStore'),
                onEnter: onEnter('auditOfflineStore'),
                onExit: onExit('auditOfflineStore'),
            },
            {
                name: 'auditcollectcollectonline',
                pattern: '/auditcollectcollectonline/:auditno',
                label: 'DATACOLLECT',
                beforeEnter: checkUserRight(UsergroupRight.UR_AUDIT_AUDIT, 'auditStore'),
                onEnter: onEnter('auditStore'),
                onExit: onExit('auditStore'),
            },
            {
                name: 'auditcollectcollectoffline',
                pattern: '/auditcollectcollectoffline/:auditno',
                label: 'DATACOLLECT',
                beforeEnter: checkUserRight(UsergroupRight.UR_AUDIT_AUDIT, 'auditOfflineStore'),
                onEnter: onEnter('auditOfflineStore'),
                onExit: onExit('auditOfflineStore'),
            },
        ],
    },
    {
        name: '_inspection',
        label: 'INSPECTION',
        userRight: UsergroupRight.UR_INSPECTION_SIDEBAR,
        pattern: '',
        icon: 'clipboard-check',
        isNavBar: true,
        children: [
            {
                name: 'inspectionplantable',
                label: 'INSPECTION_PLANS',
                userRight: UsergroupRight.UR_INSPECTION_PLAN,
                pattern: '/inspectionplan',
                beforeEnter: checkForUserSignedIn,
                onEnter: onEnter('inspectionPlanStore'),
                onExit: onExit('inspectionPlanStore'),
                isNavBar: true,
            },
            {
                name: 'inspectionplan',
                label: 'INSPECTION_PLAN',
                pattern: '/inspectionplan/:planno',
                beforeEnter: checkForUserSignedIn,
                onEnter: onEnter('inspectionPlanStore'),
                onExit: onExit('inspectionPlanStore'),
            },
            {
                name: 'inspectionplanquestion',
                pattern: '/inspectionplan/:planno/:questionno',
                label: 'INSPECTION_PLAN_QUESTION',
                beforeEnter: checkForUserSignedIn,
                onEnter: onEnter('inspectionPlanStore'),
                onExit: onExit('inspectionPlanStore'),
            },
            {
                name: 'inspectiontable',
                label: 'INSPECTIONS',
                userRight: UsergroupRight.UR_INSPECTION_INSPECTION,
                pattern: '/inspection',
                beforeEnter: checkUserRight(UsergroupRight.UR_INSPECTION_INSPECTION),
                onEnter: onEnter('inspectionStore'),
                onExit: onExit('inspectionStore'),
                isNavBar: true,
            },
            {
                name: 'inspection',
                label: 'INSPECTION',
                pattern: '/inspection/:inspectionno',
                beforeEnter: checkUserRight(UsergroupRight.UR_INSPECTION_INSPECTION, 'inspectionStore'),
                onEnter: onEnter('inspectionStore'),
                onExit: onExit('inspectionStore'),
            },
            {
                name: 'inspectionquestion',
                pattern: '/inspectionquestion/:inspectionno/:questionno',
                label: 'INSPECTION_PLAN_QUESTIONS',
                beforeEnter: checkUserRight(UsergroupRight.UR_INSPECTION_INSPECTION, 'inspectionStore'),
                onEnter: onEnter('inspectionStore'),
                onExit: onExit('inspectionStore'),
            },
            {
                name: 'inspectionreport',
                pattern: '/inspectionreport/:inspectionno',
                label: '',
                beforeEnter: checkUserRight(UsergroupRight.UR_INSPECTION_INSPECTION, 'inspectionStore'),
                onEnter: onEnter('inspectionStore'),
                onExit: onExit('inspectionStore'),
            },
            {
                name: 'inspectioncollect',
                pattern: '/inspectioncollect/:inspectionno',
                label: 'DATACOLLECT',
                beforeEnter: checkUserRight(UsergroupRight.UR_INSPECTION_INSPECTION, 'inspectionStore'),
                onEnter: onEnter('inspectionStore'),
                onExit: onExit('inspectionStore'),
            },
        ],
    },
    {
        name: '_gemba',
        label: 'GEMBA',
        userRight: UsergroupRight.UR_GEMBA_SIDEBAR,
        pattern: '',
        icon: 'walking',
        isNavBar: true,
        children: [
            /* {
                 name: 'gembatemplatetable',
                 label: 'GEMBA_TEMPLATES',
                 userRight: UsergroupRight.UR_GEMBA_GEMBA,
                 pattern: '/gembatemplate',
                 beforeEnter: checkUserRight(UsergroupRight.UR_GEMBA_GEMBA),
                 onEnter: onEnter('gembaTemplateStore'),
                 onExit: onExit('gembaTemplateStore'),
                 isNavBar: true
             },
             {
                 name: 'gembatemplate',
                 label: 'GEMBA_TEMPLATE',
                 userRight: UsergroupRight.UR_GEMBA_GEMBA,
                 pattern: '/gembatemplate/:gembatemplate',
                 beforeEnter: checkUserRight(UsergroupRight.UR_GEMBA_GEMBA),
                 onEnter: onEnter('gembaTemplateStore'),
                 onExit: onExit('gembaTemplateStore'),
             },*/
            {
                name: 'gembatable',
                label: 'GEMBAS',
                userRight: UsergroupRight.UR_GEMBA_GEMBA,
                pattern: '/gemba',
                beforeEnter: checkUserRight(UsergroupRight.UR_GEMBA_GEMBA),
                onEnter: onEnter('gembaStore'),
                onExit: onExit('gembaStore'),
                isNavBar: true,
            },
            {
                name: 'gemba',
                label: 'GEMBA',
                userRight: UsergroupRight.UR_GEMBA_GEMBA,
                pattern: '/gemba/:gembano',
                beforeEnter: checkUserRight(UsergroupRight.UR_GEMBA_GEMBA, 'gembaStore'),
                onEnter: onEnter('gembaStore'),
                onExit: onExit('gembaStore'),
            },
            {
                name: 'gembareport',
                pattern: '/gembareport/:gembano',
                label: '',
                beforeEnter: checkUserRight(UsergroupRight.UR_GEMBA_GEMBA, 'gembaStore'),
                onEnter: onEnter('gembaStore'),
                onExit: onExit('gembaStore'),
            },
        ],
    },
    {
        name: '_action',
        label: 'ACTION',
        userRight: UsergroupRight.UR_TASK_SIDEBAR,
        pattern: '',
        icon: 'tasks',
        isNavBar: true,
        children: [
            {
                name: 'actiontable',
                label: 'ACTIONS',
                userRight: UsergroupRight.UR_TASK_TASK,
                pattern: '/action',
                beforeEnter: checkUserRight(UsergroupRight.UR_TASK_TASK),
                onEnter: onEnter('actionStore'),
                onExit: onExit('actionStore'),
                isNavBar: true,
            },
            {
                name: 'action',
                label: 'ACTION',
                userRight: UsergroupRight.UR_TASK_TASK,
                pattern: '/action/:no',
                beforeEnter: checkUserRight(UsergroupRight.UR_TASK_TASK, 'actionStore'),
                onEnter: onEnter('actionStore'),
                onExit: onExit('actionStore'),
            },
        ],
    },
    {
        name: '_activity',
        label: 'OFFLINE_ACTIVITIES',
        pattern: '',
        icon: 'chart-network',
        userRight: UsergroupRight.UR_ACTIVITY_SIDEBAR,
        isNavBar: true,
        children: [
            {
                name: 'activitytable',
                label: 'OFFLINE_ACTIVITIES',
                pattern: '/activity',
                userRight: UsergroupRight.UR_ACTIVITY_ACTIVITIES,
                beforeEnter: checkUserRight(UsergroupRight.UR_ACTIVITY_ACTIVITIES, 'activityStore'),
                onEnter: onEnter('activityStore'),
                onExit: onExit('activityStore'),
                isNavBar: true,
            },
        ],
    },
    {
        name: '_mobile',
        label: 'MOBILE',
        icon: 'mobile',
        pattern: '',
        isNavBar: true,
        userRight: UsergroupRight.UR_MOBILE_SIDEBAR,
        children: [
            {
                name: 'mobilelist',
                label: 'MOBILE_ACTIVITIES',
                pattern: '/mobile',
                userRight: UsergroupRight.UR_MOBILE_ACTIVITIES,
                beforeEnter: checkUserRight(UsergroupRight.UR_MOBILE_ACTIVITIES, 'mobileStore'),
                onEnter: onEnter('mobileStore'),
                onExit: onExit('mobileStore'),
                isNavBar: true,
            },
            {
                name: 'mobilegemba',
                label: 'GEMBA',
                pattern: '/mobilegemba/:gembano',
                beforeEnter: checkUserRight(UsergroupRight.UR_GEMBA_GEMBA),
                onEnter: onEnter('mobileGembaStore'),
                onExit: onExit('mobileGembaStore'),
            },
        ],
    },

    {
        name: 'test',
        label: 'TEST',
        pattern: '/test',
    },
    {
        name: 'nopermission',
        label: '',
        pattern: '/nopermission',
    },
    {
        name: 'test2',
        label: 'TEST',
        pattern: '/test2/:p',
        onEnter: (fromState, toState) => {
            console.log(' enter', toState);
            return Promise.resolve();
        },
        onExit: (fromState, toState) => {
            console.log(' exit', toState);
            return Promise.resolve();
        },
    },
];

/** Used by the routerStore
 * while navRoutes is a tree, which you can see in the sidebar.
 * routes is a linear list used by the router.
 */
export const routerRoutes: INavRoute[] = routes.reduce((r: INavRoute[], route: INavRoute) => {
    if (route.children) {
        route.children.forEach((childroute: any) => {
            r.push(childroute);
        });
    } else {
        r.push(route);
    }
    return r;
}, []);
