import { BaseStore, IBaseStore } from './base-store';
import { IRootStore } from '../routes/root-store';
import { auditTemplateColumns, IAuditTemplate } from '../models/AuditTemplate';
import { action, observable } from 'mobx';
import { IDataset } from '../components/dataset/IDataset';
import { auditTemplateQuestionColumns, IAuditTemplateQuestion } from '../models/AuditTemplateQuestion';
import { Dataset } from '../components/dataset/dataset';
import { RouterState } from 'mobx-state-router';
import * as R from 'ramda';
import { decodeParams } from '../components/lib/encode-decode-params';

export interface IAuditTemplateStore extends IBaseStore<IAuditTemplate> {
    dsQuestion: IDataset<IAuditTemplateQuestion>;
}

export class AuditTemplateStore extends BaseStore<IAuditTemplate> {
    @observable
    dsQuestion: IDataset<IAuditTemplateQuestion>;

    @action.bound
    dsQuestionBeforeInsert(ds: IDataset<IAuditTemplateQuestion>) {
        let nextValue = ds.data.length;
        ds.actual.questionno = nextValue.toString().padStart(4, '0');
    }

    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/audittemplate/', auditTemplateColumns);
        this.dsQuestion = new Dataset<IAuditTemplateQuestion>('/gridApi/audittemplatequestion/', auditTemplateQuestionColumns);
        this.dsQuestion.setMasterSource(this.ds, [{ field: 'audittemplate', masterField: 'audittemplate' }]);
        this.dsQuestion.onAfterInsert = this.dsQuestionBeforeInsert;
    }

    @action.bound
    async openDetails() {
        await this.dsQuestion.open();
    }

    @action.bound
    closeDetails() {
        this.dsQuestion.close();
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        if (toState.routeName === 'audittemplatetable') {
            await this.open();
            if (fromState.routeName === 'audittemplate') {
                this.ds.locate(this.ds.pkFields, fromState.params);
            }
        } else if (toState.routeName === 'audittemplate') {
            await this.open(decodeParams(toState.params));

            await this.openDetails();
            if (fromState.routeName === 'audittemplatequestion') {
                this.dsQuestion.locate(this.dsQuestion.pkFields, fromState.params);
            }
        } else if (toState.routeName === 'audittemplatequestion') {
            await this.open(R.pick(['audittemplate'], toState.params) as any);
            await this.openDetails();
            //this.ds.locate(this.ds.pkFields, toState.params);
            this.dsQuestion.locate(this.dsQuestion.pkFields, toState.params);
            /*this.dsQuestion.edit();*/
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'audittemplatequestion') {
            this.closeDetails();
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'audittemplate') {
            this.closeDetails();
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'audittemplatetable') {
            this.close();
            await Promise.resolve();
        }
    }
}
