//import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { Button } from 'reactstrap';

export const RoundHeaderBtn = styled(Button)`
    flex: 1 1 auto;
    display: inline-block;
    height: 2rem;
    width: 2rem;
    margin-top: -8px;
    margin-bottom: -7px;
    padding: 0;
    margin-left: 0.25rem; // Abstand zum nächtsten Btn
    border: 0;
    text-align: center;
    vertical-align: middle;
    border-radius: 1rem;
    background-color: ${(props) => props.theme.cardHeaderButtonBg};
    color: ${(props) => props.theme.cardHeaderColor};
`;

export const RoundGridBtn = styled(Button)`
    flex: 1 1 auto;
    display: inline-block;
    height: 2rem;
    width: 2rem;
    margin-top: -8px;
    margin-bottom: -7px;
    padding: 0;
    //margin-left: 0.25rem; // Abstand zum nächtsten Btn
    //border: 0;
    text-align: center;
    vertical-align: middle;
    border-radius: 1rem;
    background-color: ${(props) => props.theme.cardGridButtonBg};
    color: ${(props) => props.theme.cardGridButtonColor};
    border-color: ${(props) => props.theme.cardGridButtonBg};

    :hover {
        background-color: ${(props) => darken(0.1, props.theme.cardGridButtonBg)};
    }

    :disabled {
        background-color: ${(props) => props.theme.cardGridButtonBg};
    }

    :focus {
        background-color: ${(props) => props.theme.cardGridButtonBg};
    }

    .active {
        background-color: ${(props) => props.theme.cardGridButtonBg};
    }
`;
