import * as React from 'react';
import { CSVReader } from 'react-papaparse';
import { IconButton } from '../styled/icon-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBaseView } from '../base-view';
import { UncontrolledTooltip } from 'reactstrap';
import { inject, observer } from 'mobx-react';

const buttonRef: any = React.createRef();

interface ICsvButtonUpload extends IBaseView {
    onUpload: (rawdata: any) => void;
}

@inject('rootStore')
@observer
export class CsvButtonUpload extends React.Component<ICsvButtonUpload, {}> {
    handleOpenDialog = (e: any) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    handleOnError = (err: any /*, file, inputElem, reason*/) => {
        console.log(err);
    };

    handleOnRemoveFile = (data: any) => {
        console.log('---------------------------');
        console.log(data);
        console.log('---------------------------');
    };

    handleRemoveFile = (e: any) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e);
        }
    };

    render() {
        const {
            onUpload,
            rootStore: {
                langStore: { lang },
            },
        } = this.props;
        return (
            <CSVReader
                ref={buttonRef}
                onFileLoad={onUpload}
                onError={this.handleOnError}
                config={{ delimiter: ';' }}
                noClick
                noDrag
                onRemoveFile={this.handleOnRemoveFile}
                style={{ display: 'flex' }}
            >
                {() => (
                    <>
                        <IconButton id="id_csv_upload" type="button" onClick={this.handleOpenDialog}>
                            <FontAwesomeIcon icon={['fas', 'file-import']} />
                        </IconButton>
                        <UncontrolledTooltip placement="bottom-end" target="id_csv_upload">
                            {lang.IMPORT}
                        </UncontrolledTooltip>
                    </>
                )}
            </CSVReader>
        );
    }
}
