import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../_base/base-view';
import { action } from 'mobx';
import { TableForm } from '../../_base/styled/table-form';
import { DetailOneColLayout } from '../../_base/styled/layout/detail-one-col-layout';
import { DbField } from '../../../components/dbform/db-field';
import { Col, Row } from 'reactstrap';
import { Logo } from '../../_base/logo/logo';
import { CardWithTab, ITab } from '../../_base/styled/card-with-tab';
import { DbGrid } from '../../../components/dbgrid/db-grid';

@inject('rootStore')
@observer
export class SupplierDetailView extends React.Component<IBaseView, {}> {
    @action.bound
    async gotoProject() {
        const {
            rootStore: {
                routerStore,
                supplierStore: { dsProject },
            },
        } = this.props;
        await routerStore.goTo('project', { projectno: dsProject.actual.projectno });
    }

    @action.bound
    gotoUser() {}

    getTabs = (): ITab[] => {
        const {
            rootStore: {
                supplierStore: { dsProject, dsUser },
            },
        } = this.props;
        return [
            {
                title: 'PROJECTS',
                icon: 'project-diagram',
                content: (
                    <div style={{ position: 'relative', height: '100%' }}>
                        <DbGrid ds={dsProject} onRowDoubleClicked={this.gotoProject} />
                    </div>
                ),
            },
            {
                title: 'USERS',
                icon: 'user',
                content: (
                    <div style={{ position: 'relative', height: '100%' }}>
                        <DbGrid ds={dsUser} onRowDoubleClicked={this.gotoUser} />
                    </div>
                ),
            },
        ];
    };

    render() {
        const {
            rootStore: {
                supplierStore: { ds, uploadLogo, uploadIcon, iconUrl, logoUrl },
                langStore: { lang },
            },
        } = this.props;
        return (
            <DetailOneColLayout
                topElement={
                    <TableForm icon="database" title="SUPPLIER" ds={ds} keyTitle={ds.actual?.supplierno + ' - ' + ds.actual?.supplier1}>
                        <Row>
                            <Col>
                                <Row>
                                    <Col md={6}>
                                        <DbField ds={ds} field="supplierno" />
                                    </Col>
                                    <Col md={6}>
                                        <DbField ds={ds} field={'supplier_code'} />
                                    </Col>
                                </Row>
                                <DbField ds={ds} field="supplier1" />
                                <DbField ds={ds} field="supplier2" />
                                <DbField ds={ds} field="street" />
                                <Row>
                                    <Col md={2}>
                                        <DbField ds={ds} field="postcode" />
                                    </Col>
                                    <Col md={10}>
                                        <DbField ds={ds} field="city" />
                                    </Col>
                                </Row>
                                <DbField ds={ds} field="country" />
                                <Row>
                                    <Col>
                                        <DbField ds={ds} field="description1" />
                                    </Col>
                                    <Col>
                                        <DbField ds={ds} field="description2" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Logo
                                            label="Icon (Project)"
                                            src={iconUrl}
                                            ds={ds}
                                            fieldName="icon"
                                            uploadFile={uploadIcon}
                                            lang={lang}
                                            width={32}
                                            height={32}
                                        />
                                        <br />
                                        <Logo
                                            label="Logo (Report)"
                                            src={logoUrl}
                                            ds={ds}
                                            fieldName="logo"
                                            uploadFile={uploadLogo}
                                            lang={lang}
                                            width={300}
                                            height={150}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </TableForm>
                }
                mainElement={<CardWithTab tabs={this.getTabs()} />}
            />
        );
    }
}
