import * as React from 'react';
import styled from 'styled-components';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBaseView } from '../../_base/base-view';
import { cl_black, cl_gray600, cl_green, cl_red, cl_white } from '../../_theme/colors';
import { IInspectionQuestion } from '../../../models/InspectionQuestion';
import { InspectionCollectRating } from './inspection-collect-rating';
import { InspectionCollectImages } from './inspection-collect-images';
import { DbField } from '../../../components/dbform/db-field';
import { InspectionCollectInfo } from './inspection-collect-info';

interface IInspectionCollectQuestion extends IBaseView {
    question: IInspectionQuestion;
    cursor: boolean;
}

@inject('rootStore')
@observer
class _InspectionCollectQuestion extends React.Component<IInspectionCollectQuestion, {}> {
    onClick = async () => {
        const {
            rootStore: {
                inspectionStore: { dsInspectionQuestion },
            },
            question,
        } = this.props;
        //console.log(dsAuditQuestion.actual.questionno, question.questionno)
        if (dsInspectionQuestion.actual.questionno !== question.questionno) {
            await dsInspectionQuestion.post();
            await dsInspectionQuestion.locate(['questionno'], { questionno: question.questionno });
            dsInspectionQuestion.edit();
        }
    };

    render() {
        const {
            rootStore: {
                inspectionStore: { dsInspectionQuestion },
                langStore: { lang },
            },
            className,
            question,
        } = this.props;
        return (
            <Card className={className} onClick={this.onClick}>
                <CardHeader className={'qheader'}>
                    <div className="left">{question.question}&nbsp;</div>
                    <div className="right">
                        <Button>
                            {question.notapplicable ? (
                                lang['N_A']
                            ) : question.score === -1 ? (
                                ''
                            ) : question.score === 10 ? (
                                <FontAwesomeIcon icon="check" />
                            ) : question.score === 0 ? (
                                <FontAwesomeIcon icon="times" />
                            ) : question.score === -2 ? (
                                'n.a.'
                            ) : (
                                question.score
                            )}
                        </Button>
                    </div>
                </CardHeader>
                <CardBody className={'qbody'}>
                    <Row>
                        <Col sm={7} md={7} lg={7} xl={3}>
                            <InspectionCollectRating />
                        </Col>
                        <Col sm={5} md={5} lg={5} xl={3} style={{ paddingLeft: 0 }}>
                            <InspectionCollectImages />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InspectionCollectInfo />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={6}>
                            <DbField ds={dsInspectionQuestion} field="answer" />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export const InspectionCollectQuestion = styled(_InspectionCollectQuestion)`
  margin-bottom:1rem; 
  border-top:0; 
  box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.45);
  
  .qheader{
    background-color: ${(props) => props.theme.bg};
    color: ${cl_black};
   
    display:flex;
    padding-right:0.2rem;
    border:0;
    
     .left{
       flex:1 1 auto;
       font-size:1rem;
       font-weight: 500;
     }
     
     .right{
        color:${cl_black}
        flex:0 0 2rem;
        font-size:1rem;
        font-weight: 500;
        .btn{
            padding:0.375rem;
            margin-top: -6px;
            margin-bottom: -6px;
            width:2.5rem;
            height:2.5rem;
            border-radius:2rem;
            border:0px;
            background-color: ${(props) =>
                props.question.score === 10
                    ? cl_green
                    : props.question.score === 0
                    ? cl_red
                    : props.question.score === -2
                    ? cl_gray600
                    : props.theme.bg};
            color:${(props) =>
                props.question.score === 10 ? cl_white : props.question.score === 0 ? cl_white : props.question.score === -2 ? cl_white : cl_black}
        }
     }
  }
  
  .qbody{
    display:${(props) => (props.cursor ? 'block' : 'none')};
    
    .not-applicable{
     
    }
  }
    
  
`;
