import { BaseStore, IBaseStore } from './base-store';
import { gembaColumns, IGemba } from '../models/Gemba';
import { IDataset } from '../components/dataset/IDataset';
import { IRootStore } from '../routes/root-store';
import { gembaFindingColumns, IGembaFinding } from '../models/GembaFinding';
import { action, computed, observable, runInAction } from 'mobx';
import { Dataset } from '../components/dataset/dataset';
import { RouterState } from 'mobx-state-router';
import * as R from 'ramda';
import { gembaFindingImage, IGembaFindingImage } from '../models/GembaFindingImage';
import { ActionTyp, gembaActionColumns, IAction } from '../models/Action';
import axios from 'axios';
import moment from 'moment';
import { IProject, projectColumns } from '../models/Project';
import { Status, STATUS_ALL } from '../models/Status';
import { customerColumns, ICustomer } from '../models/Customer';
import { resizeImage } from '../lib/resize-image';
import { IField } from '../components/dataset/IField';
import { catalogColumns } from '../models/Catalog';
import { includes } from '../components/lib/Includes';
import { authorizer } from '../components/dataset/authorizer';

export interface IGembaStore extends IBaseStore<IGemba> {
    ds: IDataset<IGemba>;
    dsGembaFinding: IDataset<IGembaFinding>;
    dsImage: IDataset<IGembaFindingImage>;
    dsReportImages: IDataset<IGembaFindingImage>;
    dsAction: IDataset<IAction>;
    dsProject: IDataset<IProject>;
    dsCustomer: IDataset<ICustomer>;
    uploadFile: (acceptFile: any[], finding: IGembaFinding) => Promise<void>;
    saveResponsible: string;
    statusOptionsFunc: () => number[];
}

export class GembaStore extends BaseStore<IGemba> implements IGembaStore {
    /**
     /**
     *  dient als Zwischenspeicher des aktuellen Owners, wenn Form in Edit Mode geht und der Owner geändert wird.
     */
    saveResponsible: string;

    @observable
    dsGembaFinding: IDataset<IGembaFinding>;

    @observable
    dsImage: IDataset<IGembaFindingImage>;

    @observable
    dsReportImages: IDataset<IGembaFindingImage>;

    @observable
    dsAction: IDataset<IAction>;

    @observable
    dsProject: IDataset<IProject>;

    @observable
    dsCustomer: IDataset<ICustomer>;

    @observable
    actionByFindingFilter: boolean = true;

    /**
     *  Zwischenspeicher bei neuem Finding
     *  onBefore werden sie gesetzt
     *  onAfter werden sie benutzt
     */
    saveArea: string;
    saveElement: string;

    /**
     * Wenn Gemba geöffnet wird müssen Area und Element auf die konkreten Kataloge angepasst werden
     * Das passiert hier.
     */
    gembaFindingCatalogColumns = (): IField<IGembaFinding>[] => {
        return R.clone(gembaFindingColumns).map((field) => {
            if (field.fieldName === 'area' && this.ds.actual.area_cat) {
                return {
                    fieldName: 'area',
                    label: 'GEMBA_AREA',
                    dataType: 'string',
                    maxLength: 80,
                    insert: 'show',
                    grid: {
                        width: 80,
                    },
                    input: 'selectdlg',
                    selectdlg: {
                        url: '/gridApi/catalogdetail/?catalogno=' + this.ds.actual.area_cat,
                        columns: catalogColumns.filter((field) => includes(['description1'], field.fieldName)),
                        field: 'description1',
                    },
                };
            } else if (field.fieldName === 'element' && this.ds.actual.element_cat) {
                return {
                    fieldName: 'element',
                    label: 'AUDIT_ELEMENT',
                    dataType: 'string',
                    maxLength: 80,
                    //insert: 'show',
                    hide: 'always',
                    grid: {
                        width: 80,
                    },
                    input: 'selectdlg',
                    selectdlg: {
                        url: '/gridApi/catalogdetail/?catalogno=' + this.ds.actual.element_cat,
                        columns: catalogColumns.filter((field) => includes(['description1'], field.fieldName)),
                        field: 'description1',
                    },
                };
            } else {
                return field;
            }
        });
    };

    @action.bound
    dsdsGembaFindingOnBeforeInsert(ds: IDataset<IGembaFinding>) {
        if (ds.cursor !== undefined) {
            this.saveArea = ds.actual.area;
            this.saveElement = ds.actual.element;
        }
    }

    @action.bound
    dsdsGembaFindingOnAfterInsert(ds: IDataset<IGembaFinding>) {
        let mx = ds.data.reduce((max: number, record) => {
            if (parseInt(record.findingno) + 1 > max) {
                max = parseInt(record.findingno) + 1;
            }
            return max;
        }, 0);
        if (mx === 0) {
            mx = 1;
        }
        ds.actual.findingno = mx.toString().padStart(4, '0');
        ds.actual.area = this.saveArea;
        ds.actual.element = this.saveElement;
        ds.actual.posneg = 'negative';
    }

    @action.bound
    dsActionOnAfterInsert(ds: IDataset<IAction>) {
        const {
            authStore: { username },
        } = this.rootStore;
        ds.actual.typ = ActionTyp.GEMBATask;
        ds.actual.createdby = username;
        ds.actual.taskowner = username;
        ds.actual.createdat = moment().format();
        ds.actual.key1 = this.dsGembaFinding.actual.gembano;
        ds.actual.key2 = this.dsGembaFinding.actual.findingno;
        // spezielle Logik hier beim Gemba
        ds.actual.keyword = this.dsGembaFinding.actual.keyword;
    }

    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/gemba/', R.clone(gembaColumns));
        //
        // StatusColumn im Filter anpassen.
        let status = this.cdsFilter.columns.find((column) => column.fieldName === 'status');
        status.options = [STATUS_ALL, Status.PLANNED, Status.INPROGRESS, Status.COMPLETED];
        status.defaultValue = STATUS_ALL;
        //
        this.dsGembaFinding = new Dataset<IGembaFinding>('/gridApi/gembafinding/', gembaFindingColumns);
        this.dsGembaFinding.setMasterSource(this.ds, [{ field: 'gembano', masterField: 'gembano' }]);
        this.dsGembaFinding.onBeforeInsert = this.dsdsGembaFindingOnBeforeInsert;
        this.dsGembaFinding.onAfterInsert = this.dsdsGembaFindingOnAfterInsert;

        this.dsAction = new Dataset<IAction>('/gridApi/action/', R.clone(gembaActionColumns), { typ: ActionTyp.GEMBATask });
        this.dsAction.setMasterSource(this.dsGembaFinding, this.actionFilter);
        this.dsAction.onAfterInsert = this.dsActionOnAfterInsert;

        this.dsImage = new Dataset<IGembaFindingImage>('/gridApi/gembafindingimage/', gembaFindingImage);
        this.dsImage.setMasterSource(this.dsGembaFinding, [
            {
                field: 'gembano',
                masterField: 'gembano',
            },
            {
                field: 'findingno',
                masterField: 'findingno',
            },
        ]);

        this.dsProject = new Dataset<IProject>('/gridApi/project/', projectColumns);
        this.dsProject.setMasterSource(this.ds, [
            {
                field: 'projectno',
                masterField: 'projectno',
            },
        ]);
        this.dsCustomer = new Dataset<ICustomer>('/gridApi/customer/', customerColumns);
        this.dsCustomer.setMasterSource(this.dsProject, [
            {
                field: 'customerno',
                masterField: 'customerno',
            },
        ]);

        this.dsReportImages = new Dataset<IGembaFindingImage>('/gridApi/gembafindingimage/', gembaFindingImage);
        this.dsReportImages.setMasterSource(this.dsGembaFinding, [
            {
                field: 'gembano',
                masterField: 'gembano',
            },
        ]);
    }

    @action.bound
    async openDetails() {
        this.saveResponsible = '';
        this.dsGembaFinding.columns = this.gembaFindingCatalogColumns();
        await this.dsProject.open();
        await this.dsCustomer.open();
        await this.dsGembaFinding.open();

        // hier muss für das responsible Feld die Url: /gridapi/projectmember/<projectno> gesetzt werden.
        // Da columns im constructor gecloned sind, dürfte das keine Seiteneffekte haben
        const responsible = this.dsAction.columns.find((column) => column.fieldName === 'responsible');
        responsible.selectdlg.url = '/gridApi/projectmember/?projectno=' + this.dsProject.actual.projectno;
        responsible.selectdlg.InserRight = undefined;
        await this.dsAction.open();
        await this.dsImage.open();
        this.saveArea = '';
        this.saveElement = '';
    }

    @action.bound
    closeDetails() {
        this.dsImage.close();
        this.dsAction.close();
        this.dsGembaFinding.close();
        this.dsCustomer.close();
        this.dsProject.close();
        this.saveResponsible = '';
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        switch (toState.routeName) {
            case 'gembatable':
                await this.loadFilter('gemba');
                await this.open(this.cdsFilter.actual);
                break;

            case 'gemba':
                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                await this.openDetails();
                await this.dsReportImages.open();
                // aus Richtung task komment:
                if (toState.queryParams?.findingno) {
                    this.dsGembaFinding.locate(['findingno'], { findingno: toState.queryParams.findingno });
                }
                break;

            case 'gembareport':
                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                await this.openDetails();
                await this.dsReportImages.open();
                break;

            case 'gembacollectonline':
                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                await this.openDetails();
                await this.dsReportImages.open();
                break;
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        switch (fromState.routeName) {
            case 'gemba':
            case 'gembareport':
            case 'gembacollectonline':
                this.dsReportImages.close();
                this.closeDetails();
                this.close();
                await Promise.resolve();
                break;

            case 'gembatable':
                this.close();
                await Promise.resolve();
                break;
        }
    }

    @computed
    get actionFilter() {
        if (this.actionByFindingFilter) {
            return [
                {
                    field: 'key1',
                    masterField: 'gembano',
                },
                {
                    field: 'key2',
                    masterField: 'findingno',
                },
            ];
        } else {
            return [
                {
                    field: 'key1',
                    masterField: 'gembano',
                },
            ];
        }
    }

    @action.bound
    async uploadFile(acceptFile: any[], finding: IGembaFinding): Promise<void> {
        const url = '/gridApi/gembafindingimage/fileupload';
        let file: File = acceptFile[0];
        if (acceptFile[0].type === 'image/jpeg') {
            file = await resizeImage(file);
        }
        const formData = new FormData();
        formData.append('type', 'gembafindingimage');
        formData.append('file', file);
        formData.append('gembano', finding.gembano);
        formData.append('findingno', finding.findingno.toString());
        const config = authorizer();
        config.headers['content-type'] = 'multipart/form-data';
        await axios.post(url, formData, config);
        await this.dsImage.refresh(this.dsImage.cursor);
        await runInAction(async () => {
            this.dsImage.last();
        });
    }

    statusOptionsFunc = () => {
        const {
            authStore: { username },
        } = this.rootStore;
        if (username === this.dsProject.actual?.owner) {
            return [Status.PLANNED, Status.INPROGRESS, Status.COMPLETED, Status.NOTAPPROVED, Status.APPROVED];
        } else if (username === this.ds.actual?.responsible) {
            return [Status.PLANNED, Status.INPROGRESS, Status.COMPLETED];
        } else {
            return [];
        }
    };
}
