import * as React from 'react';

export const SidebarFooter = () => (
    <div className="footer">
        <div className="icon">
            <img src="/img/logo.png" alt="logo" />
        </div>
        <div className="label">
            <h5>qp|squid</h5>
            <p>+49 451 30 50 62 02 </p>
        </div>
    </div>
);
