import { BaseStore, IBaseStore } from './base-store';
import { IUser, userColumns } from '../models/User';
import { action, computed, observable, runInAction } from 'mobx';
import { RouterState } from 'mobx-state-router';
import { IDataset } from '../components/dataset/IDataset';
import axios from 'axios';
import { IProject, projectColumns } from '../models/Project';
import { IRootStore } from '../routes/root-store';
import { Dataset } from '../components/dataset/dataset';
import { IAction, projectActionColumns } from '../models/Action';
import { IProjectActivity, projectActivityColumns } from '../models/ProjectActivity';

export interface IUserStore extends IBaseStore<IUser> {
    pictureUrl: string;
    uploadPicture: (acceptFile: any[]) => Promise<void>;
    dsProject: IDataset<IProject>;
    dsActivity: IDataset<IProjectActivity>;
    dsAction: IDataset<IAction>;
}

export class UserStore extends BaseStore<IUser> implements IUserStore {
    @observable
    dsProject: IDataset<IProject>;

    @observable
    dsActivity: IDataset<IProjectActivity>;

    @observable
    dsAction: IDataset<IAction>;

    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/user/', userColumns);
        this.dsProject = new Dataset<IProject>('/gridApi/projectmembership/', projectColumns);
        //this.dsProject.setMasterSource(this.ds, [{field: 'owner', masterField: 'username'}]);

        this.dsActivity = new Dataset<IProjectActivity>('/gridApi/projectactivity/', projectActivityColumns);
        this.dsActivity.setMasterSource(this.ds, [{ field: 'responsible', masterField: 'username' }]);

        this.dsAction = new Dataset<IAction>('/gridApi/action/', projectActionColumns);
        this.dsAction.setMasterSource(this.ds, [{ field: 'responsible', masterField: 'username' }]);
    }

    @action.bound
    async openDetails() {
        this.dsProject.dataUrl = '/gridApi/projectmembership/' + this.ds.actual.username;
        await this.dsProject.open();
        await this.dsActivity.open();
        await this.dsAction.open();
    }

    @action.bound
    closeDetails() {
        this.dsAction.close();
        this.dsActivity.close();
        this.dsProject.close();
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        await this.initializeAsync();
        if (toState.routeName === 'usertable') {
            await this.open();
            if (fromState.routeName === 'user') {
                this.ds.locate(this.ds.pkFields, fromState.params);
            }
        } else if (toState.routeName === 'user') {
            await this.open(toState.params as any);
            await this.openDetails();
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'user') {
            this.closeDetails();
            this.close();
            Promise.resolve();
        } else if (fromState.routeName === 'usertable') {
            this.close();
            Promise.resolve();
        }
    }

    @action.bound
    async uploadPicture(acceptFile: any[]): Promise<void> {
        const url = '/gridApi/userpicture/upload';
        const file = acceptFile[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('username', this.ds.actual.username);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        await axios.post(url, formData, config);
        runInAction(async () => {
            this.ds.actual.picture = file.name;
        });
    }

    @computed
    get pictureUrl() {
        if (this.ds.actual?.picture) {
            return '/gridApi/userpicture/download/' + this.ds.actual?.picture;
        } else return '';
    }
}
