import * as React from 'react';
import { IBaseView } from '../../_base/base-view';
import { inject, observer } from 'mobx-react';
import { BarGraph } from '../../../components/graphic/bar-graph';
import { AuditScore } from '../../../models/AuditQuestion';

@inject('rootStore')
@observer
export class InspectionStatisticArea extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                inspectionStore: { getCatValue, dsInspectionQuestion },
            },
        } = this.props;
        const data = getCatValue(
            dsInspectionQuestion.data,
            'area',
            'score',
            (x: any) => x.score !== AuditScore.UNRATED && x.score !== AuditScore.NOT_APPLICABLE,
        );

        return (
            <div style={{ flex: '1 1 50%', height: '100%' }}>
                <BarGraph orientation={'horizontal'} title="" data={data} ratingmethod={200} />
            </div>
        );
    }
}
