import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../_base/base-view';
import { Col, Row } from 'reactstrap';
import { DbField } from '../../../components/dbform/db-field';
import { DetailOneColLayout } from '../../_base/styled/layout/detail-one-col-layout';
import { TableForm } from '../../_base/styled/table-form';

@inject('rootStore')
@observer
export class InspectionPlanQuestionView extends React.Component<IBaseView, {}> {
    render() {
        const {
            rootStore: {
                inspectionPlanStore: { dsQuestion },
            },
        } = this.props;
        return (
            <DetailOneColLayout
                topElement={
                    <TableForm icon="question" title="INSPECTION_PLAN_QUESTION" ds={dsQuestion}>
                        <Row>
                            <Col>
                                <DbField ds={dsQuestion} field="planno" />
                            </Col>
                            <Col>
                                <DbField ds={dsQuestion} field="questionno" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={dsQuestion} field="area" />
                                <DbField ds={dsQuestion} field="element" />
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={dsQuestion} field="question" />
                                <DbField ds={dsQuestion} field="requirements" />
                                <DbField ds={dsQuestion} field="mandatory" />
                            </Col>
                            <Col></Col>
                        </Row>
                    </TableForm>
                }
            />
        );
    }
}
