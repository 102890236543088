import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IBaseView } from '../../_base/base-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeaderUser } from './header-user';
import { HeaderLogin } from './header-login';
import { BaseTableFilterMenu } from '../../_base/table-filter/base-table-filter-menu';

@inject('rootStore')
@observer
export class Header extends React.Component<IBaseView, any> {
    render() {
        const {
            rootStore: {
                appStore: { toggleSidebar, headerTitle },
                authStore: { loggedIn },
                routerStore: {
                    routerState: { routeName },
                },
            },
        } = this.props;
        return (
            <div className="header">
                <div className="header-left">
                    <div className="toggle-button" onClick={toggleSidebar}>
                        <FontAwesomeIcon icon="bars" />
                    </div>
                </div>
                <div className="center">{headerTitle}</div>
                <div className="right">
                    <div className="inner">
                        {loggedIn ? <HeaderUser /> : <HeaderLogin />}
                        {routeName === 'mobilelist' ? (
                            <div style={{ marginLeft: '1rem', fontSize: '16px', display: 'inline' }}>
                                <BaseTableFilterMenu storeName={'mobileStore'} filtername={'mobile'} />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}
